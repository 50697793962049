import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { NavigationService } from './navigation.service';

@Injectable()
export class AdminGuardService implements CanActivate {

  constructor(private authService: AuthService, private nav: NavigationService) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (! this.authService.showAdminSection()) {
      this.nav.navigateTo(["/flyboard"]);
      return false;
    }

    const url: string = state.url;
    const userRole: string = this.authService.getUserRole();

    switch (userRole) {
      case "Vengreso Admin":
        if(! url.includes("vengreso-admin") && ! this.authService.onCompanyMasqueradeSession) {
          this.nav.navigateTo(["/vengreso-admin/users"]);
        }
        break;
      case "Global Admin":
      case "Reporting Admin":
      case "Group Admin":
        if(! url.includes("/admin")) {
          const companySlug = this.authService.getUserCompanySlug();
          this.nav.navigateTo(["/"+companySlug+"/admin/users"]);
        }
        break;
    
      default:
        this.nav.navigateTo(["/flyboard"]);
        break;
    }

    return true;
  }
}
