import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()

export class EventService {

    private shortcutCategories$ = new Subject();
    private addShortcutListener$ = new Subject();
    private addCategoryListener$ = new Subject();

    getShortcutCategories() {
        return this.shortcutCategories$;
    }

    updateShortcutCategories(data: any) {
        this.shortcutCategories$.next(data);
    }

    getAddShortcutListener() {
        return this.addShortcutListener$;
    }

    updateAddShortcutListener(data: any) {
        this.addShortcutListener$.next(data);
    }

    getAddCategoryListener() {
        return this.addCategoryListener$;
    }

    updateAddCategoryListener(data: any) {
        this.addCategoryListener$.next(data);
    }

}
