import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-in-error',
  templateUrl: './sign-in-error.component.html',
  styleUrls: ['./sign-in-error.component.scss']
})
export class SignInErrorComponent implements OnInit {
  adminEmail: string;
  supportEmail: string = 'heysupport@vengreso.com';

  constructor(
    public dialogRef: MatDialogRef<SignInErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  { 
    this.adminEmail = data?.admin_email;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
}
