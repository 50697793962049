import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { RouterModule } from "@angular/router";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { ScriptService } from "src/app/core/core-services/services/script.service";
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { PlansDetailsComponent } from "./plans-details.component";

@NgModule({
  declarations: [
    PlansDetailsComponent
  ],
  exports:[
    PlansDetailsComponent
  ],
  imports:[
    CommonModule,
    IconSpriteModule,
    MatCardModule
  ],
  providers:[
    DataService,
    StripeService,
    RouterModule,
    ScriptService
  ]
})
export class PlanDetailsModule{

}