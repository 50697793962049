export class ValidationConstants {
    public static EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    public static EMAIL_EXTRA_REGEX = /^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])*$/;
    public static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]/;
    public static ONLY_CHARCTER = /^[a-zA-Z]+$/;
    public static FILE_NAME = /^[a-zA-Z0-9_-]*$/;
    public static CHARACTER_NO_SPACE = /^[a-zA-Z0-9]*$/;
    public static NUMBER_ONLY = /^[0-9]+$/;
    public static PHONE_NUMBER_REGEX = /^((\d)(-?)|(\+?))+$/;
    public static CHARCTER_SPACE = /^[a-zA-Z_-]*$/;
    public static LETTERS_PLUS_SPACE = /^[a-zA-Z-']+( [a-zA-Z]+)*$/;
    public static NON_DIGIT = /^\D+$/;
    // tslint:disable-next-line:no-unused-expression
    // public static URL_PATTERN = /^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*$/;
}
