export const LICENSE_TYPES = [
    {id: 'freemium', label: 'Freemium'},
    {id: 'starter', label: 'Starter MTHLY'},
    {id: 'starter-yearly', label: 'Starter YRLY'},
    {id: 'growth', label: 'Growth MTHLY'},
    {id: 'growth-yearly', label: 'Growth YRLY'},
    {id: 'sales-pro-monthly', label: 'Sales Pro Individual MTHLY'},
    {id: 'sales-pro-yearly', label: 'Sales Pro Individual YRLY'},
    {id: 'pro-plan-teams-smb', label: 'Pro Plan Teams - SMB'},
    {id: 'pro-plan-teams-ent', label: 'Pro Plan Teams - ENT'}
];
