import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService, LocalStorage } from "ngx-webstorage";
import { distinctUntilChanged } from "rxjs/operators";

import { Router } from "@angular/router";
import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";
import { PlansConstants, RewardsConstants, getPlanId, getRewards } from "src/app/core/core-services/constants/plans.constants";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ShortcutService } from "src/app/core/core-services/services/shortcut.service";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { CategoryRenameComponent } from "../add-shortcut-category/category-rename/category-rename.component";
import { ShortcutCategoryService, SHORTCUT_CATEGORIES } from "src/app/core/core-services/services/shortcut-category.service";
@Component({
  selector: "app-duplicate-shortcut",
  templateUrl: "./duplicate-shortcut.component.html",
  styleUrls: ["./duplicate-shortcut.component.scss"]
})
export class DuplicateShortcutComponent implements OnInit {

  public form: FormGroup;
  @LocalStorage(SHORTCUT_CATEGORIES) categoryList: any[];
  filteredCategoryList: any;
  exactMatch: boolean = false
  editorConfig: any;
  editorApiKey: string;
  editorManager: any;
  isEditorLoaded: boolean;
  filterCtrl = new FormControl();
  @ViewChild("selectElem") selectElem: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    config: NgbPopoverConfig,
    private dataService: DataService,
    private shortcutService: ShortcutService,
    private notification: NotificationService,
    private extensionService: ExtensionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DuplicateShortcutComponent>,
    public dialog: DialogService,
    private storage: LocalStorageService,
    private shortcutCategoryService: ShortcutCategoryService
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
    this.editorConfig = this.shortcutService.getEditorConfig();
    this.form = this.fb.group({
      title: [this.data?.data.title, [Validators.required]],
      shortcut: [null, [Validators.required]],
      category_id: [this.data?.data.category_id, [Validators.required]],
      uploads: [this.data?.data.uploads],
      html: [this.data?.data.html, [Validators.required]]
    });

    this.filterCtrl.valueChanges
    .subscribe(() => {
      this.filterCategories();
    });

    this.getCategories();

    this.storage.observe(SHORTCUT_CATEGORIES)
    .pipe(
      distinctUntilChanged(
        (prev, curr) => prev.value === curr.value
      )
    )
    .subscribe((value) => {
      this.categoryList = value;
      this.setCategory();
    });

    this.setCategory();
  }

  flycutExistsSearch(value: any): any {
    this.dataService
    .flycutExistsSearch({ q: value })
    .subscribe((response: any) => {
      if (response && response.result) {
        if (response.result.data.exists) {
          this.form.controls.shortcut.setErrors({notUnique: true})
        }
      }
    });
  }
  
  filterCategories() {
    this.exactMatch = false
    let search = this.filterCtrl.value;
    search = search.toLowerCase();
    if (!search) {
      this.filteredCategoryList = [...this.categoryList];
      return
    }

    let filteredCategoryList = []
    for (const o of this.categoryList) {
      let catSearch =  o.name.toLowerCase().indexOf(search) > -1; 
      if (catSearch) {
        this.exactMatch =  search === o.name.toLowerCase() ? true : false
        filteredCategoryList.push({...o, sub_category_lv1: []})
        continue
      }
      for (const sub_category_lv1 of o.sub_category_lv1) {
        let catSearch =  sub_category_lv1.name.toLowerCase().indexOf(search) > -1;
        if (catSearch) {
          this.exactMatch =  search === sub_category_lv1.name.toLowerCase() ? true : false
          filteredCategoryList.push({...sub_category_lv1, sub_category_lv1: [], name: `${o.name} / ${sub_category_lv1.name}`})
          continue
        }
        for (const sub_category_lv2 of sub_category_lv1.sub_category_lv2) {
          let catSearch =  sub_category_lv2.name.toLowerCase().indexOf(search) > -1;
          if (catSearch) {
            this.exactMatch =  search === sub_category_lv2.name.toLowerCase() ? true : false
            filteredCategoryList.push({...sub_category_lv2, sub_category_lv1: [], name: `${o.name} / ${sub_category_lv1.name} / ${sub_category_lv2.name}`})
            continue
          }
        }
      }
    }
    this.filteredCategoryList = filteredCategoryList
  }

  saveCategory(data) {
    this.dataService.post({ url: "v1/user/shortcut-category", data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.getCategories(true);
          this.extensionService.sendUpdateMessage();
          this.data.self.categoryId = response.result
          this.selectElem.close()
          this.notification.toast("Category added successfully!");
        }
      },
        () => { }
      );
  }

  shortcutCategoryRename(data: any) {
    this.dialog.openDialogComponent(CategoryRenameComponent, { self: this, data });
  }

  setEditorManager(event: any) {
    this.isEditorLoaded = true;
    this.editorManager = event.editor.editorManager;
  }

  setCategory(): void {
    this.filteredCategoryList = [...this.categoryList];
    this.form.patchValue({
      category_id: this.data.self.categoryId &&
        this.data.self.categoryId !== "all" ? this.data.self.categoryId : this.categoryList[0].id
    });
  }

  getCategories(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({reloadFromApi});
  }

  getCategoryType(id: string) {
    for (const item of this.categoryList) {
      if (item.id == id) {
        return "category"
      }
      if (item.sub_category_lv1) {
        for (const subLv1 of item.sub_category_lv1) {
          if (subLv1.id == id) {
            return "sub_categories_lv1"
          }
          if (subLv1.sub_category_lv2) {
            for (const subLv2 of subLv1.sub_category_lv2) {
              if (subLv2.id == id) {
                return "sub_categories_lv2"
              }
            }
          }
        }
      }
    }
  }

  saveShortcut(data: any, editorId: string) {
    let planId = getPlanId()
    
    const editor = this.editorManager.get(editorId);
    this.shortcutService.processMedia(editor, (uploads: string[]) => {
      const shortcut: any = this.dataService.toSave(data, ["title", "shortcut", "category_id"]);
      shortcut.uploads = uploads;
      shortcut.html = editor.getContent();
      shortcut.text = this.shortcutService.getInlineStyledHTML(shortcut.html);
      shortcut.first_line = this.shortcutService.getFirstLine(editor);
      shortcut.type = this.getCategoryType(data.category_id);

      let rewards = getRewards();
      if (!rewards.length && !rewards.includes(RewardsConstants.UNLIMITED_CHARACTERS)) {
        if (planId === "Free" && shortcut.text.length >= PlansConstants.FREE.NUM_OF_CHAR) {
          this.dialog.openDialogComponent(
            WarningDialogComponent,
            { self: this, type: "numOfChar", showTheStarter: planId === "Free", error: "maxCharFree" },
            "800px"
          );
          return
        }
        if (planId === "Starter" && shortcut.text.length >= PlansConstants.STARTER.NUM_OF_CHAR) {
          this.dialog.openDialogComponent(
            WarningDialogComponent,
            { self: this, type: "numOfChar", showTheStarter: false, error: "maxCharStarter" },
            "800px"
          );
          return
        }
      }

      this.dataService.post({ url: "v1/user/shortcut", data: shortcut }).subscribe((response: any) => {
        if (response && response.result) {
          this.extensionService.sendUpdateMessage();
          // this.notification.toast("Shortcut added successfully!");
          this.close();
          if (this.data.isDashboard) {
            this.data.self.getShortcutList();
          } else {
            this.data.self.listService.getList(true);
          }
          localStorage.setItem('expand', response.result)
          this.router.navigate(["/shortcuts", data.category_id]);
        }
      });
    });
  }

  checkForError() {
    if (this.form.controls['shortcut'].hasError('required')) {
      this.notification.toast("FlyCut is required", "OK", 6000);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
