import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AdminApiService } from "../services/admin-api.service";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { Observable, finalize, lastValueFrom } from "rxjs";

@Component({
  selector: "app-edit-group",
  templateUrl: "./edit-group.component.html",
  styleUrls: ["./edit-group.component.scss"],
})
export class EditGroupComponent implements OnInit {
  action: string;
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users: any[] = [];
  displayedColumns: string[] = ["select", "name", "email"];
  addNewGroupForm: FormGroup;
  editGroupForm: FormGroup;
  onStepTwoAddNewGroup: boolean = false;
  groupName: string;
  search: string;
  allChecked: boolean = false;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: DialogService,
    private fb: FormBuilder,
    private notification: NotificationService,
    private authService: AuthService,
    private adminApiService: AdminApiService,
    private location: Location,
    private router: Router,
  ) {
    this.action = this.data.action;
  }

  ngOnInit(): void {
    let currentUrl = this.router.url;

    switch (this.action) {
      case "addNewGroup":
      case "addSubGroup":
        this.loadUsers();
        this.addNewGroupForm = this.fb.group({
          group: [null, [Validators.required]],
        });
        break;
      case "deleteGroup":
        this.groupName = this.data.data.name;
        break;
      case "deleteSubGroup":
        this.groupName = this.data.data?.group?.name ?? this.data.data?.subgroupName;
        break;
      case "editGroup":
        this.editGroupForm = this.fb.group({
          group: [this.data.data.name, [Validators.required]],
        });
        break;
      case "editSubGroup":
        this.editGroupForm = this.fb.group({
          group: [this.data.data?.group?.name ?? this.data.data?.subgroupName, [Validators.required]],
        });
        break;
      case "addUsers":
        this.loadUsers();
        if(!currentUrl.includes('add-users')){
          this.location.replaceState(currentUrl+'/add-users');
        }
        break;
      case "removeUsers":
        this.displayedColumns = ["select", "name", "actions"];
        if(!currentUrl.includes('remove-users')){
          this.location.replaceState(currentUrl+'/remove-users');
        }
        this.dataSource = new MatTableDataSource(this.data.data.users);
        this.dataSource.paginator = this.paginator;
        break;
      default:
        break;
    }
  }

  loadUsers() {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }
    try {
      this.adminApiService.getUsers().subscribe((response: any) => {
        if (response && response.result && response.result.success) {
          let existingUsersIds = this.data.data?.users?.map((user: any) => user.id);
          let users = response.result.users;
          this.users = Array.isArray(existingUsersIds) && existingUsersIds.length > 0 ? users.map((user: any) => {
            return existingUsersIds.includes(user.id) ? {...user, checked: true} : user
          }) : users;

          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  removeInConfirmationView(element: any): void {
    this.dataSource.data = this.dataSource.data.filter((user: any) => user.email !== element.email);
    this.dataSource.paginator = this.paginator;
  }

  searchUsers() {
    this.dataSource.filter = this.search.trim().toLowerCase();
  }

  updateAllChecked() {
    this.allChecked =
      this.dataSource.connect().value != null &&
      this.dataSource.connect().value.every((t: any) => t.checked);
  }

  someChecked(): boolean {
    if (this.dataSource.connect().value == null) {
      return false;
    }
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource.connect().value == null) {
      return;
    }
    this.dataSource.connect().value.forEach((t: any) => (t.checked = checked));
  }

  getCheckedValues() {
    return this.dataSource.data.filter((element) => element.checked);
  }

  isGroupAdmin(): boolean {
    return this.authService.getUserRole() === "Group Admin";
  }

  async addNewGroup() {
    if (!this.onStepTwoAddNewGroup) {
      const response: any =  await lastValueFrom(this.adminApiService.validateGroupSubgroupUniqueness(this.addNewGroupForm.value.group));

      if (response && response.result && response.result.exists === true) {
        this.addNewGroupForm.controls['group'].setErrors({ not_unique: true });
        return;
      } else {
        this.addNewGroupForm.controls['group'].setErrors(null);
      }

      this.onStepTwoAddNewGroup = true;
      setTimeout(() => this.dataSource.paginator = this.paginator);
      return;
    }

    this.loading = true;

    let group = this.data.data;

    let data = {
      name: this.addNewGroupForm.value.group,
      users: this.getCheckedValues().map((user) => user.id),
    };

    let request_stream: Observable<Response>;
  
    if (this.action == "addSubGroup") {
      request_stream = this.adminApiService.createSubgroup(group.id, data, false);
    } else {
      request_stream = this.adminApiService.createGroup(data);
    }

    request_stream.pipe(finalize(() => this.loading = false)).subscribe({
      next: (response: any) => {
        if (response && response.result && response.result.success == true) {
          const result = response.result.group;
  
          this.notification.toastWithConfig(
            `New ${
              this.action == "addNewGroup" && !this.isGroupAdmin()
                ? "group"
                : "subgroup"
            } added`,
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            }
          );
  
          if(this.action == "addSubGroup"){
            this.router.navigate([`${this.data.redirectBaseUrl}/${group.slug}/${this.toSlug(result.name)}`]); 
            this.close();
            return;
          }
          
          this.close(result);
        }
      },
      error: (error: any) => console.log(error)
    });
  }

  editGroup() {
    this.groupName = this.editGroupForm.value.group;
    let group = this.action == "editSubGroup" ? this.data.data.group : this.data.data;

    this.adminApiService
      .updateGroup(group.id, {name: this.groupName})
      .subscribe({
        next: (response: any) => {
          if (response && response.result && response.result.success == true) {
            this.close(true);
            this.notification.toastWithConfig(
              `${this.action == "editGroup" ? "Group" : "Subgroup"} updated`,
              null,
              {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["green-notification"],
              }
            );
          } 
        },
        error: (error: any) => {
          console.log(error)
          if(error.status == 422){
            this.editGroupForm.controls['group'].setErrors({ not_unique: true });
          }
        }
      });
  }

  deleteGroup() {
    this.loading = true;
    let group = this.data.data;
    let group_id = group.id ?? group.group.id;
    this.adminApiService.deleteGroup(group_id).pipe(finalize(() => this.loading = false)).subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        this.close(group_id);
        this.notification.toastWithConfig(
          `${
            ["editSubGroup", "deleteSubGroup"].includes(this.action) ? "Subgroup" : "Group"
          } deleted successfully`,
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          }
        );
      } // Else show error response
    });
  }

  deleteGroupTrigger(){
    let group = this.data.data;
    let group_id = this.action == "editSubGroup" ? group.group.id ?? group.id : group.id;
    let name = this.action == "editSubGroup" ? group?.group?.name ?? group.subgroupName : group.name
    let action = this.action == "editSubGroup" ? "subgroup" : "group"

    const dialogRef = this.dialog.openDialogComponent(
      ConfirmationComponent,
      { action, group_id, name, class: 'portal-admin' },
      "550px"
    );

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.close();
        setTimeout(() => location.reload(), 1000);
      }
    })
  }

  addUserToGroup(){
    this.loading = true;
    let group = this.data.data.group;
    let data = {
      users: this.getCheckedValues().map((user) => user.id),
    };

    this.adminApiService.addUsersToGroup(group.id, data).pipe(finalize(() => this.loading = false)).subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        this.close(true);
        this.notification.toastWithConfig("Members added to group", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
      } 
    });
  }

  removeUserFromGroup(){
    let group = this.data.data.group;
    let data = {
      users: this.getCheckedValues().map((user) => user.id),
    };

    this.adminApiService.removeUsersFromGroup(group.id, data).subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        this.close();
        this.notification.toastWithConfig("Members removed from group", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
      } // Else show error.
    });
  }

  toSlug(text: string): string {
    return text
        .toLowerCase()                   
        .trim()                          
        .replace(/[^a-z0-9\s-]/g, '')    
        .replace(/\s+/g, '-')            
        .replace(/-+/g, '-');
  }

  close(data?: any) {
    let currentUrl = this.router.url;

    if(this.action == "addUsers"){
      this.router.navigate([currentUrl.replace("/add-users", "")]);
      this.location.replaceState(
        currentUrl.replace("/add-users", "")
      );
    }

    if(this.action == "removeUsers"){
      this.router.navigate([currentUrl.replace("/remove-users", "")]);
      this.location.replaceState(
        currentUrl.replace("/remove-users", "")
      );
    }
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {
    let currentUrl = this.router.url;

    if(this.action == "addUsers"){
      this.location.replaceState(
        currentUrl.replace("/add-users", "")
      );
      //this.router.navigate([currentUrl.replace("/add-users", "")])
    }

    if(this.action == "removeUsers"){
      this.location.replaceState(
        currentUrl.replace("/remove-users", "")
      );
      //this.router.navigate([currentUrl.replace("/remove-users", "")])
    }
  }
}
