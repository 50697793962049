import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, findIndex, isNumber } from "lodash";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { EventService } from "src/app/core/core-services/services/event.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { ListService } from "src/app/core/core-services/services/list.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ScrollService } from "src/app/core/core-services/services/scroll.service";
import { ShortcutService } from "src/app/core/core-services/services/shortcut.service";

import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";

import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";
import { MetaService } from "src/app/core/core-services/services/meta.service";
import { FlyCutShareComponent } from "src/app/vengreso/share/fly-cut-share/fly-cut-share.component";
import { PlansConstants, PlansRestrictions, RewardsConstants, getPlanId, getRewards } from "../../core/core-services/constants/plans.constants";
import { WarningDialogComponent } from "../../core/core-services/warning-dialog/warning-dialog.component";
import { EmulatorPreviewComponent } from "./actions/emulator-preview/emulator-preview.component";
import { StatisticsComponent } from "./actions/statistics/statistics.component";
import { AddShortcutComponent } from "./add-shortcut/add-shortcut.component";
import { ChangeShortcutCategoryComponent } from "./change-shortcut-category/change-shortcut-category.component";
import { DuplicateShortcutComponent } from "./duplicate-shortcut/duplicate-shortcut.component";
import { VersionHistoryComponent } from "./version-history/version-history.component";

const placeholders = {
  textArea:
    "Test your FlyCut here by typing it in now. Remember will not display any rich text like bold, underline, words that are hyperlinked, images, etc. Switch to Rich Text Preview to test those elements.",
  testingRichEditor: "Test your FlyCut here by typing it in now.",
};

@Component({
  selector: "app-shortcuts",
  templateUrl: "./shortcuts.component.html",
  styleUrls: ["./shortcuts.component.scss"],
})
export class ShortcutsComponent implements OnInit, OnDestroy {
  @Input() searchShortcuts: any[];
  @Input() fromSearch: any;
  @Input() upScroll: boolean;
  @Input() searchValue: string;
  @Output("detectUpdates") detectUpdates: EventEmitter<any> = new EventEmitter();
  plc = placeholders;
  shortcuts: any[];
  categoryId: string;
  lv1CategoryId: string;
  lv2CategoryId: string;
  shortcutsCopy: any[];
  testingRichEditor: string;
  textArea: string;
  testElement: string;
  editorConfig: any;
  isSaving: boolean = false;
  currentIndex: number;
  panelOpenState = {};
  panelExpandState = {};
  subscriptions = new Subscription();
  modelChanged = new Subject<string>();
  editorApiKey: string;
  editorManager: any;
  scrollDisabled = true;
  searchText = "";
  allShortCutChecked: boolean = false;
  noShortcutChecked: boolean = false;

  //list of the restricted feature to starter plan
  Starter = PlansRestrictions.Starter;
  //list of the restricted feature to free plan
  Free = PlansRestrictions.Free;

  scrollerTimeout
  flycutIndexToBeDeleted: number;

  constructor(
    config: NgbPopoverConfig,
    public dialog: DialogService,
    private route: ActivatedRoute,
    public listService: ListService,
    private dataService: DataService,
    private eventService: EventService,
    public scrollService: ScrollService,
    private shortcutService: ShortcutService,
    private notification: NotificationService,
    private extensionService: ExtensionService,
    private router: Router,
    private metaService: MetaService,
    private el: ElementRef
  ) {
    config.triggers = "hover";
    config.container = "body";

    let expand = localStorage.getItem('expand')
    if (expand) {
      localStorage.removeItem('expand')
      this.panelOpenState[expand] = true
      this.panelExpandState[expand] = true
    }

    this.modelChanged.pipe(debounceTime(1000)).subscribe(() => {
      if (isNumber(this.currentIndex)) {
        let isChangeDetected = false;

        if (this.isContentChanged("html")) {
          isChangeDetected = true;
          this.shortcuts[this.currentIndex].first_line =
            this.shortcutService.getFirstLine(
              this.editorManager.get(
                "shortcut" + this.shortcuts[this.currentIndex].id
              )
            );
        }

        if (
          this.isContentChanged("title") ||
          this.isContentChanged("shortcut")
        ) {
          isChangeDetected = true;
        }

        if (isChangeDetected) {
          this.setContentToCopy("html");
          this.setContentToCopy("title");
          this.setContentToCopy("shortcut");
          this.saveShortcut(
            this.shortcuts[this.currentIndex],
            "shortcut" + this.shortcuts[this.currentIndex].id
          );
        } else {
          const index = findIndex(this.shortcuts, { id: this.shortcuts[this.currentIndex].id })
          this.shortcuts[index].errors = []
        }
      }
    });
  }
  onEditorInput(event: any) {
    this.testingRichEditor = event.target.innerHTML;
  }
  onChangeEditor(editor: 'text' | 'edit') {
    this.testElement = editor;
    this.showPlaceholder();
    this.textArea = placeholders.textArea;
    this.testingRichEditor = placeholders.testingRichEditor;
  }
  ngOnChanges() {
    this.shortcuts = this.searchShortcuts;
    if (this.fromSearch) {
      this.searchText = this.searchValue;
    }
  }
  placeholder = true;
  hidePlaceholder() {
    this.placeholder = false;
  }
  showPlaceholder() {
    this.placeholder = this.testingRichEditor === '' || this.testingRichEditor == placeholders.testingRichEditor;
  }
  initialise() {
    this.testElement = "text";
    // tslint:disable-next-line:max-line-length
    this.textArea = placeholders.textArea;
    this.testingRichEditor = placeholders.testingRichEditor;

    setTimeout(() => {
      this.scrollDisabled = false;
      this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
      this.editorConfig = this.shortcutService.getEditorConfig();
      this.editorConfig.placeholder = this.testingRichEditor;
    }, 0);

    this.subscriptions.add(
      this.route.data.subscribe((data) => {
        this.categoryId = this.route.snapshot.paramMap.get("categoryId");
        this.lv1CategoryId = this.route.snapshot.paramMap.get("lv1CategoryId");
        this.lv2CategoryId = this.route.snapshot.paramMap.get("lv2CategoryId");

        if (
          (data.shortcutList && data.shortcutList.result) ||
          this.fromSearch
        ) {

          let url = `v1/user/shortcut?order_by=shortcut,asc`;

          if(this.categoryId !== "all") {
              url += `&category_id=${this.categoryId}`;
          }
          if (this.lv1CategoryId) {
              url += `&sub_categories_lv1_id=${this.lv1CategoryId}`;
          }
          if (this.lv2CategoryId) {
              url += `&sub_categories_lv2_id=${this.lv2CategoryId}`;  
          }

          const config = {
            self: this,
            listName: "shortcuts",
            url: url + "&",
            callback: () => {
              this.shortcuts = this.shortcuts.map(shortcut => {
                return {
                  ...shortcut, mappedId: `id${shortcut.id}`
                }
              })
              this.shortcutsCopy = cloneDeep(this.shortcuts);
            },
          };

          this.fromSearch == true
            ? this.listService.init(config, this.searchShortcuts)
            : this.listService.init(config, data.shortcutList.result);
        }
      })
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params.add === "true") {
          this.addNewShortcut();
        }
        if (params.shortcutId) {
          this.dataService.get({ url: `v1/user/shortcut/${params.shortcutId}` }).subscribe({
            next: (response: any) => {
              let modal = this.dialog.openDialogComponent(
                AddShortcutComponent,
                { self: this, action: 'edit', shortcut: response.result },
                "800px"
              );
              modal.afterClosed().subscribe(res => {
                if (res?.success && res?.action == "edit") {
                  this.shortcuts.unshift(res.result)
                }
              })
            },
            error: (err) => {
            }
          })
        }
      })
    );

    this.subscriptions.add(
      this.eventService.getAddShortcutListener().subscribe((data) => {
        if (data) {
          this.addNewShortcut();
        }
      })
    );
  }

  scrollToShortcutToBeEdited(shortcutId: string) {
    const index = this.shortcuts.findIndex(shortcut => {
      return shortcut.id === shortcutId
    })
    if (index !== -1) {
      this.scrollerTimeout = setTimeout(() => {
        const element = this.el.nativeElement.querySelector(`#id${shortcutId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' }); // Use 'auto' for instant scrolling, Use 'smmooth' for smooth scrolling
        }
        this.clearScrollingEvents()
        return
      }, 500)
    }
  }

  clearScrollingEvents() {
    clearTimeout(this.scrollerTimeout)
  }

  ngOnInit() {
    this.initialise();
    this.metaService.addMetaTag(
      'FlyMSG View All FlyCuts Page',
      "FlyMSG can help you view all of your FlyCuts in one view all page, making it easier to navigate your templates."
    );
  }

  ngOnDestroy() {
    this.clearScrollingEvents()
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  reRender() {
    window.location.reload();
  }

  setEditorManager(event: any) {
    this.editorManager = event.editor.editorManager;
  }

  isContentChanged(key: string | number) {
    return (
      this.shortcuts[this.currentIndex][key] &&
      this.shortcutsCopy[this.currentIndex][key] !==
      this.shortcuts[this.currentIndex][key]
    );
  }

  setContentToCopy(key: string | number) {
    this.shortcutsCopy[this.currentIndex][key] =
      this.shortcuts[this.currentIndex][key];
  }

  onChangeContent(index: number) {
    this.modelChanged.next(null);
    this.currentIndex = index;
  }

  saveShortcut(data: any, editorId: string) {
    const editor = this.editorManager.get(editorId);
    this.shortcutService.processMedia(editor, (uploads: string[]) => {
      const shortcut: any = this.dataService.toSave(data, [
        "title",
        "shortcut",
        "category_id",
        "first_line",
      ]);
      shortcut.uploads = uploads;
      shortcut.html = editor.getContent();
      shortcut.text = this.shortcutService.getInlineStyledHTML(shortcut.html);
      if (
        shortcut.title &&
        shortcut.shortcut &&
        shortcut.html
      ) {
        this.shortcutUpdate(data, shortcut);
      }
    }, data?.uploads);
  }

  isValidateShortcut(data: any) {
    const index = findIndex(this.shortcuts, { id: data.id })
    this.shortcuts[index].errors = []
    let planId = getPlanId()
    if (planId == "Growth") return true
    if (planId == "Pro Teams - ENT" || planId == "Pro Teams - SMB") {
      planId = "Growth" //This is easier than refactoring all the check for planId==Growth to give pro features
      return true
    }

    let result = true

    let tempDataHtml = data.html

    data.html = data.html.replaceAll('font-size: 10pt;', '')
    data.html = data.html.replaceAll('font-size: 11pt;', '')
    data.html = data.html.replaceAll('font-size: 12pt;', '')

    data.html = data.html.replaceAll('font-family: calibri;', '')
    data.html = data.html.replaceAll('font-family: Calibri;', '')
    data.html = data.html.replaceAll('font-family: arial, helvetica, sans-serif;', '')
    data.html = data.html.replaceAll('font-family: helvetica, sans-serif;', '')


    if (this[planId]?.includes('FontSize') && data.html.includes("font-size: ")) {
      this.shortcuts[index].errors.push('Exceeds font size limit. Use 10pt, 11pt, 12pt font size or upgrade plan. ')
      result = false
    }

    if (this[planId]?.includes('italic') && data.html.includes("<em>")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use italics feature, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('underline') && data.html.includes("text-decoration: underline;")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use underline feature, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('strikethrough') && data.html.includes("text-decoration: underline;")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use strikethrough feature, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('InsertUnorderedList') && data.html.includes("</ul>")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use bullet points, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('InsertOrderedList') && data.html.includes("</ol>")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use numbered list, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('hyperlinks') && data.html.includes("</a>")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use hyperlinks, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('mceApplyTextcolor') && data.html.includes("background-color:")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To change background color, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('mceApplyTextcolor') && data.html.includes("color:")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To change font color, upgrade your plan. ')
      result = false
    }

    if (
      data.html.includes('</pre>') ||
      data.html.includes('</p>') ||
      data.html.includes('</h1>') ||
      data.html.includes('</h2>') ||
      data.html.includes('</h3>') ||
      data.html.includes('</h4>') ||
      data.html.includes('</h5>') ||
      data.html.includes('</h6>')) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. For block styling, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('JustifyCenter') && data.html.includes("text-align: center;")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use centered-alignment, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('JustifyRight') && data.html.includes("text-align: right;")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To use right-alignment, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('indent') && data.html.includes("padding-left:")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To increase indent, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('outdent') && data.html.includes("padding-right:")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. To decrease indent, upgrade your plan. ')
      result = false
    }

    if (this[planId]?.includes('FontName') && data.html.includes("font-family:")) {
      this.shortcuts[index].errors.push('Exceeds text editor limit. Use Arial, Calibri or Sans Serif font type or upgrade your plan. ')
      result = false
    }

    let length = data.html.replaceAll("&nbsp;", ' ').replace(/<\/?[^>]+(>|$)/g, "").length;
    if (planId === "Free" && length >= PlansConstants.FREE.NUM_OF_CHAR) {
      this.shortcuts[index].errors.push('Exceeds 2,500 character limit. Reduce characters or upgrade plan. ')
      result = false
    }
    if (planId === "Starter" && length >= PlansConstants.STARTER.NUM_OF_CHAR) {
      this.shortcuts[index].errors.push('Exceeds 25,000 character limit. Reduce characters or upgrade plan. ')
      result = false
    }

    data.html = tempDataHtml

    return result
  }

  shortcutUpdate(data: any, shortcut: any, isCategoryUpdate = false) {
    if (!this.isValidateShortcut(data)) return

    this.isSaving = true;
    this.dataService
      .put({
        url: "v1/user/shortcut/" + data.id,
        data: shortcut,
        isLoader: false,
      })
      .subscribe(
        (response: any) => {
          if (response) {
            this.isSaving = false;
            this.extensionService.sendUpdateMessage();

            const index = findIndex(this.shortcuts, { id: data.id });

            //To propagate the changes to the dialog components incase of preview since fetching of shortcuts doesn't happen after update.
            this.shortcuts[index].shortcut = shortcut.shortcut;
            this.shortcuts[index].title = shortcut.title;
            this.shortcuts[index].text = shortcut.text;
            
            if (isCategoryUpdate) {
              // if in all shortcut or dashboard update the categories id in the frontend witout refresh
              const currentRoute = this.router.url;

              if (currentRoute.includes("shortcuts/all") || currentRoute.includes("/advanced-search-result")) {
                // Soft Update category without refresh
                this.shortcuts[index].category_id = response.result.category_id;
                this.shortcuts[index].sub_categories_lv1_id =
                  response.result.sub_categories_lv1_id;
                this.shortcuts[index].sub_categories_lv2_id =
                  response.result.sub_categories_lv2_id;

                const category_name = response.result.category_name;

                switch (shortcut.category_level) {
                  case 0:
                    this.shortcuts[index].category_name = category_name;
                    this.shortcuts[index].sub_categories_lv1_name = "";
                    this.shortcuts[index].sub_categories_lv2_name = "";
                    break;
                  case 1:
                    this.shortcuts[index].category_name = "";
                    this.shortcuts[index].sub_categories_lv1_name =
                      category_name;
                    this.shortcuts[index].sub_categories_lv2_name = "";
                    break;
                  case 2:
                    this.shortcuts[index].category_name = "";
                    this.shortcuts[index].sub_categories_lv1_name = "";
                    this.shortcuts[index].sub_categories_lv2_name =
                      category_name;
                    break;
                }
              } else {
                this.fetchShortcuts()
              }

              this.notification.toast("Category updated successfully!");
            }

            this.shortcuts[index].errors = [];
            if (!isCategoryUpdate) {
              if (isNaN(this.shortcuts[index].version_counts)) {
                this.shortcuts[index].version_counts = 1
              } else {
                this.shortcuts[index].version_counts++;
              }
            }
          }
        },
        (err) => {
          if (err.status == 403) {
            const index = findIndex(this.shortcuts, { id: data.id });
            if (!this.shortcuts[index].hasOwnProperty("errors")) {
              this.shortcuts[index].errors = [];
            }
          }
          this.isSaving = false;
        }
      );
  }

  addNewShortcut() {
    let planId = getPlanId();
    let rewards = getRewards();
    if (!rewards.length && !rewards.includes(RewardsConstants.UNLIMITED_FLYCUTS)) {
      if (
        planId === "Free" &&
        this.shortcuts.length >= PlansConstants.FREE.NUM_OF_FLYCUTS
      ) {
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          { self: this, type: "numOfFlycuts", showTheStarter: planId === "Free", error: "maxFree" },
          "800px"
        );
        return;
      }
      if (
        planId === "Starter" &&
        this.shortcuts.length >= PlansConstants.STARTER.NUM_OF_FLYCUTS
      ) {
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          { self: this, type: "numOfFlycuts", showTheStarter: false, error: "maxStarter" },
          "800px"
        );
        return;
      }
    }

    let currentUrl = this.router.url || 'shortcuts/all'
    let modal = this.dialog.openDialogComponent(
      AddShortcutComponent,
      { self: this, parentPage: currentUrl },
      "800px"
    );

    modal.afterClosed().subscribe(result => {
      if (result?.success) {
        setTimeout(() => {
          this.fetchShortcuts()
        }, 1000)
      }
    })

  }

  fetchShortcuts() {
    this.route.data.subscribe((data) => {
      this.categoryId = this.route.snapshot.paramMap.get("categoryId");
      this.lv1CategoryId = this.route.snapshot.paramMap.get("lv1CategoryId");
      this.lv2CategoryId = this.route.snapshot.paramMap.get("lv2CategoryId");

      if (
        (data.shortcutList && data.shortcutList.result) ||
        this.fromSearch
      ) {

        let url = `v1/user/shortcut?order_by=shortcut,asc`;

        if(this.categoryId !== "all") {
            url += `&category_id=${this.categoryId}`;
        }
        if (this.lv1CategoryId) {
            url += `&sub_categories_lv1_id=${this.lv1CategoryId}`;
        }
        if (this.lv2CategoryId) {
            url += `&sub_categories_lv2_id=${this.lv2CategoryId}`;  
        }

        this.dataService.get({
          url
        }).subscribe((response: any) => {
          this.shortcuts = response?.result
          this.shortcutsCopy = cloneDeep(this.shortcuts);
        })
      }
    })
  }
  openedPanel(id){
    this.panelOpenState[id] = true
  }
  openVersionHistory(shortcut, expanded) {
    this.dialog.openDialogComponent(
      VersionHistoryComponent,
      { self: this, shortcut },
      "800px"
    );

    if (!expanded) {
      this.panelOpenState[shortcut.id] = {};
      this.panelExpandState[shortcut.id] = {};
    }
  }

  openEmulatorPreview(shortcut) {
    this.dialog.openDialogComponent(
      EmulatorPreviewComponent,
      { self: this, shortcut, isMax: false },
      "76vw"
    );
  }

  openStat(id) {
    this.dialog.openDialogComponent(
      StatisticsComponent,
      { self: this, id },
      "430px"
    );
  }

  deleteShortcut(data: any, i: number) {
    this.flycutIndexToBeDeleted = i
    this.dialog.openDialogComponent(
      ConfirmDialogComponent,
      { self: this, data },
      "330px"
    );
  }

  duplicateFlycut(data: any) {
    this.dialog.openDialogComponent(
      DuplicateShortcutComponent,
      { self: this, data },
      "800px"
    );
  }

  changeCategory(data: any) {
    this.dialog.openDialogComponent(
      ChangeShortcutCategoryComponent,
      { self: this, data },
      "330px"
    );
  }

  delete(data: any) {
    this.dataService
      .delete({ url: "v1/user/shortcut/" + data.id })
      .subscribe((response: any) => {
        this.shortcuts.splice(this.flycutIndexToBeDeleted, 1);
        this.notification.toast("FlyCut deleted successfully!");
        this.extensionService.sendUpdateMessage();
        // if (response && response.result) {
        //   this.extensionService.sendUpdateMessage();
        //   if (this.router.url != '/advanced-search-result') {
        //     // this.listService.getList(true);
        //     setTimeout(() => {
        //       this.fetchShortcuts()
        //     }, 1000);
        //   }
        //   this.detectUpdates?.emit()
        //   this.notification.toast("FlyCut deleted successfully!");
        // }
      });
  }

  checkPlaceHolder(input: string) {
    if (this[input]) {
      this[input] = null;
      return;
    } else {
      this[input] = placeholders[input];
      return;
    }
  }

  openFlyCutShare(shortcut: any): void {
    const shortcuts = [shortcut];

    this.dialog.openDialogComponent(
      FlyCutShareComponent,
      { shortcuts: shortcuts, fromFlyPlates: false },
      "859px"
    );
  }

  updateCheckList(isChecked: boolean, index: number) {
    if (index === -1) {
      this.shortcuts = this.shortcuts.map((shortcut) => {

        // Don't check flyplate
        // if (isChecked === true && shortcut.user_defined === false) {
        //   return shortcut;
        // }

        shortcut.checked = isChecked;

        return shortcut;
      });

      return;
    }

    // Don't check flyplate
    // if (isChecked === true && this.shortcuts[index].user_defined === false) {

    //   this.dialog.openDialogComponent(
    //     FlyCutShareComponent,
    //     { shortcuts: [this.shortcuts[index]], fromFlyPlates: true  },
    //     "407px"
    //   );

    //   return;
    // }

    this.allShortCutChecked = true;
    for (const item of this.shortcuts) {
      if (!item.checked) {
        this.allShortCutChecked = false;
        break;
      }
    }
  }

  share() {
    const checked = this.shortcuts.filter((shortcut) => shortcut.checked === true);

    if (!checked || checked.length < 1) {
      this.notification.toast("You should select at least one FlyCut to share.");

      this.noShortcutChecked = true;

      setTimeout(() => {
        this.noShortcutChecked = false;
      }, 3000);

      return;
    }

    // const fromFlyPlates = checked.some(({user_defined}) => user_defined === false);

    // this.dialog.openDialogComponent(
    //   FlyCutShareComponent,
    //   { shortcuts: checked, fromFlyPlates: fromFlyPlates },
    //   fromFlyPlates ? "407px" : "859px"
    // );

    this.dialog.openDialogComponent(
      FlyCutShareComponent,
      { shortcuts: checked, fromFlyPlates: false },
      "859px"
    );
  }
}
