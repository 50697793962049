import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { find } from "lodash";
import { Subscription } from "rxjs";
import { getCategoriesReward, getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { NotifyService } from "src/app/core/menu/not.service";

@Component({
  selector: "app-category-rename",
  templateUrl: "./category-rename.component.html",
  styleUrls: ["./category-rename.component.scss"]
})
export class CategoryRenameComponent implements OnInit, OnDestroy {

  form: FormGroup;
  id: any;
  categoryErrMsg: any;
  subCategoryErrMsg: any;
  isSelectDisable: any;
  filterCtrlSubscription: Subscription

  constructor(public dialogRef: MatDialogRef<CategoryRenameComponent>,
    public notifyService: NotifyService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.form = this.fb.group({
      name: [(this.data.data.name ? this.data.data.name : null), [Validators.required, Validators.maxLength(26)]],
      category_id: [(this.data.data.category_id ? this.data.data.category_id : null)],
    });
    this.isSelectDisable = new FormControl(this.data.data.category_id != null);
  }

  filterCtrl = new FormControl();

  categories: any[] = [];
  filteredCategories: any[] = [];

  ngOnInit(): void {
    this.id = this.data.data.id;
    this.categories = [...(this.data.self.shortcutCategoryList || this.data.self.categoryList)]
    let planId = getPlanId();
    let subCategoriesCount = this.countSubCategories();
    let additionalCategoryReward = getCategoriesReward()
    if (planId == "Free" && (this.categories.length >= (7 + additionalCategoryReward))) {
      this.categoryErrMsg = [`Exceeds the limit of ${7 + additionalCategoryReward} categories.`, `Reassign to an existing category or upgrade plan. `]
    }
    if (planId == "Starter" && this.categories.length >= (14 + additionalCategoryReward)) {
      this.categoryErrMsg = [`Exceeds the limit of ${14 + additionalCategoryReward} categories.`, `Reassign to an existing category or upgrade plan. `]
    }
    if (planId == "Free" && (subCategoriesCount >= 14)) {
      this.subCategoryErrMsg = [`Exceeds the limit of 14 subcategories.`, `Reassign to an existing subcategory or upgrade plan. `]
    }
    if (planId == "Starter" && subCategoriesCount >= 28) {
      this.subCategoryErrMsg = [`Exceeds the limit of 28 subcategories.`, `Reassign to an existing subcategory or upgrade plan. `]
    }
    this.filteredCategories = [...this.categories]
    this.filterCtrlSubscription = this.filterCtrl.valueChanges
      .subscribe(() => {
        this.filterCategories();
      });
  }

  countSubCategories(): number {
    let count = 0;
    for (const item of this.categories) {
      count += item.sub_category_lv1.length
    }
    return count
  }

  isSelectDisabled(event) {
    this.isSelectDisable.setValue(!this.isSelectDisable.value)
    if (!this.isSelectDisable.value) this.form.get('category_id').setValue(null)
    event.preventDefault();
    event.stopPropagation();
  }

  tooltip(element: any): string {
    return `${element.name1 || element.name2 ? 'Sub-' : ''}Category`;
  }

  filterCategories() {
    let search = this.filterCtrl.value;
    search = search.toLowerCase();
    if (!search) {
      this.filteredCategories = [...this.categories];
      return
    }

    let filteredCategories = []
    for (const o of this.categories) {
      let catSearch = o.name.toLowerCase().indexOf(search) > -1;
      if (catSearch) {
        filteredCategories.push({ ...o, sub_category_lv1: [] })
        continue
      }
      for (const sub_category_lv1 of o.sub_category_lv1) {
        let catSearch = sub_category_lv1.name.toLowerCase().indexOf(search) > -1;
        if (catSearch) {
          filteredCategories.push({ ...sub_category_lv1, sub_category_lv1: [], name: `${o.name} / ${sub_category_lv1.name}` })
          continue
        }
      }
    }
    this.filteredCategories = filteredCategories
  }

  updateCategory(value) {
    let item = find(this.categories, { 'id': value.category_id });
    this.data.data.destination_category_type = 'category'
    
    if (!item && this.form && this.form.get('category_id')) {
      for (const category of this.categories) {
        item = find(category.sub_category_lv1, { 'id': value.category_id });
        
        if (item) {
          this.data.data.destination_category_type = 'sub_categories_lv1';
          value.sub_categories_lv1 = value.category_id;
          value.category_id = item.category_id;
          break;
        }
      }
    }

    if (this.id) {
      if (item && item.id) this.data.data.destination_category_id = item.id
      this.data.data.name = value.name;
      this.data.self.categoryUpdate(this.data.data);
    } else {
      if (!value.category_id) delete value.category_id
      this.data.self.saveCategory(value);
    }
    this.notifyService.notifyAboutChange()
    this.cdr.detectChanges()
    this.close();
  }

  close(): void {
    this.dialogRef.close();
    if (this.filterCtrlSubscription) {
      this.filterCtrlSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.filterCtrlSubscription) {
      this.filterCtrlSubscription.unsubscribe();
    }
  }
}
