import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { ClipboardService } from "ngx-clipboard";
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { ValidationConstants } from "src/app/core/core-services/constants/validation.constants";

import {
  FLYSHARE_INVITE_STATUS_PENDING,
  FLYSHARE_INVITE_STATUS_ACCEPTED,
  RESEND_TIME_EXPIRY
} from './share-history.config';


import { BASE_FLYSHARE_LINK } from "../fly-cut-share/fly-cut.config";
import { AuthService } from 'src/app/core/core-services/services/auth.service';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
@Component({
  selector: 'app-share-history',
  templateUrl: './share-history.component.html',
  styleUrls: ['./share-history.component.scss']
})
export class ShareHistoryComponent implements OnInit {

  public form: FormGroup;
  submitted: boolean = false
  history: any;
  linkCopied:boolean = false;
  FLYSHARE_INVITE_STATUS_PENDING = FLYSHARE_INVITE_STATUS_PENDING;
  FLYSHARE_INVITE_STATUS_ACCEPTED = FLYSHARE_INVITE_STATUS_ACCEPTED;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private notification: NotificationService,
    private clipboardService: ClipboardService,
    public authService: AuthService,
    public dialog: DialogService,
    public dialogRef: MatDialogRef<ShareHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { clonedShortcut: any },
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.form = this.fb.group({
      email: [
        "",
        [Validators.required, Validators.email, Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX)]
      ],
    });
    this.getHistory();
  }

  getHistory(){
    this.dataService.get({url: `v1/shares-history/${this.data.clonedShortcut.id}`, isLoader: false}).subscribe({
      next: (response: any) => {
        this.history = response?.result;

        if(!this.history.some((record: any) => record.resent)) {
          return;
        }
        
        // this.history = this.history.map((record:any) => {
        //   if(record.resent) {
        //     let timeleft = 1800000 - Math.abs(
        //       (new Date()).getTime() - (new Date(record.resent_at)).getTime()
        //     );
        //     setTimeout(() => record.resent = false, timeleft);
        //   }

        //   return record;
        // });
      },
      error: (error) => {
        this.notification.toast(error?.error?.error ?? 'Something went wrong, Contact Administrator', 'Ok', 2000)
      }
    });
  }

  share(data: any): void {
    this.submitted = true

    this.dataService.post({
      url: `v1/flyshares/reshare/${this.data.clonedShortcut.flyshare_id}`,
      data: {email: data.email, cloned_shortcut_id: this.data.clonedShortcut.id},
      isLoader: true
    }).subscribe({
      next: () => {
        this.submitted = false;
        this.notification.toast('FlyMsg Shared!');
        this.close();
        this.dialog.openDialogComponent(ShareHistoryComponent, {clonedShortcut : this.data.clonedShortcut}, "420px");
      },
      error: (error) => {
        this.notification.toast(error?.error?.error ?? 'Something went wrong, Contact Administrator', 'Ok', 2000)
        this.submitted = false;
      }
    });
  }

  resend(user: any){
    user.resent = true;

    this.dataService.post({
      url: `v1/flyshares/reshare/${user.flyshare_id}`,
      data: {email: user.email, cloned_shortcut_id: this.data.clonedShortcut.id},
      isLoader: false
    }).subscribe({
      next: (response: any) => {
        //disable for some minutes
        setTimeout(() => user.resent = false, RESEND_TIME_EXPIRY);
      },
      error: (error) => {
        user.resent = false;
        this.notification.toast(error?.error?.error ?? 'Something went wrong, Contact Administrator', 'Ok', 2000)
      }
    });
  }

  copy(): void {
    const flyshareToken = this?.history[0]?.flyshare_id;
    
    if(!flyshareToken) {
      return;
    }

    const {first_name, last_name} = this.authService.getUserDetail();
    const flyshareInviteLink = `${BASE_FLYSHARE_LINK}/${flyshareToken}?f=${first_name}&l=${last_name}`

    this.clipboardService.copyFromContent(flyshareInviteLink);

    this.linkCopied = true;
    setTimeout(() => this.linkCopied = false, 2000);
  }

  close(): void {
    this.dialogRef.close();
  }

}
