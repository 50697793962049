import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-company-filters',
  templateUrl: './company-filters.component.html',
  styleUrls: ['./company-filters.component.scss']
})
export class CompanyFiltersComponent implements OnInit {
  companies: any;

  constructor(
    public dialogRef: MatDialogRef<CompanyFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.companies = data.companies;
    if(data.filtersClear){
      this.companies.forEach((company: any) => company.selected = false);
    }
  }

  groupedCompanies: { [key: string]: any[] } = {};
  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  groupedCompaniesColumns: string[][] = [];
  filteredCompanies: any[] = [];
  searchTerm: string = '';

  ngOnInit(): void {
    this.groupCompaniesByFirstLetter(this.companies);
  }

  searchCompanies(): void {
    const searchTermLowerCase = this.searchTerm.toLowerCase();

    // Filter the companies based on the search term
    const filteredCompanies = this.companies.filter(company =>
      company.name.toLowerCase().includes(searchTermLowerCase)
    );

    // Regroup companies based on the filtered results
    this.groupCompaniesByFirstLetter(filteredCompanies);
  }

  groupCompaniesByFirstLetter(companies: any[]): void {
    this.groupedCompanies = this.alphabet.reduce((groups, letter) => {
      groups[letter] = companies.filter(company =>
        company.name.charAt(0).toUpperCase() === letter
      );
      return groups;
    }, {});
  }

  clearFilters(): void {
    this.companies.forEach((company: any) => company.selected = false);
    this.close([]);
  }

  applyFilters(): void {
    const selectedCompanies = this.companies.filter((company: any) => company.selected);
    this.close(selectedCompanies);
  }

  scrollToLetter(letter: string): void {
    const element = document.getElementById(letter);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  close(data?:any) {
    this.dialogRef.close(data)
  }
}
