import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';

@Component({
  selector: 'app-reset-in-flycuts',
  templateUrl: './reset-in-flycuts.component.html',
  styleUrls: ['./reset-in-flycuts.component.scss']
})
export class ResetInFlycutsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ResetInFlycutsComponent>,
    private dataService: DataService,
    private notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  reset(): void {
    this.dataService.put({ url: `v1/shares-shortcut/${this.data.shortcut.id}` }).subscribe({
      next: (response: any) => {
        this.notification.toast("FlyShare reset successfully.");
        this.data.self.init(this.data.self.activeRoute);
        this.close();
    },
    error: (error) => {
      this.notification.toast(error.error.error ?? 'Something went wrong. Contact administrator')
    }
   });
  }

}
