import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { takeWhile } from "lodash";
import { Subject } from "rxjs";
import { catchError, concatMap, first, map, takeUntil } from "rxjs/operators";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { SubscriptionPlanType } from "src/app/core/models/stripe.models";
import { Location } from "@angular/common";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
const stripeStyles = {
  base: {
    backgroundColor: "#F5F5F5",
    borderColor: "#EEEEEE",
  },
};

@Component({
  selector: "app-fly-learning",
  templateUrl: "./fly-learning.component.html",
  styleUrls: ["./fly-learning.component.scss"],
})
export class FlyLearningComponent {
  planId;
  SSO_URL = "";
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private stripeService: StripeService,
    private _router: Router,
    private _location: Location,
    private http: HttpClient
  ) {
    this.planId = getPlanId();
  }
}
