import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NavigationService } from 'src/app/core/core-services/services/navigation.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent  {

  firstName: string;
  lastName: string;

  constructor(
    public dialogRef: MatDialogRef<UpdateProfileComponent>,
    private nav: NavigationService,
    @Inject(MAT_DIALOG_DATA) {firstName, lastName} : {firstName:string, lastName:string}
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
   }

  close(): void {
    this.dialogRef.close();
  }

  goToProfileUpdate() {
    this.close();
  }
}
