import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";

import { ShortcutCategoryService } from "src/app/core/core-services/services/shortcut-category.service";

@Injectable()
export class ShortcutCategoryListResolver implements Resolve<any> {
    constructor(
        private shortcutCategoryService: ShortcutCategoryService
    ) { }

    resolve(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): void {
        this.shortcutCategoryService.loadShortcutCategory();
    }
}
