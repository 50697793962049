import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { AddShortcutComponent } from "src/app/vengreso/shortcuts/add-shortcut/add-shortcut.component";
import { LoadingService } from "../../core/core-services/services/loader.service";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"]
})
export class VideoComponent implements OnInit {
  safeURL: any;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddShortcutComponent>,
    private loader: LoadingService
  ) {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.link.video);
  }

  ngOnInit(): void {
    this.loader.start("video-loader");
  }

  myLoadEvent() {
    console.log("loaded");
    this.loader.stop("video-loader");
  }

  close(): void {
    this.dialogRef.close();
  }
}
