import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { DowngradePlanThreeComponent } from '../downgrade-plan-three/downgrade-plan-three.component';
import {
  getPlanId
} from "src/app/core/core-services/constants/plans.constants";
import { SubscriptionPlanType } from 'src/app/core/models/stripe.models';
import { DowngradeOptionComponent } from '../downgrade-option/downgrade-option.component';
import { CTA_GROWTH_PAUSE_PLAN_URL, CTA_PAUSE_PLAN_URL } from '../settings.config';
import { Subscription } from 'rxjs';
import { StripeService } from 'src/app/core/core-services/services/stripe.service';

@Component({
  selector: 'app-downgrade-plan-two',
  templateUrl: './downgrade-plan-two.component.html',
  styleUrls: ['./downgrade-plan-two.component.css']
})
export class DowngradePlanTwoComponent implements OnInit {

  subscriptions = new Subscription();
  currentPlan: string;

  constructor(
    public dialog: DialogService,
    public dialogRef: MatDialogRef<DowngradePlanTwoComponent>,
    private stripeService: StripeService
  ) { 
    this.currentPlan = getPlanId();
    this.getCurrentPlan();
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  getCurrentPlan() {
    this.subscriptions.add(
      this.stripeService.getCurrentPlan().subscribe((planInfo: any) => {

        if (!planInfo || !Object.values(SubscriptionPlanType).includes(planInfo.title)) {
          this.currentPlan = SubscriptionPlanType.Freemium;
          return;
        }

        switch (planInfo.identifier) {
          case "growth-yearly":
            this.currentPlan = SubscriptionPlanType.YearlyGrowth;
            break;
          case "starter-yearly":
            this.currentPlan = SubscriptionPlanType.YearlyStarter;
            break;
          default:
            this.currentPlan = SubscriptionPlanType[planInfo.title];
            break;
         }
      })
    );
  }

  pausePlan(): void {
    //TODO: Send to backend
    window.location.href = this.isStarterPlan() ? CTA_PAUSE_PLAN_URL :
    this.isGrowthPlan() ? CTA_GROWTH_PAUSE_PLAN_URL : '';
    
  }

  downgradePlan(): void {
    this.close();
    //TODO: Send to backend
    this.dialog.openDialogComponent(
      this.isGrowthPlan() ? DowngradeOptionComponent : DowngradePlanThreeComponent,
      { planDowngradedTo : SubscriptionPlanType.Freemium, planDowngradedFrom : this.currentPlan }, 
    "700px");
  }

  isGrowthPlan(): boolean {
    return (this.currentPlan === SubscriptionPlanType.Growth || this.currentPlan === SubscriptionPlanType.YearlyGrowth);
  }

  isStarterPlan(): boolean {
    return (this.currentPlan === SubscriptionPlanType.Starter || this.currentPlan === SubscriptionPlanType.YearlyStarter);
  }

}
