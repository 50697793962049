import { Component, OnInit, Inject } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { find } from "lodash";

@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.scss"],
})
export class StatisticsComponent implements OnInit {
  shortcutContent: any;
  strippedHtml: any;
  isLoading: boolean = false
  wordCounter: number = 0;
  charCounter: number = 0;

  constructor(
    config: NgbPopoverConfig,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StatisticsComponent>,
    public dialog: DialogService
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.getData()
    const shortcut = find(this.data.self.shortcuts, {id: this.data.id});
    this.strippedHtml = shortcut.html.replace(/<[^>]+>/g, '');

    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = shortcut.html;
    
    this.shortcutContent = tempDivElement.innerText || tempDivElement.textContent || "";
  }

  getData() {
    this.isLoading = true
    this.dataService
      .get({
        url: `v1/user/shortcut_version/versions/${this.data.id}`,
      })
      .subscribe((response: any) => {
        this.isLoading = false
        if (response && response.result) {
          let versionCounter = 0
          for (let index = 0; index < 999; index++) {
            if (!response.result[index]) break
            versionCounter++;
          }
          //this is for current version
          let element
          if (response.result.hasOwnProperty('current_version')) {
            element = JSON.parse(response.result[response.result.current_version]);
          } else {
            element = JSON.parse(response.result[versionCounter-1]);
          }
          this.charCounter = element.charactors_count
          this.wordCounter = element.words_count
      }});
  }

  close(): void {
    this.dialogRef.close();
  }

  countWords(): number {
    const arr = this.shortcutContent.split(' ');
    this.countAllSpaces()
    return arr.filter(word => word !== '')?.length || 0;
  }

  countCharWithoutSpaces(): number {
    return this.shortcutContent.match(/\S/g)?.length || 0;
  }

  countAllSpaces(): number {
    return (this.shortcutContent?.length||0) - ((this.shortcutContent.match(/\S/g)?.length||0) + (this.shortcutContent.match(/\n/g)?.length||0))
  }

  countCharWithSpaces(): number {
    return this.countAllSpaces() + this.countCharWithoutSpaces();
  }

  countParagraphs(): number {
    let count = 0
    for (let index = 0; index < this.strippedHtml.split('\n').length; index++) {
      const element = this.strippedHtml.split('\n')[index];
      if (element !== "" && element !== "&nbsp;") {
        count++;
      }
    }
    return count
  }

  countLines(): number {
    return this.shortcutContent.split("\n")?.length || 0;
  }
}
