import { Injectable } from "@angular/core";

@Injectable()

export class ScrollService {

    constructor() { }

    scrollTo(el: HTMLElement) {
        el.scrollIntoView({behavior: "smooth"});
    }

}
