import { Component, OnInit, Inject } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import moment from "moment";
import { find } from "lodash";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { PreviewComponent } from "./preview/preview.component";
import { ConfirmVersionComponent } from "./preview/confirm-version-dialog/confirm-version-dialog.component";
import { DeleteVersionComponent } from "./delete-version/delete-version.component";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";

@Component({
  selector: "app-version-history",
  templateUrl: "./version-history.component.html",
  styleUrls: ["./version-history.component.scss"],
})
export class VersionHistoryComponent implements OnInit {
  columns: string[] = [
    "modified",
    "charCount",
    "wordCount",
    "mediaSize",
    "action",
  ];
  dataSource = [];
  isLoading: boolean = false;
  title: string;

  constructor(
    config: NgbPopoverConfig,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VersionHistoryComponent>,
    public dialog: DialogService
  ) {
    this.getAllVersions();
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.title = find(this.data.self.shortcutsCopy, {
      id: this.data?.id,
    })?.title;
  }

  getAllVersions() {
    this.isLoading = true;
    this.dataService
      .get({
        url: `v1/user/shortcut_version/versions/${this.data?.id||this.data?.shortcut?.id}`,
        isLoader: true
      })
      .subscribe((response: any) => {
        this.isLoading = false;
        if (response && response.result) {
          
          if(response.result?.length === 0){
            return
          }

          const planId = getPlanId();
          let isHistoryAvailable = planId == "Growth" || planId == "Pro Teams - ENT" || planId == "Pro Teams - SMB"

          let results = []
          let versionsData = response.result 

          for(let i = 0; i < versionsData.length; i++){
            let tempArray:any[] = []
            for(let j in versionsData[i]){
              tempArray.push(+j)
            }
            let maxNumericValue = Math.max(...tempArray.filter(value => !isNaN(value)))
            let version
            if(versionsData[i][maxNumericValue]){
              version = JSON.parse(versionsData[i][maxNumericValue])
            }
            if(version){
              version.version_id = versionsData[i].id
              version.updated_at = versionsData[i].updated_at
              results.push(version)
            }
          }
          
          for(let index = 0; index < results.length; index++) {
            let element = results[index]
            // Assign the created_at or updated_at of the shortcut as the updated time for shortcut versions having no updated_at field
            let updatedAt = element['updated_at'] ?? this.data.shortcut.created_at ?? this.data.shortcut.updated_at;
            const mediaCount = this.countMediaUrls(element['html']);
            
            let mediaSize = !!element['media_count'] && element['media_count'] > 0 ? `${element['media_count']}Kb` : mediaCount > 0 ? `${mediaCount}Kb` : "No media";

              this.dataSource[index] = {
                id: index,
                modified: moment(updatedAt).format("MMM, DD YYYY | hh:mm A"),
                charCount: element['charactors_count'],
                updatedAt: updatedAt,
                shortcut: element['shortcut'],
                text: element['text'],
                html: element['html'],
                title: element['title'],
                wordCount: element['words_count'],
                firstLine: element['first_line'],
                sub_categories_lv1: element['sub_categories_lv1'],
                sub_categories_lv2: element['sub_categories_lv2'],
                mediaSize: mediaSize,
                current: false,
                isAvailable: isHistoryAvailable,
                version_id: element['version_id'],
              };
          }

            this.dataSource = this.dataSource.sort(
              (a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix()
            );

            //For Legacy data
            if (response.result.hasOwnProperty("current_version")) {
              const current_version = response.result.current_version;
              const currentVersionIndex = current_version > 0 ? current_version - 1 : current_version

              this.dataSource = [
                ...new Set([
                  ...[this.dataSource[currentVersionIndex]],
                  ...this.dataSource,
                ]),
              ];
            }

            if (this.dataSource[0]) {
              this.dataSource[0].current = true;
            }

            //set the first 20 version available for Freemium
            if (planId == "Free") {
              for (let index = 0; index < 21; index++) {
                if (!this.dataSource[index]) break;
                this.dataSource[index].isAvailable = true;
              }
            }
            //set the first 100 version available for Starter
            if (planId == "Starter") {
              for (let index = 0; index < 101; index++) {
                if (!this.dataSource[index]) break;
                this.dataSource[index].isAvailable = true;
              }
            }
        }
      });
  }

  countMediaUrls(text: string): number {

    if(!text){
      return 0;
    }

    // Define the regular expressions for image and video URLs
    const urlPattern = /(?:https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[a-zA-Z0-9-._~:\/?#\[\]@!$&'()*+,;=%]*)?(?:\?[a-zA-Z0-9-._~:\/?#\[\]@!$&'()*+,;=%]*)?/gi;
    const imageExtensions = /\.(png|jpg|jpeg|gif|bmp|webp)(?:\?.*)?$/i;
    const videoExtensions = /\.(mp4|avi|mov|wmv|flv|mkv|webm)(?:\?.*)?$/i;

    const urlMatches = text.match(urlPattern) || [];

    const uniqueUrlMatches = Array.from(new Set(urlMatches));

    // Filter matches for image and video URLs
    const imageMatches = uniqueUrlMatches.filter(url => imageExtensions.test(url));
    const videoMatches = uniqueUrlMatches.filter(url => videoExtensions.test(url));

    return imageMatches.length +  videoMatches.length;
}

  openPreviewDialog(element) {
    if (!element.isAvailable) {
      let planId = getPlanId();
      let type =
        planId === "Free" ? "versionHistory" : "versionHistoryForStarter";
      this.dialog.openDialogComponent(
        WarningDialogComponent,
        { self: this, type, showTheStarter: planId === "Free" },
        "800px"
      );
      return;
    }
    this.dialog.openDialogComponent(
      PreviewComponent,
      { self: this, element },
      "1200px"
    );
  }

  openMakeCurrentDialog(element) {
    if (!element.isAvailable) {
      let planId = getPlanId();
      let type =
        planId === "Free" ? "versionHistory" : "versionHistoryForStarter";
      this.dialog.openDialogComponent(
        WarningDialogComponent,
        { self: this, type, showTheStarter: planId === "Free" },
        "800px"
      );
      return;
    }
    this.dialog.openDialogComponent(
      ConfirmVersionComponent,
      { self: this, element },
      "430px"
    );
  }

  openDeleteVersionDialog(element) {
    if (!element.isAvailable) {
      let planId = getPlanId();
      let type =
        planId === "Free" ? "versionHistory" : "versionHistoryForStarter";
      this.dialog.openDialogComponent(
        WarningDialogComponent,
        { self: this, type, showTheStarter: planId === "Free" },
        "800px"
      );
      return;
    }
    const ref = this.dialog.openDialogComponent(
      DeleteVersionComponent,
      { self: this, element },
      "430px"
    );

    ref.afterClosed().subscribe((data: boolean) => {
      if (data === true) {
        this.dataSource.filter(elem => elem.version_id !== element.version_id);
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
