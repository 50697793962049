import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

    constructor() { }

    canDeactivate(component: any) {
        // Allow synchronous navigation (`true`) if no changes are done
        if (component.form.pristine || !component.form.dirty) {
            return true;
        } else {
            const confirm = window.confirm("Do you want to discard the changes?");
            return confirm;
        }
    }
}
