import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-feature-not-in-plan',
  templateUrl: './feature-not-in-plan.component.html',
  styleUrls: ['./feature-not-in-plan.component.scss']
})
export class FeatureNotInPlanComponent implements OnInit {

  added: number = 0;
  total: number = 10;
  value: number = 0;

  constructor(
    public dialogRef: MatDialogRef<FeatureNotInPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {added: number, total: number},
  ) {
    this.added = data.added;
    this.total = data.total;
    this.value = (this.added/this.total) * 100;
   }

  ngOnInit(): void {
  }

  close(action: string = 'close'): void {
    this.dialogRef.close(action);
  }

  add(): void {
    this.close('add');
  }

}
