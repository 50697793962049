import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Location } from '@angular/common';

import { SUBSCRIPTION_TAB } from '../settings.config';

@Component({
  selector: 'app-paused-plan',
  templateUrl: './paused-plan.component.html',
  styleUrls: ['./paused-plan.component.css']
})
export class PausedPlanComponent implements OnInit,OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<PausedPlanComponent>,
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.location.replaceState(`settings?tab=${SUBSCRIPTION_TAB}`);
  }

}
