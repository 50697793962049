import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/core/core-services/services/shared-data.service';

@Component({
  selector: 'app-pin-to-browser',
  templateUrl: './pin-to-browser.component.html',
  styleUrls: ['./pin-to-browser.component.scss']
})
export class PinToBrowserComponent implements OnInit {

  constructor(
    private sharedService: SharedDataService
  ) { }

  ngOnInit(): void {
  }

  dismissPrompt(){
    this.sharedService.showExtensionPrompt.next(false)
  }

}
