import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
export interface Image {
    src: string,
    alt?: string
}

@Component({
    selector: 'app-grid-gallery-item',
    templateUrl: './grid-gallery-item.component.html',
    styleUrls: ['./grid-gallery-item.component.scss']
})
export class GridGalleryItemComponent {

    @Input() image: any;
    @Input() rowHeight: number = 1;
    @Input() gutterSize: number = 1;
    @ViewChild('img') img: ElementRef;

    public rows: number = 0;

    @HostListener('window:resize')
    calculateRows() {
        this.rows = Math.floor(this.img.nativeElement.offsetHeight / (this.rowHeight + this.gutterSize));
    }
}
