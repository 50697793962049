import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../core/core-services/services/dataservices/data.service";

@Injectable()
export class TemplatesCategoryListResolver implements Resolve<any> {
    constructor(
        private dataService: DataService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.dataService.get({ url: "v1/user/template-category", isLoader: false });
    }
}
