import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { AddNewCompanyComponent } from './add-new-company/add-new-company.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { SuperAdminApiService } from '../services/super-admin-api.service';
import { NavigationService } from 'src/app/core/core-services/services/navigation.service';
import { ConfirmChangesComponent } from './confirm-changes/confirm-changes.component';
import { LoadingService } from 'src/app/core/core-services/services/loader.service';
import { DeleteCompanyComponent } from './delete-company/delete-company.component';
import { CompanyFiltersComponent } from './company-filters/company-filters.component';
import { AuthService } from 'src/app/core/core-services/services/auth.service';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';

@Component({
  selector: 'app-admin-companies',
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.scss']
})
export class AdminCompaniesComponent implements OnInit {
  deactivatedTab: boolean = false;
  subscriptions = new Subscription();
  search: string;
  dateAvailable: string = moment().subtract(1, "day").format("MMM, DD YYYY");
  downloadingCsv: boolean = false;
  displayedColumns: string[] = [
    "select",
    "company",
    "users",
    "contract_term",
    "contract_start_date",
    "contract_end_date",
    "auto_renewal",
    "payment_options",
    "account_status",
    "last_modified_on",
    "actions",
    "collapse"
  ];
  allChecked: boolean = false;
  selectedSort: string = 'last_added';
  @ViewChild(MatSort) sort: MatSort;
  paymentOptions: any[] = [];
  statuses: any[] = [];
  dataSource: any;
  expandedElement: any | null;
  loadingCompanies: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private nav: NavigationService,
    private apiService: SuperAdminApiService,
    public dialog: DialogService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private config: NgbPopoverConfig,
    public authService: AuthService,
    private dataService: DataService
  ) { 
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        switch (params.get("path")) {
          case "add-new-company":
            this.addNewCompany();
            break;
          case "deactivated":
            this.deactivatedTab = true;
            break;

          default:
            this.deactivatedTab = false;
            break;
        }
      })
    );

    this.loadCompanies(this.deactivatedTab);

    this.dataService.downloadComplete$.subscribe(filename => {
      if(["Companies.csv"].includes(filename))
      this.notification.toastWithConfig("The CSV has compiled and downloaded to your computer", null, {
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: ["green-notification"],
      });
    });
  }

  loadCompanies(deactivated: boolean = false): void {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }
    
    this.loadingCompanies = true;
    this.apiService.getCompanies(deactivated).subscribe({
      next: (response: any) => {
        if (response?.result?.success == true){
          let companies = response.result.data;

          companies.forEach((company: any) => {
            let flattenedGroups = [];
            let groups = company?.groups;

            if (Array.isArray(groups) && groups.length > 0) {
              groups.forEach((group: any) => {
                flattenedGroups.push({...group, is_group: true})
                if (Array.isArray(group?.subgroups) && group?.subgroups?.length > 0) {
                  flattenedGroups = flattenedGroups.concat(group.subgroups)
                }
              })
              company.groups = new MatTableDataSource(flattenedGroups);
            }
          });

          this.dataSource = new MatTableDataSource(companies);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paymentOptions = [...new Map(
            companies.map((company: any) => [company.payment_options, {label: company.payment_options, checked: true}])
          ).values()];
          this.statuses = [...new Map(
            companies.map((company: any) => [company.account_status, {label: company.account_status, checked: true}])
          ).values()];

          const queryParams = this.route.snapshot.queryParams;

          if(queryParams && queryParams['deactivate_company']){
            const company = companies.find((company: any) => company.slug == queryParams['deactivate_company']);

            if(company){
              this.openDeactivateCompanyDialog(company);
            }
          }
        }
      },
      error: (error: any) => console.log(error),
      complete: () => this.loadingCompanies = false
    });
  }

  ngAfterViewInit() {
    this.disableHeaderSorting();
  }

  get showActionButton(): boolean {
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length > 0
    );
  }

  addNewCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      AddNewCompanyComponent,
      { class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((result:any) => {
      if(!!result){
        this.dataSource.data = [result].concat(this.dataSource.data)
      }
    })
  }

  bulkDeactivateCompany(): void {
    this.openDeactivateCompanyDialog(
      this.dataSource.connect().value.filter((c: any) => c.checked)
    );
  }

  searchCompanies(): void {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      CompanyFiltersComponent, {companies: this.dataSource.data, filtersClear: this.filtersClear}, "1154px"
    );

    dialogRef.afterClosed().subscribe((selectedCompanies: any) => {
      if(!!selectedCompanies) {

        if(selectedCompanies.length > 0) {
          this.dataSource.filterPredicate = (data: any, filter: any) => {
            return filter.includes(data['name']);
          }
          
          this.dataSource.filter = selectedCompanies.map((c: any) => c.name);
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
            this.resetFilterPredicate();
            this.dataSource.filter = ""
        }
        
      }

    })
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => {
          return currentTerm + (data as { [key: string]: any })[key] + " ";
        }, "")
        .toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  get filtersClear(): boolean {
    return (!this.search || this.search?.trim().length < 1)  
      && this.selectedSort === 'last_added'
      && this.statuses.every((t:any) => t.checked === true) 
      && this.paymentOptions.every((t:any) => t.checked === true)
      && (!this.dataSource?.filter || this.dataSource?.filter?.length < 1);
  }

  clearFilters(): void {
    this.search = "";
    this.searchCompanies();
    this.sortBy('created_at', 'desc');

    this.statuses.forEach((t:any) => t.checked = true);
    this.paymentOptions.forEach((t:any) => t.checked = true);
  }

  get enableExportCsv(): boolean {
    return (
      this.dataSource.data.filter((t: any) => t.checked).length > 0
    );
  }

  exportCompanies(): void {
    this.downloadingCsv = true;

    const selectedCompanies = this.dataSource.data.filter((t: any) => t.checked).map((company: any) => company.id);
    
    this.apiService.exportCSV(selectedCompanies.join(','), this.deactivatedTab);

    this.downloadingCsv = false;
  }

  someChecked(): boolean {
    if (this.dataSource.connect().value == null) {
      return false;
    }
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean): void {
    this.allChecked = checked;
    if (this.dataSource.connect().value == null) {
      return;
    }
    this.dataSource.connect().value.forEach((t: any) => (t.checked = checked));
  }

  updateAllChecked(): void {
    this.allChecked =
      this.dataSource.connect().value != null &&
      this.dataSource.connect().value.every((t: any) => t.checked);
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll('.mat-sort-header-container');
    matSortHeaderElements.forEach(element => {
      (element as HTMLElement).style.cursor = 'default';
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  sortBy(sortKey: string, direction: SortDirection = 'asc') {
    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === 'name' && direction === 'asc') {
      this.selectedSort = 'a_z';
    } else if (sortKey === 'name' && direction === 'desc') {
      this.selectedSort = 'z_a';
    } else if (sortKey === 'created_at' && direction === 'asc') {
      this.selectedSort = 'first_added';
    } else if (sortKey === 'created_at' && direction === 'desc') {
      this.selectedSort = 'last_added';
    }
  }

  customFilterApplied(data: any[]): boolean {
    return data.some((g: any) => g.checked === false)
  }

  filterByOptions(columnName: 'payment_options' | 'account_status') {
    const options = columnName == 'payment_options' ? this.paymentOptions : this.statuses;
    const filter = options.filter((t:any) => t.checked).map((t:any) => t.label);

    if (filter.length < 1) {
      this.search = "";
      this.searchCompanies();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data[columnName]);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  bulkReactivateCompany(): void {
    this.openReactivateCompanyDialog(
      this.dataSource.connect().value.filter((c: any) => c.checked)
    );
  }

  openReactivateCompanyDialog(data: any): void {
    let companies: any;

    if (Array.isArray(data) && data.length > 0){
      companies = data;
    } else {
      companies = [data]
    }

    this.dialog.openDialogComponent(
      ConfirmChangesComponent,
      { 
        action: "reactivate-company",
        data: companies,
        self: this, 
        companyName: companies[0].name , 
        callback: "reactivate" 
      },
      "500px"
    );
  }

  reactivate(companes: any[]): void {
    this.loadingService.start();

    const companyIds = companes.map((company: any) => company.id);

    this.apiService.reactivateCompany(companyIds).subscribe({
      next: (response: any) => {
        const notificationMessage = companyIds.length > 1 ? "Selected companies reactivated" : companes[0].name + " reactivated successfully";

        this.notification.toastWithConfig(notificationMessage, null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["green-notification"],
        });

        this.nav.navigateTo(["/vengreso-admin/companies"]);
      },
      error: (err) => console.log(err),
      complete: () => this.loadingService.stop()
    })
  }

  goToAccountCenter(company: any): void {
    this.authService.masqueradeAs({
      company_id: company.id, 
      company_slug: company.slug,
      company_name: company.name
    });
    this.nav.navigateToUrl(`/${company.slug}/admin/users`);
  }

  openDeactivateCompanyDialog(company: any): void {
    let companies: any;

    if (Array.isArray(company) && company.length > 0){
      companies = company;
    } else {
      companies = [company]
    }

    this.dialog.openDialogComponent(
      ConfirmChangesComponent,
      { 
        action: "deactivate-company",
        data: companies,
        self: this, 
        companyName: companies[0].name , 
        callback: "deactivate" 
      },
      "500px"
    );
  }

  deactivate(companies: any[]): void {
    this.loadingService.start();

    const companyIds = companies.map((company: any) => company.id);

    this.apiService.deactivateCompany(companyIds).subscribe({
      next: (response: any) => {
        const notificationMessage = companyIds.length > 1 ? "Selected companies deactivated" : companies[0].name + " deactivated successfully";

        this.notification.toastWithConfig(notificationMessage, null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["red-notification"],
        });

        this.nav.navigateTo(["/vengreso-admin/companies/deactivated"]);
      },
      error: (err) => {
        console.log(err),
        this.loadingService.stop()
      },
      complete: () => this.loadingService.stop()
    })
  }

  openDeleteCompanyDialog(company: any): void {
    const dialogRef = this.dialog.openDialogComponent(
      DeleteCompanyComponent,
      {
        data: company,
      },
      "600px"
    );

    dialogRef.afterClosed().subscribe((companyId: any) => {
      if(!!companyId){
        this.dataSource.data = this.dataSource.data.filter((c: any) => c.id !== companyId)
      }
    })
  }

  toggleRow(element: any, event: Event): void {
    event.stopPropagation();
    
    element?.groups !== null
      ? (this.expandedElement =
          this.expandedElement === element ? null : element)
      : null;
    this.cd.detectChanges();
  }

  goToCompany(companySlug: string, event: Event) {
    if(this.deactivatedTab){
      return;
    }

    if ((event.target as HTMLElement).nodeName === 'TD' && (event.target as HTMLElement).getAttribute('clickable-cell')) {
      return;
    }

    if(!companySlug){
      return;
    }

    this.nav.navigateTo([`/vengreso-admin/companies/${companySlug}/company-info`]);
  }
}
