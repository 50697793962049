import { Injectable } from '@angular/core';

import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';

export interface Result<T = any> {
  result: {
    data?: T;
    success: boolean;
    message?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SuperAdminApiService {

  constructor(private dataService: DataService) { }

  getUsers({
    perPage,
    page,
    deactivated = false,
    categories
  }:{
    perPage: number
    page: number
    deactivated?: boolean
    categories?: string
  }) {
    return this.dataService.get({
      url: `v1/client-management/users`,
      data: {
        perPage,
        page,
        categories: categories.substring(0, 5100),
        deactivated
      }
    });
  }

  getUserCategories() {
    return this.dataService.get({
      url: `v1/client-management/users-categories`
    });
  }

  /**
   * data = {
      "first_name": "Test",
      "last_name": "User",
      "email": "user@gmail.com"
    }
  */
  addUserManually(data: any) {
    return this.dataService.post({
      url: "v1/client-management/users/add-user-manually",
      data: data,
    });
  }

 /**
  * data = {
  *  users : [
  *    {"email" : "example@gmail.com"},
  *    {"email" : "example@gmail.com"}
  *  ]
  * }
  */
  addUserByEmail(data: any) {
    return this.dataService.post({
      url: "v1/client-management/users/add-user-by-emails",
      data: data,
    });
  }

  /**
   * Sends invitations to users.
   * 
   * data = {
      "emails": [
        "user@gmail.com",
        "user2@gmail.com"
      ]
    }
   *
   */
    resendUserInvitation(data: any) {
      return this.dataService.post({
        url: `v1/client-management/users/resend-user-invitation`,
        data: data,
      });
    }

  /**
   * user_id = db id
   * data = {
      "email": "john@company.com",
      "first_name": "John",
      "last_name": "Smith"
    }
   */
  updateUser(user_id: string, data: any, is_invite: boolean = false) {
    return this.dataService.put({
      url: `v1/client-management/users/${user_id}`,
      data: data,
    });
  }

  /**
   * Assigns role to user
   * 
   * data = {
      "role_name": "Group Admin",
      "groups": [
          "665ecd70f55cb17b6e0296f6",
          "665ecd70f55cb17b6e0296f4"
        ]
      }
   *
   */
  assignRole(user_id: string, data: any) {
    return this.dataService.put({
      url: `v1/client-management/users/${user_id}/assign-role`,
      data: data,
    });
  }

  /**
   * Assign and or Move onr or more users to Company
   * 
   * data = [
   *  {
   *    "company_id": "665ecd70f55cb17b6e0296f6",
   *    "role_name": "Group Admin",
   *    "plan_identifier": "growth-yearly",
   *    "user_ids": [
   *         "665ecd70f55cb17b6e0296f6",
   *         "665ecd70f55cb17b6e0296f6",
   *     ]
   *  }
   * ]
   * 
   */
  assignMoveTo(data: any) {
    return this.dataService.post({
      url: `v1/client-management/users/move-to`,
      data: data,
    });
  }

  /**
   * Sends Reset password request email to one or more users.
   * 
   * data = {
      "user_ids": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param {any} data - An object containing an array of user IDs.
   */
    resetPassword(data: any) {
      return this.dataService.post({
        url: `v1/client-management/users/reset-password`,
        data: data,
      });
    }

  exportUsersCSV(with_deactivated: boolean = false, selected_users?: any) {
    const urlAppends = selected_users ? `&users=${selected_users}` : '';

    return this.dataService.download({
      url: "v1/client-management/users/export-csv?deactivated=" + with_deactivated + urlAppends,
    }, "Users.csv");
  }

  /**
   * Delete users by passing an array of DB ids for one or more users
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
   deleteUsers(data: any) {
     return this.dataService.post({
       url: `v1/client-management/users/delete`,
       data: data,
     });
   }

   /**
   * Deactiavte users by passing an array of User id or ids
   * 
   * data = {
      "user_ids": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
    deactivateUsers(data: any) {
      return this.dataService.post({
        url: `v1/client-management/users/deactivate`,
        data: data,
      });
    }

  /**
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
    reactivateUsers(data: any) {
      return this.dataService.post({
        url: `v1/client-management/users/reactivate`,
        data: data,
      });
    }

  getCompanies(deactivated: boolean = false) {
    return this.dataService.get({
      url: `v1/client-management/companies?deactivated=${deactivated}`
    });
  }

  /**
   * @param companyData : {
   *  company: any,
   *  pocs: {email: string, first_name: string, last_name: string}[]
   * }
   */
  addCompany(companyData: any) {
    return this.dataService.post({
      url: `v1/client-management/companies`,
      data: companyData
    });
  }

  getCompanyInfoBySlug(slug: string) {
    return this.dataService.get({
      url: `v1/client-management/companies/${slug}`
    });
  }

  getCompanyUsers(slug: string, load_admins_only : boolean = false) {
    return this.dataService.get({
      url: `v1/client-management/company/${slug}/users?load_admins_only=${load_admins_only}`
    });
  }

  getCompanyGroups(slug: string) {
    return this.dataService.get({
      url: `v1/client-management/company/${slug}/groups`
    });
  }

  getCompanyGroupUsers(companySlug:string, groupSlug: string) {
    return this.dataService.get({
      url: `v1/client-management/company/${companySlug}/group-users/${groupSlug}`
    });
  }

  /**
   * 
   * @param {any} companyData 
   * @param {string} slug
   */
  editCompany(slug:string, companyData: any) {
    return this.dataService.put({
      url: `v1/client-management/companies/${slug}`,
      data: companyData
    });
  }

   /**
   * 
   * @param {string[]} companyIds 
   */
  deactivateCompany(companyIds: string[]) {
    return this.dataService.post({
      url: `v1/client-management/companies/deactivate`,
      data: {company_ids : companyIds}
    });
  }

  /**
   * 
   * @param {string[]} companyIds 
   */
  reactivateCompany(companyIds: string[]){
    return this.dataService.post({
      url: `v1/client-management/companies/reactivate`,
      data: {company_ids : companyIds}
    });
  }

  /**
   * 
   * @param companyId 
   * @param data {
   *   company_id: string, // Id of the new company
   *   role: string,
   *   selected_plan: string
   *  }
   */
  deleteCompany(companyId: string, data?: any) {
    return this.dataService.post({
      url: `v1/client-management/company/${companyId}/delete`,
      data
    });
  }

  getCompaniesLicenses(): any {
    return this.dataService.get({
      url: `v1/client-management/companies-licenses`
    });
  }

  exportCSV(company_ids: any, deactivated: boolean = false) {
    return this.dataService.download({
      url: `v1/client-management/companies/export/csv?company_ids=${company_ids}&deactivated=${deactivated}`
    }, "Companies.csv");
  }
}
