import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../core/core-services/services/auth.service';
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { NavigationService } from '../core/core-services/services/navigation.service';
import { Subscription } from 'rxjs';
import { NotifyService } from '../core/menu/not.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  user: any;
  userRole:string = '';
  planId: any;
  mainNav: string = '';
  navItems = [];
  notifierSubscription: Subscription =
    this.notifyService.subjectNotifier.subscribe(() => {
      this.userRole = this.authService.getUserRole();
    });

  constructor (
    public authService: AuthService,
    public notifyService: NotifyService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const {user_details} = this.authService.getUserDetail(true);
    this.user = user_details;

    this.userRole = this.authService.getUserRole();
    this.planId = getPlanId();

    switch (this.userRole) {
      case 'Vengreso Admin':
        if(this.companyMasqueradeMode){
          this.setCustomerAdminNav();
        } else {
          this.setVengresoAdminNav();
        }
        break;
      case 'Global Admin':
      case 'Reporting Admin':
      case 'Group Admin':
        this.setCustomerAdminNav();
        break;
    
      default:
        this.goToDashboard();
        break;
    }
  }

  get companyMasqueradeMode(): boolean {
    return this.userRole === 'Vengreso Admin' && this.authService.onCompanyMasqueradeSession;
  }

  setCustomerAdminNav(){
    this.mainNav = 'Account Center';
    const companySlug = this.authService.getUserCompanySlug();
    this.navItems = [
      { label: 'Users', link: `/${companySlug}/admin/users` },
      { label: 'Groups', link: `/${companySlug}/admin/groups` },
      { label: 'Reporting', link: `/${companySlug}/admin/reporting` }
    ];
  }

  setVengresoAdminNav(){
    this.mainNav = 'Client Mgmt Center';
    this.navItems = [
      { label: 'Users', link: '/vengreso-admin/users' },
      { label: 'Companies', link: '/vengreso-admin/companies' },
      { label: 'Reporting', link: '/vengreso-admin/reporting' }
    ];
  }

  goToClientManagement(): void {
    this.authService.endMasqueradeSession();
    this.setVengresoAdminNav();
    this.router.navigate(["/vengreso-admin/users"]);
  }

  goToDashboard(): void {
    this.router.navigate(["/flyboard"]);
  }

  goToSettings(): void {
    this.router.navigate(["/settings"], {
      queryParams: { tab: "subscription" },
    });
  }

  goToPlayList(): void {
    window.open("https://www.youtube.com/@Vengreso/playlists", "_blank");
  }

  navigateToExtension() {
    let site =
      "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
    if (localStorage.getItem("browser_type") == "edge") {
      site =
        "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
    }
    window.open(site, "_blank");
  }

  ngOnDestroy(): void {
    this.notifierSubscription.unsubscribe();
  }
}
