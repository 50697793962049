import { createAction, props } from '@ngrx/store';

export const loadUserCurrentSubscriptionApi = createAction(
    '[User Current Subscription API] Invoke Fetch User Current Subscription',
    props<{refetchFromApi?: boolean}>()
);

export const loadUserCurrentSubscriptionApiSuccess = createAction(
    '[User Current Subscription API] Success callback',
    props<{payload: any}>()
);

export const loadUserCurrentSubscriptionApiFailure = createAction(
    '[User Current Subscription API] Error callback',
    props<{error: any}>()
);
