import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';

@Component({
  selector: 'app-removal-confirmation',
  templateUrl: './removal-confirmation.component.html',
  styleUrls: ['./removal-confirmation.component.css']
})
export class RemovalConfirmationComponent implements OnInit {

  public paymentMethod: any;
  public paymenthMethodAttachedToActivePlan: Boolean = false;
  disabled: Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : {paymentMethod : any},
    public dialogRef: MatDialogRef<RemovalConfirmationComponent>,
    private router: Router,
    private dataService: DataService,
    private notification: NotificationService
  ) {
    this.paymentMethod = data.paymentMethod;
   }

  ngOnInit(): void {
  }

  close(val: string = '') {
    this.dialogRef.close(val);
  }

  addPaymentmethod(): void {
    this.close();
    this.router.navigate(['add-payment-method']);
  }

  removeMethod(paymentMethod) {
    this.disabled = true;
    if (paymentMethod.isPrimary) {
      this.notification.toast("Card is primary payment method!", "Error");
      this.disabled = false;
      return;
    }

    this.dataService
    .get({ url: "v1/subscriptions/payments/methods/remove/"+paymentMethod.id, isLoader: true })
    .subscribe({
      next: (response) => {
      if (response) {
        this.notification.toast("Success", "Ok", 2000);
        this.close('success');
      }
     },
     error: () => {
      this.notification.toast("Something went wrong, please contact administrator", "Ok", 2000)
      this.disabled = false;
     }
   });
  }

}
