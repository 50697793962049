import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { CategoryRenameComponent } from "../add-shortcut-category/category-rename/category-rename.component";
import { NotificationService } from "../../../core/core-services/services/notification.service";
import { LocalStorageService, LocalStorage } from "ngx-webstorage";
import { distinctUntilChanged } from "rxjs/operators";
import { ShortcutCategoryService, SHORTCUT_CATEGORIES } from "src/app/core/core-services/services/shortcut-category.service";

@Component({
  selector: "app-change-shortcut-category",
  templateUrl: "./change-shortcut-category.component.html",
  styleUrls: ["./change-shortcut-category.component.scss"],
})
export class ChangeShortcutCategoryComponent implements OnInit {
  @LocalStorage(SHORTCUT_CATEGORIES) shortcutCategoryList: any[];
  filteredCategoryList: any;
  exactMatch: boolean = false;
  filterCtrl = new FormControl();
  category = new FormControl();
  createdCategoryId: string;
  selected: string;
  @ViewChild("selectElem") selectElem: any;
  category_level = 0;
  constructor(
    public dialogRef: MatDialogRef<ChangeShortcutCategoryComponent>,
    public dialog: DialogService,
    private notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
    private storage: LocalStorageService,
    private shortcutCategoryService: ShortcutCategoryService
  ) {}

  ngOnInit(): void {
    this.getShortcutCategoryList();

    this.storage.observe(SHORTCUT_CATEGORIES)
    .pipe(
      distinctUntilChanged(
        (prev, curr) => prev.value === curr.value
      )
    )
    .subscribe((value) => {
          this.shortcutCategoryList = value;
          this.filteredCategoryList = [...this.shortcutCategoryList];
          this.setSelectedCategory();
    });

    this.filteredCategoryList = [...this.shortcutCategoryList];
    this.setSelectedCategory();
    this.filterCtrl.valueChanges.subscribe(() => {
      this.filterCategories();
    });
  }

  filterCategories() {
    this.exactMatch = false;
    let search = this.filterCtrl.value;
    search = search.toLowerCase();
    if (!search) {
      this.filteredCategoryList = [...this.shortcutCategoryList];
      return;
    }

    let filteredCategoryList = [];
    for (const o of this.shortcutCategoryList) {
      o.category_level = 0;
      let catSearch = o.name.toLowerCase().indexOf(search) > -1;
      if (catSearch) {
        this.exactMatch = search === o.name.toLowerCase() ? true : false;
        filteredCategoryList.push({ ...o, sub_category_lv1: [] });
        continue;
      }
      for (const sub_category_lv1 of o.sub_category_lv1) {
        sub_category_lv1.category_level = 1;

        let catSearch =
          sub_category_lv1.name.toLowerCase().indexOf(search) > -1;
        if (catSearch) {
          this.exactMatch =
            search === sub_category_lv1.name.toLowerCase() ? true : false;
          filteredCategoryList.push({
            ...sub_category_lv1,
            sub_category_lv1: [],
            name: `${o.name} / ${sub_category_lv1.name}`,
          });
          continue;
        }
        for (const sub_category_lv2 of sub_category_lv1.sub_category_lv2) {
          let catSearch =
            sub_category_lv2.name.toLowerCase().indexOf(search) > -1;
          if (catSearch) {
            this.exactMatch =
              search === sub_category_lv2.name.toLowerCase() ? true : false;
            filteredCategoryList.push({
              ...sub_category_lv2,
              sub_category_lv1: [],
              name: `${o.name} / ${sub_category_lv1.name} / ${sub_category_lv2.name}`,
            });
            continue;
          }
        }
      }
    }
    this.filteredCategoryList = filteredCategoryList;
  }

  saveCategory(data) {
    this.dataService.post({ url: "v1/user/shortcut-category", data }).subscribe(
      (response: any) => {
        if (response && response.result) {
          this.createdCategoryId = response.result;
          this.getShortcutCategoryList(true);
          this.selectElem.close();
          this.notification.toast("Category added successfully!");
        }
      },
      () => {}
    );
  }

  setSelectedCategory(){
    let selectedCat = this.createdCategoryId || this.data.data.category_id || this.shortcutCategoryList[0].id;
    this.setCategoryLevel(0);
    let set = false;

    if ((this.data?.data?.sub_categories_lv2_name && this.data?.data?.sub_categories_lv2_name != "") || this.data?.data?.sub_categories_lv2_id) {
      this.selected = this.data.data.sub_categories_lv2_id;
      this.category.setValue(this.data.data.sub_categories_lv2_id);
      this.setCategoryLevel(2);
      set = true;
    }

    if (!set && ((this.data?.data?.sub_categories_lv1_name && this.data?.data?.sub_categories_lv1_name != "") || this.data?.data?.sub_categories_lv1_id)) {
      this.selected = this.data.data.sub_categories_lv1_id;
      this.category.setValue(this.data.data.sub_categories_lv1_id);
      this.setCategoryLevel(1);
      set = true;
    }

    if (!set || !this.selected) {
      this.selected = selectedCat;
      this.category.setValue(selectedCat);
    }
  }

  shortcutCategoryRename(data: any) {
    this.dialog.openDialogComponent(CategoryRenameComponent, {
      self: this,
      data,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  setCategoryLevel(level):void{
    this.category_level=level
  }
  
  onSaveCLick(): void {
    const shortcut: any = this.dataService.toSave(this.data.data, [
      "title",
      "shortcut",
      "html",
      "category_id",
    ]);

    shortcut.category_id = this.category.value;
    shortcut.category_level = this.getCategoryLV(this.category.value);
    this.data.self.shortcutUpdate(this.data.data, shortcut, true);
    this.getShortcutCategoryList(true);
    this.dialogRef.close();
  }

  getCategoryLV(id) {
    for (const lv0Item of this.shortcutCategoryList) {
      if (lv0Item.sub_categories_lv1?.includes(id)) {
        return 1
      }
      
      for (const lv1Item of lv0Item.sub_category_lv1) {
        if (lv1Item.sub_categories_lv2?.includes(id)) {
          return 2
        }
        
      }
    }
    return 0
  }

  getShortcutCategoryList(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({reloadFromApi});
  }
}
