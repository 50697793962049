import { Injectable } from "@angular/core";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";

class Config {
    self: any;
    listName: string;
    url: string;
    callback?: any;
    skip?: number;
    limit?: number;
}

@Injectable()

export class ListService {

    config: Config;

    public isLoading = false;

    public fromRoot = true;
    public scrollDistance = 1;
    public scrollThrottle = 60;
    public scrollDisabled = true;
    public scrollContainer = ".mat-drawer-content";

    constructor(
        private dataService: DataService
    ) { }

    init(config: Config, data: any[]) {
        this.config = config;
        this.config.skip = this.config.skip || 0;
        this.config.limit = this.config.limit || 10;
        this.config.self[this.config.listName] = [];
        this.appendResult(data);
    }

    appendResult(list: any[], retainLimit?: boolean) {
        if (Array.isArray(list)) {
            this.config.self[this.config.listName] = retainLimit ? list : this.config.self[this.config.listName].concat(list);

            // How many record need to skip for next time.
            // Enable or disable pagination based on condition.
            this.config.skip += list.length;
            this.scrollDisabled = list.length < this.config.limit;

            if (typeof this.config.callback === "function") {
                this.config.callback();
            }
        } else {
            this.scrollDisabled = true;
        }
    }

    getList(retainLimit?: boolean) {
      if(this.isLoading) return
        let limit = this.config.limit;
        if (retainLimit) {
            this.config.skip = 0;
            const len = this.config.self[this.config.listName].length;
            limit = len < this.config.limit ? this.config.limit : len;
        }

        this.isLoading = true;
        this.dataService.get({
            url: this.config.url + "skip=" + this.config.skip + "&limit=" + limit,
            isLoader: false
          }).subscribe((response: any) => {
            this.isLoading = false;
            if (response && response.result) {
                this.appendResult(response.result, retainLimit);
            }
        }, () => {
            this.isLoading = false;
        });
    }

}
