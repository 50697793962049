import { Component, Input } from "@angular/core";

@Component({
  selector: "loading-top-five",
  templateUrl: "./loading-top-five.component.html",
  styleUrls: ["./loading-top-five.component.scss"],
})
export class LoadingTopFiveComponent {
  linesCount: number[] = Array(5).fill(0)
}
