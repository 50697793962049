import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';

import { SubscriptionPlanType } from "../../../core/models/stripe.models";
import { 
  CTA_DOWNGRADE_TO_FREEMIUM_URL,
  CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_URL, 
  CTA_GROWTH_DOWNGRADE_TO_STARTER_URL 
} from '../settings.config';
@Component({
  selector: 'app-downgrade-plan-three',
  templateUrl: './downgrade-plan-three.component.html',
  styleUrls: ['./downgrade-plan-three.component.css']
})
export class DowngradePlanThreeComponent implements OnInit {

  planDowngradedFrom: string = '';
  planDowngradedTo: string = SubscriptionPlanType.Freemium;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : {planDowngradedFrom:string, planDowngradedTo : string},
    public dialog: DialogService,
    public dialogRef: MatDialogRef<DowngradePlanThreeComponent>
  ) { 
    this.planDowngradedTo = data.planDowngradedTo;
    this.planDowngradedFrom = data.planDowngradedFrom;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  downgradePlan() {
   
    //TODO: Update Backend
    window.location.href = this.growthToFreemium() ? CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_URL :
      this.growthToStarter() ? CTA_GROWTH_DOWNGRADE_TO_STARTER_URL :
        this.starterToFreemium() ? CTA_DOWNGRADE_TO_FREEMIUM_URL :
          '';
  }

  isDowngradeToFreemium() {
    return this.planDowngradedTo === SubscriptionPlanType.Freemium;
  }

  starterToFreemium() {
    return (
      (this.planDowngradedFrom === SubscriptionPlanType.Starter || this.planDowngradedFrom === SubscriptionPlanType.YearlyStarter) &&
      this.planDowngradedTo === SubscriptionPlanType.Freemium
    );
  }

  growthToStarter() {
    return (
      (this.planDowngradedFrom === SubscriptionPlanType.Growth || this.planDowngradedFrom === SubscriptionPlanType.YearlyGrowth) &&
      this.planDowngradedTo === SubscriptionPlanType.Starter
    );
  }

  growthToFreemium() {
    return (
      (this.planDowngradedFrom === SubscriptionPlanType.Growth || this.planDowngradedFrom === SubscriptionPlanType.YearlyGrowth) &&
      this.planDowngradedTo === SubscriptionPlanType.Freemium
    );
  }

}
