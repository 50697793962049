import { Component, OnInit, Inject } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { findIndex } from "lodash";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";

@Component({
  selector: "app-delete-version",
  templateUrl: "./delete-version.component.html",
  styleUrls: ["./delete-version.component.scss"],
})
export class DeleteVersionComponent implements OnInit {
  isRemoved = false

  constructor(
    config: NgbPopoverConfig,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteVersionComponent>,
    public dialog: DialogService,
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
  }

  removeVersion(): void {
    this.dataService.get({ url: 
      `v1/user/shortcut_version/delete/${this.data.self.data.shortcut.id}/${this.data.element.id}/${this.data.element?.version_id}`
    }).subscribe(
      (response: any) => {
        if (response && response.result) {
          const index = findIndex(this.data.self.data.self.shortcuts, { id: this.data.self.data.shortcut.id })
          if (isNaN(this.data.self.data.self.shortcuts[index].version_counts)) {
            this.data.self.data.self.shortcuts[index].version_counts = 1;
          } else {
            this.data.self.data.self.shortcuts[index].version_counts--;
          }
          //this.data.self.getAllVersions();
          this.isRemoved = true
        }
      },
      () => {}
    );
  }

  close(): void {
    this.dialogRef.close(this.isRemoved);
  }
}
