import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { NavigationService } from "./navigation.service";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,  private nav: NavigationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url?: string): boolean {

    if (this.isLoggedIn()) {
      return true;
    }

    // Store the attempted URL for redirecting
    if (url)
      this.authService.redirectUrl = url;

    // Navigate to the login page with extras
    if (!window.document.URL.includes('session')) {
      window.open(window.document.baseURI + 'session/signin', '_self');
    }
    return false;
  }

  validateStatus() {
    setTimeout(() => {
      if (this.checkLogin()) {
        if (window.document.URL.includes('signin')) {
          window.open(window.document.baseURI + 'flyboard', '_self');
          return
        }
      } else
        this.validateStatus()
    }, 3000);
  }

  isLoggedIn() {
    let acc = localStorage.getItem("vengreso.accessToken");
    let acc2 = localStorage.getItem("vengreso.userDetails");
    return Boolean(
      localStorage.getItem("vengreso.accessToken") &&
      localStorage.getItem("vengreso.userDetails")
    );
  }
}
