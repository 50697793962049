import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subscription, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
} from "rxjs/operators";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { AdvancedSearchDialogComponent } from "../advanced-search-dialog/advanced-search-dialog.component";
import { VideoContentComponent } from "./../../../dashboard/video-content/video-content.component";

import { Location } from "@angular/common";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDatepicker } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from "@angular/router";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
@Component({
  selector: "app-top-search-bar",
  templateUrl: "./top-search-bar.component.html",
  styleUrls: ["./top-search-bar.component.scss"],
})
export class TopSearchBarComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public src: string;
  public myControl = new FormControl();
  filteredOptions: Observable<any>;
  advancedSearch = false;
  currentDialog: any;
  flycut: string;
  title: string;
  hasTheWords: string;
  doesntHave: string;
  createdWithin: string;
  minDate: any;
  maxDate: any;
  bySearch: string;
  hasAttachment: boolean;
  hasHyperlink: boolean;
  date: string;
  createdWithinPicker: MatDatepicker<any>;
  show: any;
  progressValue = 0;
  // Initialized to today
  model: any = { jsdate: new Date() };
  progresshow = false;

  currentUrl: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private location: Location,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any
  ) {
    let user = JSON.parse(localStorage.getItem("vengreso.userDetails"));
    let onboarding_percentage = JSON.parse(
      localStorage.getItem("onboarding_percentage")
    );
    this.progressValue =
      onboarding_percentage || user.user_details.onboarding_percentage || 0;

      //PROGRESS BAR SHOULD BE SHOWED FOR ALL USERS
      this.progresshow = true

      this.subscriptions.push(
        router.events.subscribe((val) => {
          // reset search query
          if (
            val instanceof NavigationEnd &&
            val.url != "/advanced-search-result" &&
            val.url == val.urlAfterRedirects
          ) {
            this.myControl.setValue("");
          }
        })
      );
    
  }
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  async ngOnInit(): Promise<void> {
    this.checkIfIsOnboardingProgressTrainingUrl()
    this.isLoading = true;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val) => {
        return this.search(val);
      }),
      finalize(() => (this.isLoading = false))
    );
  }
  clicked() {
    this.advancedSearch = false;
    this.isLoading = false;
    this.autocomplete.openPanel();
  }
  search(value: any): any {
    if (!value) {
      return of([]);
    }

    this.isLoading = false;
    return this.dataService
      .searchTrack({ q: value })
      .pipe(map((tracks) => tracks.result.data));
  }

  displayFn(value: any): string {
    return value ? value.name : undefined;
  }

  toggleAdvanced() {
    this.advancedSearch = true;
    this.openDialog();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  openDialog() {
    this.autocomplete.closePanel();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "35%";

    const isSideMenuOpened = localStorage.getItem('sidePanelOpened')
    dialogConfig.position = {
      left: isSideMenuOpened == 'true' ? "240px" : "63px",
      top: "3.5%",
    };
    dialogConfig.panelClass = "advanced-search";

    this.currentDialog = this.dialog.open(
      AdvancedSearchDialogComponent,
      dialogConfig
    );

    this.currentDialog.beforeClosed().subscribe((result) => {
      this.advancedSearch = false;
      this.isLoading = false;
      this.autocomplete.openPanel();
    });
  }

  goToAdvancedSearch() {
    // if (!this.myControl.value.id?.trim()) {
    //   return;
    // }

    this.isLoading = true;
    const navigationExtras: NavigationExtras = {
      state: {
        searchValue: this.myControl.value,
      },
    };
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/advanced-search-result"], navigationExtras);
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.goToAdvancedSearch();
    }
  }

  clickOption() {
    this.autocomplete.closePanel();
    this.goToAdvancedSearch();
  }

  showVideo() {
    this.location.replaceState('onboardingprogress_training');
    this.currentUrl = this.router.url
    const modal = this.dialogService.openDialogComponent(VideoContentComponent, {
      self: this,
      class: "video-dialog",
    });
    this.afterModalIsClosed(modal)
  }

  checkIfIsOnboardingProgressTrainingUrl(){
    if(this.router.url === '/onboardingprogress_training'){
      this.showVideo()
      return
    }
   
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if(params?.showonboardingprogresstraining === 'true'){
          this.showVideo()
          return
        }
      })
    );
    
  }

  afterModalIsClosed<T>(modal: MatDialogRef<T>) {
    modal.afterClosed().subscribe({
      next: (data) => {
        if(data?.action === 'retake_onboarding'){
          return
        }
        if(this.currentUrl === '/flyboard?showonboardingprogresstraining=true'){
          this.location.replaceState('flyboard');
          return
        }
        this.location.replaceState(this.currentUrl || 'flyboard');
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
