import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { SuperAdminApiService } from '../../services/super-admin-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ROLES } from 'src/app/core/core-services/constants/roles.constant';
import { NotifyService } from 'src/app/core/menu/not.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-company',
  templateUrl: './delete-company.component.html',
  styleUrls: ['./delete-company.component.scss']
})
export class DeleteCompanyComponent implements OnInit {
  company: any;
  loading: boolean = false;
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  assignMoveToForm: FormGroup;
  companies: any[] = [];
  selectedCompany: any;
  companySelectOpened: boolean = false;
  roles: any[] = ROLES;
  notifierSubscription: Subscription =
    this.notifyService.subjectNotifier.subscribe((notified) => {
      if(notified && notified.action == 'company-added'){
        this.loadCompaniesInfo();
      }
  });

  constructor(
    public dialogRef: MatDialogRef<DeleteCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notification: NotificationService,
    private apiService: SuperAdminApiService,
    private fb: FormBuilder,
    public notifyService: NotifyService,
  ) {
    this.company = data.data
  }

  ngOnInit(): void {
    this.assignMoveToForm = this.fb.group({	              
      company_id: [null, [Validators.required]],
      role: ['User', [Validators.required]],
      selected_plan: [null, [Validators.required]]
    });

    this.loadCompaniesInfo()
  }

  loadCompaniesInfo(){
    this.apiService.getCompaniesLicenses().subscribe({
      next: (response: any) => {
        if (response.result.success){
          this.companies = response.result.data.filter((c: any) => c.id !== this.company.id).map(
            (company: any) => ({
              ...company, 
              isSelected: false
            })
          )
        }
      },
      error: (err) => console.log(err)
    })
  }

  onSelectionChange(id:string): void {
    this.selectedCompany = this.companies.find((company: any) => company.id === id);
  }

  confirm(): void {
    if(this.company.users < 1) {
      this.loading = true;
      this.apiService.deleteCompany(this.company.id).subscribe({
        next: (response: any) => {
          if (response.result.success){
            this.close(this.company.id)
            this.notification.toastWithConfig(this.company.name + " deleted successfully", null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["red-notification"],
            });
          }
        },
        error: (err) => console.log(err),
        complete: () => this.loading = false
      });

      return;
    }

    if(this.stepOne){
      this.moveToStep(2)
      return;
    }

    if(this.stepTwo){
      this.moveToStep(3)
      return;
    }

    this.loading = true;
    this.apiService.deleteCompany(this.company.id, this.assignMoveToForm.value).subscribe({
      next: (response: any) => {
        if (response.result.success){
          this.dialogRef.close(this.company.id);
          this.notification.toastWithConfig(this.company.name + " deleted successfully. All users were assigned to "+ this.selectedCompany.name, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
        }
      },
      error: (err) => console.log(err),
      complete: () => this.loading = false
    });
  }

  moveToStep(step: number) {
    this.stepOne = step === 1;
    this.stepTwo = step === 2;
    this.stepThree = step === 3;
  }

  ngOnDestroy(): void {
  }

  close(data?: any): void {
    if(this.stepThree) {
      this.moveToStep(2);
      return;
    }

    if(this.stepTwo) {
      this.loading = true;
      this.apiService.deleteCompany(this.company.id).subscribe({
        next: (response: any) => {
          if (response.result.success){
            this.dialogRef.close(this.company.id);
            this.notification.toastWithConfig(this.company.name + " deleted successfully", null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["red-notification"],
            });
          }
        },
        error: (err) => console.log(err),
        complete: () => this.loading = false
      });
      return;
    }

    this.dialogRef.close(data);
  }

}
