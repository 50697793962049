import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';

@Component({
  selector: 'app-primary-method-confirmation',
  templateUrl: './primary-method-confirmation.component.html',
  styleUrls: ['./primary-method-confirmation.component.css']
})
export class PrimaryMethodConfirmationComponent implements OnInit {

  public paymentMethod: any;
  disabled: Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : {paymentMethod : any},
    public dialogRef: MatDialogRef<PrimaryMethodConfirmationComponent>,
    private dataService: DataService,
    private notification: NotificationService
  ) { 
    this.paymentMethod = data.paymentMethod;
  }

  ngOnInit(): void {
  }

  close(val: string = '') {
    this.dialogRef.close(val);
  }

  makePrimary(paymentMethod: any) {
    this.disabled = true;
    this.dataService.post({ url: "v1/user/set_default_payment_method", data: {
      payment_method_id: paymentMethod.id
    }, isLoader: false }).subscribe({
      next: (response) => {
        this.notification.toast("Success", "Ok", 2000);
        this.close('success');
     },
     error: () => {
      this.disabled = false;
     }
  });
  }

}
