import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { DowngradePlanOneComponent } from '../downgrade-plan-one/downgrade-plan-one.component';
import { PausedPlanComponent } from '../paused-plan/paused-plan.component';

import {
  getPlanId
} from "src/app/core/core-services/constants/plans.constants";
import { SubscriptionPlanType } from 'src/app/core/models/stripe.models';
import { SUBSCRIPTION_TAB } from '../settings.config';

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.css']
})
export class EditPlanComponent implements OnInit,OnDestroy {

  currentPlan;

  constructor(
    public dialog: DialogService,
    public dialogRef: MatDialogRef<EditPlanComponent>,
    private location: Location
  ) {

    this.currentPlan = getPlanId();

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.location.replaceState(`settings?tab=${SUBSCRIPTION_TAB}`);
  }

  close(): void {
    this.dialogRef.close();
  }

  pausePlan(): void {
    this.close();
    //TODO: Send to backend
    this.dialog.openDialogComponent(PausedPlanComponent, {}, "500px");
  }

  downgradePlan(): void {
    this.close();
    //TODO: Send to backend
    this.dialog.openDialogComponent(DowngradePlanOneComponent, {}, "500px");
  }

  isGrowthPlan(): boolean {
    return this.currentPlan === SubscriptionPlanType.Growth;
  }

}
