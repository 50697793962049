import { Injectable } from "@angular/core";
import { forEach } from "lodash";

import juice from "juice";

import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";
import {
  PlansConstants,
  PlansRestrictions,
  RewardsConstants,
  getPlanId,
  getRewards,
} from "src/app/core/core-services/constants/plans.constants";
import { environment } from "src/environments/environment";
import { GiphyDialogComponent } from "../giphy-dialog/giphy-dialog.component";
import { WarningDialogComponent } from "../warning-dialog/warning-dialog.component";
import { DataService } from "./dataservices/data.service";
import { DialogService } from "./dialog.service";

let imgSize = 0;
const TOKEN_PROVIDER_URL = 'v1/drive/token',
  TINYDRIVE_GOOGLE_DRIVE_KEY = `${environment.GOOGLE_CLIENT_SECRET}`,
  TINYDRIVE_GOOGLE_DRIVE_CLIENT_ID = `${environment.GOOGLE_CLIENT_ID}`,
  TINYDRIVE_DROPBOX_APP_KEY = `${environment.TINYDRIVE_DROPBOX_APP_KEY}`;
@Injectable()
export class ShortcutService {
  maxImageSize = 600;
  DOMAIN_REGEX = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;
  imageTagIdentifier = "img[src]:not([src=''])";
  anchorTagIdentifier = "a[href]:not([href=''])";
  editorConfig: any;
  beforeCommandExec = 0;
  //list of the restricted feature to growth plan
  Growth = PlansRestrictions.Growth;
  //list of the restricted feature to starter plan
  Starter = PlansRestrictions.Starter;
  //list of the restricted feature to free plan
  Free = PlansRestrictions.Free;
  uploadedImageSize: any = {};

  resizeImage = (file: Blob, maxWidth: number, maxHeight: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth: number;
        let newHeight: number;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type, 1);
      };
      image.onerror = reject;
    });
  };

  imageUploadHandler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const blobData = blobInfo.blob();
      const blobSize = 0.000001 * blobData.size;
      imgSize = blobSize;
      if (blobSize > 20) {
        reject("Media size is too large");
        return;
      }
      // Resize image to max size
      this.resizeImage(blobData, this.maxImageSize, this.maxImageSize).then(
        (blob) => {
          // Max size 5MB validation
          // if (blob.size >= (1024 * 1024 * 5)) {
          //   failure("Max upload size is 5MB");
          //   return;
          // }

          const reader = new FileReader();
          const data = blobData.type == 'image/gif' ? blobData : blob
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            const base64data = reader.result.toString();
            const Imageextension = base64data.substring(
              "data:image/".length,
              base64data.indexOf(";base64")
            );
            const imageData = {
              file: base64data,
              extension: Imageextension,
            };

            // if (Imageextension == 'gif') {//&& blob.size >= (1942880)
            //   this.dataService
            //     .postExternal({
            //       url: environment.API_URL + " /v1/user/upload/base64",//this constant url as Ahmed mentioned to let it like this for now
            //       // url: "http://54.224.89.138/romeo/api/v1/user/upload/base64",//this constant url as Ahmed mentioned to let it like this for now
            //       data: imageData,
            //       isLoader: false,
            //     })
            //     .subscribe(
            //       (response: any) => {
            //         if (response && response.result && response.result.url) {
            //           const {url, size} = response.result;
            //           //size in kb
            //           this.uploadedImageSize[url] = parseFloat((size/1024).toFixed(2));
            //           resolve(url);
            //         } else if (response && response.error) {
            //           reject(response.error);
            //         } else {
            //           reject("Image upload failed!");
            //         }
            //       },
            //       (error) => {
            //         console.log(error);
            //         reject("Image upload failed!");
            //       }
            //     );
            // } else {
              this.dataService
                .post({
                  url: "v1/user/upload/base64",
                  data: imageData,
                  isLoader: false,
                })
                .subscribe(
                  (response: any) => {
                    if (response && response.result && response.result.url) {
                      const {url, size} = response.result;
                      //size in kb
                      this.uploadedImageSize[url] = parseFloat((size/1024).toFixed(2));
                      resolve(url);
                    } else if (response && response.error) {
                      reject(response.error);
                    } else {
                      reject("Image upload failed!");
                    }
                  },
                  (error) => {
                    console.log(error);
                    reject("Image upload failed!");
                  }
                );
           // }
          };
        },
        (err) => {
          console.error(err);
          reject("Image resize failed!");
        }
      );
    });

  constructor(
    private dataService: DataService,
    private dialog: DialogService) { }

  isValidateShortcut(html) {
    let planId = getPlanId();
    if (planId == "Growth") return true;
    if (planId == "Pro Teams - ENT" || planId == "Pro Teams - SMB") {
      planId = "Growth" //This is easier than refactoring all the check for planId==Growth to give pro features
      return true;
    }

    html = html.replaceAll("font-size: 12pt;", "");
    if (this[planId]?.includes("FontSize") && html.includes("font-size: ")) {
      return false;
    }

    if (this[planId]?.includes("italic") && html.includes("<em>")) {
      return false;
    }

    if (
      this[planId]?.includes("underline") &&
      html.includes("text-decoration: underline;")
    ) {
      return false;
    }

    if (
      this[planId]?.includes("strikethrough") &&
      html.includes("text-decoration: underline;")
    ) {
      return false;
    }

    if (
      this[planId]?.includes("InsertUnorderedList") &&
      html.includes("</ul>")
    ) {
      return false;
    }

    if (this[planId]?.includes("InsertOrderedList") && html.includes("</ol>")) {
      return false;
    }

    if (this[planId]?.includes("hyperlinks") && html.includes("</a>")) {
      return false;
    }

    if (
      this[planId]?.includes("mceApplyTextcolor") &&
      html.includes("background-color:")
    ) {
      return false;
    }

    if (
      this[planId]?.includes("mceApplyTextcolor") &&
      html.includes("color:")
    ) {
      return false;
    }

    if (
      html.includes("</pre>") ||
      html.includes("</p>") ||
      html.includes("</h1>") ||
      html.includes("</h2>") ||
      html.includes("</h3>") ||
      html.includes("</h4>") ||
      html.includes("</h5>") ||
      html.includes("</h6>")
    ) {
      return false;
    }

    if (
      this[planId]?.includes("JustifyCenter") &&
      html.includes("text-align: center;")
    ) {
      return false;
    }

    if (
      this[planId]?.includes("JustifyRight") &&
      html.includes("text-align: right;")
    ) {
      return false;
    }

    if (this[planId]?.includes("indent") && html.includes("padding-left:")) {
      return false;
    }

    if (this[planId]?.includes("outdent") && html.includes("padding-right:")) {
      return false;
    }

    if (this[planId]?.includes("FontName") && html.includes("font-family:")) {
      return false;
    }

    let rewards = getRewards();
    if (
      !rewards.length &&
      !rewards.includes(RewardsConstants.UNLIMITED_CHARACTERS)
    ) {
      let length = html
        .replaceAll("&nbsp;", " ")
        .replace(/<\/?[^>]+(>|$)/g, "").length;
      if (planId === "Free" && length >= PlansConstants.FREE.NUM_OF_CHAR) {
        return false;
      }
      if (
        planId === "Starter" &&
        length >= PlansConstants.STARTER.NUM_OF_CHAR
      ) {
        return false;
      }
    }

    return true;
  }

  // get the tinyMC config based on user plan
  getEditorConfig() {
    let planId = getPlanId();
    if (planId == "Pro Teams - ENT" || planId == "Pro Teams - SMB") {
      planId = "Growth" //This is easier than refactoring all the check for planId==Growth to give pro features
    }
    const instance = this;
    this.editorConfig = {
      tinydrive_token_provider: (success: (arg0: { token: any; }) => void, failure: (arg0: string) => void) => {
        this.dataService
          .post({
            url: TOKEN_PROVIDER_URL,
            data: {},
            isLoader: false,
          })
          .subscribe({
            next: (response: any) => {
              success({ token: response.result.token });
            },
            error: (error) => {
              failure(error.message);
            },
          });
      },
      tinydrive_google_drive_key: TINYDRIVE_GOOGLE_DRIVE_KEY,
      tinydrive_google_drive_client_id: TINYDRIVE_GOOGLE_DRIVE_CLIENT_ID,
      tinydrive_dropbox_app_key: TINYDRIVE_DROPBOX_APP_KEY,
      statusbar: false,
      menubar: false,
      min_height: 100,
      max_height: 500,
      // autoresize_on_init: false,
      toolbar_mode: "sliding",
      indentation: planId === "Growth" ? "30px" : "0px",
      // formats: instance.formatOption(planId),
      // font_formats: instance.fontFormat(planId),
      font_family_formats: instance.fontFormat(planId),
      color_map: instance.colorFormat(planId),

      custom_colors: planId === "Growth" ? true : false,

      // image options
      image_description: false,
      // imagetools_cors_hosts: [environment.AWS_S3_DOMAIN],

      // link options
      link_title: false,
      link_default_target: "_blank",
      link_default_protocol: "https",
      relative_urls: true,
      document_base_url: "http://www.example.com/path1/",
      spellchecker_language: "en_us",

      // Handle Pasted content
      paste_webkit_styles:
        "color background-color font-size font-family text-align text-decoration \
        margin padding padding-left list-style-type",

      // https://www.tiny.cloud/docs/configure/content-filtering/#forced_root_block
      // If you set this option to false it will never produce p tags on enter, or,
      // automatically it will instead produce br elements and Shift+Enter will produce a p.
      forced_root_block: "div",
      block_formats:
        "Normal=div; Heading 1=h1; Heading 2=h2; Heading 3=h3;\
      Heading 4=h4; Heading 5=h5; Heading 6=h6; Preformatted=pre",

      content_style: ApplicationConstants.TINYMCE_CONTENT_CSS,

      font_size_formats: instance.fontSizeFormat(planId),
      // Upload image to s3
      images_upload_handler: instance.imageUploadHandler,

      // Disable toolbar options for non premier user
      setup: (editor: any) => {
        //   https://www.tiny.cloud/docs/tinymce/6/custom-basic-toolbar-button/
        // https://www.tiny.cloud/docs/tinymce/6/custom-toolbarbuttons/
        editor.ui.registry.addButton('GIF_button', {
          text: 'GIF',
          onAction: (_) => {
            const insertContent = (content) => {
              editor.insertContent(content)
            }
            instance.dialog.openDialogComponent(
              GiphyDialogComponent,
              { type: "editor", showTheStarter: true, error: 'blockStyle', class: "black-bg", insertContent },
              "720px"
            );
          }

        }),
          editor.on("ExecCommand", function (e: any) {
            if (e.command === "mceUpdateImage") return;
            if (instance.isValidateShortcut(editor.getContent())) {
              if (
                e.command === "mceToggleFormat" &&
                (instance[planId]?.includes(e.value) ||
                  instance[planId]?.includes(e.command))
              ) {
                if (
                  ["InsertUnorderedList", "InsertOrderedList"].includes(e.command)
                ) {
                  editor.execCommand("RemoveList");
                }
                let showTheStarter = !(
                  instance["Starter"]?.includes(e.value) ||
                  instance["Starter"]?.includes(e.command)
                );
                instance.dialog.openDialogComponent(
                  WarningDialogComponent,
                  { type: "editor", showTheStarter, error: e.value || e.command },
                  "800px"
                );
              }
            }
          });
        editor.on("BeforeExecCommand", function (e: any) {
          if (e.command === "mceFocus") {
            return;
          }
          if (instance.isValidateShortcut(editor.getContent())) {
            //Block Styling (Headings)
            if (
              planId !== "Growth" &&
              e.command == "mceToggleFormat" &&
              ["h1", "h2", "h3", "h4", "h5", "h6", "p", "pre"].includes(e.value)
            ) {
              instance.dialog.openDialogComponent(
                WarningDialogComponent,
                { type: "editor", showTheStarter: false, error: "blockStyle" },
                "800px"
              );
              return false;
            }
            if (
              e.command === "mceToggleFormat" &&
              (instance[planId]?.includes(e.value) ||
                instance[planId]?.includes(e.command))
            ) {
              if (
                ["InsertUnorderedList", "InsertOrderedList"].includes(e.command)
              ) {
                editor.execCommand("RemoveList");
              }
              let showTheStarter = !(
                instance["Starter"]?.includes(e.value) ||
                instance["Starter"]?.includes(e.command)
              );
              instance.dialog.openDialogComponent(
                WarningDialogComponent,
                { type: "editor", showTheStarter, error: e.value || e.command },
                "800px"
              );
              return false;
            }
            if (instance[planId]?.includes(e.command)) {
              if (e.command === "mceUpdateImage") {
                if (
                  planId === "Free" &&
                  imgSize > PlansConstants.FREE.IMG_SIZE
                ) {
                  instance.dialog.openDialogComponent(
                    WarningDialogComponent,
                    {
                      type: "images",
                      showTheStarter:
                        imgSize > PlansConstants.STARTER.IMG_SIZE
                          ? false
                          : true,
                      error: "imagesFree",
                    },
                    "800px"
                  );
                  return false;
                }
                if (
                  planId === "Starter" &&
                  imgSize > PlansConstants.STARTER.IMG_SIZE
                ) {
                  instance.dialog.openDialogComponent(
                    WarningDialogComponent,
                    {
                      type: "images",
                      showTheStarter: false,
                      error: "imagesStarter",
                    },
                    "800px"
                  );
                  return false;
                }
              } else {
                // if (e.command === "mceApplyTextcolor") {
                //   if (e.value === "#000000") return
                //   e.target.editorManager.execCommand('mceApplyTextcolor', "forecolor", "#000000")
                //   e.target.editorManager.execCommand('ForeColor', true, '#000000')
                // }

                //enable freemuim user to select 10pt, 11pt, 12pt font size
                if (e.command === "FontSize" && (e.value === "10pt" || e.value === "11pt" || e.value === "12pt")) return;
                //enable freemuim user to select Arial as font type
                if (
                  e.command === "FontName" &&
                  (e.value === "arial,helvetica,sans-serif" || e.value === "calibri" || e.value === "helvetica,sans-serif")
                )
                  return;

                let showTheStarter = !instance["Starter"]?.includes(e.command);
                let error = "";
                if (e.command == "mceApplyTextcolor") {
                  error =
                    e.ui == "hilitecolor" ? "backgroundColor" : "fontColor";
                }
                instance.dialog.openDialogComponent(
                  WarningDialogComponent,
                  { type: "editor", showTheStarter, error: error || e.command },
                  "800px"
                );
                return false;
              }
            }

            //this is for hyperlinks
            var matches = [];
            const regex = /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g;
            const urlsFound = e.value?.content?.match(regex);
            if (urlsFound) {
              urlsFound.forEach((element) => {
                let para = document.createElement("div");
                para.innerHTML = element;
                matches.push({
                  actualText: element,
                  replacedText: para.innerText,
                });
              });
            }
            if (
              typeof e.value?.content == "string" &&
              matches.length != 0 &&
              instance[planId]?.includes("hyperlinks")
            ) {
              for (let index = 0; index < matches.length; index++) {
                const element = matches[index];
                //those for giphy so we don't replace them
                if (
                  element.actualText.indexOf("onemob.com") != -1 ||
                  element.actualText.indexOf("h-vd.io") != -1 ||
                  element.actualText.indexOf("loom.com") != -1
                )
                  continue;
                //replace the urls with normal string
                e.value.content = e.value.content?.replace(
                  element.actualText,
                  element.replacedText
                );
              }
              return;
              // let showTheStarter = !(instance["Starter"]?.includes("hyperlinks"))
              // instance.dialog.openDialogComponent(
              //   WarningDialogComponent,
              //   { type: "editor", showTheStarter },
              //   "800px"
              // );
              // return false;
            }

            //this is for copy paste
            if (e.value?.paste && instance[planId]?.includes("paste")) {
              let showTheStarter = !instance["Starter"]?.includes("paste");
              instance.dialog.openDialogComponent(
                WarningDialogComponent,
                { type: "editor", showTheStarter },
                "800px"
              );
              return false;
            }
          }
        });
        editor.on("SpellcheckerLanguageChanged", function (e: any) {
          if (instance.isValidateShortcut(editor.getContent())) {
            if (planId == "Free" && e.language != "en_us") {
              instance.dialog.openDialogComponent(
                WarningDialogComponent,
                { type: "editor", showTheStarter: true, error: "spellchecker" },
                "800px"
              );
              e.target.plugins.tinymcespellchecker.setLanguage("en_us");
              return false;
            }
          }
        });
      },

      paste_postprocess: (plugin: any, args: any) => {
        const images: NodeListOf<HTMLImageElement> = args.node.querySelectorAll(
          this.imageTagIdentifier
        );
        forEach(images, (image: HTMLImageElement) => {
          image.style.maxWidth = this.maxImageSize.toString() + "px";
          image.style.maxHeight = this.maxImageSize.toString() + "px";
        });
      },

      plugins: [
        "searchreplace",
        "preview",
        "paste",
        "autoresize",
        "tinymcespellchecker",
        planId == "Growth" ? "autolink" : "",
        "lists",
        "link",
        "image",
        "preview",
        "advlist",
        "emoticons",
        "media",
        "mediaembed",
        "tinydrive",
        "editimage",
      ], 
      paste_as_text: planId === "Free", 
      contextmenu: false,
      toolbar:
        "bold italic underline strikethrough | insertfile link image media GIF_button | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist | spellchecker emoticons | outdent indent | \
      forecolor backcolor removeformat | blocks | \
      fontsize | fontfamily | preview",
    };
    return this.editorConfig;
  }

  // get the text format based on user plan
  formatOption(planId) {
    let options = {};
    switch (planId) {
      case "Starter":
        options = {
          alignleft: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          aligncenter: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          alignright: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          alignfull: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          indent: [
            {
              inline: "ul",
              classes: "disable-text-indent",
            },
          ],
          outdent: [
            {
              inline: "ul",
              classes: "disable-text-indent",
            },
          ],
        };
        break;
      case "Growth":
        options = {};
        break;
      case "Free":
        options = {
          // bold: [
          //     {
          //         inline: "div",
          //         classes: "disable-bold",
          //     },
          // ],
          italic: [
            {
              inline: "div",
              classes: "disable-italic",
            },
          ],
          strikethrough: [
            {
              inline: "div",
              classes: "disable-text-decoration",
            },
          ],
          underline: [
            {
              inline: "div",
              classes: "disable-text-decoration",
            },
          ],
          alignleft: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          aligncenter: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          alignright: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          alignfull: [
            {
              inline: "div",
              classes: "disable-text-align",
            },
          ],
          indent: [
            {
              inline: "ul",
              classes: "disable-text-indent",
            },
          ],
          outdent: [
            {
              inline: "ul",
              classes: "disable-text-indent",
            },
          ],
        };
    }
    return options;
  }

  // get the text size format based on user plan
  fontSizeFormat(planId) {
    return "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 24pt 30pt 36pt";
  }

  // get the text font format based on user plan
  fontFormat(planId) {
    return "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Impact=impact,chicago; Sans Serif=helvetica,sans-serif; Symbol=symbol; Helvetica=helvetica; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;";
  }

  // get the text color format based on user plan
  colorFormat(planId) {
    return [
      "#BFEDD2",
      "Light Green",
      "#FBEEB8",
      "Light Yellow",
      "#F8CAC6",
      "Light Red",
      "#ECCAFA",
      "Light Purple",
      "#C2E0F4",
      "Light Blue",

      "#2DC26B",
      "Green",
      "#F1C40F",
      "Yellow",
      "#E03E2D",
      "Red",
      "#B96AD9",
      "Purple",
      "#3598DB",
      "Blue",

      "#169179",
      "Dark Turquoise",
      "#E67E23",
      "Orange",
      "#BA372A",
      "Dark Red",
      "#843FA1",
      "Dark Purple",
      "#236FA1",
      "Dark Blue",

      "#ECF0F1",
      "Light Gray",
      "#CED4D9",
      "Medium Gray",
      "#95A5A6",
      "Gray",
      "#7E8C8D",
      "Dark Gray",
      "#34495E",
      "Navy Blue",

      "#000000",
      "Black",
      "#ffffff",
      "White",
    ];
  }

  resizePastedImage(image: HTMLImageElement) {
    const width = image.width;
    const height = image.height;

    if (width && height) {
      let newWidth: number;
      let newHeight: number;

      if (width > height) {
        newHeight = height * (this.maxImageSize / width);
        newWidth = this.maxImageSize;
      } else {
        newWidth = width * (this.maxImageSize / height);
        newHeight = this.maxImageSize;
      }

      image.setAttribute("width", newWidth.toString());
      image.setAttribute("height", newHeight.toString());
    }
  }

  processMedia(editor: any, callback: (uploads: string[]) => void, existingUploads?: any) {
    if (!editor) {
      console.error("Invalid editor");
      return;
    }

    const uploads = [];
    const doc: Document = editor.dom.doc;
    const images: NodeListOf<HTMLImageElement> = doc.querySelectorAll(
      this.imageTagIdentifier
    );

    if(existingUploads?.length > 0) {
      existingUploads.forEach((upload: any) => {
        if(upload.hasOwnProperty('size')) {
          this.uploadedImageSize[upload.source] = upload.size;
        }
      });
    }

    // Process images
    // 1. get local uploads and set alt attribute to image's src if it's not hyperlinked
    // 2. set max size for images
    forEach(images, async (image: HTMLImageElement) => {
      const domain = image.src.match(this.DOMAIN_REGEX);
      if (domain && domain[1] && domain[1] === environment.AWS_S3_DOMAIN) {
        const size = this.uploadedImageSize[image.src] ?? await this.getImageSizeInKB(image.src) ?? 0;
        uploads.push({source: image.src, size});
      } else {
        this.resizePastedImage(image);
      }

      if (image.closest(this.anchorTagIdentifier) === null) {
        image.setAttribute("alt", image.getAttribute("src"));
      }
    });

    // Process links: set link to alt attribute of image if it's hyperlinked
    const anchorTags: NodeListOf<HTMLAnchorElement> = doc.querySelectorAll(
      this.anchorTagIdentifier
    );
    forEach(anchorTags, (anchorTag: HTMLAnchorElement) => {
      const imageTags: NodeListOf<HTMLImageElement> =
        anchorTag.querySelectorAll(this.imageTagIdentifier);
      if (imageTags.length === 1) {
        imageTags[0].setAttribute("alt", anchorTag.getAttribute("href"));
      }
    });

    if (typeof callback === "function") {
      callback(uploads);
    }
  }

  async getImageSizeInKB(url: string): Promise<number | null> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        return 0;
        //throw new Error('Network response was not ok');
      } 
      const blob = await response.blob();
      const sizeInBytes = blob.size;
      const sizeInKB = sizeInBytes / 1024;
      return sizeInKB;
    } catch (error) {
      console.error('Error fetching image:', error);
      return 0;
    }
  }

  getInlineStyledHTML(html = "") {
    return juice(
      "<style>" +
      ApplicationConstants.TINYMCE_CONTENT_CSS +
      "</style>" +
      "<div id='editorContentWrapper'>" +
      html +
      "</div>"
    );
  }

  getFirstLine(editor: any) {
    if (!editor) {
      console.error("Invalid editor");
      return;
    }

    return /[^\n]*/.exec(editor.dom.doc.body.innerText)[0].trim();
  }
}
