import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";

import { ValidationConstants } from "src/app/core/core-services/constants/validation.constants";
import { Option } from "../models/option.model";
import { AdminApiService } from "../services/admin-api.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { AddGroupComponent } from "../add-group/add-group.component";
import { lastValueFrom } from 'rxjs';
import { Router } from "@angular/router";

interface GroupWithSubgroups extends Option {
  subgroups: Option[];
}

@Component({
  selector: "app-add-user-by-email",
  templateUrl: "./add-user-by-email.component.html",
  styleUrls: ["./add-user-by-email.component.scss"],
})
export class AddUserByEmailComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupCtrl = new FormControl("");
  subgroupCtrl = new FormControl("");
  emails: any[] = [];
  invalidEmail: boolean = false;
  assignGroup = false;
  emailValidation: ValidatorFn[] = [
    Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX),
    Validators.minLength(5),
    Validators.email,
    Validators.required,
    this.createUniqueEmailValidator(this.emails),
  ];
  emailCtrl: FormControl = new FormControl("",{
    validators: this.emailValidation
  });
  plans: any = [
    {
      value: "Starter",
      label: "Starter",
      licensesAvailable: 0,
      totalLicenses: 0
    },
    {
      value: "Growth",
      label: "Growth",
      licensesAvailable: 0,
      totalLicenses: 0
    },
    {
      value: "Sales Pro",
      label: "Sales Pro",
      licensesAvailable: 0,
      totalLicenses: 0
    },
    {
      value: "Sales Pro Teams",
      label: "Sales Pro Teams",
      licensesAvailable: 0,
      totalLicenses: 0
    }
  ];

  groups: GroupWithSubgroups[] = [];
  subgroups: Option[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddUserByEmailComponent>,
    public dialog: DialogService,
    private adminApiService: AdminApiService,
    private notification: NotificationService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) { }

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if(!currentUrl.includes('add-by-email')){
      this.location.replaceState(currentUrl+'/add-by-email');
    }

    this.adminApiService.getGroups().subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        const newGroups: GroupWithSubgroups[] = response.result.groups.map(
          (group: any) => ({
            value: group.group.id,
            displayValue: group.group.name,
            subgroups: group.group.subgroups?.map((subgroup: any) => ({
              value: subgroup.group.id,
              displayValue: subgroup.group.name,
            })),
          })
        );
        this.groups = newGroups;
      }
    });

    this.groupCtrl.valueChanges.subscribe((selectedGroupValue) => {
      const selectedGroup = this.groups.find(
        (group) => group.value === selectedGroupValue
      );
      this.subgroups = selectedGroup ? selectedGroup.subgroups : [];
      this.subgroupCtrl.reset();
    });

    this.adminApiService.getCompanyLicences().subscribe({
      next: (response: any) => {
        this.plans = response?.result?.licences.map((plan: any) => ({...plan, totalLicenses: plan.licensesAvailable}));
      }, 
      error: (error) => console.log(error)
    }); 
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole() === "Vengreso Admin";
  }

  onAdd() {
    let data = {};
    const users = [];

    if (this.emails.length > 0) {
      this.emails.forEach((user: any) => {
        users.push({
          email: user.address,
          plan_id: user.plan,
        });
      });

      data = {
        users: users,
        group_id: this.groupCtrl.value,
        subgroup_id: this.subgroupCtrl.value,
      };
    }

    this.adminApiService.addUserByEmail(data).subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        this.notification.toastWithConfig("Users added", null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["green-notification"],
        });
        this.dialogRef.close(response.result.users);
      } // else show error message
    });
  }

  async add(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.emailCtrl.setValue(value);

    if(this.emailCtrl.errors !== null) {
      return;
    }
   
    const response: any =  await lastValueFrom(this.adminApiService.validateUserEmailUniqueness(value));

    if (response && response.result && response.result.exists === true) {
      this.invalidEmail = true;
      this.notification.toast("Please enter a valid or unique email address.", "OK");
      return;
    } else {
      this.invalidEmail = false;
    }

    // Add email
    this.emails.push({ address: value, plan: "Starter", isSelected: false });
    this.onAssignLicense();

    // Clear the input value
    event.chipInput!.clear();
    this.emailCtrl.setValue(null);
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  createUniqueEmailValidator(emailArray: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasEmail = emailArray.some((obj) => obj.address === value);

      return hasEmail ? { uniqueEmail: true } : null;
    };
  }

  get invalidEmails(): boolean {
    if (!this.emailCtrl.value?.trim()) {
      return false;
    }

    const emails =
      this.emailCtrl.touched || this.emailCtrl.dirty
        ? this.emails?.concat({ address: this.emailCtrl.value })
        : this.emails;

    return emails.some(
      (email) =>
        new FormControl(email?.address?.toLowerCase(), this.emailValidation)
          .invalid
    );
  }

  planLabel(plan: string): string {
    return this.plans.find((planObj: any) => planObj.value === plan)?.label;
  }

  onAssignLicense(event?: any): void {
    this.plans.forEach((plan: any) => {
      let assignedLicenses = this.emails.filter((user: any) => user.plan === plan.value)?.length ?? 0;
      let unassignedLicenses = plan.totalLicenses - assignedLicenses;
      plan.licensesAvailable = unassignedLicenses > 0 ? unassignedLicenses : 0;
    });
  }

  get emailLink(): string {
    const { first_name, last_name, company } = this.authService.getUserDetail();
    const body: string = `
    "Hello FlyMSG team"
    I'd like to inquire about adding on _______ more licenses to my account.
    Account name: ${company.name}
    Administrator Requesting: ${first_name} ${last_name}`;

    return `mailto:sales@vengreso.com?subject=${encodeURIComponent(
      "Additional License Inquiry"
    )}&body=${encodeURIComponent(body)}`;
  }

  addNewGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent, {action: "add-group", class: "portal-admin"},
      "450px"
    );

    dialogHandle.afterClosed().subscribe((group: any) => {
      if(!!group){
        this.groups = this.groups.concat({
          value: group.group.id,
          displayValue: group.group.name,
          subgroups: [],
        });
      }
    });
  }

  addNewSubGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent, {action: "add-subgroup", groupId: this.groupCtrl.value, class: "portal-admin"},
      "450px"
    );

    dialogHandle.afterClosed().subscribe((subgroup: any) => {
      if(!!subgroup){
        this.subgroups = this.subgroups.concat({
          value: subgroup.id,
          displayValue: subgroup.name
        });
      }
    });
  }

  ngOnDestroy(): void {
    let currentUrl = this.router.url;

    this.location.replaceState(
      currentUrl.replace("/add-by-email", "")
    );
  }

  onCancel() {
    let currentUrl = this.router.url;
    this.location.replaceState(
      currentUrl.replace("/add-by-email", "")
    );
    this.dialogRef.close();
  }
}
