import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { VideoComponent } from "../video/video.component";
import { Location } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { NgImageSliderComponent } from "ng-image-slider";

@Component({
  selector: "app-video-content",
  templateUrl: "./video-content.component.html",
  styleUrls: ["./video-content.component.scss"],
})
export class VideoContentComponent implements OnInit {
  imageObject: Array<object> = [
    {
      video: "https://www.youtube.com/embed/OHeN6xzqzA8",
      title: "1:20",
    },
    {
      video: "https://www.youtube.com/embed/vXS4S5ZtaL4",
      title: "0:57",
    },
    {
      video: "https://www.youtube.com/embed/iV0ub9bekIo",
      title: "1:10",
    },
    {
      video: "https://www.youtube.com/embed/gnZyQ6P1KA8",
      title: "1:55",
    },
    {
      video: "https://www.youtube.com/embed/y1gA5EMS20c",
      title: "1:20",
    },
    {
      video: "https://www.youtube.com/embed/GL07zxwJ5Zw",
      title: "1:01",
    },
    {
      video: "https://www.youtube.com/embed/QzuL9vARewU",
      title: "1:37",
    },
    {
      video: "https://www.youtube.com/embed/m7I0fVtJzqw",
      title: "3:07",
    },
    {
      video: "https://www.youtube.com/embed/WVYgnRXefLU",
      title: "1:13",
    },
    {
      video: "https://www.youtube.com/embed/gIWlxiAVnbg",
      title: "3:10",
    },
    {
      video: "https://www.youtube.com/embed/DtyZby0m2c8",
      title: "3:10",
    },
    {
      video: "https://www.youtube.com/embed/Uz2mgJrX5ys",
      title: "1:27",
    },
    {
      video: "https://www.youtube.com/embed/djgCUclifR0",
      title: "1:31",
    },
    {
      video: "https://www.youtube.com/embed/OHeN6xzqzA8",
      title: "1:19",
    },
  ];

  determinate: any;
  progressValue: number;
  constructor(
    private dialog: DialogService, 
    private router: Router,
    private location: Location,
    private dialogRef: MatDialogRef<VideoContentComponent>
    ) {}

  playVideo(videoIndex: number) {
    this.dialog.openDialogComponent(
      VideoComponent,
      { self: this, class: "video-dialog", link: this.imageObject[videoIndex] },
      "800px"
    );
  }

  navigateToOnboarding(action?: string){
    if(action === 'retake'){
      console.log('retake')
      this.dialogRef.close({action: "retake_onboarding"})
      this.router.navigate([`/onboarding`], {})
      return
    }
    console.log('resume')
    // this.closeDialog();
    this.dialogRef.close({action: "retake_onboarding"})
    let user = JSON.parse(localStorage.getItem("vengreso.userDetails"));
    let step = JSON.parse(localStorage.getItem("onboarding_step"));
    const onboarding_step = step || user.user_details.onboarding_step || "";
    if (onboarding_step === "10") {
      this.router.navigate([`/onboarding`], {});
    } else {
      this.router.navigate([`/onboarding${onboarding_step}`], {});
    }
  }

  closeDialog() {
    this.dialog.closeDialog();
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("vengreso.userDetails"));
    let onboarding_percentage = JSON.parse(
      localStorage.getItem("onboarding_percentage")
    );
    this.progressValue =
      onboarding_percentage || user.user_details?.onboarding_percentage || 0;
  }

  @ViewChild('nav') slider: NgImageSliderComponent;

    prevImageClick() {
        this.slider.prev();
    }

    nextImageClick() {
        this.slider.next();
    }
}
