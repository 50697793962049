import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { NavigationService } from 'src/app/core/core-services/services/navigation.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {

  displayedColumns = ['plan', 'type', 'billingDate', 'status', 'amount','reason','invoice'];
  dataSource = [];

  constructor(
    private dataService: DataService,
    public nav:NavigationService,
    private notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getInvoices();
  }

  statusClass(status: string): string {
    return status === 'paid' ?  'green' : 'red';
  }

  getInvoices(): void {
    this.dataService.get({ url: "v1/user/payment/details", isLoader: true })
    .subscribe({
      next: (response: any) => {
        const invoices = response?.result?.invoices;

        this.dataSource = invoices && invoices.length > 0 ? invoices : [];
      },
      error: () => {
        this.notification.toast("Something went wrong", "Ok");
      }
    });
  }

  downloadInvoice(url: string): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', `Invoice Billing.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
