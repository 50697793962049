import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Subscription, tap, timer } from "rxjs";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SharedDataService } from "../../core/core-services/services/shared-data.service";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent implements OnInit {

  browser_type;
  show = false;

  timerDuration = environment.production ? 60000 : 8640000
  timerSub$: Subscription

  modalTimer
  modalOpened = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WelcomeComponent>,
    private sharedData: SharedDataService,
    private dialog: DialogService,
    private dialogg: MatDialog,
  ) {    this.browser_type = localStorage.getItem("browser_type");
  if (
    this.browser_type == "firefox" ||
    this.browser_type == "opera" ||
    this.browser_type == "safari"
  ) {
    this.show = false;
  } else {
    this.show = true;
  }}

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  navigateToExtension() {
    this.close();
    let site =
      "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
    if (this.browser_type == "edge") {
      site =
        "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
    }
    window.open(site, "_blank");
  }

  // remindMeLater() {
  //   this.sharedData.setAttribute("welcomeRemindMe", (new Date().getTime()));
  //   this.close();
  // }

  dismiss() {
    this.sharedData.setAttribute("welcomeDismiss", true);
    this.close();
  }

  displayExtensionPrompt(){
    this.sharedData.showExtensionPrompt.next(true)
    this.dismiss()
  }

  
  remindMeLater(){
    this.close()
    clearTimeout(this.modalTimer)

    this.modalTimer = setTimeout(() => {
      this.dialogg.openDialogs.forEach(dialog => {
        if(dialog?.componentInstance?.data?.class === "welcome-dialog"){
          console.log("don't open")
          this.modalOpened = true
          return
        }
      })

      if(!this.modalOpened){
        if(
          location.href.includes('session/signin') || 
          location.href.includes('session/signup') ||
          location.href.includes('session/pricing')
          ){
            return
        }
        this.dialog.openDialogComponent(WelcomeComponent, {
          self: this,
          // do not change the name of this class
          class: "welcome-dialog",
        },
          "400px",
          true
        ); 
      }
    },this.timerDuration)

    ////////////
    // this.timerSub$ = timer(this.timerDuration).pipe(
    //   tap((x)=>{
    //     this.sharedData.showDownloadModalTimer.next(true)
    //   }),
    // ).subscribe();
    // this.sharedData.showDownloadModalTimer.next(false)
    // this.timerSub$?.unsubscribe()
    // this.close()
  }
}
