import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { lastValueFrom, Subscription } from "rxjs";
import { finalize } from 'rxjs/operators';
import {
  NgbPopoverConfig,
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { Chart, ChartConfiguration, ChartType } from "chart.js";
import { MatPaginator } from "@angular/material/paginator";
import Annotation from "chartjs-plugin-annotation";
import { FormControl } from "@angular/forms";

import { StatisticsService } from "src/app/core/core-services/services/statistics.service";
import { EditReportingComponent } from "../edit-reporting/edit-reporting.component";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { TABLE_COLUMN } from "../admin.data";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AdminApiService } from "../services/admin-api.service";
import { LoadingService } from "src/app/core/core-services/services/loader.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { HorizontalScrollDirective } from "./horizontal-scrolling.directive";

@Component({
  selector: "app-reporting",
  templateUrl: "./reporting.component.html",
  styleUrls: ["./reporting.component.scss"]
})
export class ReportingComponent implements OnInit {
  tabs: any[];
  companySlug: string;
  currentTrailingPath: string;
  loadedApis: number = 0;
  downloadingCsv: boolean = false;
  dateAvailable: string = moment().subtract(1, "day").format("MMM, DD YYYY");
  subscriptions = new Subscription();
  overview: any[];

  lineChartType: ChartType = "line";
  lineChartData: ChartConfiguration["data"] =
    this.statisticsService.lineChartData;
  lineChartOptions: ChartConfiguration["options"] =
    this.statisticsService.adminLineChartOptions;
  lineChartPlugins: ChartConfiguration["plugins"] = [];

  roiLineChartType: ChartType = this.lineChartType;
  roiLineChartData: ChartConfiguration["data"] = this.lineChartData;
  roiLineChartOptions: ChartConfiguration["options"] = this.lineChartOptions;

  prodLineChartType: ChartType = this.lineChartType;
  prodLineChartData: ChartConfiguration["data"] = this.lineChartData;
  prodLineChartOptions: ChartConfiguration["options"] = this.lineChartOptions;

  totalCharLineChartType: ChartType = this.lineChartType;
  totalCharLineChartData: ChartConfiguration["data"] = this.lineChartData;
  totalCharLineChartOptions: ChartConfiguration["options"] =
    this.lineChartOptions;

  bar_chart_data: any = [0, 0, 0, 0, 0];
  barChartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: this.bar_chart_data,
        label: "Current Levels",
        backgroundColor: [
          "#FFC20E",
          "#F15A2A",
          "#FFEAAB",
          "#FF9658",
          "#5F177F",
        ],
        borderWidth: 0,
        pointBackgroundColor: "#5F177F",
        fill: "origin",
      },
    ],
    labels: ["Beginner", "Intermediate", "Proficient", "Advanced", "Expert"],
  };
  barChartOptions: ChartConfiguration["options"] = {};
  barChartType: ChartType = "bar";
  barChartlegendItems: { label: string; value: number; color: string }[] = [];

  dateRanges = [
    { text: "Last month", differenceFromNow: 1 },
    { text: "Last 3 months", differenceFromNow: 3 },
    { text: "Last 6 months", differenceFromNow: 6 },
    { text: "Last 9 months", differenceFromNow: 9 },
    { text: "Last 12 months", differenceFromNow: 12 },
    //{ text: "Custom", differenceFromNow: -1 },
  ];
  dateFilterValue: number = 12;
  dateFilterOpened: boolean = false;
  
  @ViewChild("dropdown") dropdown: ElementRef;
  generalFilterView: string = 'groups';
  generalFilterSelected: any = {
    group: [],
    subgroup: [],
    user: [],
  };
  generalFilterOpened: boolean = false;
  generalSearchList: any[] = [];
  generalSearchOptions: any[] = [];
  generalFilterLoading: boolean = false;
  generalFilterSelectedGroup: any;
  generalFilterSelectedSubGroup: any;
  generalFilterSubGroupInGroups: boolean = false;
  groups: any[];

  activeFilterOpened: boolean = false;
  filterCtrl = new FormControl(null);
  activeFilterCtrl = new FormControl([]);
  activeFilterValue: string[] = [];
  activeFilterOptions: string[] = ["Active", "Deactivated", "Not Activated"];
  @ViewChild("selectElem") selectElem: any;
  usageViewMaximised: boolean = true;
  usage: any[];
  top5 = [
    { image: "assets/images/mario.jpg", name: "Jane Smith", count: 90 },
    { image: "assets/images/mario.jpg", name: "Jane Smith", count: 75 },
    { image: "assets/images/mario.jpg", name: "Jane Smith", count: 50 },
    { image: "assets/images/mario.jpg", name: "Jane Smith", count: 24 },
    { image: "assets/images/mario.jpg", name: "Jane Smith", count: 11 },
  ];
  selectedChartLabel: string;
  usageCharts: any[] = [
    {
      label: "Cost Savings ($)",
      tooltip:
        "This shows the total cost savings in USD using the average US knowledge worker wage of $31.65/hour over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "Time Saved (hrs)",
      tooltip:
        "This shows the total amount of time users saved over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "Characters Typed",
      tooltip:
        "This shows the total amount of characters users typed over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyCuts Created",
      tooltip:
        "Total number of FlyCuts created from users over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyPlates Added",
      tooltip:
        "Total number of FlyPlates added to FlyCuts from users over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyEngage AI Used",
      tooltip:
        "Total number of times that FlyEngage was used over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyPosts AI Used",
      tooltip:
        "Total number of times that FlyPost was used over the selected period of time.",
      total: 0,
      average: 0,
      topFive: this.top5,
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
  ];
  filteredUsageCharts: any[];
  extensionChart: any = {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
    type: "line",
    label: "Extension Usage",
    tooltip:
      "The number of extensions installed and uninstalled over the selected period of time.",
  };
  extensionLineLayers = {
    chrome_installed: {
      data: [],
      color: "#00B43D",
      label: "Chrome Installed",
    },
    edge_installed: {
      data: [],
      color: "#58B9FF",
      label: "Edge Installed",
    },
    uninstalls: {
      data: [],
      color: "#FF9658",
      label: "Extension Uninstalled",
    },
  };
  showCustomDatePicker = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  search: string = "";

  allChecked: boolean = false;
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  defaultColumns: string[] = ["first_name", "last_name", "email"];
  selectedColumns: string[] = [
    "license_type",
    "flymsg_account_creation_date",
    "is_the_user_signed_into_flymsg_extension",
    "last_login_to_flymsg",
  ];
  displayedColumns: string[] = this.defaultColumns;
  tableColumns: any = TABLE_COLUMN;

  expert_users: any = 0;
  beginner_users: any = 0;
  total_time_saved: any = 0;
  total_cost_savings: any = 0;
  total_characters_typed: any = 0;

  overviewLength = Array(4).fill(0);
  loadingCharts: boolean;
  loadingRoiLine: boolean;
  loadingProductivity: boolean;
  loadingTotalCharacterTyped: boolean;
  loadingCurrentFlyMsgCoach: boolean;

  loadingExtensionUsage: boolean;

  constructor(
    private route: ActivatedRoute,
    config: NgbPopoverConfig,
    public statisticsService: StatisticsService,
    private calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public dialog: DialogService,
    private authService: AuthService,
    private adminApiService: AdminApiService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private notification: NotificationService,
  ) {
    this.companySlug = this.authService.getUserCompanySlug();

    let adminBaseUrl = this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession
      ? "/vengreso-admin/reporting/"
      : `/${this.companySlug}/admin/reporting/`;
    this.tabs = [
      { url: adminBaseUrl + "overview", label: "Overview" },
      { url: adminBaseUrl + "usage", label: "Usage" },
      // { url: adminBaseUrl + "details", label: "Details" },
    ];

    config.triggers = "hover";
    config.container = "body";
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 20);
    Chart.register(Annotation);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        this.currentTrailingPath = params.get("path");
        this.loadedApis = 0;
        this.loadingCharts = true

        switch (this.currentTrailingPath) {
          case "overview":
            this.loadOverviewTab();
            break;
          case "usage":
            this.loadUsageTab();
            break;
          case "details":
            this.loadDetailsTab();
            break;
          default:
            break;
        }
      })
    );

    this.filterCtrl.valueChanges.subscribe(() => {
      this.filterGeneralSearch();
    });

    this.activeFilterCtrl.valueChanges.subscribe(() => {
      this.filterActiveSearch();
    });
  }

  loadInitTabData(datePeriod?: { fromDate: string; toDate: string }, generalFilter?: string) {
    try {
      this.loadingRoiLine = true;
      this.usageCharts[0].loading = true;
      this.adminApiService
        .reportingGetROIspotlight(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let { chart, total_cost_savings, average_cost_savings } = response.result.data;
            this.roiLineChartData = this.prepareChartData(chart, "cost_saved");
            this.total_cost_savings = total_cost_savings?.toLocaleString('en-US');
            this.roiLineChartOptions.plugins.annotation = this.getLinePlugin(
              this.roiLineChartData
            );

            this.usageCharts[0].total = this.total_cost_savings;
            this.usageCharts[0].average = average_cost_savings?.toLocaleString('en-US');
            this.usageCharts[0].chartData = this.roiLineChartData;
            this.usageCharts[0].chartOptions = this.roiLineChartOptions;
          }
          this.updateLoadingStatus();
          this.loadingRoiLine = false;
          this.usageCharts[0].loading = false;
        });

      this.usageCharts[1].loading = true
      this.loadingProductivity = true
      this.adminApiService
        .reportingGetProductivitySpotlight(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let { chart, total_time_saved, average_time_saved } = response.result.data;
            this.prodLineChartData = this.prepareChartData(chart, "time_saved");
            this.total_time_saved = total_time_saved?.toLocaleString('en-US');
            this.prodLineChartOptions.plugins.annotation = this.getLinePlugin(
              this.prodLineChartData
            );

            this.usageCharts[1].total = this.total_time_saved;
            this.usageCharts[1].average = average_time_saved?.toLocaleString('en-US');
            this.usageCharts[1].chartData = this.prodLineChartData;
            this.usageCharts[1].chartOptions = this.prodLineChartOptions;
          }
          this.usageCharts[1].loading = false
          this.loadingProductivity = false
          this.updateLoadingStatus();
        });

      this.loadingTotalCharacterTyped = true;
      this.usageCharts[2].loading = true
      this.adminApiService
        .reportingGetTotalCharactersSpotlight(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let { chart, total_characters_typed, average_characters_typed } = response.result.data;
            this.totalCharLineChartData = this.prepareChartData(
              chart,
              "characters_typed"
            );
            this.total_characters_typed = total_characters_typed?.toLocaleString('en-US');
            this.totalCharLineChartOptions.plugins.annotation =
              this.getLinePlugin(this.totalCharLineChartData);

            this.usageCharts[2].total = this.total_characters_typed;
            this.usageCharts[2].average = average_characters_typed?.toLocaleString('en-US');
            this.usageCharts[2].chartData = this.totalCharLineChartData;
            this.usageCharts[2].chartOptions = this.totalCharLineChartOptions;
          }
          this.updateLoadingStatus();
          this.loadingTotalCharacterTyped = false;
          this.usageCharts[2].loading = false;
        });
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  loadOverviewTab() {
    this.loadInitTabData();
    try {
      this.adminApiService.reportingGetLicences().subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          let {
            purchased = 0,
            licenses_remaining_to_be_used = 0,
            assigned = 0,
            invited_licenses_pending_activation = 0,
            activated = 0,
            percentage_of_assigned_licenses_have_been_activated = 0,
            extensions_installed = 0,
            percentage_of_extension_installed_have_been_installed = 0,
          } = response.result.licences;
          this.overview = [
            {
              count: purchased?.toLocaleString('en-US'),
              label: "Purchased Licenses",
              tooltip: "Total number of licenses purchased.",
              usage: `${licenses_remaining_to_be_used?.toLocaleString('en-US')} license${
                licenses_remaining_to_be_used !== 1 ? "s" : ""
              } remaining to be used`,
              link: this.isVengresoAdmin ? "" : "Assign licenses",
            },
            {
              count: assigned?.toLocaleString('en-US'),
              label: "Assigned Licenses",
              tooltip: "Total number of licenses assigned that have not signed up.",
              usage: `${invited_licenses_pending_activation?.toLocaleString('en-US')} invited license${
                invited_licenses_pending_activation > 0 ? "s" : ""
              } pending activation`,
              link: this.isVengresoAdmin ? "" : "Re-send invites",
            },
            {
              count: activated?.toLocaleString('en-US'),
              label: "Activated Licenses",
              tooltip: "Total number of teams licenses activated.",
              usage: `${percentage_of_assigned_licenses_have_been_activated}% of assigned licenses have been activated`,
              link: this.isReportingAdmin || this.isVengresoAdmin ? "" : "Need More Licenses?",
              sendEmail: true,
            },
            {
              count: extensions_installed?.toLocaleString('en-US'),
              label: "Extension Installed",
              tooltip:
                "Total number of users with the FlyMSG extension installed.",
              usage: `${percentage_of_extension_installed_have_been_installed}% of users with activated licenses that have installed the extension.`,
              link: "",
            },
          ];
        }
        this.updateLoadingStatus();
      });

      this.loadingCurrentFlyMsgCoach = true;
      this.adminApiService
        .reportingGetFMScoachLevelSpotlight()
        .subscribe((response: any) => {
          const barChartData = {
            datasets: [
              {
                data: this.bar_chart_data,
                label: "Current Levels",
                backgroundColor: [
                  "#FFC20E",
                  "#F15A2A",
                  "#FFEAAB",
                  "#FF9658",
                  "#5F177F",
                ],
                borderWidth: 0,
                pointBackgroundColor: "#5F177F",
                fill: "origin",
              },
            ],
            labels: [
              "Beginner",
              "Intermediate",
              "Proficient",
              "Advanced",
              "Expert",
            ],
          };

          if (response && response.result && response.result.success == true) {
            const { chart, expert_users, beginner_users } =
              response.result.data;
            this.bar_chart_data = chart;
            this.expert_users = expert_users;
            this.beginner_users = beginner_users;
            barChartData.datasets[0].data = this.bar_chart_data;
            const legendItems = [];
            barChartData.datasets[0].data.forEach((value, index) => {
              legendItems.push({
                label: barChartData.labels[index] as string,
                value: value?.toLocaleString('en-US') as number,
                color: barChartData.datasets[0].backgroundColor[
                  index
                ] as string,
              });
            });
            this.barChartlegendItems = legendItems;
            this.barChartData = barChartData;
            this.barChartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return `${context.dataset.label}: ${context.raw}`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    callback: function (value, index, values) {
                      return barChartData.labels[index];
                    },
                  },
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    color: "#E0E0E0",
                  },
                },
              },
            };
          }
          this.updateLoadingStatus();
          this.loadingCurrentFlyMsgCoach = false;
        });
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  loadUsageTab(datePeriod?: { fromDate: string; toDate: string }, generalFilter?: string) {

    if(!datePeriod){
      let fromDate = moment().subtract(this.dateFilterValue ?? 12, "months")
        .format("YYYY-MM-DD");
      let toDate = moment().format("YYYY-MM-DD");
      datePeriod = { fromDate, toDate }
    }

    this.loadInitTabData(datePeriod, generalFilter);
    try {
      this.adminApiService
        .reportingActiveUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              active_users,
              inactive_users,
              with_extension_installed,
              with_extension_uninstalled,
            } = response.result.data;

            this.usage = [
              {
                count: inactive_users?.toLocaleString('en-US'),
                label: "Deactivated Users",
                tooltip: "Total number of users deactivated.",
              },
              {
                count: active_users?.toLocaleString('en-US'),
                label: "Activated Users",
                tooltip: "Total number of teams licenses activated.",
              },
              {
                count: with_extension_installed?.toLocaleString('en-US'),
                label: "Extension Installed",
                tooltip: "Total number of activated users with the extensions installed.",
              },
              {
                count: with_extension_uninstalled?.toLocaleString('en-US'),
                label: "Extension Uninstalled",
                tooltip: "Total number of extensions uninstalled.",
              },
            ];
          }
          this.updateLoadingStatus();
        });

      this.usageCharts[0].loadingTopUsers = true
      this.adminApiService
        .costSavingTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[0].topFive = response.result.users.map(item => ({
              ...item,
              count: `$${item.count}`
            }));
          }
          this.updateLoadingStatus();
          this.usageCharts[0].loadingTopUsers = false
        });

      this.usageCharts[1].loadingTopUsers = true
      this.adminApiService
        .timeSavingTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[1].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[1].loadingTopUsers = false
        });

      this.usageCharts[2].loadingTopUsers = true
      this.adminApiService
        .charactersTypedSavingTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[2].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[2].loadingTopUsers = false
        });

      this.usageCharts[3].loadingTopUsers = true
      this.adminApiService
        .flycutsTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[3].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[3].loadingTopUsers = false
        });

      this.usageCharts[4].loadingTopUsers = true
      this.adminApiService
        .flyplatesTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[4].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[4].loadingTopUsers = false
        });

      this.usageCharts[5].loadingTopUsers = true
      this.adminApiService
        .flyengageTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[5].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[5].loadingTopUsers = false
        });

      this.usageCharts[6].loadingTopUsers = true
      this.adminApiService
        .flypostTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[6].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[6].loadingTopUsers = false
        });

      this.usageCharts[3].loading = true
      this.adminApiService
        .reportingGetFlyCutsCreated(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flycuts_created,
              average_flycuts_per_active_user,
            } = response.result.data;

            this.usageCharts[3].average = average_flycuts_per_active_user?.toLocaleString('en-US');
            this.usageCharts[3].total = total_flycuts_created?.toLocaleString('en-US');
            this.usageCharts[3].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[3].label
            );
            this.usageCharts[3].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[3].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[3].loading = false
        });
      
      this.usageCharts[4].loading = true
      this.adminApiService
        .reportingGetFlyPlatesAdded(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flyplate_created,
              average_flyplate_per_active_user,
            } = response.result.data;

            this.usageCharts[4].average = average_flyplate_per_active_user?.toLocaleString('en-US');
            this.usageCharts[4].total = total_flyplate_created?.toLocaleString('en-US');
            this.usageCharts[4].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[4].label
            );
            this.usageCharts[4].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[4].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[4].loading = false
        });

      this.usageCharts[5].loading = true
      this.adminApiService
        .reportingGetFlyEngageAI(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flyengage_ai_created,
              average_flyengage_ai_per_active_user,
            } = response.result.data;

            this.usageCharts[5].average = average_flyengage_ai_per_active_user?.toLocaleString('en-US');
            this.usageCharts[5].total = total_flyengage_ai_created?.toLocaleString('en-US');
            this.usageCharts[5].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[5].label
            );
            this.usageCharts[5].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[5].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[5].loading = false
        });

      this.usageCharts[6].loading = true
      this.adminApiService
        .reportingGetFlyPostAI(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flypost_ai_created,
              average_flypost_ai_per_active_user,
            } = response.result.data;

            this.usageCharts[6].average = average_flypost_ai_per_active_user?.toLocaleString('en-US');
            this.usageCharts[6].total = total_flypost_ai_created?.toLocaleString('en-US');
            this.usageCharts[6].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[6].label
            );
            this.usageCharts[6].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[6].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[6].loading = false
        });

      this.loadingExtensionUsage = true 
      this.adminApiService
        .reportingExtensionUsage(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let { chart: rawExtensionChartData } = response.result.data;

            let extensionChartData = {
              labels: [],
              datasets: [],
            };

            extensionChartData.labels = Object.keys(rawExtensionChartData);
            extensionChartData.labels.forEach((key: string) => {
              Object.entries(this.extensionLineLayers).forEach(
                ([line, lineLayer], index) => {
                  lineLayer.data.push(rawExtensionChartData[key][line]);

                  if (!extensionChartData.datasets[index]) {
                    extensionChartData.datasets.push({});
                    extensionChartData.datasets[index].tension = 0.5;
                    extensionChartData.datasets[index].borderColor =
                      this.extensionLineLayers[line].color;
                  }
                  extensionChartData.datasets[index].data = lineLayer.data;
                }
              );
            });

            this.extensionChart.data = extensionChartData;
          }
          this.updateLoadingStatus();
          this.loadingExtensionUsage = false
        });

      this.filteredUsageCharts = this.usageCharts;
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  async loadDetailsTab(datePeriod?: { fromDate: string; toDate: string }) {
    try {
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource();
      }

      const response: any = await lastValueFrom(
        this.adminApiService.getUserSavedColumns()
      );

      if (response && response.result && response.result.success == true) {
        //ToDo: Ambrose let this endpoint return unique key values
        let columns = [
          ...new Map(
            response.result.columns.map((column: any) => [column.key, column])
          ).values(),
        ];
        this.tableColumns = columns.filter((c: any) => c.checked == true);
        this.displayedColumns = this.tableColumns.map((c: any) => c.key);
        this.selectedColumns = this.displayedColumns.filter(
          (key: string) => !this.defaultColumns.includes(key)
        );
        this.updateLoadingStatus();
      }

      this.adminApiService
        .getUserDetails(datePeriod)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let data = this.processUserDetailsData(
              response.result.users,
              this.displayedColumns.concat(["user_id", "group_id", "subgroup_id", "status"]) //Needed for filters and selection
            );
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;

            this.runGeneralFilter();
          }
          this.updateLoadingStatus();
        });
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  updateLoadingStatus() {
    this.loadedApis++;
    this.checkAllApisLoaded();
    this.cdr.detectChanges();

    const barChartData = document.getElementById('barChartData') as HTMLCanvasElement
    const chartInstance = Chart.getChart(barChartData);
    if (chartInstance) {
      chartInstance.resize();
    }
  }

  checkAllApisLoaded() {
    let allCompleted = false;

    switch (this.currentTrailingPath) {
      case "overview":
        allCompleted = this.loadedApis == 5;
        break;
      case "usage":
        allCompleted = this.loadedApis == 16;
        break;
      case "details":
        allCompleted = this.loadedApis == 2;
        break;
      default:
        break;
    }

    if (allCompleted) {
      this.loadingCharts = false;
    }
  }

  updateAllChecked() {
    this.allChecked = this.dataSource.data != null &&
      this.dataSource.data.every((t: any) => t.checked);
  }

  someChecked(): boolean {
    if (this.dataSource.data == null) {
      return false;
    }
    return (
      this.dataSource.data.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource.data == null) {
      return;
    }
    this.dataSource.data.forEach((t: any) => (t.checked = checked));
  }

  exportCsv(): void {
    let filter = '';
    let report = this.currentTrailingPath;
    switch (report) {
      case "usage":
        let fromDate = moment()
                        .subtract(this.dateFilterValue ?? 12, "months")
                        .format("YYYY-MM-DD");
        let toDate = moment()
                        .format("YYYY-MM-DD");

        let dateFilter = `fromDate=${fromDate}&toDate=${toDate}`;
        let generalFilter = this.generalFilterQueryString;

        filter = !!generalFilter ?  `${dateFilter}&${generalFilter}` : dateFilter;
        break;
      case "details":
        const selectedUsers = this.dataSource.data.filter((t: any) => t.checked).map((user: any) => user.user_id);
        filter = Array.isArray(selectedUsers) && selectedUsers.length > 0 ? selectedUsers.join(',') : '';
        break;
      default:
        break;
    }

    this.adminApiService.exportCSVReports(filter, report);
  }

  processUserDetailsData(users: any, displayedColumnKeys: string[]) {
    return users.map((item) => {
      let newItem = {};
      displayedColumnKeys.forEach((key) => {
        if (item.hasOwnProperty(key)) {
          newItem[key] = item[key];

          if (key === 'total_no_of_characters_typed_summarized_monthly_by_flymsg_by_user') {
            newItem[key] = (newItem[key] ?? '').split('.').map(item => item.trim()).join('.\n')
          }
        }
      });
      return newItem;
    });
  }

  getLinePlugin(chartData: ChartConfiguration["data"]): { annotations: any } {
    return {
      annotations: chartData?.labels?.map((label, index) => ({
        type: "line",
        id: `line${index}`,
        mode: "vertical",
        yMax: Number(chartData?.datasets[0].data[index]),
        xMax: String(label),
        xMin: String(label),
        value: String(label),
        borderColor: "#2a1e36",
        borderWidth: 1,
        borderDash: [5, 5],
        label: {
          content: String(label),
          enabled: true,
          position: "start",
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      })),
    };
  }

  prepareChartData(
    rawChartData: any,
    filterBy = "time_saved"
  ): ChartConfiguration["data"] {
    const chartData = {
      datasets: [
        {
          data: [],
          label: "",
          backgroundColor: "#F0DBFA",
          borderColor: "#5F177F",
          pointBackgroundColor: "#5F177F",
          fill: "origin",
        },
      ],
      labels: [],
    };

    Object.keys(rawChartData).forEach((key) => {
      chartData.labels.push(key);
      chartData.datasets[0].data.push(rawChartData[key][filterBy]);
    });

    let label = "";

    switch (filterBy) {
      case "characters_typed":
        label = this.titleCase(filterBy);
        break;
      case "cost_saved":
        label = this.titleCase(filterBy) + " ($)";
        break;
      case "time_saved":
        label = this.titleCase(filterBy) + " (hrs)";
        break;

      default:
        break;
    }

    chartData.datasets[0].label = label;

    return chartData;
  }

  prepareUsageChartData(rawChartData: any, label: string) {
    const chartData = {
      datasets: [
        {
          data: [],
          label: "",
          backgroundColor: "#F0DBFA",
          borderColor: "#5F177F",
          pointBackgroundColor: "#5F177F",
          fill: "origin",
        },
      ],
      labels: [],
    };

    chartData.datasets[0].label = label;

    Object.keys(rawChartData).forEach((key) => {
      chartData.labels.push(key);
      chartData.datasets[0].data.push(rawChartData[key].count);
    });

    return chartData;
  }

  titleCase(s: string): string {
    return s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());
  }

  ngAfterViewInit() {
    if (this.currentTrailingPath === "details") {
      this.dataSource.paginator = this.paginator;
    }
  }

  get emailLink(): string {
    const { first_name, last_name, company } = this.authService.getUserDetail();
    const body: string = `
    "Hello FlyMSG team"
    I'd like to inquire about adding on _______ more licenses to my account.
    Account name: ${company.name}
    Administrator Requesting: ${first_name} ${last_name}`;

    return `mailto:sales@vengreso.com?subject=${encodeURIComponent(
      "Additional License Inquiry"
    )}&body=${encodeURIComponent(body)}`;
  }

  get isReportingAdmin(): boolean {
    return this.authService.getUserRole() === "Reporting Admin";
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole() === "Vengreso Admin";
  }

  toggleView(): void {
    if (this.loadingCharts) return;

    this.usageViewMaximised = !this.usageViewMaximised;

    if (!this.usageViewMaximised) {
      this.selectChart(this.usageCharts[0].label);
    }

    if (this.usageViewMaximised) {
      this.filteredUsageCharts = this.usageCharts;
      this.loadUsageTab();
      // this.filteredUsageCharts = this.filteredUsageCharts.map(chart => {
      //   chart.chartOptions.plugins.annotation = this.getLinePlugin(chart.chartData);
      //   return chart;
      // });
    }
  }

  selectChart(selectedlabel: string) {
    this.selectedChartLabel = selectedlabel;

    if (this.selectedChartLabel === this.extensionChart.label) {
      this.filteredUsageCharts = [];
    } else {
      this.filteredUsageCharts = this.usageCharts.filter(
        ({ label }) => label == this.selectedChartLabel
      );

      this.filteredUsageCharts[0].chartOptions.plugins.annotation =
        this.getLinePlugin(this.filteredUsageCharts[0].chartData);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  closeDatePicker() {
    this.showCustomDatePicker = false;
  }

  onSelectionChange(event: any) {
    this.showCustomDatePicker = event?.value === -1;

    if (event?.value === -1) {
      return;
    }

    let fromDate = moment()
      .subtract(event.value ?? 12, "months")
      .format("YYYY-MM-DD");
    let toDate = moment().format("YYYY-MM-DD");

    switch (this.currentTrailingPath) {
      case "usage":
        this.loadUsageTab({ fromDate, toDate });
        break;
      case "details":
        this.loadDetailsTab({ fromDate, toDate });
        break;
      default:
        break;
    }
  }

  applyDateRange() {
    let fromDate = this.ngbDateParserFormatter.format(this.fromDate);
    let toDate = this.ngbDateParserFormatter.format(this.toDate);

    switch (this.currentTrailingPath) {
      case "usage":
        this.loadUsageTab({ fromDate, toDate });
        break;
      case "details":
        this.loadDetailsTab({ fromDate, toDate });
        break;
      default:
        break;
    }

    this.showCustomDatePicker = false;
  }

  get showExtensionChart(): boolean {
    return (
      this.usageViewMaximised ||
      (!this.usageViewMaximised &&
        this.selectedChartLabel === this.extensionChart.label)
    );
  }

  toggleDropdown(event: Event) {
    if (this.loadingCharts) return

    this.generalFilterOpened = !this.generalFilterOpened;
    
    if(this.generalFilterOpened == true) {
      this.switchGeneralFilterView(event, "groups");
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: Event) {
    setTimeout(() => {
      if (
        this.generalFilterOpened &&
        !this.dropdown.nativeElement.contains(event.target)
      ) {
        this.generalFilterOpened = false;
        this.cdr.detectChanges();
      }
    }, 0); 
  }

  filterGeneralSearch() {
    let search = this.filterCtrl?.value?.toLowerCase();
    if (!search) {
      return;
    }
    this.generalFilterLoading = true;
    const selectedGroupIds = this.generalFilterSelected.group.map((g:any) =>g.id);
    const selectedSubGroupIds = this.generalFilterSelected.subgroup.map((s:any) =>s.id);
    const selectedUserIds = this.generalFilterSelected.user.map((u:any) =>u.id);
    const searchList = [];

    this.groups.forEach(group => {
      searchList.push({
        id: group.group.id,
        label: group.group.name,
        checked: selectedGroupIds.includes(group.group.id) ? true : false,
        users_count: group.members,
        type: 'group'
      });

      if(group.group?.subgroups?.length > 0) {
        group.group.subgroups.forEach(subgroup => {
          searchList.push({
            id: subgroup.group.id,
            label: subgroup.group.name,
            checked: selectedSubGroupIds.includes(subgroup.group.id) ? true : false,
            users_count: subgroup.members,
            type: 'subgroup'
          });

          if(subgroup?.users?.length > 0){
            subgroup.users.forEach(user => {
              searchList.push({
                id: user.id,
                label: user.first_name + ' ' + user.last_name,
                email: user.email,
                checked: selectedUserIds.includes(user.id) ? true : false,
                type: 'user',
              });
            })
          }

        })
      } else {
        if(group.users.length > 0){
          group.users.forEach(user => {
            searchList.push({
              id: user.id,
              label: user.first_name + ' ' + user.last_name,
              email: user.email,
              checked: selectedUserIds.includes(user.id) ? true : false,
              type: 'user'
            });
          })
        }
      }
    });

    this.generalSearchOptions = searchList.filter((entity: any) => {
      return entity.label.toLowerCase().includes(search) || entity?.email?.toLowerCase()?.includes(search)
    });

    this.generalFilterLoading = false;
  }

  switchGeneralFilterView(event: Event, view: string, group?:any){
    event.stopPropagation();

    this.generalFilterView = view;
    this.generalFilterLoading = true;
    switch (this.generalFilterView) {
      case "subgroup":
        this.generalFilterSelectedSubGroup = group;

        try {
          this.adminApiService.getGroups()
          .pipe(
            finalize(() => this.generalFilterLoading = false)
          )
          .subscribe((response: any) => {
            if (response && response.result && response.result.success) {
              let groupData = response.result.groups.find((datum: any) => datum.group.id === this.generalFilterSelectedGroup.id);
              let subgroupData = groupData.group.subgroups.find((datum: any) => datum.group.id === this.generalFilterSelectedSubGroup.id)

              if(subgroupData?.users?.length > 0) {
                const selectedUserIds = this.generalFilterSelected.user.map((g:any) =>g.id);
                this.generalSearchList = subgroupData.users.map((user: any) => ({
                  id: user.id,
                  group_id: groupData.group.id,
                  subgroup_id: subgroupData.group.id,
                  label: user.first_name + ' ' + user.last_name,
                  checked: selectedUserIds.includes(user.id) ? true : false,
                  type: 'user'
                }));
              }
            }
          });
        } catch (error) {
          console.log('Could not retrieve group users in subgroups', error);
          this.generalFilterOpened = false;
          this.notification.toast("Function not available now, try again later")
        }
        break;
      case "subgroups":
        this.generalFilterSelectedGroup = group;

        try {
          this.adminApiService.getGroups()
          .pipe(
            finalize(() => this.generalFilterLoading = false)
          )
          .subscribe((response: any) => {
            if (response && response.result && response.result.success) {
              let groupData = response.result.groups.find((datum: any) => datum.group.id === this.generalFilterSelectedGroup.id);

              if(groupData?.group?.subgroups?.length > 0) {
                this.generalFilterSubGroupInGroups = true;
                const selectedSubGroupIds = this.generalFilterSelected.subgroup.map((g:any) =>g.id);
                this.generalSearchList = groupData?.group?.subgroups.map((subgroup: any) => ({
                  id: subgroup.group.id,
                  group_id: groupData.group.id,
                  label: subgroup.group.name,
                  checked: selectedSubGroupIds.includes(subgroup.group.id) ? true : false,
                  users_count: subgroup.members,
                  type: 'subgroup'
                }));
              } else {
                this.generalFilterSubGroupInGroups = false;
                const selectedUserIds = this.generalFilterSelected.user.map((g:any) =>g.id);
                this.generalSearchList = groupData?.users?.map((user: any) => ({
                  id: user.id,
                  group_id: groupData.group.id,
                  label: user.first_name + ' ' + user.last_name,
                  checked: selectedUserIds.includes(user.id) ? true : false,
                  type: 'user'
                }));
              }
            }
          });
        } catch (error) {
          console.log('Could not retrieve group users or subgroups', error);
          this.generalFilterOpened = false;
          this.notification.toast("Function not available now, try again later")
        }

        break;
      case "groups":
      default:
        try {
          this.adminApiService.getGroups()
          .pipe(
            finalize(() => this.generalFilterLoading = false)
          )
          .subscribe((response: any) => {
            if (response && response.result && response.result.success) {
              const selectedGroupIds = this.generalFilterSelected.group.map((g:any) =>g.id);
              this.groups = response.result.groups;
              this.generalSearchList = this.groups.map((group: any) => ({
                id: group.group.id,
                label: group.group.name,
                checked: selectedGroupIds.includes(group.group.id) ? true : false,
                users_count: group.members,
                type: 'group'
              }));
            }
          });
        } catch (error) {
          console.log('Could not retrieve group list');
          this.generalFilterOpened = false;
          this.notification.toast("Function not available now, try again later")
        }
        break;
    }
  }

  updateGeneralFilter(entity: any){
    if(entity.checked == true){
      this.generalFilterSelected[entity.type].push(entity)
    } else {
      this.generalFilterSelected[entity.type] = this.generalFilterSelected[entity.type].filter(
        (selected: any) => selected.id !== entity.id
      )
    }
  }

  get generalFilterLabel(){
    if (!this.hasGeneralFilterValues){
      return "All";
    }

    return Object.entries(this.generalFilterSelected)
      .filter(([type, entity]) => (entity as any[]).length > 0)
      .map(([type, entity]) => `${(entity as any[]).length} ${type}${(entity as any[]).length > 1 ? 's' : ''}`)
      .join(', '); 
  }

  get generalFilterSelectedItems(){
    return [...this.generalFilterSelected.group, ...this.generalFilterSelected.subgroup, ...this.generalFilterSelected.user];
  }

  removeSelected(event: Event, selected: any){
    event.stopPropagation();

    this.generalFilterSelected[selected.type] = this.generalFilterSelected[selected.type].filter((entity: any) => entity.id !== selected.id);
    this.generalSearchList = this.generalSearchList.map(list => list.id === selected.id ? {...list, checked: false} : list);
  }

  unselectAll(event: Event){
    event.stopPropagation();

    this.generalFilterSelected = {
      group: [],
      subgroup: [],
      user: [],
    };
    this.generalSearchList = this.generalSearchList.map(list => ({...list, checked: false}));
  }

  runGeneralFilter() {
    if(!this.hasGeneralFilterValues){
      this.resetFilterPredicate();
      this.dataSource.filter = "";
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.group.map((g: any) => g.id).includes(data["group_id"]) ||
             filter.subgroup.map((g: any) => g.id).includes(data["subgroup_id"]) ||
             filter.user.map((u: any) => u.id).includes(data["user_id"]);
    };

    this.dataSource.filter = this.generalFilterSelected;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  indeterminate(entity: any, type: 'group' | 'subgroup'): boolean {
    if(type === 'group') {
      return entity.checked == false && 
      [ 
        ...this.generalFilterSelected.subgroup,
        ...this.generalFilterSelected.user 
      ]?.map((ent: any) => ent.group_id)
        .includes(entity.id);
    }

    return entity.checked == false && 
      this.generalFilterSelected.user?.map((ent: any) => ent.subgroup_id)
      .includes(entity.id);
  }

  disabledAndChecked(entity: any, type: 'subgroup' | 'user'): boolean {
    if(type === 'user') {
      return this.generalFilterSelected.group?.map((ent: any) => ent.id).includes(entity.group_id) 
      ||
      this.generalFilterSelected.subgroup?.map((ent: any) => ent.id).includes(entity.subgroup_id);
    }

    return this.generalFilterSelected.group?.map((ent: any) => ent.id).includes(entity.group_id);
  }

  get hasGeneralFilterValues() {
    return !Object.values(this.generalFilterSelected).every((entities: any[]) => entities.length === 0)
  }

  get generalFilterQueryString(): string {
    return this.hasGeneralFilterValues ?
     Object.entries(this.generalFilterSelected)
            .filter(([type, entities]) => (entities as any[]).length > 0)
            .map(([type, entities]) => {
              const ids = (entities as any[]).map(entity => entity.id).join(',');
              return `${type}_ids=${ids}`;
            })
            .join('&') : '';
  }

  closeGeneralFilter(): void {
    this.generalFilterOpened = false;
  }

  applyGeneralFilter(): void {
    switch (this.currentTrailingPath) {
      case "usage":
        this.loadUsageTab(null, this.generalFilterQueryString);
        break;
      case "details":
        this.runGeneralFilter();
        break;
      default:
        break;
    }

    this.closeGeneralFilter();
  }

  filterActiveSearch() {
    let search = this.activeFilterCtrl?.value;

    if (
      !search ||
      search.length < 1 ||
      search.length == this.activeFilterOptions.length
    ) {
      this.search = "";
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data["status"]);
    };

    this.dataSource.filter = search;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  searchUsers() {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => {
          return currentTerm + (data as { [key: string]: any })[key] + " ";
        }, "")
        .toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  get filtersClear() {
    let clear = this.dateFilterValue == 12
                 && !this.filterCtrl.value 
                 && !this.hasGeneralFilterValues;
    let status = this.activeFilterCtrl?.value;

    if (this.currentTrailingPath == "details") {
      clear = clear && this.search.trim().length < 1 
        && (status.length < 1 || status.length === this.activeFilterOptions.length)
    }

    return clear;
  }

  clearFilters() {
    this.dateFilterValue = 12;
    this.onSelectionChange({value: 12});

    this.generalFilterSelected = {
      group: [],
      subgroup: [],
      user: [],
    };
    this.filterCtrl.reset();

    if (this.currentTrailingPath == "details") {
      this.search = '';
      this.searchUsers();
      this.activeFilterCtrl.reset([]);
    }
  }

  chartHovered(_event: any) {}

  chartClicked(_event: any) {}

  openCustomiseColumns(): void {
    const dialogRef = this.dialog.openDialogComponent(
      EditReportingComponent,
      {
        defaultColumns: this.defaultColumns,
        selectedColumns: this.selectedColumns,
      },
      "640px"
    );

    dialogRef.afterClosed().subscribe((columns: any) => {
      if(!!columns) {
        this.tableColumns = columns.filter((c: any) => c.checked == true);
        this.displayedColumns = this.tableColumns.map((c: any) => c.key);
      }
    });
  }

  addDashedLinesToCharts(
    chartOptions: ChartConfiguration["options"],
    chartData: ChartConfiguration["data"]
  ): void {
    chartOptions.plugins.annotation = {
      annotations: chartData.labels?.map((label, index) => ({
        type: "line",
        id: `line${index}`,
        mode: "vertical",
        yMax: Number(chartData.datasets[0].data[index]),
        xMax: String(label),
        xMin: String(label),
        value: String(label),
        borderColor: "#2a1e36",
        borderWidth: 1,
        borderDash: [5, 5],
        label: {
          content: String(label),
          enabled: true,
          position: "start",
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      })),
    };
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
