import { Injectable } from "@angular/core";
import { forEach } from "lodash";
import { LocalStorageService } from "ngx-webstorage";
import { BehaviorSubject } from "rxjs";

// Declared as const as this should not be reassigned from anywhere in app
const sharedData = {};

@Injectable()
export class SharedDataService {

  public showExtensionPrompt = new BehaviorSubject<boolean>(false)
  public showExtensionPrompt$ = this.showExtensionPrompt.asObservable()

  public showDownloadModalTimer = new BehaviorSubject<boolean>(false)
  public showdownloadModalTimer$ = this.showDownloadModalTimer.asObservable()
  
  constructor(private storage: LocalStorageService) {}

  get() {
    return sharedData;
  }

  clear() {
    const welcomeDismiss = this.getAttribute("welcomeDismiss");
    let FlyMSGـFrontEndـVersion = this.getAttribute("FlyMSGـFrontEndـVersion");
    // Clearing sharedData one by one as a constant cannot be reassigned
    forEach(sharedData, (val, key) => {
      delete sharedData[key];
    });
    // Clear localStorage
    this.storage.clear();
    
    this.setAttribute("FlyMSGـFrontEndـVersion", FlyMSGـFrontEndـVersion);
    this.setAttribute("welcomeDismiss", welcomeDismiss);
  }

  getAttribute(key: string) {
    return sharedData[key];
  }

  setAttribute(key: string, value: any) {
    sharedData[key] = value;
    this.storage.store(key, value);
  }

  deleteAttribute(key: string) {
    delete sharedData[key];
    this.storage.clear(key);
  }

  restoreSharedData() {
    const len = localStorage.length;
    for (let i = 0; i < len; i++) {
      // Get plain key removing prefix and seperator
      const key = localStorage.key(i).replace("vengreso.", "");
      sharedData[key] = this.storage?.retrieve(key);
    }
  }
}
