import { environment } from "src/environments/environment";
export class ApplicationConstants {
  public static CONTENT_TYPE = "application/json";
  public static ACCEPT = "application/json";
  public static TINYMCE_API_KEY = environment.TINYMCE_API_KEY;
  public static TINYMCE_CONTENT_CSS =
    "\
      body, #editorContentWrapper {\
          font-family: Helvetica;\
          font-size: 10pt;\
          line-height: 1.4;\
      }\
      \
      ul, ol {\
          margin: 0;\
          padding-left: 40px;\
          counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;\
      }\
      \
      ul {\
          list-style-type: disc;\
      }\
      \
      ol {\
          list-style-type: decimal;\
      }\
      \
      li {\
          counter-increment: list-0;\
      }\
      \
      pre {\
          font-family: monospace;\
      }\
      \
      h1 {\
          font-size: 28px;\
      }\
      \
      h2 {\
          font-size: 21px;\
      }\
      \
      h3 {\
          font-size: 16px;\
      }\
      \
      h4 {\
          font-size: 14px;\
      }\
      \
      h5 {\
          font-size: 12px;\
      }\
      \
      h6 {\
          font-size: 10px;\
      }\
      \
      bold {\
          font-weight: lighter;\
      }\
      \
      italic {\
          font-style: normal;\
      }";
}
//   public static TINYMCE_CONTENT_CSS =
//     "\
//     body, #editorContentWrapper {\
//         font-family: Arial;\
//         font-size: 12pt;\
//         line-height: 1.4;\
//     }\
//     \
//     p, pre, h1, h2, h3, h4, h5, h6 {\
//         margin: 0;\
//         padding: 0;\
//         counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;\
//     }\
//     \
//     ul, ol {\
//         margin: 0;\
//         padding-left: 40px;\
//         counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;\
//     }\
//     \
//     ul {\
//         list-style-type: disc;\
//     }\
//     \
//     ol {\
//         list-style-type: decimal;\
//     }\
//     \
//     li {\
//         counter-increment: list-0;\
//     }\
//     \
//     pre {\
//         font-family: monospace;\
//     }\
//     \
//     h1 {\
//         font-size: 28px;\
//     }\
//     \
//     h2 {\
//         font-size: 21px;\
//     }\
//     \
//     h3 {\
//         font-size: 16px;\
//     }\
//     \
//     h4 {\
//         font-size: 14px;\
//     }\
//     \
//     h5 {\
//         font-size: 12px;\
//     }\
//     \
//     h6 {\
//         font-size: 10px;\
//     }\
//     \
//     bold {\
//         font-weight: lighter;\
//     }\
//     \
//     italic {\
//         font-style: normal;\
//     }\
//     \
//     disable-text-decoration {\
//         text-decoration: none;\
//     }\
//     \
//     disable-text-decoration {\
//         text-decoration: none;\
//     }\
//     \
//     disable-text-align {\
//         text-align: initial;\
//     }\
//     \
//     disable-text-indent {\
//         text-indent: none !important;\
//     }\
//     \
//     disable-text-indent {\
//         text-indent: none !important;\
//     }";
// }
