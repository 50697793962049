import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-confirm-changes',
  templateUrl: './confirm-changes.component.html',
  styleUrls: ['./confirm-changes.component.scss']
})
export class ConfirmChangesComponent implements OnInit {
  companyName: string;
  action: string;
  bulkOperation: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    public dialogRef: MatDialogRef<ConfirmChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.companyName = this.data.companyName;
    this.action = this.data.action;
    this.bulkOperation = Array.isArray(this.data.data) && this.data.data.length > 1;
   }

  ngOnInit(): void {
    if(this.action == "deactivate-company" && !this.bulkOperation){
      let currentUrl = this.router.url;
      if(! currentUrl.includes("deactivate_company")){
        this.location.replaceState(currentUrl+"?deactivate_company="+this.data.data[0].slug);
      }
    }
    
  }

  confirm(): void {
    if (typeof this.data.self[this.data.callback] === 'function'){
      if(["deactivate-company", "reactivate-company"].includes(this.action)){
        this.data.self[this.data.callback](this.data.data);
      } else {
        this.data.self[this.data.callback]();
      }
    }

    this.close()
  }

  ngOnDestroy(): void {
    if(this.action == "deactivate-company" && !this.bulkOperation){
      this.location.replaceState(
        this.router.url.replace("?deactivate_company="+this.data.data[0].slug, "")
      );
    }
  }

  close(): void {
    if(this.action == "deactivate-company" && !this.bulkOperation){
      this.location.replaceState(
        this.router.url.replace("?deactivate_company="+this.data.data[0].slug, "")
      );
    }
    this.dialogRef.close();
  }
}
