import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";

import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";

import { ValidationConstants } from "src/app/core/core-services/constants/validation.constants";

import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  profileData: any;
  form: FormGroup;
  pform: FormGroup;
  updating: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private notification: NotificationService
  ) {
    if (this.router.url == "/profile") {
      this.router.navigate(["/settings"], { queryParams: { tab: "Profile" } });
    }
    this.form = this.fb.group({
      first_name: [
        null,
        [
          Validators.required,
          Validators.pattern(ValidationConstants.NON_DIGIT),
        ],
      ],
      last_name: [
        null,
        [
          Validators.required,
          Validators.pattern(ValidationConstants.NON_DIGIT),
        ],
      ],
      email: [null, [Validators.required]],
    });

    this.pform = this.fb.group({
      old_password: [null, [RxwebValidators.required()]],
      new_password: [
        null,
        [
          RxwebValidators.required(),
          RxwebValidators.minLength({ value: 8 }),
          RxwebValidators.maxLength({ value: 25 }),
          RxwebValidators.password({
            validation: { digit: true, specialCharacter: true },
          }),
        ],
      ],
      new_password_confirmation: [
        null,
        [
          RxwebValidators.required(),
          RxwebValidators.minLength({ value: 8 }),
          RxwebValidators.maxLength({ value: 20 }),
          RxwebValidators.password({
            validation: { digit: true, specialCharacter: true },
          }),
          RxwebValidators.compare({ fieldName: "new_password" }),
        ],
      ],
    });
  }

  // ngOnInit(): void {
  //   if (this.route.snapshot.data.profile && this.route.snapshot.data.profile.result) {
  //     this.profileData = this.route.snapshot.data.profile.result;
  //   }
  //   this.form.patchValue(this.profileData);
  // }

  ngOnInit(): void {
    this.dataService
      .get({ url: "v1/user", isLoader: true })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.profileData = response.result;
          this.form.patchValue(this.profileData);
        }
      });
  }

  updateProfile(data) {
    this.updating = true;

    this.dataService
      .put({ url: "v1/user", isLoader: false, data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.authService.updateUserDetail();
          this.notification.toast("Profile updated successfully! Effecting Change...");
          //Allow 0.4s for change to propagate in local store.
          setTimeout(() => {
            this.refresh();
          }, 400)
        }
      },
      (error) => {
        this.updating = false;
      },
      () => {
        this.updating = false;
      });
  }

   refresh(): void {
    window.location.reload();
  }

  deleteProfile() {
    this.dialog.openDialogComponent(
      ConfirmDialogComponent,
      {
        self: this,
        title: "Do you want to <strong>delete</strong> your profile?",
      },
      "330px"
    );
  }

  delete() {
    this.dataService.delete({ url: "v1/user" }).subscribe((response: any) => {
      if (response && response.result) {
        this.notification.toast("Profile deleted successfully!");
        this.authService.redirectAfterLogout();
      }
    });
  }

  onStrengthChanged(strength: number) {
    // console.log("password strength = ", strength);
  }

  updatePassword(data) {
    this.dataService
      .post({ url: "v1/user/auth/password/change", data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.notification.toast("Password updated successfully!");
          this.authService.logout();
        }
      });
  }
}
