import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  City,
  Country,
  ICity,
  ICountry,
  IState,
  State,
} from "country-state-city";
import { findIndex } from "lodash";
import { Subject, lastValueFrom } from "rxjs";
import { finalize, first } from "rxjs/operators";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { LoadingService } from "src/app/core/core-services/services/loader.service";
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import {
  SubscriptionPlanIdentifier,
  SubscriptionPlanType,
} from "src/app/core/models/stripe.models";

import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { NotifyService } from "src/app/core/menu/not.service";
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

export const stripeStyles = {
  base: {
    backgroundColor: "#F5F5F5",
    borderColor: "#EEEEEE",
    lineHeight: "3.829",
    padding: "30px 30px 30px 30px",
    iconColor: "#666EE8",
    fontWeight: 300,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: "15px",
    "::placeholder": {
      color: "#CFD7E0",
    },
  },
};

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.scss"],
})
export class PaymentDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("cardElement")
  cardElementContainer: ElementRef;

  @ViewChild("paymentElement")
  paymentElementContainer: ElementRef;

  @ViewChild("cardButton")
  cardButton: HTMLButtonElement;

  loading: boolean = false;
  paymentDetailsId: string = "paymentDetailsId"

  public client_secret = "";
  public planType: SubscriptionPlanType;
  public subType = SubscriptionPlanType;
  private _destroy$ = new Subject<void>();
  countries: ICountry[] = Country.getAllCountries();
  country: string = "";
  countryStates: IState[] = State.getStatesOfCountry(this.country);
  state: string = "";
  stateCities: ICity[] = City.getCitiesOfState(this.country, this.state);
  city: string;
  zip;
  add1;
  add2;
  name;
  discountCode: any;
  checked;

  planTypeDisplay: string;

  discountCodeApplied: boolean;
  discountCodeInvalid: boolean;
  discountCodeNotSupplied: boolean;
  discount: any;
  appliedDiscountCode: any;

  planAmount: any = 0;
  totalAmount: any = 0;
  dueTodayAmount: any = 0;
  payAmount: any = 0;

  pricing: any = {};

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private stripeService: StripeService,
    private _router: Router,
    private loadingService: LoadingService,
    public notifyService: NotifyService,
    private sharedData: SharedDataService,
    private cookieService: CookieService
  ) {
    let USAIndex = findIndex(this.countries, { name: "United States" });
    [this.countries[0], this.countries[USAIndex]] = [
      this.countries[USAIndex],
      this.countries[0],
    ];

    this.getPricing();
    this.discount = this.sharedData.getAttribute("discount") ?? 0;
    this.discountCode = this.sharedData.getAttribute("discountCode") ?? "";
    this.appliedDiscountCode = this.discountCode;
  }

  public elements = this.stripeService.stripe.elements();
  public paymentElement = this.elements.create("card", {
    style: stripeStyles,
    hidePostalCode: true,
  });

  ngOnInit(): void {
    this.planType =
      SubscriptionPlanType[this.route.snapshot.paramMap.get("id")];
    if (
      this.planType === this.subType.YearlyStarter ||
      this.planType === this.subType.YearlyGrowth ||
      this.planType === this.subType.YearlySalesPro
    ) {
      this.checked = false;
    } else {
      this.checked = true;
    }
    this.computePlanTypeDisplay();
  }

  ngAfterViewInit(): void {
    this.paymentElement.mount(this.paymentElementContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this.sharedData.deleteAttribute("discount");
    this.sharedData.deleteAttribute("discountCode");
    this._destroy$.next();
    this._destroy$.complete();
  }

  updateStates(event) {
    this.countryStates = State.getStatesOfCountry(this.country);
  }

  updateCities(event) {
    this.stateCities = City.getCitiesOfState(this.country, this.state);
  }

  computePlanTypeDisplay(): void {
    let planTypepDisplay: string = "";

    switch (this.planType) {
      case this.subType.Starter:
        planTypepDisplay = "Starter (Monthly Plan)";
        break;

      case this.subType.YearlyStarter:
        planTypepDisplay = "Starter (Yearly Plan)";
        break;

      case this.subType.Growth:
        planTypepDisplay = "Growth (Monthly Plan)";
        break;

      case this.subType.YearlyGrowth:
        planTypepDisplay = "Growth (Yearly Plan)";
        break;

      case this.subType.SalesPro:
        planTypepDisplay = "SalesPro (Monthly Plan)";
        break;

      case this.subType.YearlySalesPro:
        planTypepDisplay = "SalesPro (Yearly Plan)";
        break;

      default:
        break;
    }

    this.planTypeDisplay = planTypepDisplay;
  }

  calculatePayment(): void {
    let amount: any = 0;

    switch (this.planType) {
      case this.subType.Starter:
      case this.subType.YearlyStarter:
        amount = this.checked
          ? this.pricing["starter"]
          : this.twoDecimal(
              this.displayedPrice(this.pricing["starter-yearly"])
            );

        // this.twoDecimal(this.displayedPrice(this.pricing['starter-yearly']) - this.displayedPrice(this.pricing['starter-yearly'] * 0.17));
        break;

      case this.subType.Growth:
      case this.subType.YearlyGrowth:
        amount = this.checked
          ? this.pricing["growth"]
          : this.twoDecimal(this.displayedPrice(this.pricing["growth-yearly"]));
        // this.twoDecimal(this.displayedPrice(this.pricing['growth-yearly']) - this.displayedPrice(this.pricing['growth-yearly'] * 0.17));
        break;

      case this.subType.SalesPro:
      case this.subType.YearlySalesPro:
        amount = this.checked
          ? this.pricing["sales-pro-monthly"]
          : this.twoDecimal(
              this.displayedPrice(this.pricing["sales-pro-yearly"])
            );
        // this.twoDecimal(this.displayedPrice(this.pricing['sales-pro-yearly']) - this.displayedPrice(this.pricing['sales-pro-yearly'] * 0.17));
        break;

      default:
        break;
    }

    this.planAmount =
      this.totalAmount =
      this.dueTodayAmount =
      this.payAmount =
        amount;
  }

  toastMsg(msg: string) {
    this.notification.toast(msg, "OK", 2000);
  }
  public async confirmPaymentSetup() {
    if (!this.name) {
      this.toastMsg("Name on Card is Required");
      return;
    }
    if (!this.add1) {
      this.toastMsg("Address Line 1 is Required");
      return;
    }
    if (!this.city) {
      this.toastMsg("City is Required");
      return;
    }
    if (!this.state) {
      this.toastMsg("State is Required");
      return;
    }
    if (!this.zip) {
      this.toastMsg("Zip Code is Required");
      return;
    }
    if (!this.country) {
      this.toastMsg("Country is Required");
      return;
    }

    try {
      this.loading = true;
      this.loadingService.start(this.paymentDetailsId);
      
      this.client_secret = await lastValueFrom(this.stripeService.genPaymentIntent());
      
      const { setupIntent, error } =
        await this.stripeService.stripe.confirmCardSetup(this.client_secret, {
          payment_method: {
            card: this.paymentElement,
            billing_details: {
              name: this.name,
              address: {
                city: this.city,
                country: this.country,
                line1: this.add1,
                line2: this.add2,
                postal_code: this.zip,
                state: this.state,
              },
            },
          },
        });

      if (error) {
        const errorMessage = error.message || "Please check your card details and try again. If this happens again, please contact heysupport@vengreso.com";
        this.notification.toast(errorMessage);
        this.loading = false;
        this.loadingService.stop(this.paymentDetailsId);
        return;
      }

      this.stripeService
      .subToPlan(
        setupIntent.payment_method,
        this.planType,
        this.discountCode
      )
      .pipe(finalize(() => this.loadingService.stop(this.paymentDetailsId)))
      .subscribe({
      next: (response: any) => {
        this.clearForm();
        this.sharedData.deleteAttribute("discount");
        this.sharedData.deleteAttribute("discountCode");
        this.toastMsg("Thank you, for your subscription");
        this.cookieService.set('flymsg_planId', this.planType, {
          domain: environment.FLYMSG_DOMAIN,
          path: '/',
          secure: true,
          sameSite: 'None'
        });
        this._router.navigate(["/settings"], {
          queryParams: { tab: "subscription" },
        });
      },
      error: (error) => {
        this.loading = false;
        const errorMessage = error?.status == 422 ?
          "Your bank declined this transaction. Please check your bank account or contact your bank." : 
          "Oops Sorry. Please try again. If this happens again, please contact heysupport@vengreso.com";
          this.notification.toast(errorMessage);
      },
      });
    } catch (error) {
      this.loading = false;
      this.loadingService.stop(this.paymentDetailsId);
      this.notification.toast("Oops Sorry. Please try again. If this happens again, please contact heysupport@vengreso.com");
    }
  }

  public backClicked() {
    let plan = this.planType as string;

    if (!this.checked) {
      plan == "Starter" || plan == "starter-yearly"
        ? (plan = "YearlyStarter")
        : plan == "Growth" || plan == "growth-yearly"
        ? (plan = "YearlyGrowth")
        : (plan = "YearlySalesPro");
    }

    this._router.navigate(["/new-plan", plan]);
  }

  clearForm() {
    this.name = "";
    this.add1 = "";
    this.city = "";
    this.state = "";
    this.zip = "";
    this.country = null;
    this.discountCode = "";
  }

  apply() {
    this.discountCodeInvalid = this.discountCodeNotSupplied = false;
    this.appliedDiscountCode = this.discountCode;
    this.discountCodeApplied = true;
    this.discount = 0;

    if (!this.discountCode?.trim()) {
      this.discountCodeNotSupplied = true;
      return;
    }

    //Check if discount has been applied
    if (this.discount !== 0) {
      this.toastMsg("Discount code already applied");
      return;
    }

    let plan_identifier = "freemium";

    for (const key in SubscriptionPlanIdentifier) {
      if (
        SubscriptionPlanIdentifier[key] === this.planType ||
        key === this.planType
      ) {
        plan_identifier = SubscriptionPlanIdentifier[key];
        break;
      }
    }
    this.stripeService
      .retrieveCouponObject(this.discountCode, plan_identifier)
      .subscribe({
        next: (response: any) => {
          const coupon = response?.result;

          if (coupon?.valid !== true) {
            this.invalidateCoupon();
            return;
          }

          this.applyCoupon(coupon);
        },
        error: () => {
          this.invalidateCoupon();
        },
      });
  }

  applyCoupon(coupon: any): void {
    const { percent_off_precise, amount_off } = coupon;
    this.discount =
      percent_off_precise !== null
        ? ((percent_off_precise / 100) * this.planAmount).toFixed(2)
        : (amount_off / 100).toFixed(2);
    this.sharedData.setAttribute("discount", this.discount);
    this.sharedData.setAttribute("discountCode", this.discountCode);
    this.appliedDiscountCode = this.discountCode;
    this.discountCodeApplied = true;
  }

  invalidateCoupon(): void {
    this.discountCodeInvalid = true;
  }

  onKeyUp(event) {
    setTimeout(() => {
      this.discountCodeApplied = false;
    }, 500);
  }

  discountedPayment(amount: any): string | number {
    return amount - this.discount > 0
      ? (amount - this.discount).toFixed(2)
      : Number(amount).toFixed(2);
  }

  displayedPrice(amount: any) {
    return amount ? Math.trunc(Number(amount) * 100) / 100 : 0.0;
  }

  twoDecimal(amount: any) {
    return amount ? Number(amount).toFixed(2) : 0.0;
  }

  getPricing() {
    this.dataService
      .get({ url: "v1/subscriptions/plans", isLoader: false })
      .subscribe({
        next: (response: any) => {
          response.result.forEach(({ identifier, unit_amount }) => {
            this.pricing[identifier] = unit_amount / 100;
          });

          this.calculatePayment();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
}
