export class PlansConstants {
    public static FREE = {
        IMG_SIZE: 1,
        NUM_OF_FLYCUTS: 20,
        NUM_OF_CHAR: 2500,
        CATEGORIES: 7,
        SUB_CATEGORIES: 14,
    };
    
    public static STARTER = {
        IMG_SIZE: 5,
        NUM_OF_FLYCUTS: 300,
        NUM_OF_CHAR: 25000,
        CATEGORIES: 14,
        SUB_CATEGORIES: 28,
    };
}

export class RewardsConstants {
  public static UNLIMITED_FLYCUTS = 'UNLIMITED_FLYCUTS';
  public static UNLIMITED_CHARACTERS = 'UNLIMITED_CHARACTERS';
  public static ONE_ADDITIONAL_FLYCUTS_CATEGORY = 'ONE_ADDITIONAL_FLYCUTS_CATEGORY';
  public static ALL_FLYPLATES = 'ALL_FLYPLATES';
}

export const getPlanId = () => {
    let planId = localStorage.getItem("planId");

    if (planId == "starter-yearly") {
      planId = "Starter";
      return planId
    }
    if (planId == "starter" || planId == "Starter") {
      planId = "Starter";
      return planId
    }
    if (planId == "growth" || planId == "Growth" || planId == "growth-yearly" || planId == "sales-pro-yearly"  || planId == "sales-pro-monthly") {
      planId = "Growth";
      return planId
    }

    if (planId == "pro-plan-teams-smb" || planId == "pro-plan-teams-ent") {
      let a = planId.split("-");
      let b = a[a.length - 1];
      let c = b.toLocaleUpperCase();
      planId = "Pro Teams - " + c;
      return planId
    }

    return "Free"
}

export const getRewards = () => {
    let rewards = []
    let inviteCounter = JSON.parse(localStorage.getItem("vengreso.inviteCounter")) || 0;

    if (inviteCounter >= 1) {
        rewards.push('UNLIMITED_FLYCUTS')
    }

    if (inviteCounter >= 2) {
        rewards.push('UNLIMITED_CHARACTERS')
    }

    if (inviteCounter >= 3) {
        rewards.push('ONE_ADDITIONAL_FLYCUTS_CATEGORY')
    }

    if (inviteCounter >= 4) {
        rewards.push('ALL_FLYPLATES')
    }
    return rewards
}

export const getCategoriesReward = () => {
    let inviteCounter = JSON.parse(localStorage.getItem("vengreso.inviteCounter")) || 0;

    if (inviteCounter >= 3) {
        return 1
    }

    return 0
}

export class PlansRestrictions {
  //list of the restricted feature to growth plan
  public static Growth = [];

  //list of the restricted feature to starter plan
  public static Starter = [
    "hyperlinks",
    "mceLink",
    // "mceMedia",
    // "paste",
    "mceUpdateImage",
    "JustifyLeft",
    "JustifyCenter",
    "JustifyRight",
    "JustifyFull",
    "RemoveFormat",
    "mcePreview",
    "indent",
    "outdent",
    "mceApplyTextcolor",
    // "FontName",
    // "FontSize",
  ];
  
  //list of the restricted feature to free plan
  public static Free = [
    // "bold",
    "italic",
    "underline",
    "strikethrough",
    "hyperlinks",
    "mceLink",
    // "mceMedia",
    // "paste",
    "mceUpdateImage",
    "JustifyLeft",
    "JustifyCenter",
    "JustifyRight",
    "JustifyFull",
    "InsertUnorderedList",
    "InsertOrderedList",
    "indent",
    "outdent",
    "mceApplyTextcolor",
    "RemoveFormat",
    "mcePreview",
    "FontName",
    "FontSize",
  ];
}