import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDatepicker } from "@angular/material/datepicker";
import { map } from "rxjs/operators";
import { DataService } from "../../core-services/services/dataservices/data.service";
import { Observable, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-advanced-search-dialog",
  templateUrl: "./advanced-search-dialog.component.html",
  styleUrls: ["./advanced-search-dialog.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdvancedSearchDialogComponent implements OnInit, OnDestroy {
  flycut: string;
  title: string;
  hasTheWords: string;
  doesntHave: string;
  createdWithin: string = "any-time";
  bySearch: string = "all-flymsg";
  hasAttachment: boolean;
  hasHyperlink: boolean;
  date: any;
  minDate: any;
  maxDate: any;
  createdWithinPicker: MatDatepicker<any>;
  filteredOptions: Observable<any>;
  form: FormGroup;
  isDialogClosed = true;
  formSubscription: Subscription

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    public dialogRef: MatDialogRef<AdvancedSearchDialogComponent>,
    public dialog: DialogService,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any
  ) {
    let item = localStorage.getItem("advancedSearchData");
    if (item) {
      let params = JSON.parse(item);
      for (const param of Object.keys(params)) {
        this[param + ""] = params[param];
      }
    }

    this.form = this.fb.group({
      flycut: new FormControl(),
      title: new FormControl(),
      hasTheWords: new FormControl(),
      doesntHave: new FormControl(),
      createdWithin: new FormControl(),
      date: new FormControl(),
      bySearch: new FormControl(),
      hasAttachment: new FormControl(),
      hasHyperlink: new FormControl(),
    });

    let params = {
      flycut: this.flycut || "",
      title: this.title || "",
      hasTheWords: this.hasTheWords || "",
      doesntHave: this.doesntHave || "",
      createdWithin: this.createdWithin == "" ? "any-time" : this.createdWithin,
      date: this.date || null,
      bySearch: this.bySearch == "" ? "all-flymsg" : this.bySearch,
      hasAttachment: this.hasAttachment ? this.hasAttachment : false,
      hasHyperlink: this.hasHyperlink ? this.hasHyperlink : false,
    };
    this.form.setValue(params);

    this.formSubscription = this.form.valueChanges.subscribe((event) => {
      if (event.hasAttachment) {
        if (!this.canEdit()) {
          this.form.get("hasAttachment").patchValue(false);
          return;
        }
      }
      if (event.hasHyperlink) {
        if (!this.canEdit()) {
          this.form.get("hasHyperlink").patchValue(false);
          return;
        }
      }
      if (event.title) {
        if (!this.canEdit()) {
          this.form.get("title").patchValue("");
          return;
        }
      }
      if (event.flycut) {
        if (!this.canEdit()) {
          this.form.get("flycut").patchValue("");
          return;
        }
      }
      if (event.hasTheWords) {
        if (!this.canEdit()) {
          this.form.get("hasTheWords").patchValue("");
          return;
        }
      }
      if (event.doesntHave) {
        if (!this.canEdit()) {
          this.form.get("doesntHave").patchValue("");
          return;
        }
      }
      if (event.date) {
        if (!this.canEdit()) {
          this.form.get("date").patchValue("");
          return;
        }
      }
      if (event.createdWithin && event.createdWithin != "any-time") {
        if (!this.canEdit()) {
          this.form.get("createdWithin").patchValue("any-time");
          return;
        }
      }
      if (event.bySearch && event.bySearch != "all-flymsg") {
        if (!this.canEdit()) {
          this.form.get("bySearch").patchValue("all-flymsg");
          return;
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.filteredOptions = await this.categories();
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  resetSearch() {
    this.form.reset()
    this.form.controls['createdWithin'].reset()
    this.form.controls['bySearch'].reset()
    this.form.controls['hasAttachment'].reset()
    this.form.controls['hasHyperlink'].reset()
    this.flycut = "";
    this.title = "";
    this.hasTheWords = "";
    this.doesntHave = "";
    this.createdWithin = "";
    this.date = null,
    this.bySearch = "";
    this.hasAttachment = false;
    this.hasHyperlink = false;
  }

  closeDialog() {
    this.resetSearch()
    this.dialogRef.close({ event: "close", data: "" });
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  advancedSearch(type: string) {
    let planId = getPlanId();
    if (
      planId != "Free" &&
      !this.form.value.date &&
      type == "createdWithen" &&
      this.form.value.createdWithin != "any-time"
    )
      this.form.get("date").setValue(new Date());

    let params = {
      flycut: this.form.value.flycut ? this.form.value.flycut : "",
      title: this.form.value.title ? this.form.value.title : "",
      hasTheWords: this.form.value.hasTheWords
        ? this.form.value.hasTheWords
        : "",
      doesntHave: this.form.value.doesntHave ? this.form.value.doesntHave : "",
      createdWithin: this.form.value.createdWithin
        ? this.form.value.createdWithin
        : "",
      date: this.getDate(),
      bySearch: this.form.value.bySearch ? this.form.value.bySearch : "",
      hasAttachment: this.form.value.hasAttachment
        ? this.form.value.hasAttachment
        : false,
      hasHyperlink: this.form.value.hasHyperlink
        ? this.form.value.hasHyperlink
        : false,
    };
    let myObjStr = JSON.stringify(params);
    localStorage.setItem("advancedSearchData", myObjStr);
    if (type == "search") {
      if (!this.canEdit()) {
        return;
      }
      if (this.form.value.createdWithin == "any-time")
        this.form.value.date = "";

      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/advanced-search-result"]);
        //this.resetSearch()
      });
    }
  }

  categories(): any {
    return this.dataService
      .getCategories()
      .pipe(map((tracks) => tracks.result.data));
  }

  getDate() {
    let dateTmp = null;
    switch (this.form.value.createdWithin) {
      case "3d":
        dateTmp = new Date();
        dateTmp.setDate(dateTmp.getDate() - 3);
        break;
      case "1w":
        dateTmp = new Date();
        dateTmp.setDate(dateTmp.getDate() - 7);
        break;
      case "2w":
        dateTmp = new Date();
        dateTmp.setDate(dateTmp.getDate() - 14);
        break;
      case "1m":
        dateTmp = new Date();
        dateTmp.setDate(dateTmp.getDate() - 31);
        break;
      case "any-time":
        dateTmp = "";
        break;
      default:
        dateTmp = "";
        break;
    }

    if (dateTmp == "") {
      return "";
    } else {
      // dateTmp = new Date(this.date);
      let monthTmp = dateTmp.getMonth() + 1;
      let month = monthTmp.toString();
      if (monthTmp < 10) {
        month = "0" + monthTmp.toString();
      }
      return dateTmp.getFullYear() + "-" + month + "-" + dateTmp.getDate();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.closeDialog();
      this.advancedSearch("search");
    }
  }

  handleCloseDialog() {
    this.isDialogClosed = true;
  }

  canEdit() {
    let planId = getPlanId();
    if (planId == "Free") {
      if (this.isDialogClosed) {
        this.isDialogClosed = false;
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          {
            self: this,
            type: "advancedSearch",
            showTheStarter: planId === "Free",
            error: "advancedSearch",
          },
          "800px"
        );
      }
      return false;
    }
    return true;
  }
}
