import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-category-dependency",
  templateUrl: "./category-dependency.component.html",
  styleUrls: ["./category-dependency.component.scss"]
})
export class CategoryDependencyComponent implements OnInit {

  deleteType: FormControl;
  shortcutCategoryList = [];
  category = new FormControl();

  constructor(public dialogRef: MatDialogRef<CategoryDependencyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.deleteType = new FormControl("move");
    this.category = new FormControl();
  }

  ngOnInit(): void {
    this.shortcutCategoryList = cloneDeep(this.data.self.shortcutCategoryList);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveCLick(): void {
    this.data.data.dependency = {
      type: this.deleteType.value
    };

    if (this.deleteType.value === "move") {
      this.data.data.dependency.category_id = this.category.value;
    }

    this.data.self.delete(this.data.data);
    this.dialogRef.close();
  }
}
