import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';

import { SubscriptionPlanType } from 'src/app/core/models/stripe.models';
import { SUBSCRIPTION_TAB } from '../settings.config';

@Component({
  selector: 'app-downgraded',
  templateUrl: './downgraded.component.html',
  styleUrls: ['./downgraded.component.css']
})
export class DowngradedComponent implements OnInit, OnDestroy {

  planDowngradedTo: string = SubscriptionPlanType.Freemium;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : {planDowngradedTo : string},
    public dialogRef: MatDialogRef<DowngradedComponent>,
    private location: Location
  ) {
    this.planDowngradedTo = data.planDowngradedTo;
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.location.replaceState(`settings?tab=${SUBSCRIPTION_TAB}`);
  }

  close() {
    this.dialogRef.close();
  }

}
