export interface SubscriptionPlan {
	stripe_id: string;
	identifier: string;
	title: string;
}

export enum SubscriptionPlanType {
	Freemium = "Freemium",
	Starter = "Starter",
	Growth = "Growth",
	SalesPro = "SalesPro",
	YearlyStarter = "starter-yearly",
	YearlyGrowth = "growth-yearly",
	YearlySalesPro = "sales-pro-yearly",
	MonthlySalesPro = "sales-pro-monthly",
	ProPlanTeamsSMB = "Pro Plan Teams - SMB",
	ProPlanTeamsEnt = "Pro Plan Teams - ENT",
}

export enum SubscriptionPlanIdentifier {
	Freemium = "freemium",
	Starter = "starter",
	YearlyStarter = "starter-yearly",
	Growth = "growth",
	YearlyGrowth = "growth-yearly",
	SalesPro = "sales-pro-monthly",
	YearlySalesPro = "sales-pro-yearly",
}

export class PlanStatus {
	public static Active = 'active';
	public static Paused = 'paused';
	public static Trial = 'trialing';
	public static PastDue = 'past_due';
	public static Canceled = 'canceled';
	public static Incomplete = 'incomplete';
  
	public static getNameByValue(value: string): string {
	  for (const prop in PlanStatus) {
		if (PlanStatus.hasOwnProperty(prop) && PlanStatus[prop] === value) {
		  return prop;
		}
	  }
	  return '';
	}
}