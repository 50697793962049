import { Injectable, NgZone } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog, private zone: NgZone) {}

  openDialogComponent(component: any, data: any, width: string = "400px", disableClose: boolean = false):  MatDialogRef<unknown, any> {
    let dialogRef: MatDialogRef<unknown, any>;
    this.zone.run(() => {
      dialogRef = this.dialog.open(component, {
        data: data || {},
        width,
        maxWidth: "none",
        maxHeight: "calc(100vh - 40px)",
        panelClass: data.class,
        closeOnNavigation: true,
        autoFocus: false,
        disableClose
      });
      return dialogRef;
    });

    if (data.self?.handleCloseDialog) {
      dialogRef.afterClosed().subscribe((result) => {
        data.self.handleCloseDialog();
      });
    }
    return dialogRef;
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
