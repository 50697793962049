import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TABLE_COLUMN } from '../admin.data';
import { AdminApiService } from '../services/admin-api.service';


@Component({
  selector: 'app-edit-reporting',
  templateUrl: './edit-reporting.component.html',
  styleUrls: ['./edit-reporting.component.scss']
})
export class EditReportingComponent implements OnInit {
  tableColumns:any = TABLE_COLUMN;
  allChecked: boolean = false;
  alwaysChecked: any;

  constructor(
    public dialogRef: MatDialogRef<EditReportingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminApiService: AdminApiService
  ) { }

  ngOnInit(): void {
    //Checked columns are columns selected by default and columns user selected 
    this.alwaysChecked = this.data.defaultColumns
    this.tableColumns = this.tableColumns.map(
       (column: any) => this.data.selectedColumns.includes(column.key) ? {...column, checked: true} : column
    );
  }

  updateAllChecked() {
    this.allChecked = this.tableColumns.filter((column:any) => !this.alwaysChecked.includes(column.key)).every((column:any) => column.checked);
  }

  someChecked(): boolean {
    return this.tableColumns.filter((column:any) => column.checked && !this.alwaysChecked.includes(column.key)).length > 0 && !this.allChecked;
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    this.tableColumns.filter((column:any) => !this.alwaysChecked.includes(column.key)).forEach((column:any) => (column.checked = checked));
  }

  save() {
    let headers = this.tableColumns.map(
      (column: any) => column.checked ? column : {...column, checked: false}
    );
    let data = {
      columns: headers
    }
    
    this.adminApiService.saveUserSavedColumns(data).subscribe((response: any) => {
      if(response && response.result && response.result.success == true) {
        this.close(response.result.column);
      }
    });
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }
}
