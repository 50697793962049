import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { EditGroupComponent } from "../edit-group/edit-group.component";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AdminApiService } from "../services/admin-api.service";

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, OnDestroy {
  companySlug: string;
  dataSource: any;
  groups: any[] = [];
  group: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "group",
    "members",
    "actions",
    "collapse",
  ];
  expandedElement: any | null;
  search: string;
  loadingGetGroups: boolean;

  constructor(
    public dialog: DialogService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private adminApiService: AdminApiService
  ) {
    this.companySlug = this.authService.getUserCompanySlug();
  }

  ngOnInit(): void {
    this.loadGroups();
  }

  loadGroups(){
    if (this.isReportingAdmin) {
      this.displayedColumns = ["group", "members"];
    } 

    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }
    try {
      this.loadingGetGroups = true
      this.adminApiService.getGroups().subscribe((response: any) => {
        if (response && response.result && response.result.groups) {
          this.groups = response.result.groups;

          this.groups.forEach((group: any) => {
            let subgroups = group.group?.subgroups;
            if (Array.isArray(subgroups) && subgroups.length > 0) {
              group.group.subgroups = new MatTableDataSource(subgroups);
            }
          });

          this.dataSource = new MatTableDataSource(this.groups);
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            return data.group['name'].toLowerCase().includes(filter);
          };
        }
        this.loadingGetGroups = false
      });
    } catch (error) {
      this.loadingGetGroups = false
      console.log(error);
    }
  }

  searchGroups() {
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  get isGlobalAdmin(): boolean {
    return this.authService.getUserRole() === "Global Admin";
  }

  get isGroupAdmin(): boolean {
    return this.authService.getUserRole() === "Group Admin";
  }

  get isReportingAdmin(): boolean {
    return this.authService.getUserRole() === "Reporting Admin";
  }

  goToGroup(groupSlug: string, event: Event) {
    if ((event.target as HTMLElement).nodeName === 'TD' && (event.target as HTMLElement).getAttribute('clickable-cell')) {
      return;
    }

    if(!groupSlug){
      return;
    }

    this.router.navigate([`/${this.companySlug}/admin/groups/${groupSlug}/allusers`]);
  }

  goToSubGroup(groupSlug: string, subGroupSlug:string, event){
    if(!subGroupSlug){
      this.goToGroup(groupSlug, event);
    }

    if(!groupSlug){
      return;
    }

    this.router.navigate([`/${this.companySlug}/admin/groups/${groupSlug}/${subGroupSlug}`]);
  }

  addAction() {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { 
        action: this.isGlobalAdmin ? "addNewGroup" : "addSubGroup", 
        data: this.group, 
        class: 'portal-admin' 
      },
      "550px"
    );

    dialogRef.afterClosed().subscribe((data: any) => {
      if(!!data){
        this.dataSource.data = this.dataSource.data.concat(data);
      }
    });
  }

  toggleRow(element: any, event: Event) {
    event.stopPropagation();
    
    element.group?.subgroup !== null
      ? (this.expandedElement =
          this.expandedElement === element ? null : element)
      : null;
    this.cd.detectChanges();
  }

  editGroup(element: any): void {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "editGroup", data: element.group, class: 'portal-admin' },
      "550px"
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if(!!result){
        this.loadGroups();
      }
    });
  }

  editSubgroup(subElement: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "editSubGroup", data: subElement, class: 'portal-admin' },
      "550px"
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if(!!result){
        this.loadGroups();
      }
    });
  }

  deleteGroup(element: any): void {
   const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "deleteGroup", data: element.group, class: 'portal-admin' },
      "550px"
    );
    dialogRef.afterClosed().subscribe((groupId: string) => {
      if(!!groupId){
        this.groups = this.groups.filter((group:any) => group.group.id !== groupId);
        this.dataSource.data = this.groups;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  deleteSubgroup(element: any, subElement: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "deleteSubGroup", data: subElement, class: 'portal-admin' },
      "550px"
    );

    dialogRef.afterClosed().subscribe((subgroupId: string) => {
      if(!!subgroupId){
        this.loadGroups();
      }
    });
  }

  toSlug(text: string) {
    return text
        .toLowerCase()                   
        .trim()                          
        .replace(/[^a-z0-9\s-]/g, '')    
        .replace(/\s+/g, '-')            
        .replace(/-+/g, '-');
  }

  addUsersFromSubgroup(element, subElement): void {
    this.router.navigate(['/'+this.companySlug+'/admin/groups/'+element.group.slug+'/'+subElement.group.slug+'/add-users']);
  }
  removeUsersFromSubgroup(element, subElement): void {
    this.router.navigate(['/'+this.companySlug+'/admin/groups/'+element.group.slug+'/'+subElement.group.slug+'/remove-users']);
  }

  ngOnDestroy() {
  }
}
