import { Component, Inject } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";

import { ConfirmVersionComponent } from "./confirm-version-dialog/confirm-version-dialog.component";
@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent {
  prevFlyCut: any
  currentFlyCut: any
  editorConfig: any;
  editorApiKey: string;
  scrollDisabled = true;

  constructor(
    config: NgbPopoverConfig,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreviewComponent>,
    public dialog: DialogService
  ) {
    config.triggers = "hover";
    config.container = "body";
    this.currentFlyCut = this.data.self.data.shortcut;
    this.prevFlyCut = this.data.element;
    this.scrollDisabled = false;
      this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
      this.editorConfig = {
        statusbar: false,
        menubar: false,
        toolbar: false,
        min_height: 100,
        max_height: 200,
        body_class: "noselect",
        content_style: ApplicationConstants.TINYMCE_CONTENT_CSS,
    };
  }

  confirmVersionDialog() {
    this.dialog.openDialogComponent(
      ConfirmVersionComponent,
      { self: this, element: this.data.element },
      "430px"
    );
  }

  close(): void {
    this.dialogRef.close();
  }
}
