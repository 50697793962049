import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlanState } from 'src/app/core/store/plan/plan.reducer';

@Component({
  selector: 'app-history-usage',
  templateUrl: './history-usage.component.html',
  styleUrls: ['./history-usage.component.scss']
})
export class HistoryUsageComponent implements OnInit {

  @Input() isFreemium: () => boolean;
  @Input() isStarter: () => boolean;
  @Input() isGrowth: () => boolean;

  size: number = 185;
  foregroundColor: string = '#F15A2A';
  type: string ="full";
  thickness:number = 15;
  characters: number = 0;
  spell_check_language: string = 'English';
  designatedTotal: number = 100;

  config: any = {
    value: 0,
    size: this.size,
    designatedTotal: 0,
    thickness: this.thickness,
    foregroundColor: this.foregroundColor
  }

  flycut: any = {
    name: "FlyCuts",
    ...this.config
  } ;

  flyplate: any = {
    name: "FlyPlates",
    ...this.config
  };

  category: any = {
    name: "Categories",
    ...this.config
   };

  subcategory: any = {
    name: "Subcategories",
    ...this.config
  };

  currentPlan$ = this.store.select(state => state.data);

  constructor(
    private store: Store<PlanState>,
  ) {

  }

  ngOnInit(): void {
    this.currentPlan$
      .subscribe(({data: currentPlan}: any) => {
        if (currentPlan?.statistics) {
          const statistics = currentPlan.statistics;

          this.flycut.value = statistics.flycuts;
          this.flycut.designatedTotal = this.isGrowth() ? this.designatedTotal : statistics.flycuts_available;

          this.flyplate.value = statistics.flyplates;
          this.flyplate.designatedTotal = this.isGrowth() ? this.designatedTotal : statistics.flyplates_available;

          this.category.value = statistics.categories;
          this.category.designatedTotal = this.isGrowth() ? this.designatedTotal : statistics.categories_available;

          this.subcategory.value = statistics.sub_categories;
          this.subcategory.designatedTotal = this.isGrowth() ? this.designatedTotal : statistics.sub_categories_available;

          this.characters = statistics.characters;
          this.spell_check_language = statistics.spell_check_language;
        }
      });
  }

}
