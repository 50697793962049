import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { cloneDeep, forEach } from "lodash";
import { Observable, catchError, map, throwError } from "rxjs";
import { Store } from '@ngrx/store';
import { ActivatedRoute } from "@angular/router";
import { detect } from "detect-browser";

import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { environment } from "src/environments/environment";
import { DataService } from "./dataservices/data.service";
import { ExtensionService } from "./extension.service";
import { LoadingService } from "./loader.service";
import { NavigationService } from "./navigation.service";
import { NotificationService } from "./notification.service";
import { SharedDataService } from "./shared-data.service";
import { NotifyService } from "../../menu/not.service";

import { PlanState } from 'src/app/core/store/plan/plan.reducer';
import { loadUserCurrentSubscriptionApi } from 'src/app/core/store/plan/plan.actions';
import { ROLES } from "../constants/roles.constant";
import { DialogService } from "./dialog.service";
import { SignInErrorComponent } from "src/app/session/signin/sign-in-error/sign-in-error.component";

declare var profitwell: any;

@Injectable()
export class AuthService {
  redirectUrl: string;
  constructor(
    private dialogRef: MatDialog,
    public dialog: DialogService,
    private nav: NavigationService,
    private dataService: DataService,
    private loaderService: LoadingService,
    private sharedData: SharedDataService,
    private socialService: SocialAuthService,
    private notification: NotificationService,
    private extensionService: ExtensionService,
    private stripeService: StripeService,
    public notifyService: NotifyService,
    private route: ActivatedRoute,
    private store: Store<PlanState>,
  ) {
    // Setting default route to navigate after login
    this.redirectUrl = "/flyboard";
    this.route.queryParams.subscribe((params) => {
      if (params.redirect) {
        if (params.redirect === "starter") {
          this.redirectUrl = "/new-plan/Starter";
        }
        if (params.redirect == "yearly-starter") {
          this.redirectUrl = "/new-plan/YearlyStarter";
        }

        if (params.redirect == "growth") {
          this.redirectUrl = "/new-plan/Growth";
        }

        if (params.redirect == "yearly-growth") {
          this.redirectUrl = "/new-plan/YearlyGrowth";
        }

        if (params.redirect == "sales-pro") {
          this.redirectUrl = "/new-plan/SalesPro";
        }

        if (params.redirect == "yearly-sales-pro") {
          this.redirectUrl = "/new-plan/YearlySalesPro";
        }

        if (params.redirect == "freemium") {
          this.redirectUrl = "/flyboard";
        }
      }
    });
  }

  getAccessToken() {
    return this.sharedData.getAttribute("accessToken");
  }

  login(userData: any) {
    if (userData.email) userData.email = userData.email.toLowerCase();
    const url = "v1/user/auth/login";
    this.dataService.post({ url, data: {value: userData, noCredentials: true}, isLoader: true }).subscribe({
      next: async (response: any) => {
        const result = response.result;
        if (result) {
          await this.saveUserDetail(result);
          this.extensionService.sendLoginMessage(url, result);

          //Check if user with temporary password hasn't changed it
          const hasTemporaryPassword = result.user_details.hasOwnProperty("temp_password");
          
          if(hasTemporaryPassword){
            this.nav.navigateTo(["/session/sign-in/set-new-password"]);
            return;
          }
          
          if (this.redirectUrl.includes("instancyssorequested=true")) {
            this.nav.navigateTo(["/flyboard"], { queryParams: { instancyssorequested: "true" } });
            return;
          }

          if (result.user_details?.onboarding == "pending" || !result?.user_details?.onboarding) {
            if (this.redirectUrl.includes("activate_trial=true")) {
              this.stripeService.startSalesProTrial().subscribe({
                next: (response: any) => {
                  this.nav.navigateTo([`/onboarding`]);
                  return;
                },
                error: (error) => {
                  console.log(error)
                }
              });
            }
            this.nav.navigateTo([`/onboarding`]);
          } else {
            if (this.redirectUrl.includes("activate_trial=true")) {
              this.nav.navigateTo(["/new-plan/SalesPro"], { queryParams: { activate_trial: "true" } });
              return;
            }
            this.nav.navigateTo([this.redirectUrl]);
          }

          profitwell("start", { user_email: result.user_details.email });
        } else {
          if (response.error) {
            this.notification.toast(response.error, "danger");
          }
        }
      },
      error: (error) => {
        if(error.status == 422) {
          if (error?.error?.code == "EXPIRED_LOGIN") {
            this.nav.navigateTo(["/session/expired-login"], { queryParams: { email: userData.email } });
            return;
          }

          if (error?.error?.code == "DEACTIVATED") {
            
            this.dialog.openDialogComponent(
              SignInErrorComponent,
              { 
                admin_email: error?.error?.admin_email,
              },
              "500px"
            );
          }
        }
        
        this.notification.toast(error?.error?.error, "danger");
      }}
    );
  }

  isLoggedIn() {
    return Boolean(
      this.sharedData.getAttribute("accessToken") &&
        this.sharedData.getAttribute("userDetails")
    );
  }

  refreshAccessToken(): Observable<any> {
    const data = { refresh_token: this.getRefreshToken() };
    return this.dataService.post({
      url: "v1/user/auth/refresh",
      data,
      isLoader: false,
    });
  }

  getRefreshToken() {
    return this.sharedData.getAttribute("refreshToken");
  }

  redirectAfterLogout() {
    var browser = detect();
    var browser_type;
    if (
      browser &&
      (browser?.name == "edge" || browser?.name == "edge-chromium")
    ) {
      browser_type = "edge";
    } else {
      browser_type = browser?.name;
    }
    this.dialogRef.closeAll();
    this.extensionService.sendLogoutMessage();
    this.sharedData.clear();
    this.stripeService.removePlanInformation();
    // Store browser type

    if (browser_type) {
      localStorage.setItem("browser_type", browser_type);
    }
    this.nav.navigateTo(["/session/signin"]);
    if (environment.production) {
      // Reset identity on heap before logout
      window.heap.resetIdentity();
    }
  }

  getUserDetail(needFullDetails?: boolean) {
    const userDetails = cloneDeep(this.sharedData.getAttribute("userDetails"));
    return needFullDetails ? userDetails : userDetails.user_details;
  }

  getUserRole(): string {
    const userRoles = this.getUserDetail()?.role;

    if(userRoles?.length > 0) {
      for (let role of ROLES) {
        if (userRoles.includes(role)) {
          return role;
        }
      }
    }
  
    return 'User';
  }

  getUserCompanySlug(): string {
    const {company, masquerade} = this.getUserDetail(true);

    return this.onCompanyMasqueradeSession ? masquerade.company_slug : company ?? "company"
  }

  showAdminSection(): boolean {
    const role = this.getUserRole();

    return [
      'Vengreso Admin',
      'Group Admin',
      'Reporting Admin',
      'Global Admin'
    ].includes(role);
  }

  logout(logoutReason: any = {}) {
    const userDetail = this.getUserDetail(true);
    if (userDetail.provider) {
      this.socialService.signOut().then((response: any) => {
      });
    }

    this.dataService
      .post({ url: "v1/user/auth/logout", data: logoutReason })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.redirectAfterLogout();
        }
      });

    this.redirectUrl = "/flyboard";
  }

  getNewTemporaryPassword(email: string) {
    return this.dataService.post({
      url: "v1/users/resend-temporary-password",
      data: {email},
      isLoader: true,
    });
  }

  resetTemporaryPassword(data: any) {
    return this.dataService.post({
      url: "v1/users/reset-invited-user-password",
      data: data,
      isLoader: true
    });
  }

  resetPassword(userData: any) {
    const data = {
      value: userData,
      noCredentials: true,
    };

    this.dataService.post({ url: "password/email", data }).subscribe(
      (result: any) => {
        this.nav.navigateTo(["/login"]);
        this.notification.toast("Check Your Mail! Click on reset link.");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  registration(val: any) {
    if (val.email) val.email = val.email.toLowerCase();
    this.loaderService.start();
    this.dataService
      .post({ url: "v1/user/auth/register", data: val, isLoader: false })
      .subscribe({
        next: async (response: any) => {
          // The user is logged in after the sign up.
          await this.saveUserDetail(response.result);
          if (this.redirectUrl.includes("activate_trial=true")) {
             this.stripeService.startSalesProTrial().subscribe({
              next: (response: any) => {
                this.nav.navigateTo([`/onboarding`]);
                return;
              },
              error: (error) => {
                console.log(error)
              }
            });
          }
          // Track the affiliate signup event
          if (typeof window.gr === 'function') {
            console.log('✅ gr function is defined');
            window.gr("track", "conversion", { email: val.email.toLowerCase()});
          } else {
             // Normally this means the gr function wasn't declared here, 
             // try and look why the Tracking Script isn't running on this page
            console.log('⛔️ gr function is NOT defined');
          }

          if(response.result?.is_company_poc === true){
            const companySlug = this.getUserCompanySlug();
            this.nav.navigateTo([`/${companySlug}/admin/users`]);
            this.loaderService.stop();
            return;
          }

          this.nav.navigateTo([`/onboarding`]);
          this.loaderService.stop();
        },
        error: ({ error }: any) => {
          this.loaderService.stop();
          let errors = []
          forEach(error?.error_list, (array, key) => {
            errors = errors.concat(array);
          });
          if(errors.length === 1 && errors[0] === 'The email has already been taken.'){
            this.notification.toast(
              "You already have an account under this email address. Please sign-in instead.",
              "ok",
              4000
            );
            return
          }
          this.notification.toast(
            error?.result?.message ?? "Something went wrong",
            "ok",
            4000
          );
        },
      });
  }

  async saveUserDetail(result: any) {
    this.sharedData.setAttribute("userDetails", result);
    this.sharedData.setAttribute("accessToken", result.access_token);
    this.sharedData.setAttribute("refreshToken", result.refresh_token);

    this.store.dispatch(loadUserCurrentSubscriptionApi({}));
  }

  updateUserDetail() {
    this.dataService
      .get({ url: "v1/user", isLoader: false })
      .subscribe((response: any) => {
        if (response && response.result) {
          let previousUserDetail = this.getUserDetail(true);
          let { is_password_set, ...userDetails } = response.result;
          let updatedUserDetail = {
            ...previousUserDetail,
            user_details: userDetails,
          };
          this.sharedData.setAttribute("userDetails", updatedUserDetail);
          this.notifyService.notifyAboutChange();
        }
      });
  }

  verifyEmail(data: any) {
    return this.dataService.post({
      url: "v1/user/auth/email/verifybycode",
      data: data,
      isLoader: true,
    });
  }

  signInWithGoogle(idToken: string): void {
    let referel = "";
    this.route.queryParams.subscribe((param) => {
      if (param.referel) {
        referel = param.referel;
      }
    });
    const url = "v1/user/auth/login/google";

    this.loaderService.start();

    let data: any = { token: idToken };
    if (referel) {
      data.referral_key = referel;
    }

    this.dataService
        .post({
          url,
          data,
          isLoader: false,
        })
        .subscribe({
          next: async (response: any) => {
            await this.saveUserDetail(response.result);
            this.extensionService.sendLoginMessage(url, data);

            if(response.result?.is_company_poc === true){
              const companySlug = this.getUserCompanySlug();
              this.nav.navigateTo([`/${companySlug}/admin/users`]);
              this.loaderService.stop();
              return;
            }

            if (this.redirectUrl.includes("instancyssorequested=true")) {
              this.nav.navigateTo(["/flyboard"], { queryParams: { instancyssorequested: "true" } });
              this.loaderService.stop();
              return;
            }

            if (response.result.user_details?.onboarding == "pending" || !response.result.user_details?.onboarding) {
              if (this.redirectUrl.includes("activate_trial=true")) {
                this.stripeService.startSalesProTrial().subscribe({
                  next: (response: any) => {
                    this.nav.navigateTo([`/onboarding`]);
                    this.loaderService.stop();
                    return;
                  },
                  error: (error) => {
                    console.log(error)
                  }
                });
              }
              this.nav.navigateTo([`/onboarding`]);
            } else {
              if (this.redirectUrl.includes("activate_trial=true")) {
                this.nav.navigateTo(["/new-plan/SalesPro"], { queryParams: { activate_trial: "true" } });
                this.loaderService.stop();
                return;
              }
              this.nav.navigateTo([this.redirectUrl]);
            }

            this.loaderService.stop();
          },
          error: (error) => {
            this.loaderService.stop();
            if(error.status == 422) {
              if (error?.error?.code == "DEACTIVATED") {
                
                this.dialog.openDialogComponent(
                  SignInErrorComponent,
                  { 
                    admin_email: error?.error?.admin_email,
                  },
                  "500px"
                );
              }
            }
            this.notification.toast(error?.error?.error, "danger");
          }
        })
        
  }

  signInWithLinkedIn(): void {
    window.location.href = environment.API_URL + "v1/linkedin/redirect";
  }

  acceptCompanyInvitation(company_id: string, user_id: string) {
    return this.dataService.post({
      url: `v1/users/${user_id}/accept-company-invitation`,
      data: {company_id},
      isLoader: true
    }).pipe(
      map((response: any) => {
        this.store.dispatch(loadUserCurrentSubscriptionApi({ refetchFromApi: true }));
        let user = JSON.parse(localStorage.getItem("vengreso.userDetails"));
        let user_details = user.user_details;
        
        delete user_details.invited_to_company;
        delete user_details.invited_to_company_by_admin;

        user_details = {...user_details, ...response?.result}

        user.user_details = user_details;

        this.sharedData.setAttribute("userDetails", user);
        this.notifyService.notifyAboutChange({planObj: response?.result?.plan});
        
        return response;
      }),
      catchError((error: any) => {
        // This catchError handles errors that occurred before the map.
        console.error("An error occurred:", error);
        return throwError(() => error);
      })
    );
  }

  /**
   * 
   * @param {any} data {company_id?: string, company_slug?: string, company_name?:string, user_id?:string}
   */
  masqueradeAs(data: any) {
    const user = this.getUserDetail(true);
    let masquerade: any;

    if (!!user.masquerade){
      masquerade = {...user.masquerade, ...data}
    } else {
      masquerade = data
    }

    this.sharedData.setAttribute("userDetails", {...user, masquerade});
    this.notifyService.notifyAboutChange()

    return this.getUserDetail(true)
  }

  get onUserMasqueradeSession(): boolean {
    const user = this.getUserDetail(true);

    return !!user.masquerade && !!user.masquerade.user_id
  }

  get onCompanyMasqueradeSession(): boolean {
    const user = this.getUserDetail(true);

    return !!user.masquerade && !!user.masquerade.company_slug
  }

  endMasqueradeSession() {
    let user = this.getUserDetail(true);
    if (!!user.masquerade){
      delete user.masquerade
    }
    this.sharedData.setAttribute("userDetails", user);
    this.notifyService.notifyAboutChange()
  }
}
