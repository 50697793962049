import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "../services/dataservices/data.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-giphy-dialog",
  templateUrl: "./giphy-dialog.component.html",
  styleUrls: ["./giphy-dialog.component.scss"],
})
export class GiphyDialogComponent implements OnInit {
  searchQuery: string = '';
  images: string[];

  cols: number = 2;
  // cols_xs: number = 1;
  // cols_sm: number = 2;
  // cols_md: number = 3;
  // cols_lg: number = 4;
  // cols_xl: number = 6;
  rowHeight: number = 1;
  gutterSize: number = 1;

  constructor(
    public dialogRef: MatDialogRef<GiphyDialogComponent>,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let url = `https://api.giphy.com/v1/gifs/trending?offset=0&rating=g&api_key=${environment.GIPHY_API_KEY}`;

    this.dataService.getExternal({
      url,
      isLoader: false,
    }).subscribe((response: any) => {
      if (response) {
        let images = response.data;
        this.images = images
      }
    });
  }
  // THE SRC OF THIS COMPONENT
  // https://stackblitz.com/edit/masonry-grid-gallery-responsive?file=main.ts

  ngOnInit() { }

  cancel(): void {
    this.dialogRef.close();
  }

  selectGiphy(src): void {
    console.log(src);
    this.data.insertContent(`<div><img src="${src.images.fixed_height.url}" alt="${src.images.fixed_height.url}" ></div>`)
    this.dialogRef.close();
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchGiphy();
    }
  }

  searchGiphy(): void {
    if (!this.searchQuery) {
      return
    }
    // TODO: ADD API KEY
    let url = `https://api.giphy.com/v1/gifs/search?offset=0&rating=g&api_key=${environment.GIPHY_API_KEY}&q=${this.searchQuery}`;

    this.dataService.getExternal({
      url,
      isLoader: false,
    }).subscribe((response: any) => {
      if (response) {
        let images = response.data;
        this.images = images
      }
    });
  }
}
