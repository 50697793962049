import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class NotificationService {

    constructor(private snackBar: MatSnackBar) { }

    public toast(message: string, action: string = "OK", duration: number = 3000) {
        this.snackBar.open(message, action, { duration });
    }

    public toastWithConfig(message: string, action: string = "OK", config: any) {
        this.snackBar.open(message, action, {duration: 3000, ...config});
    }

    public toastWithoutAutoDismissal(message: string, action: string = "X") {
        this.snackBar.open(message, action);
    }
}
