import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import { SuperAdminApiService } from "../../services/super-admin-api.service";

@Component({
  selector: 'app-admin-group',
  templateUrl: './admin-group.component.html',
  styleUrls: ['./admin-group.component.scss']
})
export class AdminGroupComponent implements OnInit, OnDestroy {
  companyName: string;
  companySlug: string;
  dataSource: any;
  group: any;
  groupSlug: string;
  groupName: string;
  subgroups:any[] = [];
  subgroup: any;
  subGroupSlug: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[];
  subscriptions = new Subscription();
  totalUsersCount: number = 0;
  roles: any[];
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };
  allRoleFilterChecked: boolean = true;
  licenseTypes: any[] = [];
  allLicenseFilterChecked: boolean = true;
  statuses: any = [{label: "Active", checked: true},{label: "Invited", checked: true}];
  selectedSort: string = 'last_added';
  loading: boolean;

  constructor(
    public dialog: DialogService,
    private route: ActivatedRoute,
    private apiService : SuperAdminApiService
  ) {
    this.roles = ROLES.map(role => ({ name: role, checked : true }));
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        this.companySlug = params.get("companySlug");
        this.groupSlug = params.get("groupSlug");
        this.subGroupSlug = params.get("subGroupSlug");

        if(this.subGroupSlug === "allusers") {
          this.loadGroup(this.companySlug, this.groupSlug);
        } else {
          this.loadSubGroup(this.companySlug, this.groupSlug, this.subGroupSlug);
        }

      })
    );
  }

  ngAfterViewInit() {
    this.disableHeaderSorting();
  }

  loadGroup(companySlug:string, groupSlug: string){
    this.loading = true;

    this.displayedColumns = [
      "name",
      "subgroup",
      "licenseType",
      "status",
      "role"
    ];

    this.dataSource = undefined;
    this.dataSource = new MatTableDataSource();
    
    this.apiService.getCompanyGroupUsers(companySlug, groupSlug).subscribe({
      next: (response: any) => {
        if (response.result.success) {
            const data = response.result.data;
            this.companyName = data.company_name;
            this.groupName = data.group.name;
            this.totalUsersCount = data.group.members;
            this.group = data.group;

          if(this.group?.subgroups?.length > 0) {
            this.subgroups = this.group?.subgroups.map((subgroup: any) => ({
              id: subgroup.id,
              subgroupName: subgroup.name,
              slug: subgroup.slug,
              count: subgroup.members,
              checked: true
            }));
          }

          this.subgroups = this.subgroups.concat({
            id: -1,
            subgroupName: 'Not Assigned',
            checked: true,
          });

          this.dataSource = new MatTableDataSource(this.group.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.licenseTypes = [...new Map(this.group.users.map(user => [user.licenseType, {label: user.licenseType, checked: true}])).values()];
        }
      },
      error: (err) => console.log(err),
      complete: () => this.loading = false
    });
  }
  loadSubGroup(companySlug:string, groupSlug: string, subGroupSlug: string){
    this.loading = true;

    this.displayedColumns = [
      "name",
      "licenseType",
      "status",
      "role",
    ];
    
    this.dataSource = undefined;
    this.dataSource = new MatTableDataSource();

    
    this.apiService.getCompanyGroupUsers(companySlug, groupSlug).subscribe({
      next: (response: any) => {
        if(response.result.success){
          const data = response.result.data;

          this.companyName = data.company_name;
          this.groupName = data.group.name;
          this.totalUsersCount = data.group.members;
          this.groupSlug = groupSlug;

          if(this.group?.subgroups?.length > 0) {
            this.subgroups = data.group.subgroups.map((subgroup: any) => ({
              id: subgroup.id,
              subgroupName: subgroup.name,
              slug: subgroup.slug,
              count: subgroup.members,
              checked: true
            }));
          }
          
          const subgroup = data.group?.subgroups.find((record: any) => record.slug === subGroupSlug);
          this.subgroup = subgroup;
          
          this.dataSource = new MatTableDataSource(subgroup.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      error: (err) => console.log(err),
      complete: () => this.loading = false
    });
  }

  filterBySubGroup() {
    const filter = this.subgroups.filter((t:any) => t.checked).map((t:any) => t.subgroupName);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['subgroup']);
    }
    
    this.dataSource.filter = filter.length > 0 ? filter : this.subgroups.map((t:any) => t.subgroupName);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someLicenseFilterChecked() {
    return this.licenseTypes.filter((t:any) => t.checked).length > 0 && !this.allLicenseFilterChecked;
  }

  checkLicenseFilterAll(checked: boolean) {
    this.allLicenseFilterChecked = checked;
    this.licenseTypes.forEach((l:any) => (l.checked = checked));
    
    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['licenseType']);
    }
    this.dataSource.filter = this.licenseTypes.map((l:any) => l.label);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateLicenseFilterAllChecked() {
    this.allLicenseFilterChecked = this.licenseTypes.every((l:any) => l.checked);
    const filter = this.licenseTypes.filter((l:any) => l.checked).map((l:any) => l.label);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['licenseType']);
    }

    if (this.allLicenseFilterChecked || filter.length < 1) {
      this.dataSource.filter = this.licenseTypes.map((t:any) => t.label);
    } else {
      this.dataSource.filter = filter;
    }
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByStatus() {
    const filter = this.statuses.filter((t:any) => t.checked).map((t:any) => t.label);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['status']);
    }

    this.dataSource.filter = filter.length > 0 ? filter : this.statuses.map((t:any) => t.label);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someRoleFilterChecked() {
    return this.roles.filter((t:any) => t.checked).length > 0 && !this.allRoleFilterChecked;
  }

  checkRoleFilterAll(checked: boolean) {
    this.allRoleFilterChecked = checked;
    this.roles.forEach((t:any) => (t.checked = checked));

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['role']);
    }
    this.dataSource.filter = this.roles.map((l:any) => l.name);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateRoleFilterAllChecked() {
    this.allRoleFilterChecked = this.roles.every((t:any) => t.checked);
    const filter = this.roles.filter((t:any) => t.checked).map((t:any) => t.name);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['role']);
    }

    if (this.allRoleFilterChecked || filter.length < 1) {
      this.dataSource.filter = this.roles.map((t:any) => t.name);
    } else {
      this.dataSource.filter = filter;
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toSlug(text: string) {
    return text
        .toLowerCase()                   
        .trim()                          
        .replace(/[^a-z0-9\s-]/g, '')    
        .replace(/\s+/g, '-')            
        .replace(/-+/g, '-');
  }
  
  customFilterApplied(data: any[]) {
    return data.some((l: any) => l.checked === false)
  }

  sortBy(sortKey: string, direction: string = 'asc') {
    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === 'name' && direction === 'asc') {
      this.selectedSort = 'a_z';
    } else if (sortKey === 'name' && direction === 'desc') {
      this.selectedSort = 'z_a';
    } else if (sortKey === 'created_at' && direction === 'asc') {
      this.selectedSort = 'first_added';
    } else if (sortKey === 'created_at' && direction === 'desc') {
      this.selectedSort = 'last_added';
    }
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll('.mat-sort-header-container');
    matSortHeaderElements.forEach(element => {
      (element as HTMLElement).style.cursor = 'default';
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }


  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
