import {
  Component
} from "@angular/core";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { SignOutMessageService } from "./sign-out-message.service";


@Component({
  selector: "app-sign-out-message",
  templateUrl: "./sign-out-message.component.html",
  styleUrls: ["./sign-out-message.component.scss"]
})
export class SignOutMessageComponent {
  page = 0;
  selectedOption = 0;
  description = '';

  //These are internal hubspot values that help sync to hubspot. Don't change
  options: any = [
    'Interrupting my workflow',
    'Slows down my computer',
    'It\'s too complicated',
    'Security or privacy concerns',
    'I didn\'t work on specific websites',
    'I\'m on a shared computer',
    'Other'
  ];
  constructor(private signOutMessageService: SignOutMessageService, private authService: AuthService) {

  }
  doLogout() {
    if (this.page == 0) {
      this.page++
      return;
    }

    if (this.selectedOption == 0 && this.description.trim().length > 1) {
      this.selectedOption = 7;
    }

    this.authService.logout({
      description: this.description,
      selectedOption: this.selectedOption > 0 ? this.options[this.selectedOption - 1] : ''
    })

    this.hideModal()
  }
  hideModal() {
    this.signOutMessageService.hide();
  }
}
