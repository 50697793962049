/// <reference types="chrome"/>
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { find, forEach, remove } from "lodash";
import { Subscription } from "rxjs";
import * as XLSX from "xlsx";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { EventService } from "src/app/core/core-services/services/event.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ScrollService } from "src/app/core/core-services/services/scroll.service";
import { ShortcutService } from "src/app/core/core-services/services/shortcut.service";
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { ExtensionService } from "./../../core/core-services/services/extension.service";
import { ScriptService } from "./../../core/core-services/services/script.service";

import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";
import { ImportDialogComponent } from "src/app/core/core-services/import-dialog/import-dialog.component";
import { LoadingDialogComponent } from "src/app/core/core-services/loading-dialog/loading-dialog.component";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { ChangeShortcutCategoryComponent } from "../../vengreso/shortcuts/change-shortcut-category/change-shortcut-category.component";
import { CategoryDependencyComponent } from "../shortcuts/add-shortcut-category/category-dependency/category-dependency.component";
import { CategoryRenameComponent } from "../shortcuts/add-shortcut-category/category-rename/category-rename.component";
import { EditPlanComponent } from "./edit-plan/edit-plan.component";
import { PausedPlanComponent } from "./paused-plan/paused-plan.component";

import { ChartConfiguration, ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";
import {
  PlansConstants,
  getPlanId,
} from "src/app/core/core-services/constants/plans.constants";
import { MetaService } from "src/app/core/core-services/services/meta.service";
import {
  ChartFilterKey,
  DateType,
  StatisticsService,
} from "src/app/core/core-services/services/statistics.service";
import { PlanStatus, SubscriptionPlanType } from "src/app/core/models/stripe.models";
import { environment } from "src/environments/environment";
import { DowngradedComponent } from "./downgraded/downgraded.component";
import {
  CTA_COMPARE_PLAN_ID,
  CTA_DOWNGRADE_TO_FREEMIUM_ID,
  CTA_EDIT_PLAN_GROWTH_ID,
  CTA_EDIT_PLAN_GROWTH_URL,
  CTA_EDIT_PLAN_ID,
  CTA_EDIT_PLAN_URL,
  CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_ID,
  CTA_GROWTH_DOWNGRADE_TO_STARTER_ID,
  CTA_GROWTH_PAUSE_PLAN_ID,
  CTA_PAUSE_PLAN_ID,
  CTA_UPGRADE_PLAN_ID,
  CTA_UPGRADE_PLAN_URL,
  SUBSCRIPTION_EDIT_GROWTH,
  SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_FREEMIUM,
  SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_STARTER,
  SUBSCRIPTION_EDIT_GROWTH_PAUSED,
  SUBSCRIPTION_EDIT_STARTER,
  SUBSCRIPTION_EDIT_STARTER_DOWNGRADE_FREEMIUM,
  SUBSCRIPTION_EDIT_STARTER_PAUSED,
  SUBSCRIPTION_TAB,
  SUBSCRIPTION_TAB_PARAMS,
} from "./settings.config";
import { Store } from "@ngrx/store";
import { PlanState } from "src/app/core/store/plan/plan.reducer";
import { LocalStorageService, LocalStorage } from "ngx-webstorage";
import { ShortcutCategoryService, SHORTCUT_CATEGORIES } from "src/app/core/core-services/services/shortcut-category.service";
import { CONTENT_VIEW_LOADER } from "src/app/core";
import { NotifyService } from "src/app/core/menu/not.service";
import moment from "moment";
declare let hbspt: any;
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  form: FormGroup;
  list: { shortcut_timeout: number; blocked_domains: any[] };
  timeout: number;
  isFlycutsImported: boolean = false;
  records: any[] = [];
  jsonPlaceHolder: string;
  jsonPlaceHolderCopy: string;
  @LocalStorage(SHORTCUT_CATEGORIES) shortcutCategoryList: any[];
  flattedCategories: any[];
  activeTabIndex: number;
  version: number;
  subscriptions = new Subscription();
  displayedColumns: string[] = ["name", "shortcut_count", "action"];
  editorApiKey: string;
  editorConfig: any;
  panelOpenState = {};
  isInstalled = false;
  browser_type;
  subPlans = SubscriptionPlanType;
  currentPlan: string;
  currentPlan$ = this.store.select(state => state.data);
  renewalDays = -1;
  ends_at;
  Lifetime;
  planStatus = PlanStatus;
  currentPlanStatus: string = PlanStatus.Active;
  importRestrictionError = false;
  // ! TODO To transfer to select a plan component, then change this value to whatever plan is selected

  tabIndex: number = 0;
  dateFilterValue: number = 7;
  chartFilterKey: ChartFilterKey = "time_saved";

  wordsPerMinute;
  statistics: any;

  public lineChartData: ChartConfiguration["data"] = null;

  public lineChartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y: {
        position: "left",
        min: 0,
      },
      x: {
        min: 0,
      },
      // y1: {
      //   position: "right",
      //   grid: {
      //     color: "#FDFEFE",
      //   },
      //   ticks: {
      //     // color: "red",
      //   },
      // },
    },
    maintainAspectRatio: false,
  };

  public lineChartType: ChartType = "line";

  editWPM: boolean;
  panelOpen: boolean;

  generalPanelOpen: boolean;

  DynamicDateRanges: any;

  dateRanges = [
    // { text: "Past 24 hours", id: 1, differenceFromNow: 24, dateType: "hour" },
    { text: "Last 7 days", id: 2, differenceFromNow: 7, dateType: "day" },
    { text: "Last month", id: 3, differenceFromNow: 1, dateType: "month" },
    { text: "Last 3 months", id: 4, differenceFromNow: 3, dateType: "month" },
    { text: "Last 6 months", id: 5, differenceFromNow: 6, dateType: "month" },
    { text: "Last 9 months", id: 6, differenceFromNow: 9, dateType: "month" },
    { text: "Last 12 months", id: 7, differenceFromNow: 12, dateType: "month" },
  ];

  constructor(
    private fb: FormBuilder,
    public dialog: DialogService,
    private route: ActivatedRoute,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private eventService: EventService,
    public scrollService: ScrollService,
    private notification: NotificationService,
    private shortcutService: ShortcutService,
    private extensionService: ExtensionService,
    private stripeService: StripeService,
    private renderer: Renderer2,
    private scriptService: ScriptService,
    public statisticsService: StatisticsService,
    private metaService: MetaService,
    private store: Store<PlanState>,
    private storage: LocalStorageService,
    private shortcutCategoryService: ShortcutCategoryService,
    public notifyService: NotifyService,
  ) {
    this.browser_type = localStorage.getItem("browser_type");
    this.Lifetime = localStorage.getItem("planIdType");
    // Get from local storage first for front end optimization then get from the backend.
    let plan = getPlanId();

    if (plan == "Free") {
      this.currentPlan = "Freemium";
    } else {
      this.currentPlan = plan;
    }

    this.metaService.addMetaTag(
      'FlyMSG Settings Page',
      "You can manage your entire account here from the FlyMSG Settings page including, learn about your stats, import from other providers and much more.");
  }

  getCurrentPlan(): string {
    return this.currentPlan;
  }

  ngOnInit() {
    this.Lifetime = localStorage.getItem("planIdType");

    this.currentPlan$.subscribe(({data: planInfo}: any) => {
      if (planInfo) {
        if (planInfo.title !== "Freemium") {
          if (planInfo.identifier == "growth-yearly") {
            this.currentPlan = SubscriptionPlanType.YearlyGrowth;
          } else if (planInfo.identifier == "starter-yearly") {
            this.currentPlan = SubscriptionPlanType.YearlyStarter;
          } else if (planInfo.identifier == "growth" && this.Lifetime == "sales-pro-monthly") {
            this.currentPlan = SubscriptionPlanType.Growth;
          }  else if (planInfo.identifier == "growth-yearly" && this.Lifetime == "sales-pro-yearly") {
            this.currentPlan = SubscriptionPlanType.YearlyGrowth;
          }  else if (planInfo.identifier == "pro-plan-teams-smb") {
            this.currentPlan = SubscriptionPlanType.ProPlanTeamsSMB;
          } else if (planInfo.identifier == "pro-plan-teams-ent") {
            this.currentPlan = SubscriptionPlanType.ProPlanTeamsEnt;
          } else {
            this.currentPlan = SubscriptionPlanType[planInfo.title];
          }

          this.ends_at = planInfo?.ends_at?.split("T")[0] ?? planInfo?.ends_at;
          this.currentPlanStatus = planInfo?.plan_status;
          // TODO: figure out number of days until renewal using `planInfo.interval` and `planInfo.updated`
        } else {
          this.currentPlan = SubscriptionPlanType.Freemium;
        }
      } else {
        this.currentPlan = SubscriptionPlanType.Freemium;
      }
    });

    this.storage.observe(SHORTCUT_CATEGORIES)
    .subscribe((value) => {
      if(Array.isArray(value))
      this.shortcutCategoryList = value;
      this.flattedCategories = this.flatTheCategories(
          this.shortcutCategoryList
      );
      this.eventService.updateShortcutCategories(this.shortcutCategoryList);
    });

    this.loadData();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.notifyService.behaviourNotifier.subscribe((notified) => {
        if (notified && notified.plan) {
          this.Lifetime = localStorage.getItem("planIdType");
          this.currentPlan = notified.plan === SubscriptionPlanType.SalesPro ? SubscriptionPlanType.Growth : 
                             notified.plan === SubscriptionPlanType.YearlySalesPro ? SubscriptionPlanType.YearlyGrowth :
                             notified.plan;
          this.currentPlanStatus = notified.planStatus;

          if(notified.planStatus == PlanStatus.Trial) {
            this.ends_at = moment().add(14, 'days').format("YYYY-MM-DD");
          }
        }
        this.cdr.detectChanges();
      })
    );
  }

  private loadData(): void {
    // initialize
    this.form = this.fb.group({
      blockList: this.fb.array([]),
    });

    if (
      this.route.snapshot.data.settings &&
      this.route.snapshot.data.settings.result
    ) {
      this.list = this.route.snapshot.data.settings.result;
    }

    this.jsonPlaceHolder = this.jsonPlaceHolderCopy = JSON.stringify(
      [
        {
          title: "talk to you later",
          shortcut: "ttyl",
          html: "Talk to you later",
        },
      ],
      null,
      4
    );

    this.timeout = this.list.shortcut_timeout || 5000;

    if (this.list.blocked_domains && this.list.blocked_domains.length) {
      this.list.blocked_domains.forEach((domain) => {
        this.blockList.push(this.getDomainFormGroup(domain));
      });
    } else {
      this.blockList.push(this.getDomainFormGroup());
    }

    this.flattedCategories = this.flatTheCategories(
      this.shortcutCategoryList
    );
    this.eventService.updateShortcutCategories(this.shortcutCategoryList);

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).length > 0) {
          if (params.add === "true") {
            this.activeTabIndex = 3;
            this.shortcutCategoryRename({
              shortcutCategoryList: this.shortcutCategoryList,
            });
          } else if (params.tab === "manage") {
            this.activeTabIndex = 3;
          } else if (SUBSCRIPTION_TAB_PARAMS.includes(params.tab)) {
            this.activeTabIndex = 0;
            this.loadCtaScripts(params.tab);
            this.showDialog(params.tab);
          } else if (params.tab === "Profile") {
            this.activeTabIndex = 2;
          } else if (params.tab === "extension") {
            this.activeTabIndex = 4;
          } else if (params.tab === "import") {
            this.activeTabIndex = 5;
          } else if (params.tab === "statistics") {
            this.activeTabIndex = 1;
          }
        } else {
          this.activeTabIndex = 0;
          this.loadCtaScripts(params.tab);
          this.showDialog(params.tab);
        }

      })
    );

    this.subscriptions.add(
      this.eventService.getAddCategoryListener().subscribe((data) => {
        if (data) {
          this.activeTabIndex = 3;
          this.shortcutCategoryRename({
            shortcutCategoryList: this.shortcutCategoryList,
          });
        }
      })
    );

    this.checkExtension();

    this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
    this.editorConfig = this.shortcutService.getEditorConfig();
    this.editorConfig.forced_root_block = "";
    this.getStatistic();
    this.getSettings();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getSettings() {
    this.dataService.get({ url: "v1/user/user-setting" }).subscribe({
      next: (response: any) => {
        if (response?.result?.words_per_minute) {
          this.wordsPerMinute = response.result.words_per_minute;
        }
      },
      error: () => { },
    });
  }

  get planName() {
    let planName = "";

    if (this.currentPlan == "starter-yearly") {
      planName = "Starter (Yearly Plan)";
    }
    if (this.currentPlan == "starter" || this.currentPlan == "Starter") {
      planName = "Starter (Monthly Plan)";
    }
    if (this.currentPlan == "growth-yearly") {
      planName = "Growth (Yearly Plan)";
    }
    if (this.currentPlan == "growth" || this.currentPlan == "Growth") {
      planName = "Growth (Monthly Plan)";
    }
    if (this.currentPlan == "growth-yearly" && this.Lifetime == "Lifetime") {
      planName = "FlyMSG Growth Lifetime Access Plan (AppSumo)";
    }
    if (this.currentPlan == "growth-yearly" && this.Lifetime == "dealfuel-growth-lifetime") {
      planName = "DealFuel Lifetime Growth Plan";
    }
    if (
      this.currentPlan == "growth-yearly" &&
      this.Lifetime == "sales-pro-yearly"
    ) {
      planName = "FlyMSG.io Sales Pro - Yearly Plan";
    }
    if (this.currentPlan == "Growth" && this.Lifetime == "sales-pro-monthly") {
      planName = "FlyMSG.io Sales Pro - Monthly Plan";
    }

    if (this.currentPlan == "Pro Plan Teams - SMB") {
      planName = this.currentPlan;
    }

    if (this.currentPlan == "Pro Plan Teams - ENT") {
      planName = this.currentPlan;
    }

    if (!planName) { planName = 'Freemium' }

    return planName;
  }

  // Start Subscription tab
  editPaymentDetails(): void {
    // TODO: Change this to go to an edit details page (to perform same steps as payment page minus the final purchase subscription command)
    this._router.navigate(["payment-method-details"]);
  }

  pausePlan(): void {
    // if (
    //   confirm(
    //     "Are you sure you want to pause your current " +
    //       this.currentPlan.toLowerCase() +
    //       " plan?"
    //   )
    // ) {
    this.stripeService.pausePlan();
    // }
  }

  resumePlan(): void {
    // if (confirm("Are you sure you want to resume your previous plan?")) {
    this.stripeService.resumePlan();
    // }
  }

  // upgradePlan(plan: SubscriptionPlanType): void {
  //   // this._router.navigate(["new-plan", plan]);
  //   this._router.navigate(["plans", plan]);
  // }
  upgradePlan(): void {
    window.location.href = CTA_UPGRADE_PLAN_URL;
  }

  editPlan(): void {
    window.location.href = this.isStarterPlan()
      ? CTA_EDIT_PLAN_URL
      : this.isGrowthPlan()
        ? CTA_EDIT_PLAN_GROWTH_URL
        : "";
  }

  viewBillingHistory(): void {
    // TODO: create billing history page
    this._router.navigate(["billing-history"]);
  }

  cancelAccount(): void {
    // TODO: implement cancel subscription account, for now, pausing the plan will work the same
    // if (
    //   confirm(
    //     "Are you sure you want to cancel your current " +
    //       this.currentPlan.toLowerCase() +
    //       " plan?"
    //   )
    // ) {
    return null;
    this.stripeService.pausePlan();
    // }
  }
  // End Subscription Tab

  getDomainFormGroup(domain = null) {
    return this.fb.group({
      url: [domain, [RxwebValidators.url(), RxwebValidators.unique()]],
    });
  }

  flatTheCategories(categories: any) {
    let flatedCategories: [] = [];
    categories?.forEach((group: any) => {
      flatedCategories.push(group as never);
      if (group.sub_category_lv1) {
        for (const sub_category_lv1 of group.sub_category_lv1) {
          sub_category_lv1.name1 = group.name + " /";
          flatedCategories.push(sub_category_lv1 as never);
          if (sub_category_lv1.sub_category_lv2) {
            for (const sub_category_lv2 of sub_category_lv1.sub_category_lv2) {
              sub_category_lv2.name1 = group.name + " /";
              sub_category_lv2.name2 = sub_category_lv1.name + " /";
              flatedCategories.push(sub_category_lv2 as never);
            }
          }
        }
      }
    });
    return flatedCategories;
  }

  resetJSON() {
    this.jsonPlaceHolder = this.jsonPlaceHolderCopy;
  }

  validateJSON() {
    let shortcuts: any;
    try {
      this.jsonPlaceHolder = JSON.stringify(this.records, null, 4);
      shortcuts = JSON.parse(this.jsonPlaceHolder).filter(e => e.checked);
    } catch (exception) {
      console.error(exception);
      this.notification.toast("Invalid JSON format!");
      return;
    }

    if (!Array.isArray(shortcuts)) {
      this.notification.toast("JSON should be in array format!");
      return;
    }

    if (!shortcuts.length) {
      this.notification.toast("Please select at least one FlyCut!");
      return;
    }

    const data = {};
    const duplicates = [];
    for (const index in shortcuts) {
      if (shortcuts[index] && typeof shortcuts[index] === "object") {
        const shortcut = shortcuts[index];
        if ((shortcut.shortcut && shortcut.shortcut !== '') &&
          (shortcut.title && shortcut.title !== '') &&
          (shortcut.html && shortcut.html !== '')) {
          if (!data[shortcut.shortcut]) {
            data[shortcut.shortcut] = true;
            shortcut.first_line = /[^\n]*/.exec(shortcut.html)[0].trim();
          } else {
            duplicates.push(shortcut.shortcut);
          }
        } else {
          if (!shortcut.shortcut || shortcut.shortcut === '')
            this.notification.toast("FlyCut is missing required shortcut!");
          else if (!shortcut.title || shortcut.title === '')
            this.notification.toast("FlyCut " + shortcut.shortcut + " is missing required title!");
          else if (!shortcut.html || shortcut.html === '')
            this.notification.toast("FlyCut " + shortcut.shortcut + " is missing required message!");
          return;
        }
      } else {
        this.notification.toast("FlyCut is not in object format!");
        return;
      }
    }

    if (duplicates.length) {
      this.notification.toast("Duplicate FlyCuts detected!");
      return;
    } else {
      return shortcuts;
    }
  }

  importShortcuts() {
    //case restriction error
    let planId = getPlanId();

    const selectedShortcutsHasErrors = find(this.records, function (o) {
      return o.checked && o.error && o.error != "";
    });
    if (selectedShortcutsHasErrors) {
      this.dialog.openDialogComponent(
        WarningDialogComponent,
        { self: this, type: "import", showTheStarter: planId === "Free" },
        "800px"
      );
      return;
    }

    let data = this.records;
    data = data.filter((item) => item.checked);
    forEach(data, (shortcut) => {
      delete shortcut.checked;
      shortcut.uploads = [];
      shortcut.text = this.shortcutService.getInlineStyledHTML(shortcut.html.toString());
    });

    this.dataService
      .post({ url: "v1/user/import/shortcut", data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.dialog.openDialogComponent(
            ImportDialogComponent,
            { self: this },
            "400px"
          );
          this.extensionService.sendUpdateMessage();
        } else if (response && response.error) {
          if (response.error_list) {
            console.log(response.error_list);
          } else {
            console.log(response.error);
          }
        }
        this.cancelImporting();
      });
  }

  importJSON() {
    const jsonData = this.validateJSON();
    if (jsonData) {
      this.importShortcuts();
      // this.dialog.openDialogComponent(ImportShortcutsComponent, { shortcuts: jsonData, self: this }, "500px");
    }
  }

  get blockList() {
    return this.form.get("blockList") as FormArray;
  }
  getHtml(shortcut) {
    if (shortcut?.html == null) return
    return shortcut?.html.toString().split('\n')[0]
  }
  addMoreBlockList() {
    this.blockList.push(this.getDomainFormGroup());
  }

  deleteFromBlockList(index: number) {
    if (this.blockList.controls.length === 1) {
      this.blockList.controls[index].setValue({ url: "" });
    } else {
      this.blockList.removeAt(index);
    }
  }

  saveTimeout(isSetDefault = false) {
    if (isSetDefault) {
      this.timeout = 5000;
    }
    const data = {
      shortcut_timeout: this.timeout,
    };

    // let planId = JSON.parse(localStorage.getItem("vengreso.userDetails"))
    //   ?.subscription_details?.status_plan?.plan;
    // planId = planId ? planId : "Free";
    // if (planId == "Free") {
    //   this.dialog.openDialogComponent(
    //     WarningDialogComponent,
    //     { self: this },
    //     "800px"
    //   );
    //   return;
    // }
    this.saveSetting(data, true, isSetDefault);
  }

  setAutoDefaultTimeout(currentTimeout) {
    if (currentTimeout == 750) {
      this.saveTimeout(true);
    }
  }

  saveBlockedDomain() {
    const domains: string[] = [];
    forEach(this.blockList.controls, (domain) => {
      if (domain.value.url) {
        domains.push(domain.value.url);
      }
    });

    this.saveSetting({
      blocked_domains: domains,
    });
  }

  saveSetting(
    data: { shortcut_timeout?: number; blocked_domains?: any[] },
    isTimeout = false,
    isSetDefaultTimeout = false
  ) {
    this.dataService
      .put({ url: "v1/user/setting", data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.extensionService.sendUpdateMessage();
          // tslint:disable-next-line:max-line-length
          this.notification.toast(
            isSetDefaultTimeout
              ? "Timeout set to default"
              : isTimeout
                ? "FlyCut timeout updated successfully!"
                : "Blocked domains updated successfully!"
          );
        }
      });
  }

  // Manage categories
  saveCategory(data: any) {
    this.dataService
      .post({ url: "v1/user/shortcut-category", data, loaderName: CONTENT_VIEW_LOADER })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.getShortcutCategoryList(true);
          this.extensionService.sendUpdateMessage();
          this.notification.toast(
            `${data.category_id ? "Sub-Category" : "Category"
            } added successfully!`
          );
        }
      });
  }

  deleteShortcutCategory(data: any) {
    let category_type =
      data.category_id && typeof data.category_id === "string"
        ? "sub_categories_lv1"
        : data.sub_categories_lv1 && typeof data.sub_categories_lv1 === "string"
          ? "sub_categories_lv2"
          : "category";
    this.dataService
      .get({
        url:
          "v1/user/shortcut-category/dependency/" +
          data.id +
          "/remove?category_type=" +
          category_type,
      })
      .subscribe((response: any) => {
        if (response && response.result) {
          if (response && !response.result.has_dependency) {
            data.dependency = { type: "remove" };
            this.extensionService.sendUpdateMessage();
            this.dialog.openDialogComponent(
              ConfirmDialogComponent,
              { self: this, data },
              "330px"
            );
          } else {
            this.dialog.openDialogComponent(CategoryDependencyComponent, {
              self: this,
              data,
            });
          }
        }
      });
  }

  tooltip(element: any): string {
    return `${element.name1 || element.name2 ? "Sub-" : ""}Category`;
  }

  shortcutCategoryRename(data: any) {
    const dialogRef = this.dialog.openDialogComponent(CategoryRenameComponent, {
      self: this,
      data,
    });
  }

  categoryUpdate(data: any) {
    let param: any;
    
    param = this.dataService.toSave(data, ["name"]);

    if (data.destination_category_id) {
      if (data.destination_category_type == 'sub_categories_lv1') {
        param.sub_categories_lv1 = data.destination_category_id
      } else if (data.destination_category_type == 'category') {
        param.category_id = data.destination_category_id
      }
    }

    if (data.hasOwnProperty("sub_category_lv1")) {
      //main-category
      param.source_category_type = 'category'
    } else if (data.hasOwnProperty("sub_category_lv2")) {
      //lv1
      param.source_category_type = 'sub_categories_lv1'
    } else {
      //lv2
      param.source_category_type = 'sub_categories_lv2'
    }

    this.dataService
      .put({ url: `v1/user/shortcut-category/${data.id}`, data: param, loaderName: CONTENT_VIEW_LOADER })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.getShortcutCategoryList(true);
          this.extensionService.sendUpdateMessage();
          this.notification.toast("Category updated successfully!");
        }
      });
  }

  delete(data: any) {
    if (data.hasOwnProperty("sub_category_lv1")) {
      //main-category
      data.dependency.category_type = "category";
    } else if (data.hasOwnProperty("sub_category_lv2")) {
      //lv1
      data.dependency.category_type = "sub_categories_lv1";
    } else {
      //lv2
      data.dependency.category_type = "sub_categories_lv2";
    }
    
    this.dataService
      .delete({
        url: "v1/user/shortcut-category/" + data.id,
        data: data.dependency,
        loaderName: CONTENT_VIEW_LOADER
      })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.getShortcutCategoryList(true);
          this.extensionService.sendUpdateMessage();
          this.notification.toast("Category deleted successfully!");
        }
      });
  }

  getShortcutCategoryList(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({reloadFromApi});
  }

  async checkExtensionVersion() {
    const version = await this.extensionService.getVersion()

    this.version = version;
  }

  async checkExtension() {
    this.isInstalled = await this.extensionService.isExtensionInstalled()

    if (this.isInstalled) {
      this.checkExtensionVersion();
    }

    this.cdr.detectChanges();
  }

  uploadListener($event: any): void {
    //show loading dialog
    const loadingDialog = this.dialog.openDialogComponent(
      LoadingDialogComponent,
      {},
      "582px"
    );
    let files = $event.srcElement.files;
    let input = $event.target;
    let reader = new FileReader();
    //case text expander
    if (this.isValidCSVFile(files[0])) {
      reader.readAsArrayBuffer(input.files[0]);
      reader.onload = (e) => {
        const ab: any = e.target.result;
        const wb: any = XLSX.read(ab);
        const wsname: string = wb.SheetNames[0];
        const ws: any = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        this.records = this.convertFromTextExpanderToVengresoFormat(data);
        this.jsonPlaceHolder = JSON.stringify(this.records, null, 4);
        this.isFlycutsImported = !!this.records.length;
        //hide loading dialog
        loadingDialog.close();
        try {
          this.checkImportedShortcutsRestriction();
        } catch (err) {
          console.log(err)
        }
      };
    }
    //case blaze
    if (this.isValidJSONFile(files[0])) {
      reader.readAsText(input.files[0], "UTF-8");
      reader.onload = () => {
        let snippets = [];
        const folders = JSON.parse(reader.result as string)?.folders;
        folders.forEach((folder) => {
          snippets.push(...folder.snippets);
        });
        this.records = this.convertFromBlazeToVengresoFormat(snippets);
        this.jsonPlaceHolder = JSON.stringify(this.records, null, 4);
        this.isFlycutsImported = !!this.records.length;
        //hide loading dialog
        loadingDialog.close();

        this.checkImportedShortcutsRestriction();
      };
    }
    //catch the errors
    reader.onerror = function () {
      console.log("error is occured while reading file!");
    };
  }

  checkImportedShortcutsRestriction() {
    let planId = getPlanId();

    const calcLengths = (planLength: number) => {
      this.records = this.records.map((item) => {
        const textLength = item.text?.length || item.html?.length;

        const hyperLinkPattern = /<a\s+(?:[^>]*?\s+)?href=["'](https?:\/\/\S+)["'][^>]*>.*?<\/a>/ig;

        const parser = new DOMParser()
        const doc = parser.parseFromString(item?.text || item?.html, 'text/html')

        const textWithoutImageLength = doc.body.textContent?.length

        if (textWithoutImageLength > planLength) {
          this.importRestrictionError = true;
          item.error = `Exceeds ${planLength} character limit. Reduce characters or upgrade plan.`;
        } else if (hyperLinkPattern.test(item?.html) && (planId.toLowerCase() == "free" || planId.toLowerCase() == "starter")) {
          // check if there are hyperlinks for freemium and starter
          this.importRestrictionError = true;
          item.error = `Contains hyperlink. Remove hyperlink or upgrade plan.`;
        } else if (item.html.includes('font-family') && (planId.toLowerCase() == "free")) {
          item.error = `You can only use Arial, Calibri or Sans Serif. Replace fonts or upgrade plan.`;
        } else {
          item.error = "";
        }
        return item;
      });
    };
    if (planId == "Free") {
      if (this.shortcutCategoryList.length >= 2) {
        //TODO: add category restriction
      }
      calcLengths(PlansConstants.FREE.NUM_OF_CHAR);
      return;
    }
    if (planId == "Starter") {
      if (this.shortcutCategoryList.length >= 7) {
        //TODO: add category restriction
      }
      calcLengths(PlansConstants.STARTER.NUM_OF_CHAR);
      return;
    }
  }

  convertFromTextExpanderToVengresoFormat(data: any) {
    let category: any = find(this.shortcutCategoryList, {
      name: "Imported FlyCuts",
    });
    if (!category)
      category = find(this.shortcutCategoryList, { name: "Default Category" });

    let result = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      result.push({
        checked: true,
        title: row[2] ?? '',
        shortcut: row[0] ?? '',
        html: row[1].toString() ?? '',
        category_id: category?.id
          ? category.id
          : this.shortcutCategoryList[0].id,
        category: category?.name
          ? category.name
          : this.shortcutCategoryList[0].name,
      });
    }
    return result;
  }

  convertFromBlazeToVengresoFormat(data: any) {
    let category: any = find(this.shortcutCategoryList, {
      name: "Imported FlyCuts",
    });
    if (!category)
      category = find(this.shortcutCategoryList, { name: "Default Category" });

    let result = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      result.push({
        checked: true,
        title: item.namerow[2] ?? '',
        shortcut: item.shortcutrow[2] ?? '',
        html: item.htmlrow[2].toString() ?? '',
        text: item.textrow[2].toString() ?? '',
        category_id: category?.id
          ? category.id
          : this.shortcutCategoryList[0].id,
        category: category?.name
          ? category.name
          : this.shortcutCategoryList[0].name,
      });
    }
    return result;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  isValidJSONFile(file: any) {
    return file.name.endsWith(".json");
  }

  cancelImporting() {
    this.isFlycutsImported = false;
    this.records = [];
  }

  allImportedChecked = (): boolean => (this.records ?? []).filter(e => !e.checked).length == 0;

  selectAll(event) {
    let check = this.allImportedChecked();
    this.records.forEach((item) => {
      item.checked = !check;
    });
    event.stopPropagation();
    event.preventDefault();
  }

  shortcutUpdate(data: any, shortcut: any, isCategoryUpdate = false) {
    this.records = this.records.map((item) => {
      if (item.title == shortcut.title && item.shortcut == shortcut.shortcut) {
        item.category_id = shortcut.category_id;
      }
      return item;
    });
  }

  changeCategory(data: any) {
    this.dialog.openDialogComponent(
      ChangeShortcutCategoryComponent,
      { self: this, data },
      "330px"
    );
  }

  deleteShortcut(data: any, index: number) {
    remove(this.records, function (n, i) {
      return index == i;
    });
    // this.dialog.openDialogComponent(
    //   ConfirmDialogComponent,
    //   { self: this, data },
    //   "330px"
    // );
  }

  handleNav(event: any) {
    let q = { queryParams: {} };
    switch (event.index) {
      case 0:
        q.queryParams["tab"] = "subscription";
        break;
      case 1:
        q.queryParams["tab"] = "statistics";
        break;
      case 2:
        q.queryParams["tab"] = "Profile";
        break;
      case 3:
        q.queryParams["tab"] = "manage";
        break;
      case 4:
        q.queryParams["tab"] = "extension";
        break;
      case 5:
        q.queryParams["tab"] = "import";
        break;
      case 6:
        q.queryParams["tab"] = "teams";
        break;

      default:
        break;
    }
    this._router.navigate(["/settings"], q);
  }

  isFreemiumPlan = () => {
    return this.currentPlan === SubscriptionPlanType.Freemium;
  };

  isStarterPlan = () => {
    return (
      this.currentPlan === SubscriptionPlanType.Starter ||
      this.currentPlan === SubscriptionPlanType.YearlyStarter
    );
  };

  isGrowthPlan = () => {
    return (
      this.currentPlan === SubscriptionPlanType.Growth ||
      this.currentPlan === SubscriptionPlanType.YearlyGrowth ||
      this.Lifetime === SubscriptionPlanType.MonthlySalesPro ||
      this.Lifetime === SubscriptionPlanType.YearlySalesPro ||
      this.currentPlan === SubscriptionPlanType.ProPlanTeamsSMB ||
      this.currentPlan === SubscriptionPlanType.ProPlanTeamsEnt
    );
  };

  isNotFreemiumPlan = () => {
    return (
      this.currentPlan === SubscriptionPlanType.Growth ||
      this.currentPlan === SubscriptionPlanType.Starter ||
      this.currentPlan === SubscriptionPlanType.YearlyStarter ||
      this.currentPlan === SubscriptionPlanType.YearlyGrowth
    );
  };

  loadCtaScripts(tabParam: string) {
    console.log("Embedding CTA.....");
    const scriptElement = this.scriptService.loadJsScript(
      this.renderer,
      "https://js.hscta.net/cta/current.js"
    );

    scriptElement.onload = () => {
      console.log("CTA Script loaded");

      const config = { useNewLoader: "true", region: "na1" };

      switch (tabParam) {
        case SUBSCRIPTION_TAB:
          hbspt.cta.load(11964, CTA_UPGRADE_PLAN_ID, config);
          break;
        case SUBSCRIPTION_EDIT_STARTER:
          hbspt.cta.load(11964, CTA_EDIT_PLAN_ID, config);
          hbspt.cta.load(11964, CTA_COMPARE_PLAN_ID, config);
          break;
        case SUBSCRIPTION_EDIT_STARTER_PAUSED:
          hbspt.cta.load(11964, CTA_PAUSE_PLAN_ID, config);
          break;
        case SUBSCRIPTION_EDIT_STARTER_DOWNGRADE_FREEMIUM:
          hbspt.cta.load(11964, CTA_DOWNGRADE_TO_FREEMIUM_ID, config);
          break;
        case SUBSCRIPTION_EDIT_GROWTH:
          hbspt.cta.load(11964, CTA_EDIT_PLAN_GROWTH_ID, config);
          break;
        case SUBSCRIPTION_EDIT_GROWTH_PAUSED:
          hbspt.cta.load(11964, CTA_GROWTH_PAUSE_PLAN_ID, config);
        case SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_FREEMIUM:
          hbspt.cta.load(11964, CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_ID, config);
          break;
        case SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_STARTER:
          hbspt.cta.load(11964, CTA_GROWTH_DOWNGRADE_TO_STARTER_ID, config);
          break;
        default:
          break;
      }
    };

    scriptElement.onerror = () => {
      console.log("Could not load the CTA Script!");
    };
  }

  showDialog(tabParam: string): void {
    switch (tabParam) {
      case SUBSCRIPTION_EDIT_STARTER:
      case SUBSCRIPTION_EDIT_GROWTH:
        this.dialog.openDialogComponent(EditPlanComponent, {}, "600px");
        break;
      case SUBSCRIPTION_EDIT_STARTER_PAUSED:
      case SUBSCRIPTION_EDIT_GROWTH_PAUSED:
        this.dialog.openDialogComponent(PausedPlanComponent, {}, "500px");
        break;
      case SUBSCRIPTION_EDIT_STARTER_DOWNGRADE_FREEMIUM:
      case SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_FREEMIUM:
        this.dialog.openDialogComponent(
          DowngradedComponent,
          {
            planDowngradedTo: SubscriptionPlanType.Freemium,
          },
          "500px"
        );
        break;
      case SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_STARTER:
        this.dialog.openDialogComponent(
          DowngradedComponent,
          {
            planDowngradedTo: SubscriptionPlanType.Starter,
          },
          "500px"
        );
        break;

      default:
        break;
    }
  }

  changeTab(index: number, chartFilter: ChartFilterKey) {
    this.tabIndex = index;
    this.lineChartData = null;
    this.lineChartData = this.statisticsService.prepareChartData(
      this.statistics.chart_data,
      chartFilter
    );
    this.chart?.update();
  }

  getStatistic(
    differenceFromNow: number = null,
    dateType: DateType = "month",
    chartFilter: ChartFilterKey = "time_saved"
  ) {
    this.statisticsService
      .getStatistic(differenceFromNow, dateType)
      .subscribe((response: any) => {
        if (response && response.result) {
          this.statistics = response.result;

          localStorage.setItem(
            "eligible_months",
            this.statistics?.eligible_months
          );

          let newrange = [];
          let daysTrue = false;
          for (let range of this.dateRanges) {
            if (range.dateType == "day" && this.statistics?.created_at) {
              let c = new Date(this.statistics?.created_at);
              if (
                c.getMonth() == 0 &&
                c.getFullYear() == 2023 &&
                c.getDate() >= 25
              ) {
                daysTrue = true;
                newrange.push(range);
              }
            }
            if (
              daysTrue == false &&
              range.dateType == "month" &&
              range.differenceFromNow <= this.statistics?.eligible_months
            ) {
              newrange.push(range);
            }
          }
          this.DynamicDateRanges = newrange;
          if (this.DynamicDateRanges[this.DynamicDateRanges?.length - 1])
            this.dateFilterValue = this.DynamicDateRanges[this.DynamicDateRanges?.length - 1]["id"];

          this.lineChartData = this.statisticsService.prepareChartData(
            this.statistics.chart_data,
            chartFilter
          );
          this.chart?.update();
        }
      });
  }

  filterStatistics(dateRangeId: number): void {
    const { differenceFromNow, dateType } =
      this.statisticsService.filterStatistics(dateRangeId);

    this.getStatistic(differenceFromNow, dateType, this.chartFilterKey);
  }

  editWordPerMinute(): void {
    this.editWPM = true;
  }

  chartHovered(event) { }

  chartClicked(event) { }

  saveWPM() {
    if (!this.wordsPerMinute || this.wordsPerMinute < 1) {
      this.notification.toast("Word per minute must be a valid number greater than 0");
      return;
    }

    this.dataService
      .put({
        url: "v1/user/setting",
        data: { words_per_minute: this.wordsPerMinute },
      })
      .subscribe({
        next: () => {
          this.getStatistic();
          this.editWPM = false;
          this.notification.toast("Word per minute updated");
        },
        error: () => { },
      });
  }

  handleChange(type: string) {
    switch (type) {
      case "duration":
        this.panelOpen = !this.panelOpen;
        break;
      case "general":
        this.generalPanelOpen = !this.generalPanelOpen;
        break;
    }
  }

  chartFilter(chartFilter: ChartFilterKey): void {
    this.lineChartData = null;
    this.lineChartData = this.statisticsService.prepareChartData(
      this.statistics.chart_data,
      chartFilter
    );
    this.chart?.update();
  }

  numberWithCommas(x: string | number) {
    if (!x) {
      return 0;
    }

    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
}
