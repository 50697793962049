import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";

import { BreakpointObserver } from "@angular/cdk/layout";
import { SignOutMessageService } from "src/app/components/sign-out-message/sign-out-message.service";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { WelcomeComponent } from "src/app/dashboard/welcome/welcome.component";
import {
  FLYSHARE_ALL_ROUTE,
  FLYSHARE_OTHERS_ROUTE,
  FLYSHARE_SHARED_ROUTE,
} from "src/app/vengreso/share/flyshare/flyshare.config";
import { environment } from "src/environments/environment";
import { AuthService } from "../core-services/services/auth.service";
import { DialogService } from "../core-services/services/dialog.service";
import { EventService } from "../core-services/services/event.service";
import { SharedDataService } from "../core-services/services/shared-data.service";
import { MenuService } from "../menu/menu.service";
import { AdminLayoutMenuService } from "./admin-layout-menu.service";
import { NotifyService } from "../menu/not.service";

const SMALL_WIDTH_BREAKPOINT = 960;
export const CONTENT_VIEW_LOADER = "content_view_loader";

@Component({
  selector: "app-layout",
  template: `
    <app-layout-inner
      [isScreenSmall]="isScreenSmall | async"
    ></app-layout-inner>
  `,
  styleUrls: ["./admin-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class AdminLayoutComponent {
  isScreenSmall: Observable<boolean>;

  constructor(
    public breakpoints: BreakpointObserver,
    private menuService: MenuService
  ) {
    this.isScreenSmall = breakpoints
      .observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(map((breakpoint) => breakpoint.matches));
  }
}

@Component({
  selector: "app-layout-inner",
  templateUrl: "./admin-layout.component.html",
})
export class LayoutComponent implements OnInit, OnDestroy {
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === "b") {
      // Handle the Ctrl + B key combination
      this.sidemenu.toggle();
    }
  }
  private subscriptions: Subscription[] = [];
  @Input() isScreenSmall: boolean;
  planId: string;
  openMenu(value) {
    this.menuService.openMenu.emit(value);
  }

  url: string;
  sidePanelOpened: any;
  options = {
    collapsed: false,
    boxed: false,
    dark: false,
    dir: "ltr",
  };
  user: any;
  menuItems = [];
  flyLearningShow = false;
  showSignOutModal: boolean = false;

  @ViewChild("sidemenu", { static: true }) sidemenu;
  SSO_URL = "";

  flyPlatesSubMenu: any[] = [];
  flyCutsSubMenu: any[] = [];
  flyShareSubMenu: any[] = [];

  showFloatingButtons: boolean = false;
  currentRoute: string;

  userPlan: string;
  showAdminSection: boolean;

  browser_type;

  presentExtensionPrompt: boolean = false;

  closeSideNavAfterNavigationValue: boolean = false;

  notifierSubscription: Subscription =
    this.notifyService.subjectNotifier.subscribe((notified) => {
      // Check if notification contains message to show
      // FlyLearning icon immediately and remove premium button
      if (notified && notified.plan) {
        this.userPlan = notified.plan.toLowerCase();
        if (notified.plan.includes("pro") || notified.plan.includes("Pro")) {
          this.flyLearningShow = true;
        }
      }

      if (notified && notified.planObj) {
        this.userPlan = notified.planObj?.identifier;

        if (notified.planObj?.identifier?.includes("pro")) {
          this.flyLearningShow = true;
        }
      }
    });

  contentViewLoader = CONTENT_VIEW_LOADER;

  constructor(
    private router: Router,
    private authService: AuthService,
    private adminLayoutMenuService: AdminLayoutMenuService,
    private menuService: MenuService,
    private eventService: EventService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private dialog: DialogService,
    private sharedData: SharedDataService,
    public signOutMessageService: SignOutMessageService,
    public notifyService: NotifyService,
    private extensionService: ExtensionService
  ) {
    this.subscriptions.push(this.notifierSubscription);

    this.subscriptions.push(
      this.menuService.closeSideNavAfterNavigation$.subscribe((data) => {
        this.closeSideNavAfterNavigationValue = data;
      })
    );

    this.subscriptions.push(
      signOutMessageService.showSignOutMessage.asObservable().subscribe((e) => {
        this.showSignOutModal = e;
      })
    );

    this.user = this.authService.getUserDetail();
    this.showAdminSection = this.authService.showAdminSection();
    this.planId = getPlanId();
    const menu = this.menuService.getAll();
    this.menuItems = menu;

    this.userPlan = localStorage.getItem("planId")?.toLowerCase();

    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
          // this.sidemenu.close();
          // if(this.closeSideNavAfterNavigationValue){
          //   this.sidemenu.close();
          // }
        }
      })
    );
  }

  ngOnInit(): void {
    this.browser_type = localStorage.getItem("browser_type");
    this.setFlylearning();
    this.url = this.router.url;

    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          document.querySelector(
            ".app-inner > .mat-drawer-content > div"
          ).scrollTop = 0;
          this.url = event.url;
          //this.runOnRouteChange();
        })
    );

    this.getFlyPlatesSubMenu();
    this.getFlyCutsSubMenu();
    this.checkIfUserHasExtension();
    this.getShowExtensionPromptValue();
  }

  setFlylearning() {
    let planIdType =
      localStorage.getItem("planIdType") ?? localStorage.getItem("planId");

    this.flyLearningShow =
      !!planIdType &&
      [
        "sales-pro-yearly",
        "sales-pro-monthly",
        "pro-plan-teams-smb",
        "pro-plan-teams-ent",
      ].includes(planIdType);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu.close();
    }
  }

  goToDashboard() {
    this.router.navigate(["/flyboard"]);
  }

  goToProfile() {
    this.router.navigate(["/settings"], { queryParams: { tab: "Profile" } });
  }

  updateSidePanelState(state) {
    localStorage.setItem("sidePanelOpened", state);
  }

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      // any screen that needs fullscreen
      this.url === "/taskboard"
    ) {
      return false;
    } else {
      return this.isScreenSmall;
    }
  }
  toogleMenu(event) {
    switch (event) {
      case "profile":
        this.goToProfile();
        break;
      case "dashboard":
        this.goToDashboard();
        break;
      case "learning":
        this.openlearning();
        break;
      case "flyboard":
      case "shortcuts":
      case "flyplates":
      case "flyshare":
        this.sidemenu.toggle();
    }
  }
  menuMouseOver(): void {
    if (this.isScreenSmall && this.options.collapsed) {
      this.sidemenu.mode = "over";
    }
  }

  menuMouseOut(): void {
    if (this.isScreenSmall && this.options.collapsed) {
      this.sidemenu.mode = "side";
    }
  }

  getFlyPlatesSubMenu() {
    this.subscriptions.push(
      this.adminLayoutMenuService._flyPlatesSubMenu$.subscribe((data) => {
        this.flyPlatesSubMenu = [...(data || [])];
      })
    );
  }

  getFlyCutsSubMenu() {
    this.subscriptions.push(
      this.adminLayoutMenuService._flyCutsSubMenu$.subscribe((data) => {
        let viewAll = {
          id: "all",
          name: "View All",
          sub_category_lv1: [],
        };

        this.flyCutsSubMenu = [viewAll, ...(data || [])];

        this.flyShareSubMenu = [
          { id: FLYSHARE_ALL_ROUTE, name: "View All" },
          { id: FLYSHARE_SHARED_ROUTE, name: "Shared with Me" },
          { id: FLYSHARE_OTHERS_ROUTE, name: "Shared with Others" },
        ];
      })
    );
  }

  setFlycutCatAsActive(flycutCatId) {
    if (this.currentRoute.includes("shortcut")) {
      let shortcutId = this.currentRoute.split("/")[2];
      if (flycutCatId == shortcutId) {
        return true;
      }
    }
    return false;
  }

  setFlyplateCatAsActive(flyplateCatName) {
    if (this.currentRoute.includes("flyplates")) {
      let flyplateCat = this.currentRoute.split("/")[2];
      if (flyplateCatName.includes(" ")) {
        flyplateCatName = `${flyplateCatName.split(" ")[0]}${
          flyplateCatName.split(" ")[1]
        }`;
      }
      if (flyplateCatName.toLowerCase() == flyplateCat.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  linkClicked(event: any, id: string) {
    event.stopPropagation();
    this.router.navigate(["/shortcuts", id]);
  }

  flyPlateClicked(event: any, name: string, page: string) {
    event.stopPropagation();
    this.router.navigate([
      "/flyplates",
      name.replace(" ", "").toLowerCase(),
      page ? page.replace(" ", "").toLowerCase() : "",
    ]);
  }

  flyShareClicked(event: any, submenu: any) {
    event.stopPropagation();
    this.router.navigate(["/flyshare/" + submenu.id]);
  }

  flyLearningClicked(event, value) {
    event.stopPropagation();
    if (!value) {
      this.router.navigate(["pricing"]);
      return;
    }
    this.router.navigate(["flylearning"]);
  }

  toggleFloatingButtons() {
    this.showFloatingButtons = !this.showFloatingButtons;
  }

  navigateTo(site: any) {
    window.open(site, "_blank");
  }

  goToAddCategory() {
    if (this.router.url.includes("/settings")) {
      this.eventService.updateAddCategoryListener(true);
    } else {
      this.router.navigate(["/settings"], { queryParams: { add: "true" } });
    }
  }

  goToAddShortcut() {
    if (this.router.url.includes("/shortcuts/")) {
      this.eventService.updateAddShortcutListener(true);
    } else {
      this.router.navigate(["/shortcuts/all"], {
        queryParams: { add: "true" },
      });
    }
  }

  openlearning() {
    let user = JSON.parse(localStorage.getItem("vengreso.userDetails"));
    let email = user?.user_details?.email;
    if (email) {
      //const data = { Email: encodeURIComponent(email) };
      //Encoded on the backend
      const data = { Email: email };
      const url = "v1/authenticateUser";
      this.dataService
        .post({ url, data, isLoader: true })
        .subscribe(async (response: any) => {
          if (response && response.result) {
            let redirect_url = response.result.redirect_url;
            this.SSO_URL = redirect_url;
            window.open(redirect_url, "_blank").focus();
          }
        });
    }
  }

  async checkIfUserHasExtension() {
    try {
      const isInstalled = await this.extensionService.isExtensionInstalled();
      if (!isInstalled) {
        this.shouldDownloadExtension();
      }
    } catch {
      this.shouldDownloadExtension();
    } finally {
      this.cdr.detectChanges();
    }
  }

  shouldDownloadExtension() {
    this.dialog.openDialogComponent(
      WelcomeComponent,
      {
        self: this,
        class: "welcome-dialog",
      },
      "400px",
      true
    );
  }

  getShowExtensionPromptValue() {
    this.subscriptions.push(
      this.sharedData.showExtensionPrompt$.subscribe((data) => {
        this.presentExtensionPrompt = data;
        this.cdr.detectChanges();
      })
    );
  }

  goToViewAllPage(value) {
    switch (value) {
      case "flycuts":
        this.router.navigateByUrl("/shortcuts/all");
        break;
      case "flyplates":
        this.router.navigateByUrl("/flyplates/viewall");
        break;
      case "flyshare":
        this.router.navigateByUrl("/flyshare/all");
        break;
      case "settings":
        this.router.navigateByUrl("/settings");
        break;
      default:
        this.router.navigateByUrl("/flyboard");
    }
  }
}
