import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
  private reusableRoutes = [
    ':companySlug/admin/groups/:groupSlug/:subGroupSlug',
    ':companySlug/admin/groups/:groupSlug/:subGroupSlug/add-users',
    ':companySlug/admin/groups/:groupSlug/:subGroupSlug/remove-users'
  ];

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void { }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futurePath = future.routeConfig ? future.routeConfig.path : '';
    const currPath = curr.routeConfig ? curr.routeConfig.path : '';
    const isReusableRoute = this.reusableRoutes.includes(futurePath) && this.reusableRoutes.includes(currPath);
    if (isReusableRoute) {
      return futurePath === currPath && JSON.stringify(future.params) === JSON.stringify(curr.params);
    }

    return future.routeConfig === curr.routeConfig;
  }
}
