import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutMenuService {

  constructor() { }

  public _flyPlatesSubMenu = new Subject<any>()
  public _flyPlatesSubMenu$ = this._flyPlatesSubMenu.asObservable()

  public _flyCutsSubMenu = new Subject<any>()
  public _flyCutsSubMenu$ = this._flyCutsSubMenu.asObservable()

}
