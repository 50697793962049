const CTA_BASE_URL = 'https://cta-redirect.hubspot.com';
const CTA_REDIRECT_PATH = `${CTA_BASE_URL}/cta/redirect/11964`;

export const SUBSCRIPTION_TAB = 'subscription';
export const SUBSCRIPTION_EDIT_STARTER = 'subscription/edit/starter';
export const SUBSCRIPTION_EDIT_STARTER_PAUSED = 'subscription/edit/starter/paused';
export const SUBSCRIPTION_EDIT_STARTER_DOWNGRADE_FREEMIUM = 'subscription/edit/starter/downgradefreemium';
export const SUBSCRIPTION_EDIT_GROWTH = 'subscription/edit/growth';
export const SUBSCRIPTION_EDIT_GROWTH_PAUSED = 'subscription/edit/growth/paused';
export const SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_FREEMIUM = 'subscription/edit/growth/downgradefreemium';
export const SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_STARTER = 'subscription/edit/growth/downgradestarter';

export const SUBSCRIPTION_TAB_PARAMS = [
    SUBSCRIPTION_TAB,
    SUBSCRIPTION_EDIT_STARTER,
    SUBSCRIPTION_EDIT_STARTER_PAUSED,
    SUBSCRIPTION_EDIT_STARTER_DOWNGRADE_FREEMIUM,
    SUBSCRIPTION_EDIT_GROWTH,
    SUBSCRIPTION_EDIT_GROWTH_PAUSED,
    SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_FREEMIUM,
    SUBSCRIPTION_EDIT_GROWTH_DOWNGRADE_STARTER
];

export const CTA_UPGRADE_PLAN_ID = '6ce7e4fa-c37f-41e9-bc0d-a2ab959b0b99';
export const CTA_UPGRADE_PLAN_URL = `${CTA_REDIRECT_PATH}/${CTA_UPGRADE_PLAN_ID}`;

export const CTA_EDIT_PLAN_ID = 'c6ac3bec-9263-4999-a78d-4941c2c998da';
export const CTA_EDIT_PLAN_URL = `${CTA_REDIRECT_PATH}/${CTA_EDIT_PLAN_ID}`;

export const CTA_COMPARE_PLAN_ID = '97c4cd3f-c316-4854-9966-9b36b38062b4';
export const CTA_COMPARE_PLAN_URL = `${CTA_REDIRECT_PATH}/${CTA_COMPARE_PLAN_ID}`;

export const CTA_PAUSE_PLAN_ID = '13d73c76-9a67-40cc-ae76-4f21efe440b4';
export const CTA_PAUSE_PLAN_URL = `${CTA_REDIRECT_PATH}/${CTA_PAUSE_PLAN_ID}`;

export const CTA_DOWNGRADE_TO_FREEMIUM_ID = '9df91c99-90bc-45de-ac7e-ce0ae6720d23';
export const CTA_DOWNGRADE_TO_FREEMIUM_URL = `${CTA_REDIRECT_PATH}/${CTA_DOWNGRADE_TO_FREEMIUM_ID}`;

export const CTA_EDIT_PLAN_GROWTH_ID = 'bdc772ba-cc33-484f-97d4-5bb380519902';
export const CTA_EDIT_PLAN_GROWTH_URL = `${CTA_REDIRECT_PATH}/${CTA_EDIT_PLAN_GROWTH_ID}`;

export const CTA_GROWTH_PAUSE_PLAN_ID = '4387c0a1-6137-430f-9bc1-26f9eaf7b598';
export const CTA_GROWTH_PAUSE_PLAN_URL = `${CTA_REDIRECT_PATH}/${CTA_GROWTH_PAUSE_PLAN_ID}`;

export const CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_ID = '13694406-1b42-4be1-a4b8-029dff8be67f';
export const CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_URL = `${CTA_REDIRECT_PATH}/${CTA_GROWTH_DOWNGRADE_TO_FREEMIUM_ID}`;

export const CTA_GROWTH_DOWNGRADE_TO_STARTER_ID = 'bbd68424-f774-465e-a40a-b3fdb2e09584';
export const CTA_GROWTH_DOWNGRADE_TO_STARTER_URL = `${CTA_REDIRECT_PATH}/${CTA_GROWTH_DOWNGRADE_TO_STARTER_ID}`;
