import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { findIndex } from "lodash";

import { ShortcutService } from "src/app/core/core-services/services/shortcut.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { Router } from "@angular/router";

@Component({
  selector: "profile-updated-successfully",
  templateUrl: "./profile-updated-successfully.component.html",
  styleUrls: ["./profile-updated-successfully.component.scss"],
})
export class ProfileUpdatedSuccessfullyComponent implements OnInit {
  confirmed = false
  formValue: any
  user: any

  constructor(
    private fb: FormBuilder,
    config: NgbPopoverConfig,
    private dataService: DataService,
    private shortcutService: ShortcutService,
    private notification: NotificationService,
    private extensionService: ExtensionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfileUpdatedSuccessfullyComponent>,
    public dialog: DialogService,
    private router: Router
  ) {
    config.triggers = "hover";
    config.container = "body";
    this.formValue = data.self.form.value
    this.user = JSON.parse(localStorage.getItem("vengreso.userDetails"));
  }

  ngOnInit(): void {
  }

  update(): void {
    this.confirmed = true
  }

  close(): void {
    this.dialogRef.close(this.confirmed);
    if (this.confirmed) {
      this.data.self.nextStep()
    }
  }
}
