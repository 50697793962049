import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { loadUserCurrentSubscriptionApi, loadUserCurrentSubscriptionApiSuccess, loadUserCurrentSubscriptionApiFailure } from './plan.actions';
import { of, catchError } from 'rxjs';
import { map, filter, tap, exhaustMap } from 'rxjs/operators';
import { StripeService } from '../../core-services/services/stripe.service';
import { Store } from '@ngrx/store';
import { PlanState, initialPlanState } from './plan.reducer';

@Injectable()
export class PlanEffects {
  
  loadCurrentPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserCurrentSubscriptionApi),
      concatLatestFrom(() => this.store.select((state) => state.data !== null ? state : initialPlanState)),
      filter(([action, state]) => action?.refetchFromApi || !state.loading || state.data === null),
      exhaustMap(() =>
        this.stripeService.getCurrentPlan().pipe(
          map(data => loadUserCurrentSubscriptionApiSuccess({ payload: data })),
          catchError(error => of(loadUserCurrentSubscriptionApiFailure({ error })))
        )
      )
    )
  );

//   logActions$ = createEffect(() => this.actions$.pipe(
//     tap(action => console.log('Action dispatched:', action))
//   ), { dispatch: false });

  constructor(private actions$: Actions, private stripeService: StripeService, private store: Store<PlanState>,) {}
}