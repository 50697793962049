import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { DowngradePlanTwoComponent } from '../downgrade-plan-two/downgrade-plan-two.component';
import {
  getPlanId
} from "src/app/core/core-services/constants/plans.constants";
import { SubscriptionPlanType } from 'src/app/core/models/stripe.models';

import {
  CTA_COMPARE_PLAN_URL
} from "../settings.config";

@Component({
  selector: 'app-downgrade-plan-one',
  templateUrl: './downgrade-plan-one.component.html',
  styleUrls: ['./downgrade-plan-one.component.css']
})
export class DowngradePlanOneComponent implements OnInit {

  currentPlan;

  features: string[];

  constructor(
    public dialog: DialogService,
    public dialogRef: MatDialogRef<DowngradePlanOneComponent>
  ) { 
    this.currentPlan = getPlanId();

    this.isGrowthPlan() ? this.features = [
      "Unlimited <s>Templates</s> FlyPlates",
      "Unlimited <s>Shortcodes</s> FlyCuts",
      "Unlimited Characters per FlyCut",
      "Unlimited Categories & Subcategories",
      "Spell Check for All Languages",
      "All Font Sizes & Styles",
      "Access to the Latest FlyPlates",
      "LinkedIn FlyMSG Plugin",
      "Enhanced Text & Paragraph Styling",
      "Personalization Tokens"
    ] : [
      "Up to 10 <s>Templates</s> FlyPlates",
      "Up to 300 <s>Shortcodes</s> FlyCuts",
      "25,000 Characters per FlyCut",
      "Up to 7 Categories & 14 Subcategories",
      "Spell Check for 1 Selected Language ",
      "All Fonts in All Font Sizes",
      "Access to Technical Support",
      "Insert Images into FlyCuts and Flyplates",
      "All Text Styling: Italicize, Underline, Bullets, & More",
      "Access to Additional Tutorials & Learning Content"
    ];
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  downgradePlan(): void {
    this.close();
    this.dialog.openDialogComponent(DowngradePlanTwoComponent, {}, "500px");
  }

  isGrowthPlan(): boolean {
    return this.currentPlan === SubscriptionPlanType.Growth;
  }

  comparePlan(): void {
    window.location.href = CTA_COMPARE_PLAN_URL;
  }

}
