import { createReducer, on } from '@ngrx/store';
import { loadUserCurrentSubscriptionApi, loadUserCurrentSubscriptionApiSuccess, loadUserCurrentSubscriptionApiFailure } from './plan.actions';

export interface PlanState {
  data: any;
  loading: boolean;
  error: any;
}

export const initialPlanState: PlanState = {
  data: null,
  loading: false,
  error: null
};

export const planReducer = createReducer(
  initialPlanState,
  on(loadUserCurrentSubscriptionApi, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadUserCurrentSubscriptionApiSuccess, (state, { payload }) => {
    return {
      ...state,
      loading: false,
      data: payload
    };
  }),
  on(loadUserCurrentSubscriptionApiFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error
    };
  })
);