import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DowngradePlanThreeComponent } from '../downgrade-plan-three/downgrade-plan-three.component';

import { SubscriptionPlanType } from "../../../core/models/stripe.models";

@Component({
  selector: 'app-downgrade-option',
  templateUrl: './downgrade-option.component.html',
  styleUrls: ['./downgrade-option.component.css']
})
export class DowngradeOptionComponent implements OnInit {

  planDowngradedFrom: string;
  starterMonthlyPrice: any = 0;
  starterYearlyPrice: any = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : {planDowngradedFrom:string, planDowngradedTo : string},
    public dialog: DialogService,
    public dialogRef: MatDialogRef<DowngradeOptionComponent>,
    private dataService: DataService,
  ) { 
    this.getPricing();
    this.planDowngradedFrom = data.planDowngradedFrom;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  chooseStarter(): void {
    this.close();
    //TODO: Send to backend
    this.dialog.openDialogComponent(DowngradePlanThreeComponent,
      {
        planDowngradedFrom: this.planDowngradedFrom,
        planDowngradedTo: SubscriptionPlanType.Starter
      }, 
    "700px");
  }

  chooseFreemium(): void {
    this.close();
    //TODO: Send to backend
    this.dialog.openDialogComponent(DowngradePlanThreeComponent,
      {
        planDowngradedFrom: this.planDowngradedFrom,
        planDowngradedTo: SubscriptionPlanType.Freemium
      }, 
    "700px");
  }

  getPricing() {
    this.dataService
      .get({ url: "v1/subscriptions/plans", isLoader: false })
      .subscribe({
        next: (response: any) => {
          const price = response.result.find(({ identifier }) => identifier == 'starter-yearly').unit_amount
          this.starterYearlyPrice = Number(price / 100).toFixed(2) ?? 0.00;
          this.starterMonthlyPrice = Number(this.starterYearlyPrice / 12).toFixed(2) ?? 0.00;
        },
        error: (error) => {
          console.log(error)
        }
      });
  }

}
