import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class FlyShareInviteGuardService implements CanActivate {

  constructor(
    private authService: AuthService, 
    private sharedData: SharedDataService, 
    private nav: NavigationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { params : { flyshareId = '' },  queryParams: { f = '', l = ''} } = route;

    return this.process(flyshareId, f, l);
  }

  process(flyshareId: string, firstName: string, lastName: string): boolean {

    if (!flyshareId) {
      this.nav.navigateTo(["/"]);
      return false;
    }

    this.sharedData.setAttribute('flyshareId', flyshareId);
    this.sharedData.setAttribute('flysharerFirstName', firstName);
    this.sharedData.setAttribute('flysharerLastName', lastName);

    if (!this.authService.isLoggedIn()) {
      this.nav.navigateTo(["/session/signup"]);
      return false;
    }

    this.nav.navigateTo(["/flyboard"]);

    return false;
  }
}
