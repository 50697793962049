import { Injectable } from "@angular/core";
import { ChartConfiguration } from "chart.js";

import { DataService } from "./dataservices/data.service";

export type DateType = "month" | "day" | "hour";
export type ChartFilterKey =
  | "flycuts_used"
  | "characters_typed"
  | "cost_saved"
  | "time_saved";
export interface DateRange {
  text: string;
  id: number;
  differenceFromNow: number;
  dateType: DateType;
}

export interface ChartFilterOption {
  text: string;
  value: ChartFilterKey;
}

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  dateRanges: DateRange[] = [
    // { text: "Past 24 hours", id: 1, differenceFromNow: 24, dateType: "hour" },
    { text: "Last 7 days", id: 2, differenceFromNow: 7, dateType: "day" },
    { text: "Last month", id: 3, differenceFromNow: 1, dateType: "month" },
    { text: "Last 3 months", id: 4, differenceFromNow: 3, dateType: "month" },
    { text: "Last 6 months", id: 5, differenceFromNow: 6, dateType: "month" },
    { text: "Last 9 months", id: 6, differenceFromNow: 9, dateType: "month" },
    { text: "Last 12 months", id: 7, differenceFromNow: 12, dateType: "month" },
  ];

  chartFilterOptions: ChartFilterOption[] = [
    { text: "Time Saved (hrs)", value: "time_saved" },
    { text: "FlyCuts Used", value: "flycuts_used" },
    { text: "Cost Saved($)", value: "cost_saved" },
    { text: "Characters Typed", value: "characters_typed" },
  ];

  lineChartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: [],
        label: "",
        backgroundColor: "#F0DBFA",
        borderColor: "#5F177F",
        pointBackgroundColor: "#5F177F",
        fill: "origin",
      },
    ],
    labels: [],
  };

  adminLineChartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
      },
      x: {
        grid: {
          display: false
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        position: "nearest",
        backgroundColor: "#2a1e36;",
      }
    },
  };

  constructor(
    private dataService: DataService
  ) {}

  getStatistic(differenceFromNow: number = null, dateType: DateType = "month") {
    const url = differenceFromNow
      ? `v1/user/dashboard?differenceFromNow=${differenceFromNow}&dateType=${dateType}`
      : "v1/user/dashboard";
    return this.dataService.get({
      url: url,
      isLoader: false,
    });
  }

  filterStatistics(dateRangeId: number) {
    const { differenceFromNow, dateType } = this.dateRanges.find(
      ({ id }) => id === dateRangeId
    );

    return { differenceFromNow, dateType };
  }

  prepareChartData(
    rawChartData: any,
    filterBy: ChartFilterKey = "time_saved"
  ): ChartConfiguration["data"] {
    let lineChartData = this.lineChartData;

    let newv = {};
    for (let month in rawChartData) {
      if (rawChartData[month] != undefined) {
        newv["" + month] = rawChartData[month][0] ?? rawChartData[month];
      }
    }

    if (newv) {
      rawChartData = newv;
    }

    if (!rawChartData) {
      return lineChartData;
    }

    lineChartData.labels = Object.keys(rawChartData);

    let dataset = [];
    lineChartData.labels.reverse()
    lineChartData?.labels.forEach((key: string) => {
      dataset.push(rawChartData[key][filterBy]);
      lineChartData.datasets[0].data = dataset;
    });

    let label = "";

    switch (filterBy) {
      case "flycuts_used":
        label = "FlyCuts Expanded";
        break;
      case "characters_typed":
        label = this.titleCase(filterBy);
        break;
      case "cost_saved":
        label = this.titleCase(filterBy) + " ($)";
        break;
      case "time_saved":
        label = this.titleCase(filterBy) + " (hrs)";
        break;

      default:
        break;
    }

    lineChartData.datasets[0].label = label;

    return lineChartData;
  }

  titleCase(s: string): string {
    return s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());
  }
}
