import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  subjectNotifier: Subject<any> = new Subject<any>();
  behaviourNotifier: BehaviorSubject<any> = new BehaviorSubject(null);
 
  constructor() { }
 
  notifyAboutChange(param: any = '') {
    this.subjectNotifier.next(param);
    this.behaviourNotifier.next(param);
  }
}
 