import {
  Component,
  OnInit,
  Inject,
} from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DOCUMENT } from '@angular/common';

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { NotifyService } from "src/app/core/menu/not.service";
import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "app-emulator-preview",
  templateUrl: "./emulator-preview.component.html",
  styleUrls: ["./emulator-preview.component.scss"],
})
export class EmulatorPreviewComponent implements OnInit {
  html: string = "";
  checked: boolean;
  isMax = false;
  editorConfig: any;
  editorApiKey: string;

  constructor(
    public notifyService: NotifyService,
    config: NgbPopoverConfig,
    private dataService: DataService,
    private clipboardApi: ClipboardService,
    private notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmulatorPreviewComponent>,
    public dialog: DialogService,
    @Inject(DOCUMENT) private document: any
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    //setTimeout(() => {
      this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
      this.editorConfig = {
        statusbar: false,
        menubar: false,
        toolbar: false,
        min_height: "100%",
        height: "100%",
        content_style: ApplicationConstants.TINYMCE_CONTENT_CSS
      };
    //}, 0);
    this.checked = this.data.checked == false ? false : true;
    this.isMax = this.data.isMax;
    this.switchViewer();
    this.updateClock();
  }

  copyLink() {
    this.dataService
      .get({ url: `v1/user/shortcut/share/${this.data.shortcut.id}` })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.clipboardApi.copyFromContent(
            `${this.document.location.origin}/shared-flycut/${response.result}`
          );
          this.notification.toast("Link copied successfully!");
        }
      });
  }

  switchViewer() {
    if (!this.checked) {
      var tempDivElement = document.createElement("div");
      let content = this.data.shortcut.html
      content = content.replaceAll('<div>&nbsp;</div>', '\n')
      content = content.replaceAll('<br><br>', '\n\n')
      content = content.replaceAll('&nbsp;<', '\n<')
      content = content.replaceAll('</span><br><span', '</span>\n<span')
      // Set the HTML content with the given value
      tempDivElement.innerHTML = content;
      // Get all images.
      let images: any = tempDivElement.querySelectorAll("img");
      let anchors: any = tempDivElement.querySelectorAll("a");
      if ((images && images.length > 0) || (anchors && anchors.length > 0)) {
        // Get all images stringify and replace
        let stringified_html = "";

        for (let image of images) {
          // Image to string.
          var MyNodeHTML = image.outerHTML;
          if (image.src.indexOf('onemob') != -1 || image.src.indexOf('h-vd.io') != -1 || image.src.indexOf('hippovideo') != -1 || image.src.indexOf('loom.com') != -1) {
            stringified_html == ""
            ? (stringified_html = tempDivElement.outerHTML.replace(
                MyNodeHTML, ''
              ))
            : (stringified_html = stringified_html.replace(
                MyNodeHTML, ''
              ));
            continue
          }
          stringified_html == ""
            ? (stringified_html = tempDivElement.outerHTML.replace(
                MyNodeHTML, image.src
              ))
            : (stringified_html = stringified_html.replace(
                MyNodeHTML, image.src
              ));
        }

        for (let anchor of anchors) {
          // links to string.
          var MyNodeHTML = anchor.outerHTML;
          if (anchor.href.indexOf('onemob') != -1 || anchor.href.indexOf('h-vd.io') != -1 || anchor.href.indexOf('hippovideo') != -1 || anchor.href.indexOf('loom.com') != -1) {
            let replacedTxt = anchor.innerHTML.replaceAll('<wbr>','') == anchor.href ? MyNodeHTML : anchor.innerHTML + ' ' + anchor.href
            stringified_html == ""
              ? (stringified_html = tempDivElement.outerHTML.replace(
                  MyNodeHTML,
                  replacedTxt
                ))
              : (stringified_html = stringified_html.replace(
                  MyNodeHTML,
                  replacedTxt
                ));
            continue
          }
          let replacedTxt = anchor.innerHTML.replaceAll('<wbr>','') == anchor.href ? MyNodeHTML : anchor.innerHTML + ' (' + anchor.href + ') '
          stringified_html == ""
            ? (stringified_html = tempDivElement.outerHTML.replace(
                MyNodeHTML,
                replacedTxt
              ))
            : (stringified_html = stringified_html.replace(
                MyNodeHTML,
                replacedTxt
              ));
        }

        let parsed_html: any = this.createElementFromHTML(stringified_html);
        this.html = parsed_html.textContent || parsed_html.innerText;
      } else {
        this.html =
          tempDivElement.textContent ||
          tempDivElement.innerText;
      }

      this.html = this.html.replace(/<[^>]+>/g, '');
      this.html = this.html.replace(/(\n){2,}/g, '\n\n');
      return;
    }
  
    this.html = this.data.shortcut.html;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  // Function for real clock

  updateClock() {
    let now = new Date(), // current date
      time = now.getHours() + ":" + now.getMinutes(),
      target: any = document.getElementById("time");

    // set the content of the element with the ID time to the formatted string
    target.innerHTM = [time];

    // call this function again in 1000ms
    setTimeout(this.updateClock, 1000);
  }

  resize(): void {
    this.dialogRef.close();
    this.dialog.openDialogComponent(
      EmulatorPreviewComponent,
      {
        self: this.data,
        shortcut: this.data.shortcut,
        isMax: !this.data.isMax,
        checked: this.checked,
      },
      this.data.isMax ? "76vw" : "100vw"
    );
  }

  close(): void {
    this.notifyService.notifyAboutChange();
    this.dialogRef.close();
  }
}
