import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from "rxjs/operators";

import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { LoadingService } from 'src/app/core/core-services/services/loader.service';
import { NavigationService } from 'src/app/core/core-services/services/navigation.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { SubscriptionPlanType } from 'src/app/core/models/stripe.models';
import { stripeStyles } from "../../payment-details/payment-details.component";
import { SettingsComponent } from '../../settings.component';
@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.css'],
  providers: [SettingsComponent],
})
export class AddPaymentMethodComponent implements OnInit, AfterViewInit, OnDestroy {

  name: string;
  checked: Boolean = true;

  @ViewChild("paymentElement")
  paymentElementContainer: ElementRef;
  planType: SubscriptionPlanType;
  clientSecret: string;
  stripeId: any;
  paymentName = new FormControl("");
  private _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private stripeService: StripeService,
    private dataService: DataService,
    private settingsComponent: SettingsComponent,
    private notification: NotificationService,
    public nav:NavigationService,
    private loadingService: LoadingService
  ) { }

  public elements = this.stripeService.stripe.elements();
  public paymentElement = this.elements.create("card", {
    style: stripeStyles,
    hidePostalCode: true,
  });

  ngOnInit(): void {
    let plan = this.settingsComponent.getCurrentPlan();
    this.planType = SubscriptionPlanType[plan];

    this.getStripeConfig();
  }

  ngAfterViewInit(): void {
    this.paymentElement.mount(this.paymentElementContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async addPaymentMethod() {
    this.loadingService.start();

    try {
      const { setupIntent, error } = await this._addPaymentMethod();

      if (error) {
        // Display "error.message" to the user...
        this.loadingService.stop();
        this.notification.toast("Something Went Wrong, Please Contact Administrator", "Ok", 2000);
        return;
      }
  
      this.setPaymentMethodAsPrimary(setupIntent);
    } catch {
      this.loadingService.stop();
      this.notification.toast("Something Went Wrong, Please Contact Administrator", "Ok", 2000);
      return;
    }
    
    this.router.navigate(["payment-method-details"]);
  }

  private getStripeConfig() {
    this.dataService
      .get({ url: "v1/user", isLoader: false })
      .pipe(first())
      .subscribe((response: any) => {
        if (response && response.result) {
          this.stripeId = response.result.stripe_id ?? "";
        }
      });

    this.stripeService
      .genPaymentIntent()
      .pipe(first())
      .subscribe((response: string) => {
        this.clientSecret = response;
      });
  }

  async _addPaymentMethod() {
    if (!this.clientSecret || this.clientSecret.trim() === '') {
      this.notification.toast("please wait a few seconds then try again", "Ok", 2000);
      return;
    }

    const { setupIntent, error } = await this.stripeService.stripe.confirmCardSetup(this.clientSecret, {
      payment_method: {
        card: this.paymentElement,
        billing_details: { name: this.name },
      },
    });

    return {setupIntent, error};
  }

  setPaymentMethodAsPrimary(setupIntent: any) {
    // The card has been verified successfully...
    this.stripeService
      .setDefaultPaymentMethod(setupIntent.payment_method)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe({
        next: (response) => {
         if (!response) {
          this.notification.toast("Default payment type not set.", "", 2000);
          return;
         }
        },
       error: () => {
        this.notification.toast("Default payment type not set.", "", 2000);
       }
     });
  }
}
