import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appHorizontalScroll]'
})
export class HorizontalScrollDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.el.nativeElement;
    if (element.scrollLeft > 77) {
      this.renderer.addClass(element, 'scrolling-horizontal');
    } else {
      this.renderer.removeClass(element, 'scrolling-horizontal');
    }
  }
}