import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { ROLES } from 'src/app/core/core-services/constants/roles.constant';
import moment from 'moment';
import { MatSort, SortDirection } from '@angular/material/sort';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { ClipboardService } from 'ngx-clipboard';
import { AddUserByEmailComponent } from '../add-user-by-email/add-user-by-email.component';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AddUserManuallyComponent } from '../add-user-manually/add-user-manually.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { SuperAdminApiService } from '../services/super-admin-api.service';
import { LICENSE_TYPES } from 'src/app/core/core-services/constants/licenses.constants';
import { AdminApiService } from '../services/admin-api.service';
import { AuthService } from 'src/app/core/core-services/services/auth.service';
import { NavigationService } from 'src/app/core/core-services/services/navigation.service';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { ReactivateComponent } from './reactivate/reactivate.component';

interface GroupNode {
  id: string;
  name: string;
  count: number;
  children?: GroupNode[];
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
  subscriptions = new Subscription();
  dateAvailable: string = moment().subtract(1,"day").format("MMM, DD YYYY");
  dataSource:any;
  dialogRef: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedSort: string = 'last_added';
  displayedColumns: string[] = ["select","name", "company", "licenseType", "status", "extension", "role", "actions"];
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };
  treeControl = new NestedTreeControl<GroupNode>(node => node.children);
  sidebarDataSource = new MatTreeNestedDataSource<GroupNode>();
  roles: any[];
  downloadingCsv: boolean = false;
  deactivatedTab: boolean = false;
  search: string = "";
  companySortDirection: SortDirection = 'asc';
  allChecked: boolean = false;
  allRoleFilterChecked: boolean = true;
  allLicenseFilterChecked: boolean = true;
  allExtensionFilterChecked: boolean = true;
  statuses: any = [{label: "Active"},{label: "Invited"}];
  licenseTypes: any[] = LICENSE_TYPES;
  extensions: any[] = [{label: "Yes", checked: true},{label: "No", checked: true}];
  loading: boolean;
  pageSize: number = 10;
  usersCount: number = 0;
  totalPages: number = 0;
  currentPage: number = 1;
  loadingUsersCategories: boolean;
  individualUsersCategories: any[] = [];
  companyUsersCategories: any[] = [];

  checklistSelection = new SelectionModel<GroupNode>(true /* multiple */);
  constructor(
    private apiService: SuperAdminApiService,
    private adminApiService: AdminApiService,
    public authService: AuthService,
    private notification: NotificationService, 
    private clipboardService: ClipboardService,
    public dialog: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private nav: NavigationService,
    private config: NgbPopoverConfig,
    private dataService: DataService
  ) {
    config.triggers = "hover";
    config.container = "body";

    const roleToolTips = {
      "Vengreso Admin": "Grants full access to all system functionalities and manages all user groups and companies. (This is the most powerful user role.)",
      "Global Admin": "Manages all user groups and has system-wide permissions at a company level.",
      "Group Admin": "Administers a specific user group and/or subgroup, managing its members and permissions within that group and/or subgroup.",
      "Reporting Admin": "Has access to generate and view reports across the system but cannot modify user data or functionalities.",
      "User": "Basic user with no access to admin functionalities.",
    };
    this.roles = ROLES.map(role => ({
       name: role, 
       checked : true,
       tooltip : roleToolTips[role]
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        switch (params.get("path")) {
          case "add-manually":
            this.addUserManually();
            break;
          case "add-by-email":
            this.addUserByEmail();
            break;
          case "deactivated":
            this.deactivatedTab = true;
            break;

          default:
            break;
        }
      })
    );

    this.loadAllUsers();
    this.loadUsersCategories();

    this.dataService.downloadComplete$.subscribe(filename => {
      if(filename == "Users.csv")
      this.notification.toastWithConfig("The CSV has compiled and downloaded to your computer", null, {
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: ["green-notification"],
      });
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      return sortHeaderId === 'first_name' ? `${data['first_name']} ${data['last_name']}` : data[sortHeaderId];
    };
    this.disableHeaderSorting();
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadAllUsers();
  }

  loadUsersCategories(): void {
    this.loadingUsersCategories = true;
    this.apiService.getUserCategories().subscribe({
      next: (response: any) => {
        if (response.result.success) {
          const data = response.result.data;

          this.sidebarDataSource.data = data.map((category) => ({
            id: category.id,
            name: category.name,
            count: category.items.length,
            children: category.items.map((item: any) => ({ id: item.id, name: item.name, count: item.count }))
          }));
        }
      },
      error: (error: any) => console.log(error),
      complete: () => this.loadingUsersCategories = false
    });
  }

  loadAllUsers(): void {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }

    const categories = this.checklistSelection.selected.filter((node) => !node.children).map((node) => node.id);
    
    this.loading = true;
    this.dataSource = new MatTableDataSource([]);
    this.apiService.getUsers({
      deactivated: this.deactivatedTab,
      page: this.currentPage,
      perPage: this.pageSize,
      categories: categories.join(',')
    }).subscribe({
      next: (response: any) => {
        if (response.result.success == true) {
          const data = response.result.data.items;
          this.usersCount = Number(response.result.data.total);
          this.totalPages = Number(response.result.data.total_pages);
          this.currentPage = Number(response.result.data.current_page);

          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.licenseTypes = [...new Map(
            data.map((user: any) => [user.licenseType, {label: user.licenseType, checked: true}])
          ).values()];
          this.extensions = [...new Map(
            data.map((user: any) => [user.extension, {label: user.extension, checked: true}])
          ).values()];
          this.statuses = [...new Map(
            data.map((user: any) => [user.status, {label: user.status, checked: true}])
          ).values()];

          // const queryParams = this.route.snapshot.queryParams;

          // if(queryParams && queryParams['deactivate_company']){
          //   const company = companies.find((company: any) => company.slug == queryParams['deactivate_company']);

          //   if(company){
          //     this.openDeactivateCompanyDialog(company);
          //   }
          // }
        }
      },
      error: (error: any) => console.log(error),
      complete: () => this.loading = false
    });
  }

  addUserByEmail(){
    const dialogRef = this.dialog.openDialogComponent(
      AddUserByEmailComponent,
      { class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((users: any) => {
      if(!!users){
        this.dataSource.data = users.concat(this.dataSource.data);
      }
    })
  }

  addUserManually(){
    const dialogRef = this.dialog.openDialogComponent(
      AddUserManuallyComponent,
      { class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((user: any) => {
      if(!!user){
        this.dataSource.data = [user].concat(this.dataSource.data);
      }
    })
  }

  get showActionButton(): boolean {
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length > 0
    );
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole() === "Vengreso Admin";
  }

  searchUsers() {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateAllChecked() {
    this.allChecked =
      this.dataSource.connect().value != null &&
      this.dataSource.connect().value.every((t: any) => t.checked);
  }

  someChecked(): boolean {
    if (this.dataSource.connect().value == null) {
      return false;
    }
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource.connect().value == null) {
      return;
    }
    this.dataSource.connect().value.forEach((t: any) => (t.checked = checked));
  }

  someRoleFilterChecked() {
    return this.roles.filter((t:any) => t.checked).length > 0 && !this.allRoleFilterChecked;
  }

  checkRoleFilterAll(checked: boolean) {
    this.allRoleFilterChecked = checked;
    this.roles.forEach((t:any) => (t.checked = checked));

    this.search = ''; 
    this.searchUsers();
  }

  updateRoleFilterAllChecked() {
    this.allRoleFilterChecked = this.roles.every((t:any) => t.checked);
    const filter = this.roles.filter((t:any) => t.checked).map((t:any) => t.name);

    if (this.allRoleFilterChecked || filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['role']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  assignedRoleDisabled(dropdownRole: string, user: any): boolean {

    // If there are no groups in the company user assigned to, Group Admin role option should be disabled.
    if(dropdownRole == 'Group Admin' && !user.company_has_groups){
      return true;
    }

    // If the company of user has no groups, disable 'Vengreso Admin' and 'Group Admin'
    // if(['Group Admin', 'Vengreso Admin'].includes(dropdownRole) && !user.company_has_groups){
    //   return true;
    // }

    return dropdownRole === user.role || dropdownRole == 'Vengreso Admin' && !user.company.includes('Vengreso');
  }

  someLicenseFilterChecked(): boolean {
    return this.licenseTypes.filter((t:any) => t.checked).length > 0 && !this.allLicenseFilterChecked;
  }

  checkLicenseFilterAll(checked: boolean) {
    this.allLicenseFilterChecked = checked;
    this.licenseTypes.forEach((t:any) => (t.checked = checked));

    this.search = ''; 
    this.searchUsers();
  }

  updateLicenseFilterAllChecked() {
    this.allLicenseFilterChecked = this.licenseTypes.every((t:any) => t.checked);
    const filter = this.licenseTypes.filter((t:any) => t.checked).map((t:any) => t.label);

    if (this.allLicenseFilterChecked || filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['licenseType']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByExtension() {
    const filter = this.extensions.filter((t:any) => t.checked).map((t:any) => t.label);

    if (filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['extension']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByStatus() {
    const filter = this.statuses.filter((t:any) => t.checked).map((t:any) => t.label);

    if (filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['status']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  get enableExportCsv(): boolean {
    return (
      this.dataSource.data.filter((t: any) => t.checked).length > 0
    );
  }

  exportCsv() {
    this.downloadingCsv = true;

    const selectedUsers = this.dataSource.data.filter((t: any) => t.checked).map((user: any) => user.id);
    
    this.apiService.exportUsersCSV(this.deactivatedTab, selectedUsers.join(','));

    this.downloadingCsv = false;
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll('.mat-sort-header-container');
    matSortHeaderElements.forEach(element => {
      (element as HTMLElement).style.cursor = 'default';
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  sortBy(sortKey: string, direction: SortDirection = 'asc') {

    if(sortKey === 'company'){
      direction = this.companySortDirection
      this.companySortDirection = this.companySortDirection === 'desc' ? 'asc' : 'desc';
    }

    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === 'name' && direction === 'asc') {
      this.selectedSort = 'a_z';
    } else if (sortKey === 'name' && direction === 'desc') {
      this.selectedSort = 'z_a';
    } else if (sortKey === 'created_at' && direction === 'asc') {
      this.selectedSort = 'first_added';
    } else if (sortKey === 'created_at' && direction === 'desc') {
      this.selectedSort = 'last_added';
    }
  }

  customFilterApplied(data: any[]): boolean {
    return data.some((g: any) => g.checked === false)
  }

  get filtersClear() {
    return this.search.trim().length < 1 &&
    this.statuses.every((t:any) => t.checked === true) &&
    this.licenseTypes.every((t:any) => t.checked === true) &&
    this.roles.every((t:any) => t.checked === true)
  }

  clearFilters() {
    this.search = '';
    this.searchUsers();
    this.statuses.forEach((t:any) => t.checked = true);
    this.licenseTypes.forEach((t:any) => t.checked = true);
    this.roles.forEach((t:any) => t.checked = true);
    this.allRoleFilterChecked = true;
    this.allLicenseFilterChecked = true;
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm, key) => {
        return currentTerm + (data as { [key: string]: any })[key] + ' ';
      }, '').toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };    
  }

  hasChild = (_: number, node: GroupNode) => !!node.children && node.children.length > 0;

  toggleSelection(node: GroupNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    if (node.children?.length > 0) {
      this.checkAllChildrenSelection(node);
    }
    this.loadAllUsers();
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  checkAllChildrenSelection(node: GroupNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);

    node.children?.forEach(child => {
      if (nodeSelected) {
        this.checklistSelection.select(child);
      } else {
        this.checklistSelection.deselect(child);
      }
    });
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: GroupNode): void {
    let parent: GroupNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: GroupNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Get the parent node of a node */
  getParentNode(node: GroupNode): GroupNode | null {
    let parent: GroupNode | null = null;

    this.sidebarDataSource.data.forEach(n => {
      if (n.children && n.children.includes(node)) {
        parent = n;
      }
      if (n.children) {
        n.children.forEach(child => {
          if (child.children && child.children.includes(node)) {
            parent = child;
          }
        });
      }
    });

    return parent;
  }

  resendInviteEmail(data) {
    const isBulkOperation = Array.isArray(data) && data.length > 0;
    
    if (isBulkOperation) {
      const dialogRef = this.dialog.openDialogComponent(
        EditUserComponent,
        { data, action: "resendInvite", class: "portal-admin" },
        Array.isArray(data) ? "600px" : "500px"
      );

      dialogRef.afterClosed().subscribe((data: any) => {
        if(data == true) {
          this.dataSource.data = this.dataSource.data.map(
            (user: any) =>  ({ ...user, checked: false})
          );
        }
      });
    } else {
      let request: Observable<any>

      if(this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession){
        request = this.apiService.resendUserInvitation({emails: [data.email]});
      } else {
        request = this.adminApiService.resendUserInvitation({email: data.email})
      }

      request.subscribe({
        next: () => {
          this.notification.toastWithConfig("Invite email resent successfully", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
        },
        error: (error) => console.log(error)
      });
    }
  }

  copyInviteLink(user) {
    this.clipboardService.copyFromContent(
      user.invitation_link
    );
    this.notification.toastWithConfig("Invite link copied successfully", null, {
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: ["green-notification"],
    });
  }

  assignRole(user:any, role: string): void {
    if(!user.company || user.company == 'Not Assigned'){
      return;
    }

    let dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: user, role, action: "assignRole", class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if(!!updatedUser)
      this.dataSource.data = this.dataSource.data.map(
        (user: any) => user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      );
    });
  }

  editProfile(user: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: user, action: "editProfile", class: "portal-admin" },
      "600px"
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if(!!updatedUser)
      this.dataSource.data = this.dataSource.data.map(
        (user: any) => user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      );
    });
  }

  resetPassword(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "resetPassword", class: "portal-admin" },
      Array.isArray(data) ? "600px" : "500px"
    );

    dialogRef.afterClosed().subscribe((data: any) => {
      if(data == true) {
        this.dataSource.data = this.dataSource.data.map(
          (user: any) =>  ({ ...user, checked: false})
        );
      }
    });
  }

  assignMoveTo(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "assignMoveTo", class: "portal-admin" },
      "650px"
    );

    dialogRef.afterClosed().subscribe((movedUsers: any[]) => {
      if(movedUsers) {
        const movedUsersMap = Object.fromEntries(movedUsers.map(obj => [obj.id, obj]));

        this.dataSource.data = this.dataSource.data.map(
          (user: any) => movedUsersMap[user.id] ? { ...user, ...movedUsersMap[user.id], checked: false } : {...user, checked: false}
        );
      }
    });
  }

  deleteUser(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "deleteUser", class: "portal-admin" },
      "500px"
    );

    dialogRef.afterClosed().subscribe((deactivatedUsersIds: any[]) => {
      if(Array.isArray(deactivatedUsersIds) && deactivatedUsersIds.length > 0){
        this.dataSource.data = this.dataSource.data.filter((user: any) => !deactivatedUsersIds.includes(user.id));
      }
    });
  }

  deactivateUser(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "deactivateUser", class: "portal-admin" },
      "500px"
    );

    dialogRef.afterClosed().subscribe((deactivatedUsersIds: any[]) => {
      if(Array.isArray(deactivatedUsersIds) && deactivatedUsersIds.length > 0){
        this.dataSource.data = this.dataSource.data.filter((user: any) => !deactivatedUsersIds.includes(user.id));

        this.router.navigate(["/vengreso-admin/users/deactivated"]);
      }
    });
  }

  reactivateUser(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      ReactivateComponent,
      { data, class: "portal-admin" },
      "750px"
    );

    dialogRef.afterClosed().subscribe((reactivatedUsersIds: any[]) => {
      if(Array.isArray(reactivatedUsersIds) && reactivatedUsersIds.length > 0){
        this.dataSource.data = this.dataSource.data.filter((user: any) => !reactivatedUsersIds.includes(user.id));

        this.router.navigate(["/vengreso-admin/users"]);
      }
    });
  }

  loginAs(user: any) {
    if(user.status !== 'Active'){
      return;
    }
    
    this.authService.masqueradeAs({
      user_id: user.id
    });
    this.nav.navigateToUrl(`/flyboard`);
  }

  bulkResetPassword() {
    this.resetPassword(
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkAssignMoveTo() {
    this.assignMoveTo(this.dataSource.connect().value.filter((t: any) => t.checked));
  }

  bulkResendInvite() {
    this.resendInviteEmail(
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkDeleteUser() {
    this.deleteUser(
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkDeactivateUser() {
    this.deactivateUser(
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkReactivateUser() {
    this.reactivateUser(
      this.dataSource.connect().value.filter((t: any) => t.checked)
    )
  }
}
