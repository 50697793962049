import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute} from '@angular/router';
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";

@Component({
  selector: "temp-shared-shortcut",
  templateUrl: "./temp-shared-shortcut.html",
  styleUrls: ["./temp-shared-shortcut.scss"]
})
export class TempSharedShortcutComponent implements OnInit {
  html: string = `Temp Shared Component`;
  checked: boolean = false;
  editorConfig: any;
  editorApiKey: string;
  data: any;
  
  constructor(private fb: FormBuilder, public authService: AuthService, private route: ActivatedRoute, private dataService: DataService, config: NgbPopoverConfig) {
    config.triggers = "hover";
    config.container = "body";
    this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
    this.editorConfig = {
      statusbar: false,
      menubar: false,
      toolbar: false,
      min_height: "100%",
      height: "100%",
      content_style:
        "div{-webkit-user-select: none;  -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none;   user-select: none;} body ,body:after{ img{ max-width: 100% !important; height: auto !important;} } html body#tinymce.mce-content-body.mce-content-readonly div img{ max-width: 100% !important; height: auto !important;}",
    };
    const  key = this.route.snapshot.paramMap.get('key'); 
      this.dataService.get({ url: `v1/shortcut/get_shared/${key}` }).subscribe((response: any) => {
        if (response && response.result) {
          this.checked = true
          this.data = response.result.data
          this.html = response.result.data.html
        }
      });
  }

  ngOnInit(): void {
    
  }

  // Function to toggle the menu


  switchViewer() {
    if (!this.checked) {
      var tempDivElement = document.createElement("div");

      // Set the HTML content with the given value
      tempDivElement.innerHTML = this.data.html;
      // Get all images.
      let images: any = tempDivElement.querySelectorAll("img");
      // Get all images stringify and replace
      let stringified_html = "";

      for (let image of images) {
        // Image to string.
        var MyNodeHTML = image.outerHTML;

        stringified_html == ""
          ? (stringified_html = tempDivElement.outerHTML.replace(
              MyNodeHTML,
              image.src
            ))
          : (stringified_html = stringified_html.replace(
              MyNodeHTML,
              image.src
            ));
      }

      let parsed_html: any = this.createElementFromHTML(stringified_html);
      this.html = parsed_html.textContent || parsed_html.innerText;

      return;
    }
    this.html = this.data.html;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  // Function for real clock

  updateClock() {
    let now = new Date(), // current date
      time = now.getHours() + ":" + now.getMinutes(),
      target: any = document.getElementById("time");

    // set the content of the element with the ID time to the formatted string
    target.innerHTM = [time];

    // call this function again in 1000ms
    setTimeout(this.updateClock, 1000);
  }

}
