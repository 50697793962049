import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from "rxjs";

import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { SharedDataService } from 'src/app/core/core-services/services/shared-data.service';
import { ShortcutService } from "src/app/core/core-services/services/shortcut.service";

import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";
import { getPlanId, PlansConstants, PlansRestrictions } from "src/app/core/core-services/constants/plans.constants";

import { ScrollService } from 'src/app/core/core-services/services/scroll.service';
import {
  FLYCUT_OUTSIDE_PLAN_ALLOCATED,
  FLYSHARE_ALL_ROUTE,
  FLYSHARE_OTHERS_ROUTE,
  FLYSHARE_SHARED_ROUTE
} from "src/app/vengreso/share/flyshare/flyshare.config";
import { VersionHistoryComponent } from '../../shortcuts/version-history/version-history.component';
import { AddToFlycutsComponent } from '../add-to-flycuts/add-to-flycuts.component';
import { FeatureNotInPlanComponent } from '../feature-not-in-plan/feature-not-in-plan.component';
import { FlyCutShareComponent } from '../fly-cut-share/fly-cut-share.component';
import { ResetInFlycutsComponent } from '../reset-in-flycuts/reset-in-flycuts.component';
import { ShareHistoryComponent } from '../share-history/share-history.component';

@Component({
  selector: "app-flyshare",
  templateUrl: "./flyshare.component.html",
  styleUrls: ["./flyshare.component.scss"],
})
export class FlyshareComponent implements OnInit {
  shortcutIds: string[];
  activeRoute: string;
  shortcuts: any[] = [];
  userId: any;
  upScroll: boolean = true;

  allShortCutChecked: boolean = false;
  noShortcutChecked: boolean = false;
  panelOpenState = {};
  panelExpandState = {};
  currentIndex: number;
  subscriptions = new Subscription();
  modelChanged = new Subject<string>();
  editorManager: any;

  editorConfig: any;
  isSaving: boolean;
  editorApiKey: string;
  testingRichEditor: string;

  //list of the restricted feature to starter plan
  Starter = PlansRestrictions.Starter;
  //list of the restricted feature to free plan
  Free = PlansRestrictions.Free;

  constructor(
    private route: ActivatedRoute,
    private sharedData: SharedDataService,
    private dataService: DataService,
    private authService: AuthService,
    private notification: NotificationService,
    public dialog: DialogService,
    // private loadingService: LoadingService,
    private shortcutService: ShortcutService,
    public scrollService: ScrollService,
    private cdr: ChangeDetectorRef
  ) {
    this.userId = this.authService.getUserDetail().id;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
      this.editorConfig = this.shortcutService.getEditorConfig();
      this.editorConfig.placeholder = this.testingRichEditor;
    }, 0);

    this.route.params.subscribe(({ id }) => {
      this.init(id);
    });
  }

  init(id: string) {
    // this.loadingService.start();

    this.activeRoute = id;

    switch (id) {
      case FLYSHARE_OTHERS_ROUTE:
        this.getSharedWithOthersFlyShareShortcuts();
        break;

      case FLYSHARE_SHARED_ROUTE:
        this.getShortcutsOthersShared();
        break;

      case FLYSHARE_ALL_ROUTE:
      default:
        this.getAllUserFlyShareShortcuts();
        this.shortcutIds = this.sharedData.getAttribute("shortcutIds") ?? [];
        break;
    }

    if (this.shortcuts) {
      this.upScroll = false;
    }
  }

  openShareHistory(clonedShortcut: any, _e: Event) {
    if (_e) {
      _e.stopPropagation();
    }

    this.dialog.openDialogComponent(
      ShareHistoryComponent,
      { clonedShortcut },
      "420px"
    );
  }

  openedPanel(id) {
    this.panelOpenState[id] = true
  }
  
  share() {
    const checked = this.shortcuts.filter(
      (shortcut) => shortcut.checked === true
    );

    if (!checked || checked.length < 1) {
      this.notification.toast(
        "You should select at least one FlyCut to share."
      );

      this.noShortcutChecked = true;

      setTimeout(() => {
        this.noShortcutChecked = false;
      }, 3000);

      return;
    }

    const fromFlyPlates = checked.some(
      ({ user_defined }) => user_defined === false
    );

    this.dialog.openDialogComponent(
      FlyCutShareComponent,
      { shortcuts: checked, fromFlyPlates },
      fromFlyPlates ? "407px" : "859px"
    );
  }

  addToFlycuts(shortcut: any) {
    if (!this.isShortcutInUserPlan(shortcut)) {
      const dialogHandle = this.dialog.openDialogComponent(
        FeatureNotInPlanComponent,
        {
          added: this.getNumberOfFlycutsOutsidePlanAdded(),
          total: FLYCUT_OUTSIDE_PLAN_ALLOCATED,
        },
        "550px"
      );

      dialogHandle.afterClosed().subscribe((data: string) => {
        if (data !== "add") {
          return;
        }

        this.dialog.openDialogComponent(
          AddToFlycutsComponent,
          { self: this, shortcut },
          "400px"
        );
      });

      return;
    }

    this.dialog.openDialogComponent(
      AddToFlycutsComponent,
      { self: this, shortcut },
      "400px"
    );
  }

  resetInMyFlyCuts(shortcut: any) {
    this.dialog.openDialogComponent(
      ResetInFlycutsComponent,
      { self: this, shortcut },
      "400px"
    );
  }

  deleteShortcut(shortcut: any) {
    this.dialog.openDialogComponent(
      ConfirmDialogComponent,
      { self: this, data: shortcut },
      "330px"
    );
  }

  delete(shortcut: any) {
    const url = `v1/${
      shortcut.shared_with_others === true
        ? "cloned-shared-shortcut"
        : "shares-shortcut"
    }/${shortcut.id}`;
    this.dataService.delete({ url: url }).subscribe({
      next: (response: any) => {
        this.init(this.activeRoute);
        this.notification.toast("FlyShare deleted successfully!");
      },
      error: (error) => {
        this.notification.toast(
          error.error.error ?? "Something went wrong, Contact Administrator",
          "Ok",
          2000
        );
      },
    });
  }

  getAllUserFlyShareShortcuts() {
    this.dataService
      .get({ url: `v1/user/flyshares/shortcuts`, isLoader: false })
      .subscribe({
        next: (response: any) => {
          this.shortcuts = response?.result;

          if (
            this.shortcutIds &&
            this.shortcutIds.length > 0 &&
            this.shortcuts.length > 0
          ) {
            let shortcutToExpand = this.shortcuts.find(
              (shortcut) =>
                shortcut.reference_cloned_shortcut_id === this.shortcutIds[0]
            );
            this.panelExpandState[shortcutToExpand.id] = true;
            //this.scrollService.scrollTo(document.getElementById(shortcutToExpand.id))
            this.sharedData.deleteAttribute("shortcutIds");
          }
        },
        error: (error) => {
          this.notification.toast(
            "Something went wrong, Contact Administrator",
            "Ok",
            2000
          );
        },
        complete: () => {
          // this.loadingService.stop();
        },
      });
  }

  getSharedWithOthersFlyShareShortcuts() {
    this.dataService
      .get({
        url: `v1/user/flyshares/shortcuts-shared-with-others`,
        isLoader: false,
      })
      .subscribe({
        next: (response: any) => {
          this.shortcuts = response?.result;
        },
        error: (error) => {
          this.notification.toast(
            "Something went wrong, Contact Administrator",
            "Ok",
            2000
          );
        },
        complete: () => {
          // this.loadingService.stop();
        },
      });
  }

  getShortcutsOthersShared() {
    this.dataService
      .get({
        url: `v1/user/flyshares/shortcuts-others-shared`,
        isLoader: false,
      })
      .subscribe({
        next: (response: any) => {
          this.shortcuts = response?.result;
        },
        error: (error) => {
          this.notification.toast(
            "Something went wrong, Contact Administrator",
            "Ok",
            2000
          );
        },
        complete: () => {
          // this.loadingService.stop();
        },
      });
  }

  updateCheckList(isChecked: boolean, index: number) {
    if (index === -1) {
      this.shortcuts = this.shortcuts.map((item) => {
        item.checked = isChecked;
        return item;
      });
      return;
    }

    this.allShortCutChecked = true;
    for (const item of this.shortcuts) {
      if (!item.checked) {
        this.allShortCutChecked = false;
        break;
      }
    }
  }

  openVersionHistory(shortcut, expanded) {
    const id = shortcut.reference_shortcut_id;

    this.dialog.openDialogComponent(
      VersionHistoryComponent,
      { self: this, id },
      "800px"
    );

    if (!expanded) {
      this.panelOpenState[shortcut.id] = {};
      this.panelExpandState[shortcut.id] = {};
    }
  }

  onChangeContent(index: number) {
    this.modelChanged.next(null);
    this.currentIndex = index;
  }

  setEditorManager(event: any) {
    this.editorManager = event.editor.editorManager;
  }

  viewAll(): boolean {
    return this.activeRoute === FLYSHARE_ALL_ROUTE;
  }

  viewShared(): boolean {
    return this.activeRoute === FLYSHARE_SHARED_ROUTE;
  }

  viewOthers(): boolean {
    return this.activeRoute === FLYSHARE_OTHERS_ROUTE;
  }

  getNumberOfFlycutsOutsidePlanAdded(): number {
    return this.shortcuts.filter(
      (shortcut: any) =>
        !shortcut.shared_with_others &&
        shortcut.added_to_flycuts &&
        !this.isShortcutInUserPlan(shortcut)
    ).length;
  }

  isShortcutInUserPlan(shortcut: any): boolean {
    let planId = getPlanId();

    if (planId == "Growth") {
      return true;
    }

    shortcut.text = this.shortcutService.getInlineStyledHTML(shortcut.html);

    let result = true;

    const map = {
      italic: ["<em>"],
      underline: ["text-decoration: underline;"],
      strikethrough: ["text-decoration: underline;"],
      InsertUnorderedList: ["<ul>"],
      InsertOrderedList: ["<ol>"],
      mceApplyTextcolor: ["background-color:", "color:"],
      FontSize: [
        "font-size: ",
        "<pre>",
        "<p>",
        "<h1>",
        "<h2>",
        "<h3>",
        "<h4>",
        "<h5>",
        "<h6>",
      ],
      JustifyCenter: ["text-align: center;"],
      JustifyRight: ["text-align: right;"],
      indent: ["padding-left:"],
      outdent: ["padding-right:"],
      FontName: ["font-family:"],
    };

    let comResult = [];
    for (const iterator in map) {
      let output =
        this[planId]?.includes(iterator) &&
        map[iterator].some((feature: string) =>
          shortcut.html.includes(feature)
        );
      comResult.push(output);
    }

    result = !comResult.some((result) => result === true);

    let length = shortcut.text
      .replaceAll("&nbsp;", " ")
      .replace(/<\/?[^>]+(>|$)/g, "").length;

    if (
      (planId === "Free" && length >= PlansConstants.FREE.NUM_OF_CHAR) ||
      (planId === "Starter" && length >= PlansConstants.STARTER.NUM_OF_CHAR)
    ) {
      result = false;
    }

    this.cdr.detectChanges();

    return result;
  }
}
