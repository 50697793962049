import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { MatDialogRef } from '@angular/material/dialog';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { FileService } from '../services/file.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
@Component({
  selector: 'app-add-user-by-csv',
  templateUrl: './add-user-by-csv.component.html',
  styleUrls: ['./add-user-by-csv.component.scss']
})
export class AddUserByCsvComponent implements OnInit {
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;

  mode: ProgressBarMode = 'determinate';
  progressValue = 0;
  bufferValue = 100;
  uploading = false;
  fileSize = '';
  selectedFile: File | null = null;
  private cancelUpload$ = new Subject<void>();

  constructor (
    public dialogRef: MatDialogRef<AddUserByCsvComponent>, 
    private fileService: FileService,
    private notification: NotificationService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if(!currentUrl.includes("add-csv")){
      this.location.replaceState(currentUrl+"/add-csv");
    }
  }

  downloadCsvTemplate(): void {
    const filePath = 'assets/files/Account_Onboarding_Format_for_Users.xlsx';
    this.fileService.downloadFile(filePath).subscribe(blob => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filePath.split('/').pop()!;
      a.click();
      URL.revokeObjectURL(a.href);
    });
  }

  onAdd() {
    if(this.stepOne) {
      this.dialogRef.componentInstance.dialogRef.removePanelClass('portal-admin-confirmation')
      const input = document.getElementById('add-users-by-csv-input')
      input.click();
      return;
    }

    if (this.stepTwo) {
      this.moveToStep(3);
      return;
    }

    this.dialogRef.componentInstance.dialogRef.addPanelClass('portal-admin-confirmation');
    this.uploadFile();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.fileSize = `${Math.round(this.selectedFile.size / 1024)}KB`;

      if(this.selectedFile){
        this.moveToStep(2);
        this.readFile(this.selectedFile);
      }
    }
  }

  readFile(file: File): void {
    this.uploading = true;
    const reader = new FileReader();

    reader.onprogress = (event: ProgressEvent<FileReader>) => {
      if (event.lengthComputable) {
        const percentRead = (event.loaded / event.total) * 100;
        this.progressValue = percentRead;
      }
    };

    reader.onloadstart = () => {
      this.progressValue = 0;
    };

    reader.onloadend = () => {
      this.progressValue = 100;

      // Uncomment for dummmy simulation
      // setTimeout(() => {
      //   this.uploading = false;
      // }, 3000);

      this.uploading = false;
    };

    reader.onerror = () => {
      console.error('Error reading file');
      this.uploading = false;
    };

    reader.readAsArrayBuffer(file);
  }

  uploadFile(): void {
    if (!this.selectedFile) {
      this.notification.toastWithConfig("Upload File", null, {
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: ["red-notification"],
      });
      return;
    }

    this.uploading = true;

    // Uncomment for dummmy simulation
    // setTimeout(() => {
    //       this.uploading = false;
    //       this.selectedFile = null;

    //       this.notification.toastWithConfig("Users added", null, {
    //         verticalPosition: "top",
    //         horizontalPosition: "center",
    //         panelClass: ["green-notification"],
    //       });
    //       this.close();
    // }, 3000);

    // return;
    
    const companySlug = this.route.snapshot.paramMap.get('companySlug');

    this.dataService.uploadFile({
      url: `v1/admin-center/${companySlug}/users/import-csv`, 
      file: this.selectedFile, isLoader: false
    }, 
    this.cancelUpload$).subscribe({
      next: (event) => {
        if (typeof event === 'number') {
          this.progressValue = event;
        } else if (event instanceof HttpResponse) {
          this.uploading = false;
          this.selectedFile = null;

          this.notification.toastWithConfig("Users added", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
          this.close(event);
        }
      },
      error: () => {
        this.uploading = false;
      }
    });
  }

  cancelUpload(): void {
    this.cancelUpload$.next();
    this.uploading = false;
    this.selectedFile = null;
  }

  moveToStep(step: number) {
    this.stepOne = step === 1;
    this.stepTwo = step === 2;
    this.stepThree = step === 3;
  }

  ngOnDestroy(): void {
    this.location.replaceState(
      this.router.url.replace("/add-csv", "")
    );
  }

  close(data?:any) {
    this.location.replaceState(
      this.router.url.replace("/add-csv", "")
    );
    this.dialogRef.close(data);
  }
}
