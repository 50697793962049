import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../core/core-services/services/dataservices/data.service";

@Injectable()
export class ShortcutListResolver implements Resolve<any> {
    constructor(
        private dataService: DataService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const {categoryId, lv1CategoryId, lv2CategoryId} = route.params;
        const limit = route.queryParams.shortcutId ? 200 : 10;
        
        let url = `v1/user/shortcut?skip=0&limit=${limit}&order_by=shortcut,asc`;

        if(categoryId !== "all") {
            url += `&category_id=${categoryId}`;
        }
        if (lv1CategoryId) {
            url += `&sub_categories_lv1_id=${lv1CategoryId}`;
        }
        if (lv2CategoryId) {
            url += `&sub_categories_lv2_id=${lv2CategoryId}`;  
        }
        return this.dataService.get({
            url,
            isLoader: false
        });
    }
}
