import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-import-dialog",
  templateUrl: "./import-dialog.component.html",
  styleUrls: ["./import-dialog.component.scss"]
})
export class ImportDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImportDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  cancel(): void {
    this.dialogRef.close();
  }

  navigateTo(plan: string): void {
    switch (plan) {
      case "flycuts":
        this.dialogRef.close();
        this.router.navigate(["/shortcuts/all"]);
        break;
      default:
          break;
    }
  }
}
