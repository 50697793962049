import { Injectable } from '@angular/core';
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";

@Injectable({
  providedIn: 'root'
})
export class trackService {

  constructor(private dataService: DataService) { }

  btnClickUpdatetrack(value): void {
    this.dataService.get({
      url: "v1/tracking/track/" + value,
      isLoader: false
    }).subscribe((response: any) => {
      if (response && response.result) {
        console.log(response);
      }
    });
  }
}
