import { Injectable } from '@angular/core';
import { LocalStorageService } from "ngx-webstorage";
import { Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { DataService } from './dataservices/data.service';

export const SHORTCUT_CATEGORIES = 'shortcutCategories';

@Injectable({
  providedIn: 'root'
})
export class ShortcutCategoryService {

  constructor(
    private storage: LocalStorageService,
    private dataService: DataService,
  ) { }

  loadShortcutCategory(options = {reloadFromApi : false}): void {
    const shortcutCategories = this.storage.retrieve(SHORTCUT_CATEGORIES);

    if(Array.isArray(shortcutCategories) && shortcutCategories.length > 1 && !options.reloadFromApi) {
      return;
    }

    this.dataService.get({ url: "v1/user/shortcut-category", isLoader: false })
      .subscribe({
        next: (response: any) => {
          this.storage.store(SHORTCUT_CATEGORIES, response.result);
        },
        error: (err) => {
          console.log('An error occured loading shortcut categories')
        }
      });
  }

  trackChangesToShortcutCategory(): Observable<any> {
    return this.storage.observe(SHORTCUT_CATEGORIES)
    .pipe(
      distinctUntilChanged(
        (prev, curr) => prev.value === curr.value
      )
    )
  }
}
