import { Injectable } from "@angular/core";

@Injectable()
//This is for flyplates
export class TemplateService {
    isPremiumUser = false;
    isPremiumPanelBlocked = true;

    UpdateUserPremiumStatus(date: any): void {
        this.isPremiumUser = date;
    }

    getPanelStatus(isPremiumFlycut: any, isPremiumUser: any): boolean {
        if (isPremiumFlycut == true && isPremiumUser == true) {
            this.isPremiumPanelBlocked = false;
            return this.isPremiumPanelBlocked;
        } else if (isPremiumFlycut == true && isPremiumUser == false) {
            this.isPremiumPanelBlocked = true;
            return this.isPremiumPanelBlocked;
        }
    }
}