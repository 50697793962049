import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-reward-notify',
  templateUrl: './reward-notify.component.html',
  styleUrls: ['./reward-notify.component.scss']
})
export class RewardNotifyComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RewardNotifyComponent>,
  ) { 
    
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}
