import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuardService implements CanActivate {

  constructor(private authService: AuthService, private nav: NavigationService) { }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userNotAuthenticated();
  }

  userNotAuthenticated(): boolean {

    if (!this.authService.isLoggedIn()) {
        return true;
    }

    // or Navigate to dashboard
    this.nav.navigateTo(["/flyboard"]);
    return false;
  }
}
