import { Component, Inject, OnInit } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  groupName: string;
  action:string;

  constructor(
    private adminApiService: AdminApiService,
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notification: NotificationService,
  ) {
    this.groupName = this.data.name;
    this.action = this.data.action;
   }

  ngOnInit(): void {
  }

  deleteGroup() {
    this.adminApiService.deleteGroup(this.data.group_id).subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        this.close(true);
        this.notification.toastWithConfig(
          `${
            this.action == "subgroup" ? "Subgroup" : "Group"
          } edited successfully`,
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
      } // Else show error response
    });
  }

  close(data?:any){
    this.dialogRef.close(data);
  }

}
