import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AdminApiService } from "../services/admin-api.service";
import { AddGroupComponent } from "../add-group/add-group.component";

import { Option } from "../models/option.model";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, catchError, of } from "rxjs";
import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SuperAdminApiService } from "../services/super-admin-api.service";
import { SelectNewGlobalAdminComponent } from "../select-new-global-admin/select-new-global-admin.component";

interface GroupWithSubgroups extends Option {
  subgroups: Option[];
}

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  action: string;
  user: any;
  users: any[];
  role: any;
  editUserForm: FormGroup;
  moveToForm: FormGroup;
  assignRoleForm: FormGroup;
  assignMoveToForm: FormGroup;
  confirmMoveTo: boolean = false;
  confirmAssignRole: boolean = false;
  downgradeRole: boolean = false;
  confirmAssignMoveTo: boolean = false;
  subGroups: string[] = [];
  isBulkOperation: boolean = false;
  initialFormValues: any;

  editProfileStepOne: boolean = true;
  editProfileStepTwo: boolean = false;
  editProfileStepThree: boolean = false;

  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "select",
    "name",
    this.isVengresoAdmin ? "company" : "status",
    "actions",
  ];

  selectedGroupName: string = "";
  companySelectOpened: boolean = false;
  selectedCompanyName: string = "";

  groupCtrl = new FormControl("", Validators.required);
  subgroupCtrl = new FormControl("");

  groups: GroupWithSubgroups[] = [];
  subgroups: Option[] = [];

  roles: any[] = ROLES;
  companies: any[] = [];
  plans: any[] = [
    {
      value: "starter-yearly",
      label: "Starter",
      licensesAvailable: 0,
    },
    {
      value: "growth-yearly",
      label: "Growth",
      licensesAvailable: 0,
    },
    {
      value: "sales-pro-yearly",
      label: "Sales Pro",
      licensesAvailable: 0,
    },
    {
      value: "pro-plan-teams-smb",
      label: "Sales Pro Teams",
      licensesAvailable: 0,
    },
    {
      value: "freemium",
      label: "Freemium",
      licensesAvailable: "Unlimited",
    },
  ];

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private notification: NotificationService,
    private apiService: SuperAdminApiService,
    private adminApiService: AdminApiService,
    private config: NgbPopoverConfig,
    public dialog: DialogService
  ) {
    this.action = this.data.action;
    this.user = this.data.data;
    this.users = this.data.users;
    this.confirmAssignRole = this.data.confirmAssignRole;
    this.downgradeRole = this.data.downgradeRole;
    this.isBulkOperation =
      Array.isArray(this.data.data) && this.data.data.length > 0;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.initialFormValues = this.user;

    switch (this.action) {
      case "editProfile":
        const { first_name, last_name, email } = this.user;
        this.editUserForm = this.fb.group({
          first_name: [
            {
              value: first_name,
              disabled:
                this.isUserInvite ||
                !this.isGlobalOrVengresoAdmin ||
                !this.editProfileStepTwo,
            },
            this.isUserInvite ? [] : [Validators.required],
          ],
          last_name: [
            {
              value: last_name,
              disabled:
                this.isUserInvite ||
                !this.isGlobalOrVengresoAdmin ||
                !this.editProfileStepTwo,
            },
            this.isUserInvite ? [] : [Validators.required],
          ],
          email: [
            {
              value: email,
              disabled:
                !this.isGlobalOrVengresoAdmin || !this.editProfileStepTwo,
            },
            [Validators.required, Validators.email],
          ],
        });
        break;
      case "moveTo":
        if (this.isBulkOperation) {
          this.displayedColumns = ["select", "name", "group", "actions"];
          this.dataSource = new MatTableDataSource(this.data.data);
        }
        this.moveToForm = this.fb.group({
          group: this.groupCtrl,
          subgroup: this.subgroupCtrl,
        });

        this.adminApiService.getGroups().subscribe((response: any) => {
          if (response && response.result && response.result.success) {
            this.groups = response.result.groups.map((group: any) => ({
              value: group.group.id,
              displayValue: group.group.name,
              subgroups: group.group.subgroups?.map((subgroup: any) => ({
                value: subgroup.group.id,
                displayValue: subgroup.group.name,
              })),
            }));
          }
        });

        this.groupCtrl.valueChanges.subscribe((selectedGroupValue) => {
          this.subgroupCtrl.setValue("");
          const selectedGroup = this.groups.find(
            (group) => group.value === selectedGroupValue
          );
          this.selectedGroupName = selectedGroup?.displayValue;
          this.subgroups = selectedGroup ? selectedGroup.subgroups : [];
        });
        break;
      case "assignRole":
        this.role = this.data.role;

        this.assignRoleForm = this.fb.group({
          group:
            this.role.name === "Group Admin"
              ? [null, [Validators.required]]
              : [null],
        });

        if (
          this.isVengresoAdmin &&
          !this.authService.onCompanyMasqueradeSession
        ) {
          this.apiService
            .getCompanyGroups(this.user.company_slug)
            .subscribe((response: any) => {
              if (response.result.success) {
                this.groups = response.result.data.groups.map((group: any) => ({
                  value: group.id,
                  displayValue: group.name,
                  subgroups: group.subgroups?.map((subgroup: any) => ({
                    value: subgroup.id,
                    displayValue: subgroup.name,
                  })),
                }));

                let assignedGroupId = this.groups.find(
                  (group: any) => group.displayValue === this.user.group
                )?.value;
                if (assignedGroupId) {
                  this.assignRoleForm.get("group").setValue([assignedGroupId]);
                }
              }
            });
        } else {
          this.adminApiService.getGroups().subscribe((response: any) => {
            if (response && response.result && response.result.success) {
              this.groups = response.result.groups.map((group: any) => ({
                value: group.group.id,
                displayValue: group.group.name,
                subgroups: group.group.subgroups?.map((subgroup: any) => ({
                  value: subgroup.group.id,
                  displayValue: subgroup.group.name,
                })),
              }));

              let assignedGroupId = this.groups.find(
                (group: any) => group.displayValue === this.user.group
              )?.value;
              if (assignedGroupId) {
                this.assignRoleForm.get("group").setValue([assignedGroupId]);
              }

              if (this.user.groups_admin.length && this.user.role === this.role.name) {
                this.assignRoleForm.get("group").setValue(this.user.groups_admin);
              }
            }
          });
        }
        break;
      case "assignMoveTo":
        this.assignMoveToForm = this.fb.group({
          company_id: [null, [Validators.required]],
          role_name: ["User", [Validators.required]],
          plan_identifier: ["freemium", [Validators.required]],
        });

        if (this.isBulkOperation) {
          this.displayedColumns = ["select", "name", "company", "actions"];
          this.dataSource = new MatTableDataSource(this.data.data);
        }

        this.apiService.getCompaniesLicenses().subscribe({
          next: (response: any) => {
            if (response.result.success) {
              this.companies = response.result.data.map((company: any) => ({
                ...company,
                isSelected: false,
              }));
            }
          },
          error: (err) => console.log(err),
        });
        break;
      case "reactivateUser":
        this.displayedColumns = ["select", "name", "status", "plan", "actions"];
        this.adminApiService.getCompanyLicenses().subscribe({
          next: (response: any) => {
            this.plans = response?.result?.licenses.map((plan: any) => ({
              ...plan,
              totalLicenses: plan.licensesAvailable,
            }));
          },
          error: (error) => console.log(error),
        });
        if (this.isBulkOperation) {
          this.data.data = this.data.data.map((user: any) => ({
            ...user,
            plan: null,
            isSelected: false,
          }));
          this.dataSource = new MatTableDataSource(this.data.data);
        }
        break;
      case "resetPassword":
      case "deleteUser":
      case "deactivateUser":
      case "resendInvite":
        this.displayedColumns = ["select", "name", "status", "actions"];
        if (this.isBulkOperation) {
          this.dataSource = new MatTableDataSource(this.data.data);
        }
    }
  }

  ngAfterViewInit() {
    if (
      [
        "deleteUser",
        "deactivateUser",
        "reactivateUser",
        "resendInvite",
        "resetPassword",
      ].includes(this.action) &&
      this.isBulkOperation
    ) {
      this.dataSource.paginator = this.paginator;
    }
  }

  get isUserInvite(): boolean {
    return this.user?.is_invite;
  }

  get isGlobalOrVengresoAdmin(): boolean {
    return ["Global Admin", "Vengreso Admin"].includes(
      this.authService.getUserRole()
    );
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole() === "Vengreso Admin";
  }

  setConfirmAssignRole(value: boolean) {
    if (value) {
      this.confirmAssignRole = true;
      this.dialogRef.componentInstance.dialogRef.addPanelClass(
        "portal-admin-confirmation"
      );
    } else {
      this.dialogRef.componentInstance.dialogRef.removePanelClass(
        "portal-admin-confirmation"
      );
      this.confirmAssignRole = false;
    }
  }

  setConfirmAssignMoveTo(value: boolean) {
    if (value) {
      this.confirmAssignMoveTo = true;
      this.dialogRef.componentInstance.dialogRef.addPanelClass(
        "portal-admin-confirmation"
      );
    } else {
      this.dialogRef.componentInstance.dialogRef.removePanelClass(
        "portal-admin-confirmation"
      );
      this.confirmAssignMoveTo = false;
    }
  }

  assignRole() {
    if (!this.confirmAssignRole) {
      this.setConfirmAssignRole(true);
      return;
    }

    let data = {
      role_name: this.role.name,
      groups: this.assignRoleForm.value.group,
    };

    let request: Observable<Response>;

    if (this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession) {
      request = this.apiService.assignRole(this.user.id, data);
    } else {
      request = this.adminApiService.assignRole(this.user.id, data);
    }

    request
      .pipe(
        catchError((error) => {
          if (error.status === 409) {
            let message = `Select a new admin to replace ${this.user?.first_name} ${this.user?.last_name} before a User role can be assigned.`;

            this.notification.toastWithConfig(message, null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["blue-notification"],
            });

            let dialogRef = this.dialog.openDialogComponent(
              SelectNewGlobalAdminComponent,
              {
                data: this.user,
                users: this.users,
                role: this.role,
                confirmAssignRole: this.confirmAssignRole,
                downgradeRole: this.downgradeRole,
                class: "portal-admin",
              },
              "500px"
            );

            dialogRef.afterClosed().subscribe((updatedUser: any) => {
              this.dialogRef.close(updatedUser);
            });

            return of({ error: true, message: message });
          } else {
            let message = "An error occurred while assigning the role";
            if (
              error.error &&
              error.error.result &&
              error.error.result.message
            ) {
              message = error.error.result.message;
            }
            // Show error notification
            this.notification.toastWithConfig(message, null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["red-notification"],
            });
            // Return an observable with a user-facing error message
            return of({ error: true, message: message });
          }
        })
      )
      .subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          this.setConfirmAssignRole(false);
          this.dialogRef.close(response.result.user);
          this.notification.toastWithConfig(
            "New role assigned successfully",
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            }
          );
        }
      });
  }

  cancelAsignTo(): void {
    if (this.confirmAssignRole && this.role.name === "Group Admin") {
      this.setConfirmAssignRole(false);
      return;
    }
    this.close();
  }

  deactivate(user: any): void {
    if (this.authService.getUserDetail().id === user.id) {
      this.notification.toastWithConfig(
        "Not allowed to deactivate self",
        null,
        {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["red-notification"],
        }
      );
      return;
    }
    this.adminApiService.deactivateUser(user.id).subscribe({
      next: (response: any) => {
        this.notification.toastWithConfig(
          "User deactivated successfully",
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          }
        );
        this.close([user.id]);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  editUser(user_id) {
    if (this.editProfileStepOne) {
      this.editMoveToStep(2);
      return;
    }

    if (this.editProfileStepTwo) {
      this.editMoveToStep(3);
      return;
    }

    const data = this.editUserForm.value;

    let service: any;

    if (this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession) {
      service = this.apiService;
    } else {
      service = this.adminApiService;
    }

    const isInvite = this.user.is_invite;

    service.updateUser(user_id, data, isInvite).subscribe((response: any) => {
      if (response?.result?.success) {
        this.notification.toastWithConfig(
          "User profile updated successfully",
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );

        this.dialogRef.close(response.result.user);
      } // else show error message
    });
  }

  editMoveToStep(step: number) {
    switch (step) {
      case 1:
        this.editProfileStepOne = true;
        this.editProfileStepTwo = false;
        this.editProfileStepThree = false;
        this.dialogRef.componentInstance.dialogRef.removePanelClass(
          "portal-admin-confirmation"
        );
        this.editUserForm.reset(this.initialFormValues);
      break;
        break;
      case 2:
        this.editProfileStepOne = false;
        this.editProfileStepTwo = true;
        this.editProfileStepThree = false;
        this.dialogRef.componentInstance.dialogRef.removePanelClass(
          "portal-admin-confirmation"
        );
        break;
      case 3:
        this.editProfileStepOne = false;
        this.editProfileStepTwo = false;
        this.editProfileStepThree = true;
        this.dialogRef.componentInstance.dialogRef.addPanelClass(
          "portal-admin-confirmation"
        );
        break;

      default:
        this.editProfileStepOne = true;
        this.editProfileStepTwo = false;
        this.editProfileStepThree = false;
        this.dialogRef.componentInstance.dialogRef.removePanelClass(
          "portal-admin-confirmation"
        );
        break;
    }

    if (!this.isGlobalOrVengresoAdmin) {
      return;
    }

    Object.keys(this.editUserForm.controls).forEach((key) => {
      let control = this.editUserForm.get(key);
      if (this.editProfileStepTwo) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  resetPassword(): void {
    let request: Observable<Response>;

    if (this.isBulkOperation) {
      const apiData = this.data.data
        .filter((user: any) => user.status === "Active")
        .map((user: any) => user.id);
      request = this.adminApiService.resetPasswords({ users: apiData });
    } else {
      request = this.adminApiService.resetPassword(this.user.id);
    }

    if (this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession) {
      const apiData = this.isBulkOperation
        ? this.data.data
            .filter((user: any) => user.status === "Active")
            .map((user: any) => user.id)
        : [this.user.id];

      request = this.apiService.resetPassword({ user_ids: apiData });
    }

    request.subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        const plural = this.isBulkOperation ? "s" : "";
        const notifyMessage =
          "Password reset successfully. User" +
          plural +
          " will get the link to update their password.";
        this.notification.toastWithConfig(notifyMessage, null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["green-notification"],
        });
        this.close(true);
      } // else show error message
    });
  }

  private setConfirmMoveTo(value: boolean) {
    if (value) {
      this.confirmMoveTo = true;
      this.dialogRef.componentInstance.dialogRef.addPanelClass(
        this.isBulkOperation ? "bulk-move-users-to-group" : "portal-admin-confirmation"
      );
    } else {
      this.dialogRef.componentInstance.dialogRef.removePanelClass(
        this.isBulkOperation ? "bulk-move-users-to-group" : "portal-admin-confirmation"
      );
      this.confirmMoveTo = false;
    }
  }

  moveTo(): void {
    if (!this.confirmMoveTo) {
      this.setConfirmMoveTo(true);
      if (this.isBulkOperation) {
        setTimeout(() => (this.dataSource.paginator = this.paginator));
      }
      return;
    }

    let group_id = this.moveToForm.value.subgroup
      ? this.moveToForm.value.subgroup
      : this.moveToForm.value.group;
    let request: Observable<Response>;

    if (this.isBulkOperation) {
      const apiData = this.dataSource.data.map((user: any) => user.id);
      request = this.adminApiService.moveUsersGroups({
        users: apiData,
        to_group_id: group_id,
      });
    } else {
      const isInvite = this.user.is_invite;
      
      request = this.adminApiService.moveUserGroup(this.user.id, group_id, isInvite);
    }

    request.subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        const notifyMessage = `User profile${
          this.isBulkOperation ? "s" : ""
        } updated successfully.`;

        const result = response.result;
        const users = this.isBulkOperation ? result.users : [result.user];

        this.close(users);
        this.notification.toastWithConfig(notifyMessage, null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["green-notification"],
        });
      } // else show error message
    });
  }

  addNewGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent,
      { action: "add-group", class: "portal-admin" },
      "450px"
    );

    dialogHandle.afterClosed().subscribe((group: any) => {
      this.groups = this.groups.concat({
        value: group.group.id,
        displayValue: group.group.name,
        subgroups: [],
      });
    });
  }

  addNewSubGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent,
      {
        action: "add-subgroup",
        groupId: this.groupCtrl.value,
        class: "portal-admin",
      },
      "450px"
    );

    dialogHandle.afterClosed().subscribe((subgroup: any) => {
      this.subgroups = this.subgroups.concat({
        value: subgroup.id,
        displayValue: subgroup.name,
      });
    });
  }

  cancelMoveTo(): void {
    if (this.confirmMoveTo) {
      this.setConfirmMoveTo(false);
      return;
    }

    this.close();
  }

  onSelectionChange(id: string): void {
    let company = this.companies.find((company: any) => company.id === id);

    this.selectedCompanyName = company?.name;
    this.plans = company?.plans ?? this.plans;
  }

  assignMoveTo(): void {
    if (!this.confirmAssignMoveTo) {
      this.setConfirmAssignMoveTo(true);
      return;
    }

    const user_ids = this.isBulkOperation
      ? this.data.data.map((user: any) => user.id)
      : [this.user.id];

    this.apiService
      .assignMoveTo({ ...this.assignMoveToForm.value, user_ids })
      .subscribe({
        next: (response: any) => {
          if (response.result.success) {
            this.close(response.result.data);
            const notifyMessage = `User profile${
              this.isBulkOperation ? "s" : ""
            } updated successfully.`;

            this.notification.toastWithConfig(notifyMessage, null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            });
          }
        },
        error: (err) => console.log(err),
      });
  }

  cancelAssignMoveTo(): void {
    if (this.confirmAssignMoveTo) {
      this.setConfirmAssignMoveTo(false);
      return;
    }

    this.close();
  }

  deleteUser(): void {
    let request: Observable<any>;

    if (this.isBulkOperation) {
      const apiData = this.dataSource
        .connect()
        .value.map((user: any) => user.id);

      if (
        this.isVengresoAdmin &&
        !this.authService.onCompanyMasqueradeSession
      ) {
        request = this.apiService.deleteUsers({ users: apiData });
      } else {
        request = this.adminApiService.deleteUsers({ users: apiData });
      }
    } else {
      if (
        this.isVengresoAdmin &&
        !this.authService.onCompanyMasqueradeSession
      ) {
        request = this.apiService.deleteUsers({ users: [this.user.id] });
      } else {
        request = this.adminApiService.deleteUser(this.user.id);
      }
    }

    request
      .pipe(
        catchError((error) => {
          let message = "An error occurred while deleting the user(s)";
          if (error.error && error.error.result && error.error.result.message) {
            message = error.error.result.message;
          }
          // Show error notification
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          // Return an observable with a user-facing error message
          return of({ error: true, message: message });
        })
      )
      .subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          let notifyMessage = "User deleted successfully.";

          if (this.isBulkOperation) {
            notifyMessage = "Users deleted successfully.";
          } else if (this.user.first_name || this.user.last_name) {
            notifyMessage = `${this.user?.first_name} ${this.user?.last_name} deleted successfully`;
          }

          this.notification.toastWithConfig(notifyMessage, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          this.dialogRef.close(
            this.isBulkOperation
              ? this.dataSource.connect().value.map((user: any) => user.id)
              : [this.user.id]
          );
        } else {
          this.dialogRef.close();
        }
      });
  }

  deactivateUser(): void {
    let request: Observable<any>;
    let apiData = [];
    if (this.isBulkOperation) {
      apiData = this.dataSource.data
        .filter((user: any) => user.status !== "Invited")
        .map((user: any) => user.id);
      request = this.adminApiService.deactivateUsers({ users: apiData });
    } else {
      request = this.adminApiService.deactivateUser(this.user.id);
    }

    if (this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession) {
      apiData = this.isBulkOperation
        ? this.data.data
            .filter((user: any) => user.status === "Active")
            .map((user: any) => user.id)
        : [this.user.id];

      request = this.apiService.deactivateUsers({ user_ids: apiData });
    }

    request
      .pipe(
        catchError((error) => {
          let message = "An error occurred while deactivating the user(s)";
          if (error.error && error.error.result && error.error.result.message) {
            message = error.error.result.message;
          }
          // Show error notification
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          // Return an observable with a user-facing error message
          return of({ error: true, message: message });
        })
      )
      .subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          const message = this.isBulkOperation
            ? "Users deactivated successfully"
            : `${this.user.first_name} ${this.user.last_name} deactivated successfully`;
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          this.close(this.isBulkOperation ? apiData : [this.user.id]);
        } else {
          this.close();
        }
      });
  }

  onAssignLicense(event?: any): void {
    this.plans.forEach((plan: any) => {
      let assignedLicenses =
        this.dataSource.data.filter((user: any) => user.plan === plan.value)
          ?.length ?? 0;
      let unassignedLicenses = plan.totalLicenses - assignedLicenses;
      plan.licensesAvailable = unassignedLicenses > 0 ? unassignedLicenses : 0;
    });
  }

  planLabel(plan: string): string {
    return this.plans.find((planObj: any) => planObj.value === plan)?.label;
  }

  get emailLink(): string {
    const { first_name, last_name, company } = this.authService.getUserDetail();
    const body: string = `
    "Hello FlyMSG team"
    I'd like to inquire about adding on _______ more licenses to my account.
    Account name: ${company.name}
    Administrator Requesting: ${first_name} ${last_name}`;

    return `mailto:sales@vengreso.com?subject=${encodeURIComponent(
      "Additional License Inquiry"
    )}&body=${encodeURIComponent(body)}`;
  }

  reactivateUser(): void {
    let apiData = [];
    let apiDataWithPlan = [];
    if (this.isBulkOperation) {
      if (this.dataSource.data.some((user: any) => user.plan == null)) {
        this.notification.toast(
          "Please assign a plan to all users or buy more license",
          "OK"
        );
        return;
      }
      apiData = this.dataSource.data
        .filter((user: any) => user.status !== "Active")
        .map((user: any) => user.id);
      apiDataWithPlan = this.dataSource.data
        .filter((user: any) => user.status !== "Active" && !!user.plan)
        .map((user: any) => ({ user_id: user.id, plan: user.plan }));
    } else {
      apiData = [this.user.id];
    }

    this.adminApiService
      .reactivateUsers({ users: apiData, usersWithPlan: apiDataWithPlan })
      .pipe(
        catchError((error) => {
          let message = "An error occurred while reactivating the user(s)";
          if (error.error && error.error.result && error.error.result.message) {
            message = error.error.result.message;
          }
          // Show error notification
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          // Return an observable with a user-facing error message
          return of({ error: true, message: message });
        })
      )
      .subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          const message = this.isBulkOperation
            ? "Users reactivated successfully"
            : `${this.user.first_name} ${this.user.last_name} reactivated successfully`;
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
          this.close(this.isBulkOperation ? apiData : [this.user.id]);
        } else {
          this.close();
        }
      });
  }

  resendInvite(): void {
    let request: Observable<any>;

    if (this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession) {
      const apiData = this.dataSource.data
        .filter((user: any) => user.status === "Invited")
        .map((user: any) => user.email);
      request = this.apiService.resendUserInvitation({ emails: apiData });
    } else {
      const apiData = this.dataSource.data
        .filter((user: any) => user.status === "Invited")
        .map((user: any) => user.id);
      request = this.adminApiService.resendUserInvitations({ users: apiData });
    }
    request.subscribe({
      next: () => {
        this.notification.toastWithConfig(
          "Invite emails resent successfully",
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );

        this.close(true);
      },
      error: (error) => console.log(error),
    });
  }

  removeInConfirmationView(element: any): void {
    this.dataSource.data = this.dataSource.data.filter((user: any) => user.email !== element.email);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.dataSource.paginator = this.paginator;

    if (this.dataSource.data.length === 0) {
      this.close();
    }
  }

  close(data?: any): void {
    if (this.action === "editProfile") {
      if (this.editProfileStepThree) {
        this.editMoveToStep(2);
        return;
      }

      if (this.editProfileStepTwo) {
        this.editMoveToStep(1);
        return;
      }
    }

    this.dialogRef.close(data);
  }
}
