import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../core/core-services/services/dataservices/data.service";

@Injectable()
export class TemplatesResolver implements Resolve<any> {
    constructor(
        private dataService: DataService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        if (route.params.categoryName) {
            return this.dataService.get({
                url: "v1/user/templatebyname?skip=0&limit=10&order_by=shortcut,asc" +
                (route.params.categoryName === "ViewAll" ? "" : "&category_name=" + route.params.categoryName) + 
                (route.params.subCategoryName? "&sub_category_name=" + route.params.subCategoryName : "") +
                (route.params.subSubCategoryName? "&sub_sub_category_name=" + route.params.subSubCategoryName : ""),
                isLoader: true
            });
        }
        return this.dataService.get({
            url: "v1/user/template?skip=0&limit=10&order_by=shortcut,asc" +
            (route.params.categoryId === "all" ? "" : "&category_id=" + route.params.categoryId),
            isLoader: true
        });
    }
}
