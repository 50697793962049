import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';

import { NavigationService } from 'src/app/core/core-services/services/navigation.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { SharedDataService } from 'src/app/core/core-services/services/shared-data.service';

import {
  FLYSHARE_ALL_ROUTE
} from "src/app/vengreso/share/flyshare/flyshare.config";

@Component({
  selector: 'app-fly-cut-notify',
  templateUrl: './fly-cut-notify.component.html',
  styleUrls: ['./fly-cut-notify.component.scss']
})
export class FlyCutNotifyComponent implements OnInit {

  name: string;
  shortcutIds: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : { flyshareId: string, flysharerName: string, shortcutIds: string[] },
    private dataService: DataService,
    public dialogRef: MatDialogRef<FlyCutNotifyComponent>,
    private sharedData: SharedDataService,
    private notification: NotificationService,
    private nav: NavigationService
  ) { 
    const flysharerFirstName = this.sharedData.getAttribute('flysharerFirstName');
    const flysharerLastName = this.sharedData.getAttribute('flysharerLastName');

    this.name = `${flysharerFirstName} ${flysharerLastName}`.trim() ?? data.flysharerName;
  }

  ngOnInit(): void {
  }

  close(data?: string): void {
    this.dialogRef.close(data);
  }

  addToMyFlyshares(): void {
    this.dataService.post({url: `v1/flyshares/accept/${this.data.flyshareId}`, isLoader: true}).subscribe({
      next: (response: any) => {
        this.close('add');
        this.sharedData.setAttribute('shortcutIds', this.data.shortcutIds)
        this.nav.navigateTo(["flyshare/"+FLYSHARE_ALL_ROUTE]);
      },
      error: (error) => {
        this.notification.toast('Something went wrong, Contact Administrator', 'Ok', 2000)
      }
    });
  }

}
