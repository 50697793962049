import { Component, OnInit, Inject, } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { findIndex } from "lodash";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";

@Component({
  selector: "app-confirm-version",
  templateUrl: "./confirm-version-dialog.component.html",
  styleUrls: ["./confirm-version-dialog.component.scss"],
})
export class ConfirmVersionComponent implements OnInit {

  constructor(
    config: NgbPopoverConfig,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmVersionComponent>,
    public dialog: DialogService,
    private extensionService: ExtensionService,
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  makeCurrent(): void {
    const shortcutId =  this.data?.self?.data?.id || this.data?.self?.data?.self?.data?.id || this.data?.self?.data?.shortcut?.id || this.data?.self?.data?.self?.data?.shortcut?.id
    const data = {
      versionId: this.data.element.version_id,
      shortcutId
    }
    this.dataService.post({ url: "v1/user/shortcut_version/rollback", data }).subscribe(
      (response: any) => {
        if (response && response.result) {
          // close the modal
          this.extensionService.sendUpdateMessage();
          this.dialogRef.close();
          
          //refresh the updates
          if (this.data.self.getAllVersions) {
            //update list of versions modal
            this.data.self.getAllVersions()
            //update flycuts screen
            const index = findIndex(this.data.self.data.self.shortcuts, { id: shortcutId });
            this.data.self.data.self.shortcuts[index].shortcut = this.data.element.shortcut
            this.data.self.data.self.shortcuts[index].text = this.data.element.text
            this.data.self.data.self.shortcuts[index].title = this.data.element.title
            this.data.self.data.self.shortcuts[index].html = this.data.element.html
            this.data.self.data.self.shortcuts[index].first_line = this.data.element.firstLine
            this.data.self.data.self.shortcuts[index].sub_categories_lv1 = this.data.element.sub_categories_lv1
            this.data.self.data.self.shortcuts[index].sub_categories_lv2 = this.data.element.sub_categories_lv2
          } else {
            this.data.self.dialogRef.close();
            //update list of versions modal
            this.data.self.data.self.getAllVersions()
            //update flycuts screen
            const index = findIndex(this.data.self.data.self.data.self.shortcuts, { id: shortcutId });
            this.data.self.data.self.data.self.shortcuts[index].shortcut = this.data.element.shortcut
            this.data.self.data.self.data.self.shortcuts[index].text = this.data.element.text
            this.data.self.data.self.data.self.shortcuts[index].title = this.data.element.title
            this.data.self.data.self.data.self.shortcuts[index].html = this.data.element.html
            this.data.self.data.self.data.self.shortcuts[index].first_line = this.data.element.firstLine
            this.data.self.data.self.data.self.shortcuts[index].sub_categories_lv1 = this.data.element.sub_categories_lv1
            this.data.self.data.self.data.self.shortcuts[index].sub_categories_lv2 = this.data.element.sub_categories_lv2
          }
          
        }
      },
      () => {}
    );
  }
}
