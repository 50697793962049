import { Component, Input } from "@angular/core";

@Component({
  selector: "loading-chart",
  templateUrl: "./loading-chart.component.html",
  styleUrls: ["./loading-chart.component.scss"],
})
export class LoadingChartComponent {
  @Input() width: number = 420;
  @Input() height: number = 220;
  @Input() bars: number;
  barsHeight: number[] = []

  ngOnInit(): void {
    this.barsHeight = Array(this.bars).fill(0).map(() => this.getRandomMultipleOf10(this.height));
  }
  getRandomMultipleOf10(base: number) {
    const randomNumber = Math.floor(Math.random() * (base/10));
    return randomNumber * 10;
  }
}
