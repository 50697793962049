import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { LoadingService } from 'src/app/core/core-services/services/loader.service';
import { NavigationService } from 'src/app/core/core-services/services/navigation.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { PrimaryMethodConfirmationComponent } from './primary-method-confirmation/primary-method-confirmation.component';
import { RemovalConfirmationComponent } from './removal-confirmation/removal-confirmation.component';

@Component({
  selector: 'app-payment-method-details',
  templateUrl: './payment-method-details.component.html',
  styleUrls: ['./payment-method-details.component.css']
})
export class PaymentMethodDetailsComponent implements OnInit {

  paymentMethods: any[] = [];
  
  constructor(
    public dialog: DialogService,
    private dataService: DataService,
    private router: Router,
    public nav:NavigationService,
    private loadingService: LoadingService,
    private notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getPaymentMethods();
  }

  getPaymentMethods(): void {
    this.loadingService.start();
    this.dataService.get({ url: "v1/user/payment/details", isLoader: false })
    .subscribe({
      next: (response: any) => {
        const result = response?.result;
        const paymentMethods = result?.payment_methods;

        if (!paymentMethods || paymentMethods.length < 1) {
          return [];
        }

        this.paymentMethods = paymentMethods.map((method: any, idx: number) => {
          method.id === result?.default_payment_method?.id ?
            method.isPrimary = true :
            method.isPrimary = false;

          method.cardExpiry = `Expiry 
          ${method.card.exp_month < 10 ? '0'+method.card.exp_month : method.card.exp_month}
          /${method.card.exp_year.toString().slice(-2)}`;

          method.idx = idx;

          method.cardLastFour = method.card.last4;

          method.brand = method.card.brand;
          
          return method;
        }).sort((a: any, b: any) => 
          a.isPrimary === true ? -1 : 1
        );

        this.loadingService.stop();
      },
      error: () => {
       this.loadingService.stop();
       this.notification.toast('Something went Wrong. Contact Technical Administrator', 'Ok');
      }
    });
  }

  addPaymentmethod(): void {
    this.router.navigate(["add-payment-method"]);
  }

  makePrimary(paymentMethod: object): void {
    const ref = this.dialog.openDialogComponent(PrimaryMethodConfirmationComponent, {paymentMethod}, "500px");

    ref.afterClosed().subscribe((data: string) => {
      if (data === 'success') this.getPaymentMethods();
    })
  }

  remove(paymentMethod: object): void {

    const ref = this.dialog.openDialogComponent(RemovalConfirmationComponent, {paymentMethod}, "500px");

    ref.afterClosed().subscribe((data: string) => {
      if (data === 'success') this.getPaymentMethods();
    });
  }

}
