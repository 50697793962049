import { Injectable } from "@angular/core";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { map } from "rxjs/operators";
import { LoadingService } from "src/app/core/core-services/services/loader.service";

@Injectable({
  providedIn: "root", // This makes the service available application-wide
})
export class AdminApiService {
  constructor(private dataService: DataService, private loadingService: LoadingService) {}

  private getUrlAppends(datePeriod?: any, generalFilter?: string): string{
    let urlAppends = datePeriod ? `?fromDate=${datePeriod.fromDate}&toDate=${datePeriod.toDate}` : '';
    if(generalFilter){
      urlAppends = urlAppends.length > 0 ? `${urlAppends}&${generalFilter}` : `?${generalFilter}`;
    }

    return urlAppends
  }

  getUsers(with_deactivated: boolean = false, isLoader = false) {
    if(isLoader){
      this.loadingService.start();
    }
    return this.dataService.get({
      url: "v1/admin-center/users?deactivated=" + with_deactivated,
      isLoader
    }).pipe(
      map((res: any) => {
        if(isLoader){
          this.loadingService.stop();
        }
        return res;
      })
    );
  }

  getGroups(isLoader = false) {
    if(isLoader){
      this.loadingService.start();
    }
    return this.dataService.get({
      url: "v1/admin-center/groups",
      isLoader
    }).pipe(
      map((res: any) => {
        if(isLoader){
          this.loadingService.stop();
        }
        return res;
      })
    );
  }

  search(search_term: string) {
    return this.dataService.get({
      url: `v1/admin-center/search?search_term=${search_term}`,
    });
  }

  /**
   * user_id = db id
   * data = {
      "email": "janiceb@heffins.com",
      "first_name": "E. Ambrose",
      "last_name": "Mwaka"
    }
   */
  updateUser(user_id: string, data: any) {
    return this.dataService.put({
      url: `v1/admin-center/users/${user_id}`,
      data: data,
    });
  }

  getCompanyLicences() {
    return this.dataService.get({
      url: "v1/admin-center/company/licences",
    });
  }

  /**
   * data = {
      "first_name": "Ok",
      "last_name": "Ok",
      "email": "okok@gmail.com"
    }
   */
  addUserManually(data: any) {
    return this.dataService.post({
      url: "v1/admin-center/users/add-user-manually",
      data: data,
    });
  }

  /**
   * Adds users by their email addresses
   *
  * data = {
      "users": [
        {
            "email": "amsxbyte@gmail.com",
            "plan_id": "62db349e18621419647000d2"
        },
        {
            "email": "ambrosemwaka@gmail.com",
            "plan_id": "64a73dbc4b48b0d842058075"
        }
      ],
      "group_id": "",
      "subgroup_id": ""
    }
   *
   */
  addUserByEmail(data: any) {
    return this.dataService.post({
      url: "v1/admin-center/users/add-user-by-emails",
      data: data,
    });
  }

  validateUserEmailUniqueness(email: string) {
    return this.dataService.get({
      url: "v1/admin-center/users/exists?email="+email,
    });
  }

  deactivateUser(user_id: string) {
    return this.dataService.put({
      url: `v1/admin-center/users/${user_id}/deactivate`,
    });
  }

  resetPassword(user_id: string) {
    return this.dataService.post({
      url: `v1/admin-center/users/${user_id}/reset-password`,
    });
  }

  deleteUser(user_id: string) {
    return this.dataService.delete({
      url: `v1/admin-center/users/${user_id}/delete`,
    });
  }

  /**
   * data = {
      "to_group_id": "665ecd70f55cb17b6e0296f6"
    }
   *
   * @param   {string}  user_id User db id
   * @param   {string}  group_id CompanyGroup DB id
   */
  moveUserGroup(user_id: string, group_id: string) {
    return this.dataService.put({
      url: `v1/admin-center/users/${user_id}/move-group`,
      data: {
        to_group_id: group_id,
      },
    });
  }

  /**
   * data = {
        "email": "amsxbyte@gmail.com"
    }
   *
   */
  resendUserInvitation(data: any) {
    return this.dataService.post({
      url: `v1/admin-center/users/resend-user-invitation`,
      data: data,
    });
  }

  /**
   * Assigns role to user
   * 
   * data = {
      "role_name": "Group Admin",
      "groups": [
          "665ecd70f55cb17b6e0296f6",
          "665ecd70f55cb17b6e0296f4"
        ]
      }
   *
   */
  assignRole(user_id: string, data: any) {
    return this.dataService.put({
      url: `v1/admin-center/users/${user_id}/assign-role`,
      data: data,
    });
  }

  /**
   * Checks if group or subgroup name is unique
   * 
   * @param name 
   * @returns Observable<Response>
   */
  validateGroupSubgroupUniqueness(name: string) {
    return this.dataService.get({
      url: "v1/admin-center/groups/exists?name="+name,
    });
  }

  /**
   * data = {
      "name": "Mwaka Group"
    }
   *
   */
  updateGroup(group_id: string, data: any) {
    return this.dataService.put({
      url: `v1/admin-center/groups/${group_id}`,
      data: data,
    });
  }


  /**
   * {
    "name": "YaoYou Group",
    "users": [
        "5f8860922e8ef41fc0070071",
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089"
      ]
    }
   *
   */
  createGroup(data: any) {
    return this.dataService.post({
      url: `v1/admin-center/groups`,
      data: data,
    });
  }

  deleteGroup(group_id: string) {
    return this.dataService.delete({
      url: `v1/admin-center/groups/${group_id}`,
      isLoader: false
    });
  }


  
  /**
   * Adds users to a group.
   * 
   * data = {
      "users": [
          "5f8860922e8ef41fc0070071",
          "5f8873ec2e8ef41fc007007f",
          "5f88b40f2e8ef41fc0070089"
        ]
    }
   *
   * @param {string} group_id - The ID of the group to add users to.
   * @param {any} data - The data containing the users ids to add.
   */
  addUsersToGroup(group_id: string, data: any) {
    return this.dataService.put({
      url: `v1/admin-center/groups/${group_id}/add-users`,
      data: data,
      isLoader: false
    });
  }

  /**
   * Remove users from a group.
   * 
   * data = {
      "users": [
          "5f8860922e8ef41fc0070071",
          "5f8873ec2e8ef41fc007007f",
          "5f88b40f2e8ef41fc0070089"
        ]
    }
   *
   * @param {string} group_id - The ID of the group to remove users from.
   * @param {any} data - The data containing the users ids to remove.
   */
    removeUsersFromGroup(group_id: string, data: any) {
      return this.dataService.put({
        url: `v1/admin-center/groups/${group_id}/remove-users`,
        data: data,
      });
    }

  /**
   * Creates a subgroup under a specified group.
   * 
   * data = {
      "name": "YouiYouSub",
      "users": [
        "5f8860922e8ef41fc0070071",
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089"
      ]
    }
   *
   * @param {string} group_id - The ID of the parent group.
   * @param {any} data
   */
  createSubgroup(group_id: string, data: any, isLoader = true) {
    return this.dataService.post({
      url: `v1/admin-center/groups/${group_id}/subgroup`,
      data: data,
      isLoader
    });
  }

  /**
   * Bulk delete users by passing an array of DB ids
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
  deleteUsers(data: any) {
    return this.dataService.post({
      url: `v1/admin-center/users/bulk/delete-users`,
      data: data,
    });
  }

  /**
   * Bulk deactiavte users by passing an array of User ids
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
    deactivateUsers(data: any) {
      return this.dataService.post({
        url: `v1/admin-center/users/bulk/deactivate-users`,
        data: data,
      });
    }

  /**
   * Bulk reactiavte users by passing an array of User ids
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
    reactivateUsers(data: any) {
      return this.dataService.post({
        url: `v1/admin-center/users/bulk/reactivate-users`,
        data: data,
      });
    }

  /**
   * Resets the passwords of multiple users.
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param {any} data - An object containing an array of user IDs.
   * @return {Promise<any>} A promise that resolves with the response from the server.
   */
  resetPasswords(data: any) {
    return this.dataService.post({
      url: `v1/admin-center/users/bulk/reset-passwords`,
      data: data,
    });
  }

  /**
   * Sends invitations to users in bulk.
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param {any} data - An object containing an array of user IDs.
   * @return {Promise<any>} A promise that resolves with the response from the server.
   */
  resendUserInvitations(data: any) {
    return this.dataService.post({
      url: `v1/admin-center/users/bulk/resend-user-invitations`,
      data: data,
    });
  }

  /**
   * Moves user groups in bulk.
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ],
      "to_group_id": "665ecd70f55cb17b6e0296f6"
    }
   *
   * @param {any} data - The data containing the user groups to be moved.
   * @return {Promise<any>} A promise that resolves with the response from the server.
   */
  moveUsersGroups(data: any) {
    return this.dataService.put({
      url: `v1/admin-center/users/bulk/move-groups`,
      data: data,
    });
  }

  exportCSV(with_deactivated: boolean = false, selected_users?: any) {
    const urlAppends = selected_users ? `&users=${selected_users}` : '';

    return this.dataService.download({
      url: "v1/admin-center/users/export-csv?deactivated=" + with_deactivated + urlAppends,
    }, "users.csv");
  }

  reportingGetLicences(){
    return this.dataService.get({
      url: "v1/admin-center/reporting/licences"
    });
  }

  reportingGetROIspotlight(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);
    return this.dataService.get({
      url: `v1/admin-center/reporting/roi-spotlight${urlAppends}`
    });
  }

  reportingGetTotalCharactersSpotlight(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);
    return this.dataService.get({
      url: `v1/admin-center/reporting/total-characters-typed-spotlight${urlAppends}`
    });
  }

  reportingGetProductivitySpotlight(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);
    return this.dataService.get({
      url: `v1/admin-center/reporting/productivity-spotlight${urlAppends}`
    });
  }

  reportingGetFMScoachLevelSpotlight(){
    return this.dataService.get({
      url: "v1/admin-center/reporting/fms-coach-level-spotlight"
    });
  }

  reportingGetFlyCutsCreated(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flycuts-created${urlAppends}`
    });
  }

  reportingGetFlyPlatesAdded(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flyplates-added${urlAppends}`
    });
  }

  reportingGetFlyEngageAI(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flyengage-ai${urlAppends}`
    });
  }

  reportingGetFlyPostAI(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flypost-ai${urlAppends}`
    });
  }

  reportingExtensionUsage(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/extension-usage${urlAppends}`
    });
  }

  flycutsTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flycuts-created-top-users${urlAppends}`
    }); 
  }

  flyplatesTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flyplates-added-top-users${urlAppends}`
    }); 
  }

  flyengageTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flyengage-ai-used-top-users${urlAppends}`
    }); 
  }

  flypostTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/flypost-ai-used-top-users${urlAppends}`
    }); 
  }

  charactersTypedSavingTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/characters-typed-saving-top-users${urlAppends}`
    }); 
  }

  timeSavingTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/time-saving-top-users${urlAppends}`
    }); 
  }

  costSavingTopUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/cost-saving-top-users${urlAppends}`
    }); 
  }

  reportingActiveUsers(datePeriod?:{fromDate:string, toDate:string}, generalFilter?: string){
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);

    return this.dataService.get({
      url: `v1/admin-center/reporting/active-users${urlAppends}`
    });
  }

  getUserSavedColumns() {
    return this.dataService.get({
      url: `v1/admin-center/reporting/columns`
    });
  }

  saveUserSavedColumns(data: any) {
    return this.dataService.post({
      url: `v1/admin-center/reporting/columns`,
      data: data
    });
  }

  getUserDetails(datePeriod?:{fromDate:string, toDate:string}){
    const urlAppends = datePeriod ? `?fromDate=${datePeriod.fromDate}&toDate=${datePeriod.toDate}` : '';

    return this.dataService.get({
      url: `v1/admin-center/reporting/users/details${urlAppends}`
    });
  }

  exportCSVReports(filter: string, report: string) {
    let filterDateRange = "";
    if(report == "overview"){
      let now = (new Date).toDateString();
      let date12MonthsAgo = new Date((new Date).setMonth((new Date).getMonth() - 12)).toDateString();
      filterDateRange = ` - ${date12MonthsAgo} - ${now}`;
    }

    if(report == "usage" || report == "details"){
      if(filter == ""){
        let now = (new Date).toDateString();
        let date12MonthsAgo = new Date((new Date).setMonth((new Date).getMonth() - 12)).toDateString();
        filterDateRange = ` - ${date12MonthsAgo} - ${now}`;
      } else {
        let dateRange = filter.split("&")
        filterDateRange = ` - ${dateRange[0]} - ${dateRange[1]}`;
      }
    }

    const urlAppends = !!filter.trim() ? `?${filter}` : '';
    const fileName = `${report}-report${filterDateRange}.csv`;
    return this.dataService.download({
      url: "v1/admin-center/reporting/"+ report +"/export" + urlAppends,
    }, fileName);
  }
}
