import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/core-services/services/notification.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SuperAdminApiService } from "../../services/super-admin-api.service";

@Component({
  selector: 'app-reactivate',
  templateUrl: './reactivate.component.html',
  styleUrls: ['./reactivate.component.scss']
})
export class ReactivateComponent implements OnInit {
  user: any;
  reactivateForm: FormGroup;
  companies: any[] = [];
  plans: any[] = [
    {
      value: "starter-yearly",
      label: "Starter",
      licensesAvailable: 0
    },
    {
      value: "growth-yearly",
      label: "Growth",
      licensesAvailable: 0
    },
    {
      value: "sales-pro-yearly",
      label: "Sales Pro",
      licensesAvailable: 0
    },
    {
      value: "pro-plan-teams-smb",
      label: "Sales Pro Teams",
      licensesAvailable: 0
    },
    {
      value: "freemium",
      label: "Freemium",
      licensesAvailable: "Unlimited"
    }
  ];
  isBulkOperation: boolean = false;
  confirmationStep: boolean = false;

  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["select","name", "status", "actions"];

  constructor(
    public dialogRef: MatDialogRef<ReactivateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private notification: NotificationService,
    private apiService: SuperAdminApiService,
    private config: NgbPopoverConfig,
    public dialog: DialogService,
  ) 
  {
    this.user = this.data.data;
    this.isBulkOperation =
      Array.isArray(this.data.data) && this.data.data.length > 0;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    if(this.isBulkOperation){
      this.dataSource = new MatTableDataSource(this.data.data);
    } else {
      const { first_name, last_name, email } = this.user;
        this.reactivateForm = this.fb.group({
          first_name: [
            { value: first_name, disabled: true },
            [Validators.required],
          ],
          last_name: [
            { value: last_name, disabled: true },
            [Validators.required],
          ],
          email: [
            { value: email, disabled: true },
            [Validators.required, Validators.email],
          ],
          plan_identifier: ['', Validators.required,]
        });
    }

    this.apiService.getCompaniesLicenses().subscribe({
      next: (response: any) => {
        if (response.result.success) {
          if (this.isBulkOperation) {
            this.companies = response.result.data;
          } else {
            
            if (this.user.company !== "Not Assigned") {
              this.plans = response.result.data.find((company: any) => company.name === this.user.company)?.plans ?? this.plans;
            }
          }
        }
      },
      error: (err) => console.log(err)
    })
  }

  ngAfterViewInit() {
    if (this.isBulkOperation){
      this.dataSource.paginator = this.paginator;
    }
  }

  reactivateUser(): void {
    if(!this.confirmationStep){
      this.confirmationStep = true;
      return;
    }

    let apiData = [];

    if (this.isBulkOperation){
      if(this.dataSource.data.some((user:any) => user.plan == null)){
        this.notification.toast("Please assign a plan to all users or buy more license", "OK");
        return;
      }

      apiData = this.dataSource.data
                        .filter((user: any) => user.status !== 'Active' && !!user.plan)
                        .map((user: any) => ({id: user.id, plan_identifier: user.plan}));
    } else {
      apiData = [{id: this.user.id, plan_identifier: this.reactivateForm.value.plan_identifier}];
    }

    this.apiService
    .reactivateUsers({users: apiData})
    .subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        const message = this.isBulkOperation ? "Users reactivated successfully" : `${this.user.first_name} ${this.user.last_name} reactivated successfully`;
        this.notification.toastWithConfig(message, null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["red-notification"]
        });
        this.dialogRef.close(apiData);
      } else {
        this.dialogRef.close();
      }
    });
  }

  removeInConfirmationView(element: any): void {
    this.dataSource.data = this.dataSource.connect().value.filter((user: any) => user.email !== element.email);
    this.dataSource.paginator = this.paginator;
  }

  onAssignLicense(event?: any, user?: any): void {
    if(!user){
      this.plans.forEach((plan: any) => {
        let assignedLicenses = this.dataSource.data.filter((user: any) => user.plan === plan.value)?.length ?? 0;
        let unassignedLicenses = plan.totalLicenses - assignedLicenses;
        plan.licensesAvailable = unassignedLicenses > 0 ? unassignedLicenses : 0;
      });

      return;
    }

    this.companies
    .find((company: any) => company.id === user.company_id)?.plans
    .forEach((plan: any) => {
      let assignedLicenses = this.dataSource.data.filter((user: any) => user.plan === plan.value)?.length ?? 0;
      let unassignedLicenses = plan.totalLicenses - assignedLicenses;
      plan.licensesAvailable = unassignedLicenses > 0 ? unassignedLicenses : 0;
    });;
    
  }

  planLabel(plan: string, user?: any): string {
    if(!user){
      return this.plans.find((planObj: any) => planObj.value === plan)?.label;
    } 

    const companyPlans = this.companies.find((company: any) => company.id === user.company_id)?.plans;
    return companyPlans.find((planObj: any) => planObj.value === plan)?.label;
  }

  companyPlans(user: any) {
    return this.companies.find((company: any) => company.id === user.company_id)?.plans;
  }

  close(data?: any): void {
    if(this.confirmationStep){
      this.confirmationStep = false;
      return;
    }

    this.dialogRef.close(data);
  }

}
